import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.less';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/store';
//import SentenceMatcher from './Demo3';

// import { PersistGate } from 'redux-persist/integration/react';
// import { persistStore } from 'redux-persist';

//let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    {/*  <PersistGate persistor={persistor}> */}
    <App />
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root')
);
