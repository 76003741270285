import apiClient from 'utilities/apiClient';

export const dashboardCounts = (payload) => {
  return apiClient.get(apiClient.Urls.dashboardCounts, payload, true);
};

export const pifFdList = (payload) => {
  return apiClient.get(apiClient.Urls.pifFdList, payload, true);
};

export const deleteDraft = (payload) => {
  return apiClient.post(apiClient.Urls.ADMIN.deleteDraft, payload, true);
};

export const pifApiNumbers = (payload) => {
  return apiClient.get(apiClient.Urls.ADMIN.pifApiNumbers, payload, true);
};

export const signPif = (payload) => {
  return apiClient.post(apiClient.Urls.ADMIN.sign, payload, true);
};

export const pifFdNumbers = (payload) => {
  return apiClient.get(apiClient.Urls.ADMIN.pifFdNumbers, payload, true);
};

export const signPifFd = (payload) => {
  return apiClient.post(apiClient.Urls.ADMIN.signFd, payload, true);
};

export const signOrApprove = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.signOrApprove}/${url}`,
    payload,
    true
    // 'file'
  );
};

export const signOrApproveAPI = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.API.signOrApprove}/${url}`,
    payload,
    true
    // 'file'
  );
};
export const dashboardApiCounts = (payload) => {
  return apiClient.get(apiClient.Urls.dashboardApiCounts, payload, true);
};
