import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  dashboardCounts,
  pifAPIList,
  reject,
  dashboardApiCounts,
  rejectAPI
} from './api';
//import { history } from '@/app/history';

const initialState = {
  getDashboardCountsLoading: false,
  dashboardCountsData: [],
  getPifAPIListLoading: false,
  pifAPIListData: [],
  pagination: {},
  reject_formLoading: false,
  getDashboardApiCountsLoading: false,
  dashboardApiCountsData: []
};

const actions = {
  GET_DASHBOARD_COUNT: 'getDashboardCounts/GET_DASHBOARD_COUNT',
  GET_PIF_API_LIST: 'getPifAPIList/GET_PIF_API_LIST',
  REJECT: 'reject_form/REJECT',
  REJECT_API: 'reject_API_form/REJECT_API',
  GET_DASHBOARD_API_COUNT: 'getDashboardApiCounts/GET_DASHBOARD_API_COUNT'
};

export const getDashboardApiCounts = createAsyncThunk(
  actions.GET_DASHBOARD_API_COUNT,
  async (payload) => {
    const response = await dashboardApiCounts(payload);
    return response;
  }
);

export const getPifAPIList = createAsyncThunk(
  actions.GET_PIF_API_LIST,
  async (payload) => {
    const response = await pifAPIList(payload);
    return response;
  }
);

export const getDashboardCounts = createAsyncThunk(
  actions.GET_DASHBOARD_COUNT,
  async (payload) => {
    const response = await dashboardCounts(payload);
    return response;
  }
);
export const reject_form = createAsyncThunk(actions.REJECT, async (payload) => {
  const response = await reject(
    `?pif_id=${payload?.pif_id}&hash=${payload?.hash}`,
    payload
  );
  return response;
});
export const reject_API_form = createAsyncThunk(
  actions.REJECT_API,
  async (payload) => {
    const response = await rejectAPI(
      `?pif_id=${payload?.pif_id}&hash=${payload?.hash}`,
      payload
    );
    return response;
  }
);

export const approverSlice = createSlice({
  name: 'approver',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardCounts.pending, (state) => {
        state.getDashboardCountsLoading = true;
      })
      .addCase(getDashboardCounts.fulfilled, (state, action) => {
        state.getDashboardCountsLoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dashboardCountsData = data ? data : [];
        } else {
          state.dashboardCountsData = [];
          //message.error(msg);
        }
      })
      .addCase(getDashboardCounts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getDashboardCountsLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPifAPIList.pending, (state) => {
        state.getPifAPIListLoading = true;
      })
      .addCase(getPifAPIList.fulfilled, (state, action) => {
        state.getPifAPIListLoading = false;
        const { success, data, pagination } = action.payload;

        if (success) {
          state.pifAPIListData = data ? data : [];
          state.pagination = pagination || null;
        } else {
          state.pifAPIListData = [];
          //message.error(msg);
        }
      })
      .addCase(getPifAPIList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getPifAPIListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(reject_form.pending, (state) => {
        state.reject_formLoading = true;
      })
      .addCase(reject_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.reject_formLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(reject_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.reject_formLoading = false;

        message.error(msg);
      });
    builder
      .addCase(reject_API_form.pending, (state) => {
        state.reject_formLoading = true;
      })
      .addCase(reject_API_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.reject_formLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(reject_API_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.reject_formLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getDashboardApiCounts.pending, (state) => {
        state.getDashboardApiCountsLoading = true;
      })
      .addCase(getDashboardApiCounts.fulfilled, (state, action) => {
        state.getDashboardApiCountsLoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dashboardApiCountsData = data ? data : [];
        } else {
          state.dashboardApiCountsData = [];
          //message.error(msg);
        }
      })
      .addCase(getDashboardApiCounts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getDashboardApiCountsLoading = false;

        message.error(msg);
      });
  }
});

export default approverSlice.reducer;
