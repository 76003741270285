import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import {
  Col,
  Row,
  Card,
  Divider,
  Checkbox,
  notification,
  Spin,
  message
} from 'antd';

import { useDispatch /* , useSelector */ } from 'react-redux';

import CustomLayout from '@/common/Layout';
import Steper_2 from './../../images/Step_2.svg';
import CustomButton from '@/common/CustomButton';
//import ApproverStepper from './ApproverStepper_old';
import SignatoriesStepper from './SignatoriesStepper';
import ApproversStepper from './ApproverStepper';
import { history } from '@/app/history';

const SignatoriesApprover = ({
  PIF_form_id,
  setPageNum,
  dropDown_signatoriesData,
  debounceSignatories,
  debounceApprovers,
  dropDown_approversData,
  createUpdateApprovers_formLoading,
  createUpdateApprovers_form,
  getFormDetailsByIdData,
  getFormDetailsByIdLoading,
  draft_PIF_form_start
}) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const signatory_edit = localStorage.getItem('signatory_Edit');

  const [signOrder, setSignOrder] = useState(true);
  const [fields, setFields] = useState();
  const [disabledOptions, setDisabledOptions] = useState([]);
  //const [signOrder2, setSignOrder2] = useState(true);
  const [fields2, setFields2] = useState();
  const [disabledOptions2, setDisabledOptions2] = useState([]);
  const [signatoryError, setSignatoryError] = useState(null);
  const [approversError, setApproversError] = useState(null);

  // console.log('signOrder:', signOrder);
  // console.log('fields:', fields);

  //<----------Signatory flow--------------------------->
  function generateKey_bs() {
    return Math.random().toString(36).substr(2, 10);
  }
  useEffect(() => {
    if (
      signatory_edit === false ||
      getFormDetailsByIdData?.approvers?.length === 0
    ) {
      const newFields = [
        {
          key: generateKey_bs(),
          user_id: '',
          approver_type: 'signatory'
        }
      ];
      setFields(newFields);
    }
    if (
      signatory_edit === false &&
      draft_PIF_form_start === 'true' &&
      getFormDetailsByIdData?.is_approval_sequence_wise === null
    ) {
      setSignOrder(true);
    }
  }, [getFormDetailsByIdData, signatory_edit === false, draft_PIF_form_start]);
  useEffect(() => {
    if (
      (getFormDetailsByIdData?.approvers?.length > 0 &&
        signatory_edit === 'true') ||
      (getFormDetailsByIdData?.approvers?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      const signatoriesData = getFormDetailsByIdData?.approvers?.filter(
        (item) => item.approver_type === 'signatory'
      );

      const newData = signatoriesData?.map((itm) => ({
        key: itm?.id,
        id: itm?.id,
        user_id: itm?.user_id,
        approver_type: itm?.approver_type
      }));

      setFields(newData);

      // Update disabledOptions with the user_id of each field
      const newDisabledOptions = newData?.map((field) => field.user_id);
      setDisabledOptions(newDisabledOptions);
    }
    if (signatory_edit === 'true' || draft_PIF_form_start === 'true') {
      if (getFormDetailsByIdData?.is_approval_sequence_wise === null) {
        setSignOrder(true);
      } else {
        setSignOrder(getFormDetailsByIdData?.is_approval_sequence_wise);
      }
    }
  }, [getFormDetailsByIdData, signatory_edit === 'true', draft_PIF_form_start]);

  const onCheckBoxToggle = (e) => {
    e.target.checked;
    setSignOrder(e.target.checked);
    signOrder === false ? null : (
      <>
        {notification.destroy()}
        {notification.info({
          message: (
            <strong className="sfprotext-bold font-14">
              Do you want parallel signatories?
            </strong>
          ),
          description: (
            <p className="font-12">
              Unchecking this will send the form to all signatories
              simultaneously. Please keep it checked if you want the signatories
              to review the form sequentially.
            </p>
          ),
          placement: 'bottomLeft',
          duration: null
        })}
      </>
    );
  };

  //<----------Approver flow--------------------------->
  function generateKey_bs2() {
    return Math.random().toString(36).substr(2, 10);
  }
  useEffect(() => {
    if (
      signatory_edit === false ||
      getFormDetailsByIdData?.approvers?.length === 0
    ) {
      const newFields = [
        {
          key: generateKey_bs2(),
          user_id: '',
          approver_type: 'approver'
        }
      ];
      setFields2(newFields);
    }
  }, [getFormDetailsByIdData, signatory_edit === false]);
  useEffect(() => {
    if (
      (getFormDetailsByIdData?.approvers?.length > 0 &&
        signatory_edit === 'true') ||
      (getFormDetailsByIdData?.approvers?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      const approverData = getFormDetailsByIdData?.approvers?.filter(
        (item) => item.approver_type === 'approver'
      );

      const newData = approverData?.map((itm) => ({
        key: itm?.id,
        id: itm?.id,
        user_id: itm?.user_id,
        approver_type: itm?.approver_type
      }));

      setFields2(newData);

      // Update disabledOptions with the user_id of each field
      const newDisabledOptions = newData?.map((field) => field.user_id);
      setDisabledOptions2(newDisabledOptions);
    }
    // if (signatory_edit === 'true') {
    //   setSignOrder2(getFormDetailsByIdData?.is_approval_sequence_wise);
    // }
  }, [getFormDetailsByIdData, signatory_edit === 'true', draft_PIF_form_start]);

  const onFinish = () => {
    // const approverArray = dropDown_approversData.map((itm) => ({
    //   // id: itm?.id,
    //   user_id: itm?.id,
    //   approver_type: 'approver'
    // }));

    const mergedArray = [...fields, ...fields2];

    console.log(
      '..merged arr',
      mergedArray,
      mergedArray.every((user) => user.user_id !== '')
    );

    if (!mergedArray.every((user) => user.user_id !== '')) {
      message.error('All fields must be filled');
      return;
    }

    const payload = {
      pif_id: PIF_form_id,
      edit_type: 'optional',
      is_approval_sequence_wise: signOrder,
      approvers: mergedArray?.map((obj) => ({
        ...obj
      }))
      // fields[0]?.user_id === ''
      //   ? []
      //   : fields?.map((obj) => ({
      //       ...obj
      //     }))
    };
    console.log('payload:', payload);
    //dispatch(createUpdateApprovers_form(payload));
    dispatch(createUpdateApprovers_form({ payload }))?.then(function (
      response
    ) {
      if (response?.payload?.success) {
        localStorage.setItem('signatory_Edit', true);
        setPageNum(3);
      } else {
        //message.error('Please fill required field');
        console.log('response?.payload?.error', response?.payload?.error);
      }
    });
  };

  return (
    <CustomLayout sider={false}>
      <div className={styles.stepper_flow}>
        <img src={Steper_2} alt="Steper_2" />
      </div>
      <Spin spinning={getFormDetailsByIdLoading}>
        <div className={`${styles.homepage_container}`}>
          <div className={`${styles.lowerCard_container}`}>
            <Card hoverable className={` ${styles.lower_card_container} mt-3`}>
              <Row gutter={0} className={`${styles.row_container}`}>
                <Col span={12}>
                  <div className={styles.approver_container}>
                    <Row className={styles.approver_title_container}>
                      <div className={styles.approver_title}>Signatories</div>
                      <div>
                        {' '}
                        <div className={styles.checkbox_container}>
                          <Checkbox
                            className={`font-14`}
                            onChange={onCheckBoxToggle}
                            defaultChecked={true}
                            checked={signOrder}
                          >
                            Set signing order
                          </Checkbox>
                        </div>
                      </div>
                    </Row>

                    <div className={styles.approver_stepper}>
                      <SignatoriesStepper
                        dropDown_signatoriesData={dropDown_signatoriesData}
                        debounceSignatories={debounceSignatories}
                        fields={fields}
                        setFields={setFields}
                        generateKey_bs={generateKey_bs}
                        disabledOptions={disabledOptions}
                        setDisabledOptions={setDisabledOptions}
                        error={signatoryError}
                        setError={setSignatoryError}
                      />
                    </div>
                  </div>
                </Col>

                <div className={`${styles.parent_vertical}`}>
                  <div className={`${styles.vertical_divider}`} />
                </div>

                <Col span={11}>
                  <div className={styles.approver_container}>
                    <div className={styles.approver_title}>Approvers</div>

                    {/* <div className={styles.approver_stepper}>
                      <ApproverStepper
                        dropDown_approversData={dropDown_approversData}
                      />
                    </div> */}
                    <div className={styles.approver_stepper}>
                      <ApproversStepper
                        dropDown_signatoriesData={dropDown_approversData}
                        debounceSignatories={debounceApprovers}
                        fields={fields2}
                        setFields={setFields2}
                        generateKey_bs={generateKey_bs2}
                        disabledOptions={disabledOptions2}
                        setDisabledOptions={setDisabledOptions2}
                        error={approversError}
                        setError={setApproversError}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  // className={styles.button_container}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start'
                  }}
                >
                  <CustomButton
                    className={styles.back_btn_style}
                    onClick={() => setPageNum(1)}
                    //onClick={() => history.push(`/home`)}
                  >
                    Go Back
                  </CustomButton>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end'
                  }}
                >
                  <CustomButton
                    className={styles.back_btn_style}
                    onClick={() => history.push(`/home`)}
                  >
                    Cancel
                  </CustomButton>

                  <CustomButton
                    loading={createUpdateApprovers_formLoading}
                    type="primary"
                    className={styles.post_btn_style}
                    onClick={() => onFinish()}
                    disabled={signatoryError || approversError}
                  >
                    Next
                  </CustomButton>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </Spin>
    </CustomLayout>
  );
};
export default SignatoriesApprover;
