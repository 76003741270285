import React from 'react';
import styles from './index.module.less';
import { Button, Col, Row, Card, Space, Spin, Badge } from 'antd';
//import { Link, NavLink } from 'react-router-dom';
import { FormOutlined } from '@ant-design/icons';
import { getAccessStatus, getLoggedInUser } from 'utilities/helpers';
import PIF_FD_form from 'assets/images/home/PIF_FD_form.svg';
import PIF_API_form from 'assets/images/home/PIF_API_form.svg';
import { history } from 'app/history';
// import { useDispatch /* , useSelector */ } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

const envelopeTypes = [
  {
    title: 'Finished Dosages',
    subText: 'Initiate FD PIF for review',
    //'Capture essential purchase details to inform and enhance product development strategies',
    btnText: 'Initiate FD Form',
    image: PIF_FD_form,
    path: '/pif-fd-form',
    accType: 'fd'
  },
  {
    title: 'Active Pharmaceutical Ingredients',
    subText: 'Initiate API PIF for review',
    //'Capture and leverage purchase insights within your API infrastructure with PIF-API',
    btnText: 'Initiate API Form',
    image: PIF_API_form,
    path: 'factors-modules', // '/pif-api-form'
    accType: 'api'
  }
];

const HomePage = ({
  getDashboardCountsLoading,
  dashboardCountsData,
  dashboardApiCountsData
}) => {
  const user = getLoggedInUser();
  let userName = user?.user_details?.display_name;

  console.log('getDashboardApiCountsData:::::', dashboardApiCountsData);

  localStorage.setItem('PIF_form_id', null);
  localStorage.setItem('fd_info_status', false);
  localStorage.setItem('ref_table', false);
  localStorage.setItem('prop_table', false);
  localStorage.setItem('Ip_patentScenario_table', false);
  localStorage.setItem('Ip_exclusivities_table', false);
  localStorage.setItem('Ip_generic_launch_date', false);
  localStorage.setItem('RD_Timeline_table', false);
  localStorage.setItem('RD_Budget_table', false);
  localStorage.setItem('RD_Batch_size', false);

  localStorage.setItem('BJ_Edit', false);
  localStorage.setItem('market_data_edit', false);
  localStorage.setItem('signatory_Edit', false);
  localStorage.setItem('upload_Edit', false);
  localStorage.setItem('selectedTab', '');
  localStorage.setItem('selectedTab_api', '');
  localStorage.setItem('Edit_PIF_form_start', false);
  localStorage.setItem('Edit_PIF_form_start_API', false);
  localStorage.setItem('Draft_PIF_form_start', false);
  localStorage.setItem('Draft_PIF_form_start_API', false);

  //console.log('dashboardCountsData:::::', dashboardCountsData);

  const cards = [
    {
      key: 'card1',
      path: `/requester-list-view/${3}`,
      path_api: `/requester-api-list-view/${3}`,
      icon: <FormOutlined className={`${styles.gift_antd_icon_size}`} />,
      label: 'Under approval',
      count: dashboardCountsData[0]?.count || 0,
      count_api: dashboardApiCountsData[0]?.count || 0,
      bg_color: '#185288',
      border_color: '#185288'
    },
    {
      key: 'card2',
      path: `/requester-list-view/${4}`,
      path_api: `/requester-api-list-view/${4}`,
      icon: <FormOutlined className={`${styles.gift_antd_icon_size}`} />,
      label: 'Approved',
      count: dashboardCountsData[1]?.count || 0,
      count_api: dashboardApiCountsData[1]?.count || 0,
      bg_color: 'linear-gradient(90deg, #185288 0%, #157D64 100%)',
      border_color: '#185288'
    },
    {
      key: 'card3',
      path: `/requester-list-view/${5}`,
      path_api: `/requester-api-list-view/${5}`,
      icon: <FormOutlined className={`${styles.gift_antd_icon_size}`} />,
      label: 'Rejected',
      count: dashboardCountsData[2]?.count || 0,
      count_api: dashboardApiCountsData[2]?.count || 0,
      bg_color: 'linear-gradient(90deg, #185288 0%, #6B3A3A 100%)',
      border_color: '#185288'
    },
    {
      key: 'card4',
      path: `/requester-list-view/${2}`,
      path_api: `/requester-api-list-view/${2}`,
      icon: <FormOutlined className={`${styles.gift_antd_icon_size}`} />,
      label: 'Drafts',
      count: dashboardCountsData[3]?.count || 0,
      count_api: dashboardApiCountsData[3]?.count || 0,
      bg_color: '#0D5877',
      border_color: '#0D5877'
    }
  ];

  const handelFormType = (type) => {
    if (type === '/pif-fd-form') {
      history.push('/pif-fd-form');

      // let payload = { type: 'New', form_type: 'FD' };

      // dispatch(createPIFRequest({ payload })).then((res) => {
      //   console.log(res);
      //   if (res?.payload?.success) {
      //     navigate(`/pif-fd-form/${res?.payload?.data?.form_version_id}`, {
      //       state: { PIFRequestFormData: res?.payload?.data }
      //     });
      //   }
      // });
    } else {
      // history.push('/factors-modules');
      history.push('/pif-api-form');
    }
  };
  return (
    <div className={`${styles.homepage_container}`}>
      <Spin spinning={getDashboardCountsLoading}>
        <div className={`${styles.home_carousel}`}>
          <div className={`${styles.home_carousel_container}`}>
            <div className={`${styles.home_carousel_container_center}`}>
              <Space
                direction="vertical"
                size={10}
                style={{ width: '100%' }}
                className={`${styles.home_carousel_container_width}`}
              >
                <div className={`${styles.welcome_text_container}`}>
                  <div className={`${styles.welcome_text}`}>
                    <span className={`${styles.user_text}`}>Welcome,</span>
                    <span>{userName}!</span>
                  </div>
                  <div className={`${styles.sub_text}`}>
                    Take a look at the status of your PIFs
                  </div>
                </div>
                <Row className={`${styles.carousel_card_row}`}>
                  {cards.map((card) => (
                    <Card
                      key={card.key}
                      className={`${styles.carousel_card}`}
                      style={{
                        background: card.bg_color,
                        borderColor: card.border_color
                      }}
                    >
                      <div className={`${styles.card_content}`}>
                        <div className={`${styles.carousel_card_label}`}>
                          {card.label}
                        </div>
                        <div className={`${styles.carousel_card_count}`}>
                          <Space direction="horizontal" size={30}>
                            <a href={card?.path}>
                              <Badge
                                size="small"
                                showZero
                                count={card.count}
                                overflowCount={999}
                                style={{
                                  backgroundColor:
                                    card?.key === 'card1'
                                      ? '#F5222D'
                                      : '#F5222D'
                                }}
                              >
                                <Button className={`${styles.badge_fd_button}`}>
                                  FD
                                </Button>
                              </Badge>
                            </a>
                            {console.log(
                              'card.count_api>>>>>>',
                              card.count_api
                            )}
                            <a href={card?.path_api}>
                              <Badge
                                size="small"
                                showZero
                                count={card.count_api}
                                overflowCount={999}
                                style={{
                                  backgroundColor:
                                    card?.key === 'card1'
                                      ? '#F5222D'
                                      : '#F5222D'
                                }}
                              >
                                <Button className={`${styles.badge_fd_button}`}>
                                  API
                                </Button>
                              </Badge>
                            </a>
                          </Space>
                        </div>
                      </div>
                    </Card>
                  ))}
                </Row>
              </Space>
            </div>
          </div>
        </div>
        <div className={`${styles.lowerCard_container}`}>
          <Card hoverable className={` ${styles.lower_card_container} mt-3`}>
            <Row gutter={[48, 16]} className={`df-jc-ac ${styles.cardRow}`}>
              {envelopeTypes.map((item, index) => {
                const { title, subText, btnText, image, path, accType } = item;
                const isButtonDisabled = getAccessStatus(accType);
                return (
                  <>
                    <Col
                      key={title}
                      className={`df-jc-ac p-3 ${styles.cardCol}`}
                      span={11}
                    >
                      <div className={`${styles.item}`}>
                        <img src={image} />
                        <span className={`${styles.caption}`}>{title}</span>
                        <span className={`${styles.description}`}>
                          {subText}
                        </span>
                        <Button
                          onClick={() => handelFormType(path)}
                          className={`${styles.lower_card_button}`}
                          disabled={!isButtonDisabled}
                        >
                          {btnText}
                        </Button>
                      </div>
                    </Col>
                    {index != envelopeTypes.length - 1 ? (
                      <div className={`${styles.vertical_divider}`} />
                    ) : null}
                  </>
                );
              })}
            </Row>
          </Card>
        </div>
      </Spin>
    </div>
  );
};
export default HomePage;
