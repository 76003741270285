import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Input, Button, Form } from 'antd';
import styles from './index.module.less';

// import microsoft from 'features/login/images/microsoft_login_button.png';
// import { useMsal, useIsAuthenticated } from '@azure/msal-react';
// import LocalStorage from 'utilities/localStorage';
// import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
// import { login } from '../../redux/slice';
import { useLocation } from 'react-router-dom';

import left_image from '../../images/granule-banner.svg';
import leftInside_image from '../../images/leftInside_image.svg';
import granules_logo from '../../images/granules-logo.png';
import granules_logo_mobile from 'assets/images/granules-mobile.png';
import { resetPassword } from '../../redux/slice';
import { useNavigate } from 'react-router-dom';
import { useResponsiveness } from 'hooks/useResponsiveness';
// import rightIcon_image from '../../images/rightIcon_image.svg';
//import { getLoggedInUser } from 'utilities/helpers';

const ResetPassword = () => {
  // const isAuthenticated = useIsAuthenticated();
  // const { instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [payload, setPayload] = useState({
    password: '',
    confirmPassword: ''
  });
  const { success, loading } = useSelector((state) => state.login);
  const { xs, sm, md } = useResponsiveness();
  let location = useLocation();
  const params = new URLSearchParams(location.search);

  const hash = params.get('hash');
  const email = params.get('email');

  useEffect(() => {
    if (!hash || !email) {
      navigate('/', { replace: true });
    }
  }, []);

  //signatory, //approver ,creator

  const handleResetPassword = () => {
    if (!payload.password || !payload.confirmPassword) return;

    dispatch(resetPassword({ password: payload.password, email, hash }));
  };

  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (success) {
      localStorage.clear();
      navigate('/login', { replace: true });
    }
  }, [success, navigate]);

  return (
    <Row>
      <Col
        md={{ span: 12 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
        style={{
          position: 'relative',
          width: '100%',
          objectFit: 'cover',
          height: xs || sm ? '40vh' : '100vh'
        }}
      >
        {(xs || sm) && (
          <img
            src={granules_logo_mobile}
            style={{
              height: xs ? '2rem' : '3rem',
              width: xs ? '6rem' : '7rem',
              objectFit: 'contain',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              left: '20%',
              top: '10%'
            }}
          />
        )}
        <img
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            zIndex: -1
          }}
          src={left_image}
        />
        <img
          src={leftInside_image}
          style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: xs ? '50%' : '55%',
            height: xs ? '60%' : '70%',
            width: '100%'
          }}
          alt="Left Inside Image"
        />
        {!xs && !sm && (
          <div
            style={{
              position: 'absolute',
              margin: '1rem',
              top: 0,
              opacity: 0.8
            }}
          >
            <Typography.Title
              level={2}
              style={{ color: 'white', fontSize: md && '1.2rem' }}
            >
              Manage PIF approvals
            </Typography.Title>
            <Typography.Text
              style={{ fontSize: md ? '1rem' : '1.2rem', color: 'white' }}
            >
              Streamline PIF approvals for FDs and APIs with intuitive workflows
            </Typography.Text>
          </div>
        )}
      </Col>
      <Col
        style={{
          position: 'relative',
          width: '100%'
        }}
        md={{ span: 12 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <Form
          form={form}
          onFinish={handleResetPassword}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: !xs && !sm && 'center',
            alignItems: !xs && !sm && 'center',
            width: '100%',
            height: xs || sm ? '60vh' : '100vh'
          }}
        >
          {!xs && !sm && (
            <img
              src={granules_logo}
              style={{ height: '4rem', width: '12rem', objectFit: 'contain' }}
            />
          )}
          <div
            style={{
              marginTop: '2rem',
              width: '100%',
              paddingInline: xs ? '1rem' : '2rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <Typography.Title
              level={4}
              style={{
                fontSize: '1.4rem',
                color: '#0098da',
                textAlign: (xs || sm) && 'center'
              }}
              className="sfprotext-regular"
            >
              Reset Password
            </Typography.Title>
            <Form.Item
              style={{ padding: 0, margin: 0 }}
              name="new password"
              rules={[
                {
                  required: true,
                  message: 'New password is required'
                }
              ]}
            >
              <div className={styles.input_field}>
                <label>New Password</label>
                <Input.Password
                  onChange={handleChange}
                  name="password"
                  allowClear
                  placeholder="Enter your new password"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="confirm password"
              rules={[
                {
                  required: true,
                  message: 'Confirm password is required'
                },
                {
                  validator(_, value, callback) {
                    const password = form.getFieldValue('new password');
                    if (value && value !== password) {
                      callback('Password and confirm password should match');
                    } else {
                      callback();
                    }
                  }
                }
              ]}
              style={{ padding: 0, margin: 0 }}
            >
              <div className={styles.input_field}>
                <label>Confirm Password</label>
                <Input
                  onChange={handleChange}
                  name="confirmPassword"
                  allowClear
                  placeholder="Re-enter your new password"
                />
              </div>
            </Form.Item>
            <Form.Item
              style={{
                marginTop: '2rem',
                width: '100%',
                display: 'flex',
                justifyContent: (xs || sm) && 'center'
              }}
            >
              <Button
                htmlType="submit"
                style={{ float: 'left', width: xs && '100%' }}
                type="primary"
                disabled={loading}
                className="sfprotext-regular"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPassword;
