import React from 'react';
import PIF_API_FormPage from './components/PIF_API_FormPage';
import CustomLayout from '@/common/Layout';

const PIF_API_Form = () => {
  return (
    <CustomLayout>
      <PIF_API_FormPage />
    </CustomLayout>
  );
};

export default PIF_API_Form;
