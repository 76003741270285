import React from 'react';
//import { Layout, Menu } from 'antd';

const ManagePage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20%',
        fontSize: '30px'
      }}
    >
      <p>Content</p>
    </div>
  );
};

export default ManagePage;
