import React /* , { useState }  */ from 'react';
import { Modal, Typography, Button /* , Input, Row, Col */ } from 'antd';
import styles from './index.module.less';
//import FormItem from 'common/FormItem';
import { /* Form, */ Space } from 'antd';
//import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '@/app/history';

// import { Upload } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
//import FormData from 'form-data';
import { signOrApproveAPI_form } from 'features/signatoryFlow/redux/slice';

const SignModal = ({
  signModal,
  setSignModal,
  userDetails,
  loggedIn_User,
  hash,
  PIF_id
}) => {
  // const params = useParams();
  // const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { signOrApprove_formLoading } = useSelector((state) => state.signatory);

  // const [fileList, setFileList] = useState([]);
  // const [fileName, setFileName] = useState('');

  // const props = {
  //   name: 'file',
  //   accept: '.jpg,.jpeg,.png',
  //   fileList,
  //   beforeUpload: (file) => {
  //     // Limit to one file at a time
  //     setFileList([file]);
  //     setFileName(file.name);
  //     return false; // Prevent automatic upload, manage it manually if needed
  //   },
  //   onRemove: () => {
  //     setFileList([]);
  //     setFileName('');
  //   }
  // };

  // console.log('fileName:', fileName);
  // console.log('fileList:', fileList[0]);

  const onFinish = () => {
    if (userDetails === undefined || loggedIn_User === null) {
      const payload = {
        pif_id: PIF_id,
        hash: hash
      };
      dispatch(signOrApproveAPI_form(payload)).then(function (response) {
        if (response) {
          setSignModal(false);
          if (response?.payload?.success) {
            //message.success(response?.payload?.msg);
            history.push('/one-click-approve');
          }
        }
      });
    } else {
      const payload = {
        pif_id: PIF_id,
        hash: ''
      };

      dispatch(signOrApproveAPI_form(payload)).then(function (response) {
        if (response) {
          setSignModal(false);
          if (response?.payload?.success) {
            //message.success(response?.payload?.msg);
            history.push('/signatory-api-list-view');
          }
        }
      });
    }
  };

  return (
    <>
      <Modal
        title={''}
        visible={signModal}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'370px'}
        onCancel={() => setSignModal(false)}
      >
        <Typography
          style={{
            fontSize: '18px',
            marginTop: '6px',
            fontFamily: 'SFProText Regular',
            fontWeight: '600',
            lineHeight: '18px'
          }}
        >
          Do you want to sign the PIF
        </Typography>
        <br />
        <br />
        {/* <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <FormItem
            label="Attach your digital signature"
            name="reason_for_rejection"
            rules={[
              {
                required: true,
                message: 'Required field'
              }
            ]}
            className={`${styles.select_styles}`}
          >
            <div className={`${styles.input_field_container}`}>
              <Row gutter={0}>
                <Col span={12}>
                  <Input
                    value={fileName}
                    placeholder="Uploaded file will be shown here"
                    readOnly
                    className={`${styles.input_field1}`}
                  />
                </Col>
                <Col>
                  <Upload {...props} showUploadList={false}>
                    <Button
                      icon={<UploadOutlined />}
                      className={`${styles.input_field2}`}
                    >
                      Select File
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </div>
          </FormItem>
          <FormItem className={`${styles.bottom_margin}`}> */}
        <Space className={`${styles.button_container}`}>
          <Button
            //className={styles.back_btn_style}
            className={styles.import_style_cancel}
            size="small"
            onClick={() => setSignModal(false)}
          >
            Cancel
          </Button>
          <Button
            loading={signOrApprove_formLoading}
            // className={styles.post_btn_style}
            className={styles.approve_modal_button}
            // htmlType="submit"
            size="small"
            onClick={onFinish}
          >
            Sign
          </Button>
        </Space>
        {/*  </FormItem>
        </Form> */}
      </Modal>
    </>
  );
};

export default SignModal;
