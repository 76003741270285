import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL;
//export const apiUrl = '//https://pif-api.lexyslabs.com/api/v1/auth/login';

const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

export const getLocalToken = () => {
  const user = LocalStorage.getItem('granules_PIF');
  const authToken = user?.accessToken;
  if (authToken !== null && authToken !== '') {
    return `Bearer ${authToken}`;
  } else {
    return '';
  }
};
const apiClient = {
  Urls: {
    apiUrl,
    signin: `${apiUrl}/auth/signIn`,
    signout: `${apiUrl}/auth/signOut`,
    forgetPass: `${apiUrl}/auth/sendPasswordResetLink`,
    resetPass: `${apiUrl}/auth/resetPassword`,
    dashboardCounts: `${apiUrl}/user/getDashboardCounts`,
    PIFRequest: `${apiUrl}/PIF-Form/createPIFRequest`,
    pif_FD_DropDownData: `${apiUrl}/PIF-Form/getDropDownData`,
    createUpdateFdInfo: `${apiUrl}/PIF-Form/createUpdateFdInfo`,
    createUpdateRefProductDetail: `${apiUrl}/PIF-Form/createUpdateRefProductDetail`,
    getFormDetailsById: `${apiUrl}/PIF-Form/getFormDetailsById`,
    createUpdatePropProductDetail: `${apiUrl}/PIF-Form/createUpdatePropProductDetail`,
    createUpdateIPDetail: `${apiUrl}/PIF-Form/createUpdateIPDetail`,
    createUpdateRDDetail: `${apiUrl}/PIF-Form/createUpdateRDDetail`,
    createUpdateCompetitiveScenario: `${apiUrl}/PIF-Form/createUpdateCompetitiveScenario`,
    createUpdateBJ: `${apiUrl}/PIF-Form/createUpdateBJ`,
    createUpdateMarketData: `${apiUrl}/PIF-Form/createUpdateMarketData`,
    signatories: `${apiUrl}/getSignatories`,
    approvers: `${apiUrl}/getApprovers`,
    createUpdateApprovers: `${apiUrl}/createUpdateApprovers`,
    uploadAttachments: `${apiUrl}/uploadAttachments`,
    deleteDocuments: `${apiUrl}/deleteAttachment`,
    initiateFdFormApi: `${apiUrl}/initiateFdForm`,
    pifFdList: `${apiUrl}/pifFdList`,
    signOrApprove: `${apiUrl}/signOrApprove`,
    reject: `${apiUrl}/reject`,
    dashboardApiCounts: `${apiUrl}/PIF-Form/API/getDashboardCounts`,
    API: {
      getFormDetailsById: `${apiUrl}/PIF-Form/API/getFormDetailsById`,
      pif_API_DropDownData: `${apiUrl}/PIF-Form/API/getDropDownData`,
      pifAPIList: `${apiUrl}/PIF-Form/API/pifFdList`,
      createUpdateAPIInfo: `${apiUrl}/PIF-Form/API/createUpdateFdInfo`,
      createUpdateRefProductDetail: `${apiUrl}/PIF-Form/API/createUpdateRefProductDetail`,
      createUpdatePropProductDetail: `${apiUrl}/PIF-Form/API/createUpdatePropProductDetail`,
      createUpdateIPDetail: `${apiUrl}/PIF-Form/API/createUpdateIPDetail`,
      createUpdateRDDetail: `${apiUrl}/PIF-Form/API/createUpdateRDDetail`,
      createUpdateBJ: `${apiUrl}/PIF-Form/API/createUpdateBJ`,
      createUpdateCompetitiveScenario: `${apiUrl}/PIF-Form/API/createUpdateCompetitiveScenario`,
      createUpdateMarketData: `${apiUrl}/PIF-Form/API/createUpdateMarketData`,
      signatories: `${apiUrl}/PIF-Form/API/getSignatories`,
      approvers: `${apiUrl}/PIF-Form/API/getApprovers`,
      createUpdateApprovers: `${apiUrl}/PIF-Form/API/createUpdateApprovers`,
      uploadAttachments: `${apiUrl}/PIF-Form/API/uploadAttachments`,
      deleteDocuments: `${apiUrl}/PIF-Form/API/deleteAttachment`,
      initiateFdFormApi: `${apiUrl}/PIF-Form/API/initiateFdForm`,
      signOrApprove: `${apiUrl}/PIF-Form/API/signOrApprove`,
      reject: `${apiUrl}/PIF-Form/API/reject`
    },
    ADMIN: {
      deleteDraft: `${apiUrl}/admin/delete-draft`,
      pifApiNumbers: `${apiUrl}/admin/pif-api-numbers`,
      pifFdNumbers: `${apiUrl}/admin/pif-fd-numbers`,
      sign: `${apiUrl}/admin/sign`,
      signFd: `${apiUrl}/admin/sign-fd`
    }
  },

  make: function (url, method, params, auth, type) {
    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    let authHeaders = {
      Authorization: getLocalToken()
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders
      };
    }

    if (method == 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params)
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: function (url, params, auth) {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: function (url, params, auth) {
    return apiClient.make(url, 'PATCH', params, auth);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, 'DELETE', params, auth);
  }
};

export default apiClient;
