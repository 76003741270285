import SearchBar from '@/common/Content/searchBar';
//import CustomButton from '@/common/CustomButton';

import {
  MessageOutlined,
  DownloadOutlined,
  AuditOutlined
  //EditOutlined
} from '@ant-design/icons';

import {
  Empty,
  Progress,
  Modal,
  Col,
  Row,
  Timeline,
  Table,
  Tooltip,
  Spin
  //Button
} from 'antd';

import React, { useState } from 'react';
import styles from './index.module.less';
import moment from 'moment';
import { sizeChecker } from 'utilities/helpers';
// import { getLoggedInUser } from 'utilities/helpers';
// import { apiUrl } from 'utilities/apiClient';

const All_ApproverList = ({
  pifFdListData,
  pagination,
  page,
  setPage,
  pageSize,
  setPageSize,
  handleSearchChange,
  selectedTab,
  getPifFdListLoading
}) => {
  // const user = getLoggedInUser();
  // const authToken = user?.accessToken;

  const isMobile = sizeChecker('xs');

  //const [loading, setLoading] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [allApproverList, setAllApproverList] = useState();

  const gcd = function (a, b) {
    if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

    return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
  };

  const HendaleDownload = (record) => {
    //console.log('id', record);
    const link = document.createElement('a');
    link.href = record?.pdf_pif;
    link.download = 'pif_fd.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // console.log('id', record?.id);

    // //setLoading(true);

    // fetch(`${apiUrl}/preview?pif_id=${record?.id}`, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/pdf',
    //     Authorization: `Bearer ${authToken}`
    //   }
    // })
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // setLoading(false);
    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     console.log('url:::', url);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `download.pdf`);
    //     document.body.appendChild(link);
    //     link.click();
    //     link.parentNode.removeChild(link);
    //   });
  };

  const handleHistory = (record) => {
    setShowHistoryModal(true);
    record ? setAllApproverList(record?.allApprover) : '';
  };
  console.log('AllApproverList', allApproverList);

  // const handleEdit = (record) => {
  //   localStorage.setItem('Edit_PIF_form_start', true);
  //   window.location.href = `/edit-pif-fd-form/${record.id}`;
  // };

  const pifData = pifFdListData?.map((item) => ({
    id: item?.id,
    request_id: item?.request_id,
    pif_num: item?.pif_num,
    reference_product_details:
      item?.ref_product_detail === null
        ? 'NA'
        : item?.ref_product_detail?.product_name,
    statusDetails: {
      total_apprvs: item?.total_apprvs === null ? 0 : item?.total_apprvs,
      action_taken_apprvs:
        item?.action_taken_apprvs === null ? 0 : item?.action_taken_apprvs,
      status: item?.approvers[0]?.status ? item?.approvers[0]?.status : ''
    },
    actionTakenApprvs: item?.action_taken_apprvs,
    totalApprvs: item?.total_apprvs,
    allApprover: item?.all_approvers,
    pdf_pif: item?.pdf_pif?.fileUrl,
    status: item?.approvers[0]?.status ? item?.approvers[0]?.status : '',
    edit_button: item?.edit_button
  }));

  const columns = [
    {
      title: (
        <Tooltip
          title={'PIF Request No.'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          PIF Request No.
        </Tooltip>
      ),
      dataIndex: 'request_id',
      key: 'request_id',
      // width: '45%',
      sorter: (a, b) => a?.request_id?.localeCompare(b?.request_id),
      render: (request_id, record) => (
        <>
          <Tooltip
            title={request_id}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            <a
              href={`/approver-api-preview/${record.id}`}
              style={{ color: '#0098da' }}
            >
              {request_id === null || request_id === '' ? 'NA' : request_id}
            </a>
          </Tooltip>
        </>
      )
    },
    {
      title: (
        <Tooltip
          title={'PIF No.'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          PIF No.
        </Tooltip>
      ),
      dataIndex: 'pif_num',
      key: 'pif_num',
      ellipsis: true,
      // width: '45%',
      sorter: (a, b) => a?.pif_num?.localeCompare(b?.pif_num),
      render: (pif_num) => (
        <>
          <Tooltip
            title={pif_num}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {pif_num === null ? 'NA' : pif_num}
          </Tooltip>
        </>
      )
    },
    {
      title: (
        <Tooltip
          title={'Reference Product'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Ref. Product
        </Tooltip>
      ),
      dataIndex: 'reference_product_details',
      key: 'reference_product_details',
      ellipsis: true,
      //width: '25%',
      render: (reference_product_details) => (
        <>
          <Tooltip
            title={reference_product_details}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            <span className={styles.text_style}>
              {reference_product_details === null
                ? 'NA'
                : reference_product_details}
            </span>
          </Tooltip>
        </>
      )
    },
    {
      title: 'Status',
      key: 'statusDetails',
      dataIndex: 'statusDetails',
      width: '20%',
      render: (statusDetails) => (
        <>
          <Row>
            <Col span={18}>
              <div className={`${styles.progress_style}`}>
                <Progress
                  size="small"
                  // success={{ percent: 30 }}
                  percent={`${Math.floor(
                    (statusDetails?.action_taken_apprvs /
                      statusDetails?.total_apprvs) *
                      100
                  )}`}
                  strokeColor={
                    statusDetails?.status === 'approved'
                      ? '#52C41A'
                      : statusDetails?.status === 'rejected'
                      ? '#FF4D4F'
                      : statusDetails?.status === 'signed'
                      ? '#52C41A'
                      : statusDetails?.status === 'draft'
                      ? '#e6f7ff'
                      : statusDetails?.status === 'pending'
                      ? '#FFC53D'
                      : statusDetails?.status === 'waiting_for_others'
                      ? '#FFC53D'
                      : '#FFC53D'
                  }
                  status="active"
                  format={gcd}
                  showInfo={false}
                />

                <div className={`${styles.status_text}`}>
                  {statusDetails?.status === 'waiting_for_others'
                    ? 'Waiting for Others'
                    : statusDetails?.status === 'approved'
                    ? 'Approved'
                    : statusDetails?.status === 'rejected'
                    ? 'Rejected'
                    : statusDetails?.status === 'pending'
                    ? 'Pending'
                    : statusDetails?.status === 'signed'
                    ? 'Signed'
                    : statusDetails?.status === 'draft'
                    ? 'Draft'
                    : 'NA'}
                </div>
              </div>
            </Col>
            <Col
              span={6}
              style={{
                marginLeft: '-10px', //---newly added for- number
                fontSize: '11px', //---newly added for- number
                marginTop: '-2px'
              }}
            >{`${statusDetails?.action_taken_apprvs}/${statusDetails?.total_apprvs}`}</Col>
          </Row>
        </>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (_, record) => {
        // console.log('record>>>...', record);
        return (
          <>
            {' '}
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Tooltip title="Download">
                <a
                  type="link"
                  className={`${styles.use_button}`}
                  onClick={() => {
                    record?.actionTakenApprvs !== record?.totalApprvs ||
                    (record?.actionTakenApprvs === null &&
                      record?.totalApprvs === null)
                      ? ''
                      : HendaleDownload(record);
                  }}
                  disabled={
                    record?.actionTakenApprvs !== record?.totalApprvs ||
                    (record?.actionTakenApprvs === null &&
                      record?.totalApprvs === null)
                  }
                >
                  <DownloadOutlined />
                </a>
              </Tooltip>

              <Tooltip title="Audit Trail">
                <a
                  className={`${styles.use_button}`}
                  onClick={() => handleHistory(record)}
                  disabled={record?.status === 'draft'}
                >
                  <AuditOutlined />
                </a>
              </Tooltip>

              {/* <Tooltip title="Edit">
                <a
                  className={`${styles.edit_button}`}
                  //disabled={record?.edit_button === false}
                  disabled={record?.status !== 'draft'}
                >
                  <EditOutlined
                  // onClick={() =>
                  //   record?.edit_button === false ? '' : handleEdit(record)
                  // }
                  />
                </a>
              </Tooltip> */}
            </Row>
          </>
        );
      }
    }
    // {
    //   title: 'Status',
    //   key: 'statusDetails',
    //   dataIndex: 'statusDetails',
    //   width: '25%',
    //   render: (statusDetails) => (
    //     <>
    //       <Row>
    //         <Col span={18}>
    //           <div className={`${styles.progress_style} mr-5`}>
    //             <Progress
    //               size="small"
    //               // success={{ percent: 30 }}
    //               percent={`${Math.floor(
    //                 (statusDetails?.action_taken_apprvs /
    //                   statusDetails?.total_apprvs) *
    //                   100
    //               )}`}
    //               strokeColor={
    //                 statusDetails?.status === 'approved'
    //                   ? '#1CB697'
    //                   : statusDetails?.status === 'rejected'
    //                   ? '#FF4D4F'
    //                   : statusDetails?.status === 'signed'
    //                   ? '#1CB697'
    //                   : statusDetails?.status === 'pending'
    //                   ? '#FFC53D'
    //                   : '#FFC53D'
    //               }
    //               status="active"
    //               format={gcd}
    //               showInfo={false}
    //             />

    //             <div className={`${styles.status_text}`}>
    //               {statusDetails?.status === 'waiting_for_others'
    //                 ? 'Waiting for Others'
    //                 : statusDetails?.status === 'approved'
    //                 ? 'Approved'
    //                 : statusDetails?.status === 'rejected'
    //                 ? 'Rejected'
    //                 : statusDetails?.status === 'pending'
    //                 ? 'Pending'
    //                 : statusDetails?.status === 'signed'
    //                 ? 'Signed'
    //                 : 'NA'}
    //             </div>
    //           </div>
    //         </Col>
    //         <Col
    //           span={6}
    //           style={{
    //             marginLeft: '-40px',
    //             fontSize: '13px',
    //             marginTop: '-2px'
    //           }}
    //         >{`${statusDetails?.action_taken_apprvs}/${statusDetails?.total_apprvs}`}</Col>
    //       </Row>
    //     </>
    //   )
    // },
    // {
    //   title: 'Actions',
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   width: '20%',
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Row>
    //           <CustomButton
    //             // loading={loading}
    //             className={`${styles.use_button} ml-0 mr-0`}
    //             onClick={() => {
    //               HendaleDownload(record);
    //             }}
    //             disabled={
    //               record?.actionTakenApprvs !== record?.totalApprvs ||
    //               (record?.actionTakenApprvs === null &&
    //                 record?.totalApprvs === null)
    //             }
    //           >
    //             {'Download'}
    //           </CustomButton>
    //           <CustomButton
    //             className={`${styles.use_button} ml-2 mr-0`}
    //             onClick={() => handleHistory(record)}
    //           >
    //             {'Audit Trail'}
    //           </CustomButton>
    //         </Row>
    //       </>
    //     );
    //   }
    // }
  ];

  let locale = {
    emptyText: (
      <div>
        <Empty description={`No PIF request to display`} />
      </div>
    )
  };

  return (
    <Spin spinning={getPifFdListLoading}>
      <div className={`mb-4 ${styles.outer_container}`}>
        <div className={`mb-4 ${styles.pending_container}`}>
          <div className={`${styles.title_text}`}>
            {selectedTab === 1
              ? 'All PIFs'
              : selectedTab === 2
              ? 'Pending'
              : selectedTab === 3
              ? 'Approved'
              : selectedTab === 4
              ? 'Rejected'
              : ''}
          </div>
          <div>
            <SearchBar
              justify="start"
              align="middle"
              gutter={32}
              inputProps={{
                placeholder: 'Search by request no'
              }}
              filters={false}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div>
          <Table
            showSorterTooltip={false}
            locale={locale}
            rowKey={(record) => record.id}
            className={`${styles.custom_table}`}
            columns={columns}
            dataSource={pifData}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total: pagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
            scroll={{ x: isMobile ? true : false }}
          />
        </div>
        {showHistoryModal ? (
          <Modal
            title="Audit Trail"
            visible={showHistoryModal}
            onCancel={() => setShowHistoryModal(false)}
            footer={false}
          >
            <Timeline>
              {allApproverList?.map((item, index) => {
                return (
                  <Timeline.Item
                    key={index}
                    color={
                      item.status === 'rejected'
                        ? '#FF4D4F'
                        : item.status === 'signed'
                        ? '#52C41A'
                        : item.status === 'approved'
                        ? '#52C41A'
                        : item.status === 'waiting_for_others'
                        ? '#FFC53D'
                        : '#91caff'
                    }
                  >
                    {` ${item?.user?.first_name} ${item?.user?.last_name}`}
                    {item.status !== '' ? (
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>{`${
                          item.status === 'signed'
                            ? 'Signed'
                            : item.status === 'rejected'
                            ? 'Rejected'
                            : item.status === 'approved'
                            ? 'Approved'
                            : item.status === '' || item.status === null
                            ? `Waiting for Others ${moment(
                                item.createdAt
                              ).format('DD MMM YY, h:mm A')}`
                            : item.status
                        } on ${moment(item.updatedAt).format(
                          'DD MMM YY, h:mm A'
                        )}`}</span>
                      </div>
                    ) : (
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>{`${
                          item.status === '' || item.status === null
                            ? `Waiting for Others ${moment(
                                item.createdAt
                              ).format('DD MMM YY, h:mm A')}`
                            : item.status
                        }`}</span>
                      </div>
                    )}
                    {item.remarks !== '' && item.remarks !== null ? (
                      <>
                        <div className={`${styles.content_text}`}>
                          <span style={{ fontSize: '9px' }}>
                            <MessageOutlined />
                          </span>
                          <span style={{ marginLeft: '4px', fontSize: '8px' }}>
                            <Tooltip placement="top" title={item?.remarks}>
                              {item?.remarks?.length > 40
                                ? item?.remarks.substring(0, 40) + '...'
                                : item?.remarks}
                            </Tooltip>
                          </span>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Modal>
        ) : (
          ''
        )}
      </div>
    </Spin>
  );
};

export default All_ApproverList;
