import CustomLayout from '@/common/Layout';
import React from 'react';
import ApproverAPIListViewPage from './components';

const ApproverAPiListView = () => {
  return (
    <CustomLayout sider={false}>
      <ApproverAPIListViewPage />
    </CustomLayout>
  );
};

export default ApproverAPiListView;
