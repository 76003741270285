import CustomLayout from '@/common/Layout';
import React from 'react';
import ApproverListViewPage from './components';

const ApproverListView = () => {
  return (
    <CustomLayout sider={false}>
      <ApproverListViewPage />
    </CustomLayout>
  );
};

export default ApproverListView;
