import React from 'react';
import CustomLayout from '@/common/Layout';
import Revise_PIF_API_FormPage from './components/revise_PIF_API_FormPage';

const Revise_PIF_API_Form = () => {
  return (
    <CustomLayout>
      <Revise_PIF_API_FormPage />
    </CustomLayout>
  );
};

export default Revise_PIF_API_Form;
