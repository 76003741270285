import SearchBar from '@/common/Content/searchBar';
import { sizeChecker } from 'utilities/helpers';

import {
  // CheckOutlined,
  // ClockCircleOutlined,
  // CloseOutlined,
  // DownOutlined,
  // ForwardOutlined,
  MessageOutlined,
  EditOutlined,
  DownloadOutlined,
  AuditOutlined,
  FormOutlined,
  WarningOutlined,
  DeleteOutlined
} from '@ant-design/icons';

import {
  Empty,
  Progress,
  Modal,
  Col,
  Row,
  Timeline,
  Table,
  Tooltip,
  Spin,
  Typography,
  Badge
} from 'antd';

import React, { useState } from 'react';
import styles from './index.module.less';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import { useDispatch } from 'react-redux';
import { removeDraft } from '@/features/signatoryFlow/redux/slice';
// import { getLoggedInUser } from 'utilities/helpers';
// import { apiUrl } from 'utilities/apiClient';

const All_RequesterList = ({
  pifFdListData,
  pagination,
  page,
  setPage,
  pageSize,
  setPageSize,
  handleSearchChange,
  selectedTab,
  getPifFdListLoading,
  hasAccess,
  getDraftList
  //getFormDetailsByIdData
}) => {
  //const user = getLoggedInUser();
  //const authToken = user?.accessToken;
  const isMobile = sizeChecker('xs');
  localStorage.setItem('Edit_PIF_form_start', false);
  localStorage.setItem('Draft_PIF_form_start', false);

  localStorage.setItem('PIF_form_id', null);
  localStorage.setItem('fd_info_status', false);
  localStorage.setItem('ref_table', false);
  localStorage.setItem('prop_table', false);
  localStorage.setItem('Ip_patentScenario_table', false);
  localStorage.setItem('Ip_exclusivities_table', false);
  localStorage.setItem('Ip_generic_launch_date', false);
  localStorage.setItem('RD_Timeline_table', false);
  localStorage.setItem('RD_Budget_table', false);
  localStorage.setItem('RD_Batch_size', false);

  localStorage.setItem('BJ_Edit', false);
  localStorage.setItem('market_data_edit', false);
  localStorage.setItem('signatory_Edit', false);
  localStorage.setItem('upload_Edit', false);
  localStorage.setItem('selectedTab', '');
  const dispatch = useDispatch();

  //const [loading, setLoading] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [allApproverList, setAllApproverList] = useState();
  const [isReviseModalOpen, setIsReviseModalOpen] = useState(false);
  const [revisedRecord, setRevisedRecord] = useState();
  const [optionalRecord, setOptionalRecord] = useState();
  const [isOptionalModalOpen, setIsOptionalModalOpen] = useState(false);
  const gcd = function (a, b) {
    if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

    return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
  };

  const HendaleDownload = (record) => {
    if (hasAccess) return;
    console.log('id', record);
    const link = document.createElement('a');
    link.href = record?.pdf_pif;
    //link.download = 'pif_fd.pdf';
    // Set the target to '_blank' to open in a new tab
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    //setLoading(true);

    // fetch(`${apiUrl}/preview?pif_id=${record?.id}`, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/pdf',
    //     Authorization: `Bearer ${authToken}`
    //   }
    // })
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // setLoading(false);
    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     console.log('url:::', url);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `download.pdf`);
    //     document.body.appendChild(link);
    //     link.click();
    //     link.parentNode.removeChild(link);
    //   });
  };

  const handleReviseModalOpen = (record) => {
    if (hasAccess) return;
    console.log('record', record);
    setRevisedRecord(record);
    setIsReviseModalOpen(true);
  };

  const handleReviseModalClose = () => setIsReviseModalOpen(false);

  const handleOptionalModalOpen = (record) => {
    if (hasAccess) return;
    console.log('record', record);
    setOptionalRecord(record);
    setIsOptionalModalOpen(true);
  };

  console.log('revisedRecords>>>', revisedRecord);

  const handleOptionalModalClose = () => setIsOptionalModalOpen(false);

  const handleHistory = (record) => {
    setShowHistoryModal(true);
    record ? setAllApproverList(record?.allApprover) : '';
  };

  const handleEdit = (record) => {
    if (hasAccess) return;
    localStorage.setItem('Edit_PIF_form_start', true);
    window.location.href = `/edit-pif-fd-form/${record.id}`;
  };

  const handleReviseEdit = (record) => {
    if (hasAccess) return;
    localStorage.setItem('Edit_PIF_form_start', true);
    window.location.href = `/revise_edit-pif-fd-form/${record.id}`;
  };
  const handleDraft = (record) => {
    if (hasAccess) return;
    localStorage.setItem('Draft_PIF_form_start', true);
    window.location.href = `/draft-pif-fd-form/${record.id}`;
  };

  const handleDeleteDraft = (record) => {
    dispatch(removeDraft({ draft_id: record.id }))?.then(function (response) {
      console.log('..DELETE', response.payload);
      if (response?.payload?.success) {
        getDraftList();
      }
    });
  };

  const pifData = pifFdListData?.map((item) => ({
    id: item?.id,
    request_id: item?.request_id,
    pif_num: item?.pif_num,
    reference_product_details:
      item?.ref_product_detail === null
        ? 'NA'
        : item?.ref_product_detail?.product_name,
    statusDetails: {
      total_apprvs: item?.total_apprvs === null ? 0 : item?.total_apprvs,
      action_taken_apprvs:
        item?.action_taken_apprvs === null ? 0 : item?.action_taken_apprvs,
      status: item?.status
    },
    actionTakenApprvs: item?.action_taken_apprvs,
    totalApprvs: item?.total_apprvs,
    allApprover: item?.all_approvers,
    pdf_pif: item?.pdf_pif?.fileUrl,
    status: item?.status,
    edit_button: item?.edit_button,
    optional_edit_btn: item?.optional_edit_btn,
    mandatory_edit_btn: item?.mandatory_edit_btn,
    changes_in_progress: item?.changes_in_progress
  }));

  const columns = [
    {
      title: (
        <Tooltip
          title={'PIF Request No.'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          PIF Request No.
        </Tooltip>
      ),
      dataIndex: 'request_id',
      key: 'request_id',
      width: '24%',
      sorter: (a, b) => a?.request_id?.localeCompare(b?.request_id),
      render: (request_id, record) => (
        <>
          <Row className={styles.pif_request_style}>
            <Tooltip
              title={request_id}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a
                href={
                  record?.status === 'draft'
                    ? '#'
                    : `/requester-preview/${record.id}`
                }
                disabled={record?.status === 'draft' || hasAccess}
              >
                <span>
                  {' '}
                  {request_id === null || request_id === '' ? 'NA' : request_id}
                </span>
              </a>
            </Tooltip>
            {record?.changes_in_progress === true && (
              <Badge
                count={'Changes in progress'}
                style={{
                  backgroundColor: '#e6f4ff',
                  color: '#333333',
                  textTransform: 'none',
                  fontSize: '10px'
                }}
              />
            )}
          </Row>
        </>
      )
    },
    {
      title: (
        <Tooltip
          title={'PIF No.'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          PIF No.
        </Tooltip>
      ),
      dataIndex: 'pif_num',
      key: 'pif_num',
      ellipsis: true,
      // width: '45%',
      sorter: (a, b) => a?.pif_num?.localeCompare(b?.pif_num),
      render: (pif_num) => (
        <>
          <Tooltip
            title={pif_num}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {pif_num === null ? 'NA' : pif_num}
          </Tooltip>
        </>
      )
    },
    {
      title: (
        <Tooltip
          title={'Reference Product'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Ref. Product
        </Tooltip>
      ),
      dataIndex: 'reference_product_details',
      key: 'reference_product_details',
      ellipsis: true,
      //width: '25%',
      render: (reference_product_details) => (
        <>
          <Tooltip
            title={reference_product_details}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            <span className={styles.text_style}>
              {reference_product_details === null
                ? 'NA'
                : reference_product_details}
            </span>
          </Tooltip>
        </>
      )
    },
    {
      title: 'Status',
      key: 'statusDetails',
      dataIndex: 'statusDetails',
      width: '20%',
      render: (statusDetails) => (
        <>
          <Row>
            <Col span={18}>
              <div className={`${styles.progress_style}`}>
                <Progress
                  size="small"
                  // success={{ percent: 30 }}
                  percent={`${Math.floor(
                    (statusDetails?.action_taken_apprvs /
                      statusDetails?.total_apprvs) *
                      100
                  )}`}
                  strokeColor={
                    statusDetails?.status === 'approved'
                      ? '#52C41A'
                      : statusDetails?.status === 'rejected'
                      ? '#FF4D4F'
                      : statusDetails?.status === 'signed'
                      ? '#52C41A'
                      : statusDetails?.status === 'draft'
                      ? '#e6f7ff'
                      : statusDetails?.status === 'pending'
                      ? '#FFC53D'
                      : statusDetails?.status === 'waiting_for_others'
                      ? '#FFC53D'
                      : '#FFC53D'
                  }
                  status="active"
                  format={gcd}
                  showInfo={false}
                />

                <div className={`${styles.status_text}`}>
                  {statusDetails?.status === 'waiting_for_others'
                    ? 'Waiting for Others'
                    : statusDetails?.status === 'approved'
                    ? 'Approved'
                    : statusDetails?.status === 'rejected'
                    ? 'Rejected'
                    : statusDetails?.status === 'pending'
                    ? 'Pending'
                    : statusDetails?.status === 'signed'
                    ? 'Signed'
                    : statusDetails?.status === 'draft'
                    ? 'Draft'
                    : 'NA'}
                </div>
              </div>
            </Col>
            <Col
              span={6}
              style={{
                marginLeft: '-10px', //---newly added for- number
                fontSize: '11px', //---newly added for- number
                marginTop: '-2px'
              }}
            >{`${statusDetails?.action_taken_apprvs}/${statusDetails?.total_apprvs}`}</Col>
          </Row>
        </>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '15%',
      render: (_, record) => {
        return (
          <>
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col span={6}>
                <Tooltip title="Download">
                  <a
                    type="link"
                    className={`${styles.use_button}`}
                    onClick={() => {
                      record?.actionTakenApprvs !== record?.totalApprvs ||
                      (record?.actionTakenApprvs === null &&
                        record?.totalApprvs === null)
                        ? ''
                        : HendaleDownload(record);
                    }}
                    disabled={
                      record?.actionTakenApprvs !== record?.totalApprvs ||
                      (record?.actionTakenApprvs === null &&
                        record?.totalApprvs === null) ||
                      hasAccess
                    }
                  >
                    <DownloadOutlined />
                  </a>
                </Tooltip>
              </Col>
              <Col span={6}>
                <Tooltip title="Audit Trail">
                  <a
                    className={`${styles.use_button}`}
                    onClick={() =>
                      record?.status === 'draft' ? '' : handleHistory(record)
                    }
                    disabled={record?.status === 'draft' || hasAccess}
                  >
                    <AuditOutlined />
                  </a>
                </Tooltip>{' '}
              </Col>
              <Col span={6}>
                {record?.status === 'draft' ? (
                  <Tooltip title="Edit">
                    <a className={`${styles.edit_button}`} disabled={hasAccess}>
                      <EditOutlined
                        onClick={() =>
                          record?.edit_button === true &&
                          record?.status === 'draft'
                            ? handleDraft(record)
                            : ''
                        }
                      />
                    </a>
                  </Tooltip>
                ) : record?.status === 'approved' ? (
                  <>
                    <Tooltip title="Edit">
                      <a
                        disabled={
                          (record?.status === 'approved' &&
                            record?.optional_edit_btn === false) ||
                          hasAccess
                        }
                        className={`${styles.edit_button}`}
                      >
                        <EditOutlined
                          onClick={() =>
                            record?.status === 'approved' &&
                            record?.optional_edit_btn === true
                              ? handleOptionalModalOpen(record)
                              : ''
                          }
                        />
                      </a>
                    </Tooltip>
                    <Modal
                      title={
                        <span>
                          <WarningOutlined
                            style={{
                              marginRight: '2%',
                              color: 'red'
                            }}
                          />
                          Optional Alert
                        </span>
                      }
                      className="optional-modal"
                      centered
                      open={isOptionalModalOpen}
                      onCancel={handleOptionalModalClose}
                      width={'470px'}
                      style={{
                        backgroundColor: 'transparent !important'
                      }}
                      // bodyStyle={{
                      //   padding: 0,
                      //   paddingInline: '0.5rem'
                      // }}
                      maskStyle={{
                        backgroundColor: 'transparent !important',
                        opacity: '0.2'
                      }}
                      footer={[
                        <CustomButton
                          key="submit"
                          type="primary"
                          onClick={() =>
                            optionalRecord?.status === 'approved' &&
                            optionalRecord?.optional_edit_btn === true
                              ? handleEdit(optionalRecord)
                              : ''
                          }
                          // style={{ borderRadius: '0.5rem' }}
                          className={styles.post_btn_style}
                          disabled={hasAccess}
                        >
                          Proceed
                        </CustomButton>,
                        <CustomButton
                          key="back"
                          onClick={handleOptionalModalClose}
                          // style={{ borderRadius: '0.5rem' }}
                          className={styles.back_btn_style}
                        >
                          Cancel
                        </CustomButton>
                      ]}
                    >
                      <Typography className={styles.alert_text}>
                        You are now going to edit only the optional fields in
                        the form. It will update the PIF form and it will not
                        trigger the approval flow
                      </Typography>
                    </Modal>
                  </>
                ) : (
                  ''
                )}{' '}
              </Col>
              {record?.status === 'draft' && (
                <Col span={6}>
                  <Tooltip title="Delete">
                    <a className={`${styles.edit_button}`} disabled={hasAccess}>
                      <DeleteOutlined
                        onClick={() =>
                          record?.status === 'draft'
                            ? handleDeleteDraft(record)
                            : ''
                        }
                      />
                    </a>
                  </Tooltip>
                </Col>
              )}
              <Col span={6}>
                {record?.status === 'approved' ? (
                  <>
                    <Tooltip title="Revise">
                      <a
                        disabled={
                          (record?.status === 'approved' &&
                            record?.mandatory_edit_btn === false) ||
                          hasAccess
                        }
                        className={`${styles.edit_button}`}
                      >
                        <FormOutlined
                          onClick={() => {
                            console.log('record', record);
                            record?.status === 'approved' &&
                            record?.mandatory_edit_btn === true
                              ? handleReviseModalOpen(record)
                              : '';
                          }}
                        />
                      </a>
                    </Tooltip>
                    <Modal
                      title={
                        <span>
                          <WarningOutlined
                            style={{
                              marginRight: '2%',
                              color: 'red'
                            }}
                          />
                          Revise Alert
                        </span>
                      }
                      className="revise-modal"
                      centered
                      open={isReviseModalOpen}
                      onCancel={handleReviseModalClose}
                      width={'470px'}
                      style={{
                        backgroundColor: 'transparent !important'
                      }}
                      // bodyStyle={{
                      //   padding: 0,
                      //   paddingInline: '0.5rem'
                      // }}
                      maskStyle={{
                        backgroundColor: 'transparent !important',
                        opacity: '0.2'
                      }}
                      footer={[
                        <CustomButton
                          key="submit"
                          type="primary"
                          onClick={() =>
                            revisedRecord?.status === 'approved' &&
                            revisedRecord?.mandatory_edit_btn === true
                              ? handleReviseEdit(revisedRecord)
                              : ''
                          }
                          // style={{ borderRadius: '0.5rem' }}
                          className={styles.post_btn_style}
                          disabled={hasAccess}
                        >
                          Proceed
                        </CustomButton>,
                        <CustomButton
                          key="back"
                          onClick={handleReviseModalClose}
                          // style={{ borderRadius: '0.5rem' }}
                          className={styles.back_btn_style}
                        >
                          Cancel
                        </CustomButton>
                      ]}
                    >
                      <Typography className={styles.alert_text}>
                        You are now editing a field, either mandatory or
                        optional, in the PIF form. Once updated, the PIF form
                        will initiate an approval process, and its version will
                        change
                      </Typography>
                    </Modal>
                  </>
                ) : (
                  ''
                )}{' '}
              </Col>
            </Row>
          </>
        );
      }
    }
  ];

  let locale = {
    emptyText: (
      <div>
        <Empty description={`No PIF request to display`} />
      </div>
    )
  };

  return (
    <Spin spinning={getPifFdListLoading}>
      <div className={`mb-4 ${styles.outer_container}`}>
        <div className={`mb-4 ${styles.pending_container}`}>
          <div className={`${styles.title_text}`}>
            {selectedTab === 1
              ? 'All PIFs'
              : selectedTab === 2
              ? 'Draft'
              : selectedTab === 3
              ? 'Pending'
              : selectedTab === 4
              ? 'Approved'
              : selectedTab === 5
              ? 'Rejected'
              : ''}
          </div>
          <div>
            <SearchBar
              justify="start"
              align="middle"
              gutter={32}
              inputProps={{
                placeholder: 'Search by PIF request no'
              }}
              filters={false}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div>
          <Table
            showSorterTooltip={false}
            locale={locale}
            rowKey={(record) => record.id}
            className={`${styles.custom_table}`}
            columns={columns}
            dataSource={pifData}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total: pagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
            scroll={{ x: isMobile ? true : false }}
          />
        </div>
        {showHistoryModal ? (
          <Modal
            title="Audit Trail"
            visible={showHistoryModal}
            onCancel={() => setShowHistoryModal(false)}
            footer={false}
          >
            <Timeline>
              {allApproverList?.map((item, index) => {
                return (
                  <Timeline.Item
                    key={index}
                    color={
                      item.status === 'rejected'
                        ? '#FF4D4F'
                        : item.status === 'signed'
                        ? '#52C41A'
                        : item.status === 'approved'
                        ? '#52C41A'
                        : item.status === 'draft'
                        ? '#FFC53D'
                        : item.status === 'waiting_for_others'
                        ? '#91caff'
                        : '#FFC53D'
                    }
                  >
                    {` ${item?.user?.first_name} ${item?.user?.last_name}`}
                    {item.status !== '' ? (
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>{`${
                          item.status === 'signed'
                            ? 'Signed'
                            : item.status === 'rejected'
                            ? 'Rejected'
                            : item.status === 'approved'
                            ? 'Approved'
                            : item.status === 'draft'
                            ? 'Draft'
                            : item.status === '' || item.status === null
                            ? `Waiting for Others ${moment(
                                item.createdAt
                              ).format('DD MMM YY, h:mm A')}`
                            : item.status
                        } on ${moment(item.updatedAt).format(
                          'DD MMM YY, h:mm A'
                        )}`}</span>
                      </div>
                    ) : (
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>{`${
                          item.status === '' || item.status === null
                            ? `Waiting for Others ${moment(
                                item.createdAt
                              ).format('DD MMM YY, h:mm A')}`
                            : item.status
                        }`}</span>
                      </div>
                    )}
                    {item.remarks !== '' && item.remarks !== null ? (
                      <>
                        <div className={`${styles.content_text}`}>
                          <span style={{ fontSize: '9px' }}>
                            <MessageOutlined />
                          </span>
                          <span style={{ marginLeft: '4px', fontSize: '8px' }}>
                            <Tooltip placement="top" title={item?.remarks}>
                              {item?.remarks?.length > 40
                                ? item?.remarks.substring(0, 40) + '...'
                                : item?.remarks}
                            </Tooltip>
                          </span>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Modal>
        ) : (
          ''
        )}
      </div>
    </Spin>
  );
};

export default All_RequesterList;
