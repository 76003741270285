import React from 'react';
import ReviseEdit_PIF_FD_FormPage from './components/revise_edit_PIF_FD_FormPage';
import CustomLayout from '@/common/Layout';

const ReviseEdit_PIF_FD_Form = () => {
  return (
    <CustomLayout sider={false}>
      <ReviseEdit_PIF_FD_FormPage />
    </CustomLayout>
  );
};

export default ReviseEdit_PIF_FD_Form;
