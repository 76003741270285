import styles from './stepper.module.less';
import React from 'react';
import { Space, Steps, Row, Col, Select } from 'antd';
import { PlusCircleFilled, DeleteOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';
//import miss_icon from '../../images/miss_icon.svg';

const { Option } = Select;
const { Step } = Steps;

const ApproversStepper = ({
  dropDown_signatoriesData,
  debounceSignatories,
  fields,
  setFields,
  generateKey_bs,
  disabledOptions,
  setDisabledOptions
}) => {
  const addField = () => {
    const newField = {
      key: generateKey_bs(),
      user_id: '',
      approver_type: 'approver'
    };
    setFields([...fields, newField]);
  };

  const deleteField = (key) => {
    const fieldToDelete = fields?.find((field) => field.key === key);
    const updatedFields = fields?.filter((field) => field.key !== key);
    setFields(updatedFields);

    if (fieldToDelete) {
      const updatedDisabledOptions = disabledOptions.filter(
        (id) => id !== fieldToDelete.user_id
      );
      setDisabledOptions(updatedDisabledOptions);
    }
  };

  const handleInputChange = (key, value) => {
    const updatedFields = fields?.map((field) =>
      field.key === key ? { ...field, user_id: value } : field
    );
    setFields(updatedFields);

    // Retrieve the previously disabled option related to the changed row
    const prevDisabledOption = fields?.find(
      (item) => item?.key === key
    )?.user_id;

    // Filter out the previously disabled option related to the same key
    const updatedDisabledOptions = disabledOptions.filter(
      (id) => id !== prevDisabledOption
    );

    // Add the selected option to the disabledOptions array
    setDisabledOptions([...updatedDisabledOptions, value]);
  };
  console.log('fields_signatories:', fields);
  return (
    <Space
      direction="vertical"
      size={5}
      className={`${styles.Space_container}`}
    >
      <Row className={`${styles.row_container}`}>
        <Col span={2}>
          <Steps direction="vertical" size="small">
            {fields?.map((field) => (
              <Step
                key={field.key}
                status={
                  field.key === fields[fields.length - 1].key
                    ? 'process'
                    : 'wait'
                }
              />
            ))}
          </Steps>
        </Col>

        <Col span={22}>
          {fields?.map((field) => (
            <Row key={field.key}>
              <Col span={12}>
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.select_styles}`}
                >
                  {console.log('field?.user_id>>>>>>>', field?.user_id)}
                  <Select
                    showSearch
                    onSearch={debounceSignatories}
                    defaultValue={field?.user_id}
                    placeholder="Search and add signatories"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option?.children
                        ?.toString()
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase());
                    }}
                    onChange={(value) => handleInputChange(field?.key, value)}
                  >
                    {dropDown_signatoriesData?.map((item) => {
                      return (
                        <Option
                          value={item.id}
                          key={item.id} //{index}
                          disabled={disabledOptions?.includes(item.id)}
                        >
                          {`${item.first_name} (${item.email})`}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col span={4}>
                {fields?.length > 1 && (
                  <span
                    //style={{ marginLeft: '10px' }}
                    className={`${styles.container_deleteIcon}`}
                  >
                    <DeleteOutlined
                      onClick={() => deleteField(field?.key)}
                      className={`${styles.inputList_deleteIcon}`}
                    />
                  </span>
                )}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <div onClick={addField} className={`${styles.add_container}`}>
        <PlusCircleFilled className={`${styles.add_plusIcon}`} />
        <span className={`${styles.add_plusTex}`}>Add approver</span>
      </div>
    </Space>
  );
};

export default ApproversStepper;
