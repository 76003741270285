import React, { useEffect } from 'react';
import { Table, Space, Input, Dropdown, Menu, Select } from 'antd';
import { DownOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './us_ims_table.module.less';
const { Option } = Select;
import FormItem from 'common/FormItem';

const IMS__API_table = ({
  dropDown_table_headersData,
  dataSource,
  setDataSource,
  // count,
  setCount,
  selectedYears,
  setSelectedYears,
  dropDown_md_ims_data,
  debounceMd_ims,
  global_total,
  dataSourceWithTotals,
  totals,
  setTotals,
  setDataSourceWithTotals,
  generateKey
  // headerStyle
}) => {
  useEffect(() => {
    logTableData();
    calculateTotals(dataSource);
  }, [dataSource, selectedYears]);
  const handleMenuClick = (e, dataIndex) => {
    setSelectedYears({ ...selectedYears, [dataIndex]: parseInt(e.key) });
  };

  const renderDropdown = (title, dataIndex) => (
    <Dropdown
      overlay={
        <Menu
          style={{ maxHeight: '10rem', overflowY: 'auto' }}
          onClick={(e) => handleMenuClick(e, dataIndex)}
        >
          {dropDown_table_headersData?.map((item) => (
            <Menu.Item key={item.id}>{item.name}</Menu.Item>
          ))}
        </Menu>
      }
    >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        {title} <DownOutlined />
      </a>
    </Dropdown>
  );

  const getColumnTitle = (title, dataIndex) => {
    const selectedYearId = selectedYears[dataIndex];
    if (selectedYearId) {
      const yearName = dropDown_table_headersData?.find(
        (item) => item.id === selectedYearId
      )?.name;
      return `${title} (${yearName})`;
    }
    return title;
  };

  const calculateGR = (val1, val2) => {
    if (
      val1 !== undefined &&
      val2 !== undefined &&
      !isNaN(val1) &&
      !isNaN(val2)
    ) {
      const gr = ((val2 - val1) / val1) * 100;
      if (isNaN(gr)) {
        return 0;
      } else if (!isFinite(gr)) {
        return 'NaN';
      } else {
        return gr.toFixed(2);
      }
    }
    return '';
  };

  const calculateTotals = (data) => {
    const data_notTotal = data?.filter(
      (item) => item.units_and_others_id !== global_total[0]?.id
    );
    const totals = {
      sales1: 0,
      sales2: 0,
      api1: 0,
      api2: 0,
      vol1: 0,
      vol2: 0,
      gr: 0
    };

    data_notTotal?.forEach((item) => {
      totals.sales1 += parseFloat(item.sales1) || 0;
      totals.sales2 += parseFloat(item.sales2) || 0;
      totals.vol1 += parseFloat(item.vol1) || 0;
      totals.vol2 += parseFloat(item.vol2) || 0;
      totals.api1 += parseFloat(item.api1) || 0;
      totals.api2 += parseFloat(item.api2) || 0;
      totals.gr = calculateGR(totals.vol1, totals.vol2);
    });

    setTotals(totals);
  };

  const handleInputChange = (value, key, column) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      item[column] = value;

      if (column === 'vol1' || column === 'vol2') {
        item.gr = calculateGR(parseFloat(item.vol1), parseFloat(item.vol2));
      }

      newData.splice(index, 1, { ...item });
      setDataSource(newData);
      calculateTotals(newData);
    }
  };
  console.log('..IMS DARTA', dataSource);

  const columns = [
    {
      title: 'Strength',
      dataIndex: 'units_and_others_id',
      // onHeaderCell: headerStyle,
      key: 'units_and_others_id',
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          className={`${styles.select_styles}`}
        >
          <Select
            disabled={record?.units_and_others_id === global_total[0]?.id}
            showSearch
            onSearch={debounceMd_ims}
            placeholder="Select strength"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            defaultValue={record?.units_and_others_id}
            onChange={(value) =>
              handleInputChange(value, record.key, 'units_and_others_id')
            }
            className={styles.select_styles} // Add this class for custom styling
            popupClassName={styles.select_dropdown} // Add this class for custom dropdown styling
          >
            {dropDown_md_ims_data?.map((item, index) => {
              return (
                <Option
                  disabled={item?.id === global_total[0]?.id}
                  value={item.id}
                  key={index}
                >
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      title: renderDropdown(getColumnTitle('Sales($ Mn)', 'sales1'), 'sales1'),
      dataIndex: 'sales1',
      // onHeaderCell: headerStyle,

      key: 'sales1',
      render: (text, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          <Input
            type="number"
            step="0.01"
            min={0}
            readOnly={
              record?.units_and_others_id === global_total[0]?.id ? true : false
            }
            defaultValue={text}
            onChange={(e) =>
              handleInputChange(e.target.value, record.key, 'sales1')
            }
            // className="border-none"
            placeholder="Click to add"
          />
        </FormItem>
      )
    },
    {
      title: renderDropdown(getColumnTitle('Sales($ Mn)', 'sales2'), 'sales2'),
      dataIndex: 'sales2',
      // onHeaderCell: headerStyle,

      key: 'sales2',
      render: (text, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          <Input
            type="number"
            step="0.01"
            readOnly={
              record?.units_and_others_id === global_total[0]?.id ? true : false
            }
            min={0}
            defaultValue={text}
            onChange={(e) =>
              handleInputChange(e.target.value, record.key, 'sales2')
            }
            // className="border-none"
            placeholder="Click to add"
          />
        </FormItem>
      )
    },
    {
      title: renderDropdown(getColumnTitle('Volume(Mn)', 'vol1'), 'vol1'),
      dataIndex: 'vol1',
      // onHeaderCell: headerStyle,

      key: 'vol1',
      render: (text, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          <Input
            readOnly={
              record?.units_and_others_id === global_total[0]?.id ? true : false
            }
            type="number"
            step="0.01"
            min={0}
            defaultValue={text}
            onChange={(e) =>
              handleInputChange(e.target.value, record.key, 'vol1')
            }
            // className="border-none"
            placeholder="Click to add"
          />
        </FormItem>
      )
    },
    {
      title: renderDropdown(getColumnTitle('Volume(Mn)', 'vol2'), 'vol2'),
      dataIndex: 'vol2',
      // onHeaderCell: headerStyle,

      key: 'vol2',
      render: (text, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          <Input
            readOnly={
              record?.units_and_others_id === global_total[0]?.id ? true : false
            }
            type="number"
            step="0.01"
            min={0}
            defaultValue={text}
            onChange={(e) =>
              handleInputChange(e.target.value, record.key, 'vol2')
            }
            // className="border-none"
            placeholder="Click to add"
          />
        </FormItem>
      )
    },
    {
      title: renderDropdown(getColumnTitle('API(Kgs)', 'api1'), 'api1'),
      dataIndex: 'api1',
      // onHeaderCell: headerStyle,

      key: 'api1',
      render: (text, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          <Input
            readOnly={
              record?.units_and_others_id === global_total[0]?.id ? true : false
            }
            type="number"
            step="0.01"
            min={0}
            defaultValue={text}
            onChange={(e) =>
              handleInputChange(e.target.value, record.key, 'api1')
            }
            // className="border-none"
            placeholder="Click to add"
          />
        </FormItem>
      )
    },
    {
      title: renderDropdown(getColumnTitle('API(Kgs)', 'api2'), 'api2'),
      dataIndex: 'api2',
      // onHeaderCell: headerStyle,

      key: 'api2',
      render: (text, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          <Input
            readOnly={
              record?.units_and_others_id === global_total[0]?.id ? true : false
            }
            type="number"
            step="0.01"
            min={0}
            defaultValue={text}
            onChange={(e) =>
              handleInputChange(e.target.value, record.key, 'api2')
            }
            // className="border-none"
            placeholder="Click to add"
          />
        </FormItem>
      )
    },
    {
      title: '% GR(Vol)',
      dataIndex: 'gr',
      // onHeaderCell: headerStyle,

      key: 'gr',
      render: (text /* , record */) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          {' '}
          <Input
            value={text}
            readOnly
            // className="border-none"
            placeholder="Click to add"
          />
        </FormItem>
      )
    },
    {
      title: 'Action',
      key: 'action',
      // onHeaderCell: headerStyle,
      render: (/* text, */ record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          {record?.units_and_others_id === global_total[0]?.id ? null : (
            <Space
              size="middle"
              style={{
                display: 'flex',
                justifyContent: 'center' /* paddingLeft: '16px' */
              }}
            >
              <PlusOutlined
                className={`${styles.plusIcon}`}
                onClick={handleAdd}
              />
              {(
                dataSource?.some(
                  (data) => data.units_and_others_id === global_total[0]?.id
                )
                  ? dataSource?.length > 2
                  : dataSource?.length > 1
              ) ? (
                <DeleteOutlined
                  className={`${styles.deleteIcon}`}
                  onClick={() => handleDelete(record.key)}
                />
              ) : null}
            </Space>
          )}
        </FormItem>
      )
    }
  ];

  const handleAdd = () => {
    const newData = {
      key: generateKey,
      units_and_others_id: '',
      sales1: '',
      sales2: '',
      vol1: '',
      vol2: '',
      api1: '',
      api2: '',
      gr: ''
    };
    const updatedData = [...dataSource, newData];
    setDataSource(updatedData);
    setCount(updatedData.length);
    calculateTotals(updatedData);
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const logTableData = () => {
    console.log('Table Data:', dataSource);
  };

  // const transformData1 = (dataSource) => {
  //   console.log('rawData_dataSource:', dataSource);
  //   const transformedData = dataSource?.map((row) => ({
  //     units_and_others_id: row.units_and_others_id,
  //     ims_strength_values: [
  //       {
  //         fy_master_id: selectedYears.sales1 || '',
  //         value: parseFloat(row.sales1)
  //       },
  //       {
  //         fy_master_id: selectedYears.sales2 || '',
  //         value: parseFloat(row.sales2)
  //       },
  //       {
  //         fy_master_id: selectedYears.vol1 || '',
  //         value: parseFloat(row.vol1)
  //       },
  //       {
  //         fy_master_id: selectedYears.vol2 || '',
  //         value: parseFloat(row.vol2)
  //       },
  //       {
  //         fy_master_id: '',
  //         value: parseFloat(row.gr)
  //       }
  //     ]
  //   }));
  //   return transformedData;
  // };

  useEffect(() => {
    const dataSource_notTotal = dataSource?.filter(
      (item) => item.units_and_others_id !== global_total[0]?.id
    );
    console.log('...total: ' + dataSource_notTotal);
    const dataSourceWithTotals1 = [
      ...dataSource_notTotal,
      {
        key: `totals-${Date.now()}`, // Unique key for the totals row
        units_and_others_id: global_total[0]?.id,
        vol1: totals.vol1?.toFixed(2),
        vol2: totals.vol2?.toFixed(2),
        sales1: totals.sales1?.toFixed(2),
        sales2: totals.sales2?.toFixed(2),
        api1: totals.api1?.toFixed(2),
        api2: totals.api2?.toFixed(2),
        gr: totals.gr
      }
    ];
    setDataSourceWithTotals(dataSourceWithTotals1);
  }, [totals, dataSource]);

  return (
    <div>
      <Table
        dataSource={dataSourceWithTotals}
        columns={columns}
        pagination={false}
        scroll={{ x: 1300 }}
        // className="custom-table"
      />
    </div>
  );
};

export default IMS__API_table;
