import apiClient, { getLocalToken } from 'utilities/apiClient';

export const signin = (payload) => {
  return apiClient.post(`${apiClient.Urls.signin}`, payload);
};

export const forgetPass = (payload) => {
  return apiClient.post(`${apiClient.Urls.forgetPass}`, payload);
};

export const resetPass = (payload) => {
  return apiClient.post(`${apiClient.Urls.resetPass}`, payload);
};

export const signout = (payload) => {
  return apiClient.post(`${apiClient.Urls.signout}`, payload, {
    Authorization: getLocalToken()
  });
};
