import React from 'react';
import ApproverDashboardPage from './components/ApproverDashboardPage';
import CustomLayout from '@/common/Layout';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardCounts, getDashboardApiCounts } from '../home/redux/slice';

const ApproverDashboard = () => {
  const dispatch = useDispatch();

  const {
    getDashboardCountsLoading,
    dashboardCountsData,
    dashboardApiCountsData
  } = useSelector((state) => state.home);

  useEffect(() => {
    let payload = {};
    dispatch(getDashboardCounts(payload));
  }, []);

  useEffect(() => {
    let payload = {};
    dispatch(getDashboardApiCounts(payload));
  }, []);

  return (
    <CustomLayout sider={false}>
      <ApproverDashboardPage
        getDashboardCountsLoading={getDashboardCountsLoading}
        dashboardCountsData={dashboardCountsData}
        dashboardApiCountsData={dashboardApiCountsData}
      />
    </CustomLayout>
  );
};

export default ApproverDashboard;
