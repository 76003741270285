import apiClient from 'utilities/apiClient';

console.log('apiClient:::::', apiClient);

export const dashboardCounts = (payload) => {
  return apiClient.get(apiClient.Urls.dashboardCounts, payload, true);
};

export const pollsResult = (payload) => {
  return apiClient.get(apiClient.Urls.pollsResult, payload, true);
};

export const PIFRequest = (payload) => {
  return apiClient.post(
    apiClient.Urls.PIFRequest,
    payload,
    true
    // 'file'
  );
};

export const dashboardApiCounts = (payload) => {
  return apiClient.get(apiClient.Urls.dashboardApiCounts, payload, true);
};
