import CustomLayout from '@/common/Layout';
import React from 'react';
import RequesterAPIListViewPage from './components';

const RequesterAPIListView = () => {
  return (
    <CustomLayout sider={false}>
      <RequesterAPIListViewPage />
    </CustomLayout>
  );
};

export default RequesterAPIListView;
