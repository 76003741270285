import { useEffect, useState /* , useMemo */ } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Collapse,
  Divider,
  Spin,
  Typography
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
//import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './ip_details.module.less';
import { useDispatch } from 'react-redux';
import FormItem from 'common/FormItem';
import CustomCollapse from '@/common/CustomCollapse';
//import { debounce } from 'lodash';

import moment from 'moment';
import { history } from 'app/history';

import Ip_patentScenario_table from '@/common/ip_patentScenario_table/Ip_patentScenario_table';
import Ip_exclusivities_table from '@/common/ip_exclusivities_table/Ip_exclusivities_table';
import Ip_generic_launch_date from '@/common/ip_generic_launch_date/Ip_generic_launch_date';
import { useResponsiveness } from 'hooks/useResponsiveness';
import Ip_granules_launch_date from '@/common/ip_granules_launch_date/Ip_granules_launch_date';
// import Ip_granules_launch_date from '@/common/ip_granules_launch_date/Ip_granules_launch_date';

const { Panel } = Collapse;

const IP_details = ({
  PIF_form_id,
  setSelectedTab,
  createUpdateIPDetail_formLoading,
  createUpdateIPDetail_form,
  completedTabs,
  setCompletedTabs,
  isTabEnabled,
  getFormDetailsByIdLoading,
  getFormDetailsByIdData,
  dropDown_patent_typeData,
  dropDown_countryData,
  dropDown_exclusivity_typeData,
  debouncePatent_type,
  debounceExclusivity_type,
  debounceCountry,
  draft_PIF_form_start
}) => {
  if (!completedTabs?.includes(4)) {
    setCompletedTabs([...completedTabs, 4]);
    //console.log('completedTabs::::', completedTabs);
  }
  isTabEnabled(4);

  const storedData = localStorage.getItem('Ip_patentScenario_table');
  const storedData1 = localStorage.getItem('Ip_exclusivities_table');
  const storedData2 = localStorage.getItem('Ip_generic_launch_date');

  //console.log('storedData222@@:', storedData2);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { xs, sm } = useResponsiveness();
  const [formData1, setFormData1] = useState({});
  //const [tagSearch, setTagSearch] = useState('');
  const [dataSource, setDataSource] = useState();
  const [dataSource1, setDataSource1] = useState();
  const [dataSource2, setDataSource2] = useState();
  const [dataSource3, setDataSource3] = useState();

  //----Ip_patentScenario_table-------
  useEffect(() => {
    if (
      storedData === 'false' ||
      getFormDetailsByIdData?.ip_detail?.patent_scenarios?.length === 0
    ) {
      setDataSource([
        {
          key: generateKey(),
          //ip_detail_id: '',
          patent_type_id: '',
          patent_expiration_us: '',
          patent_expiration_eu: '',
          patent_expiration_row: '',
          strategy: ''
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [storedData === 'false']);

  useEffect(() => {
    if (
      (getFormDetailsByIdData?.ip_detail?.patent_scenarios?.length > 0 &&
        storedData === 'true') ||
      (getFormDetailsByIdData?.ip_detail?.patent_scenarios?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      const newData = getFormDetailsByIdData?.ip_detail?.patent_scenarios?.map(
        (item) => ({
          key: item.id.toString(),
          //id: item.id,
          //ip_detail_id: '',
          patent_type_id: item?.patent_type_id,
          patent_expiration_us: item.patent_expiration_us,
          patent_expiration_eu: item.patent_expiration_eu,
          patent_expiration_row: item.patent_expiration_row,
          strategy: item.strategy
          //is_active: item.//is_active
        })
      );
      setDataSource(newData);
    }
  }, [getFormDetailsByIdData, storedData === 'true', draft_PIF_form_start]);

  function generateKey() {
    return Math.random().toString(36).substr(2, 10);
  }

  //-----Ip_exclusivities_table-------
  useEffect(() => {
    if (
      storedData1 === 'false' ||
      getFormDetailsByIdData?.ip_detail?.exclusivities?.length === 0
    ) {
      setDataSource1([
        {
          key: generateKey1(),
          exclusivity_type_id: '',
          us: '',
          eu: '',
          row: ''
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [storedData1]);

  useEffect(() => {
    if (
      (getFormDetailsByIdData?.ip_detail?.exclusivities?.length > 0 &&
        storedData1 === 'true') ||
      (getFormDetailsByIdData?.ip_detail?.exclusivities?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      const newData = getFormDetailsByIdData?.ip_detail?.exclusivities?.map(
        (item) => ({
          key: item.id.toString(),
          // id: item.id,
          exclusivity_type_id: item?.exclusivity_type_id,
          us: item?.us,
          eu: item?.eu,
          row: item?.row
          //is_active: item?.//is_active
        })
      );
      setDataSource1(newData);
    }
  }, [getFormDetailsByIdData, storedData1, draft_PIF_form_start]);

  function generateKey1() {
    return Math.random().toString(36).substr(2, 10);
  }

  //-----Ip_generic_launch_date-------
  useEffect(() => {
    if (
      storedData2 === 'false' ||
      getFormDetailsByIdData?.ip_detail?.generic_launch_date?.length === 0
    ) {
      setDataSource2([
        {
          key: generateKey2(),
          country_id: '',
          launch_date: '' // moment().format('YYYY-MM-DD'),
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [storedData2]);

  useEffect(() => {
    if (
      (getFormDetailsByIdData?.ip_detail?.generic_launch_date?.length > 0 &&
        storedData2 === 'true') ||
      (getFormDetailsByIdData?.ip_detail?.generic_launch_date?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      const newData =
        getFormDetailsByIdData?.ip_detail?.generic_launch_date?.map((item) => ({
          key: item.id.toString(),
          // id: item?.id,
          country_id: item?.country_id,
          launch_date: item?.launch_date
          //is_active: item?.//is_active
        }));
      setDataSource2(newData);
    }
  }, [getFormDetailsByIdData, storedData2, draft_PIF_form_start]);

  function generateKey2() {
    return Math.random().toString(36).substr(2, 10);
  }

  //-----Ip_granules_launch_date-------
  useEffect(() => {
    if (
      storedData2 === 'false' ||
      getFormDetailsByIdData?.ip_detail?.granules_launch_dates?.length === 0
    ) {
      setDataSource3([
        {
          key: generateKey3(),
          country_id: '',
          launch_date: '' // moment().format('YYYY-MM-DD'),
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [storedData2]);

  useEffect(() => {
    if (
      (getFormDetailsByIdData?.ip_detail?.granules_launch_dates?.length > 0 &&
        storedData2 === 'true') ||
      (getFormDetailsByIdData?.ip_detail?.granules_launch_dates?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      const newData =
        getFormDetailsByIdData?.ip_detail?.granules_launch_dates?.map(
          (item) => ({
            key: item.id.toString(),
            // id: item?.id,
            country_id: item?.country_id,
            launch_date: item?.launch_date
          })
        );
      setDataSource3(newData);
    }
  }, [getFormDetailsByIdData, storedData2, draft_PIF_form_start]);

  function generateKey3() {
    return Math.random().toString(36).substr(2, 10);
  }

  useEffect(() => {
    form.setFieldsValue({
      ip_remarks: getFormDetailsByIdData?.ip_detail?.ip_remarks
    });
  }, [getFormDetailsByIdData]);

  useEffect(() => {
    form.setFieldsValue({
      initiation_date: moment() || ''
    });
  });
  // useEffect(() => {
  //   const payload = {};
  //   if (tagSearch) payload['search'] = tagSearch;
  //   dispatch(getCategoryList(payload));
  // }, [tagSearch]);

  // const debounceFetcher = useMemo(() => {
  //   const loadOptions = (tagSearch) => {
  //     setTagSearch(tagSearch);
  //   };
  //   return debounce(loadOptions, 400);
  // }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };
  // const isactiveArray = dataSource?.map((obj) => ({ ...obj, //is_active: true }));
  // console.log('isactiveArray::::', isactiveArray);
  const onFinish = () => {
    const payload = {
      edit_type: 'draft',
      pif_id: PIF_form_id,
      ip_remarks:
        formData1?.ip_remarks === undefined
          ? getFormDetailsByIdData?.ip_detail?.ip_remarks
          : formData1?.ip_remarks,
      patent_scenarios_list:
        dataSource.length &&
        (dataSource[0].patent_expiration_eu ||
          dataSource[0].patent_expiration_row ||
          dataSource[0].patent_expiration_us ||
          dataSource[0].patent_type_id ||
          dataSource[0].strategy)
          ? dataSource?.map((obj) => ({
              ...obj
            }))
          : [],
      exclusivities_list:
        dataSource1.length &&
        (dataSource1[0].eu ||
          dataSource1[0].exclusivity_type_id ||
          dataSource1[0].us ||
          dataSource1[0].row)
          ? dataSource1?.map((obj) => ({
              ...obj
            }))
          : [],
      generic_launch_date_list:
        dataSource2.length &&
        (dataSource2[0].country_id || dataSource2[0].launch_date)
          ? dataSource2?.map((obj) => ({
              ...obj
            }))
          : [],
      granules_launch_dates:
        dataSource3.length &&
        (dataSource3[0].country_id || dataSource3[0].launch_date)
          ? dataSource3?.map((obj) => ({
              ...obj
            }))
          : []
    };
    console.log('IP_payload::::', payload);
    dispatch(createUpdateIPDetail_form({ payload }))?.then(function (response) {
      if (response?.payload?.success) {
        //history.push(`/admin-quizzes`);
        localStorage.setItem('Ip_patentScenario_table', true);
        localStorage.setItem('Ip_exclusivities_table', true);
        localStorage.setItem('Ip_generic_launch_date', true);
        setSelectedTab(5);
      } else {
        console.log('response?.payload?.error', response?.payload?.error);
      }
    });
  };
  //const defaultDate = moment().format('MMM DD, YYYY');

  return (
    <div
      className={`${styles.page_container}`}
      style={{
        marginBlock: xs ? '0.5rem' : sm ? '0.7rem' : '1.2rem',
        marginInline: xs ? 0 : sm ? '0.5rem' : '1.3rem'
      }}
    >
      <Spin spinning={getFormDetailsByIdLoading}>
        <div className={`${styles.page_title}`}>
          <Typography.Title
            level={2}
            style={{ fontSize: xs ? '1rem' : sm ? '1.1rem' : '1.2rem' }}
            className={styles.title_label}
          >
            IP Details
          </Typography.Title>
        </div>

        <Form
          form={form}
          name="PIF_RD_Information"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: '14px'
                    }}
                  >
                    Patent Scenario
                    {/* <span
                      style={{
                        color: 'red',
                        fontSize: '16px',
                        marginLeft: '2px'
                      }}
                    >
                      *
                    </span> */}
                  </span>
                }
                // {
                //   <span style={{ fontWeight: 500, fontSize: '14px' }}>
                //     Patent Scenario *
                //   </span>
                // }
                //extra="Add email message"
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />
                <div className={styles.scrollableContainer}>
                  <Ip_patentScenario_table
                    setDataSource={setDataSource}
                    dataSource={dataSource}
                    generateKey={generateKey}
                    storedData={storedData}
                    dropDown_patent_typeData={dropDown_patent_typeData}
                    debouncePatent_type={debouncePatent_type}
                  />
                </div>
              </Panel>
            </CustomCollapse>
          </Row>
          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: '14px'
                    }}
                  >
                    Exclusivities
                    {/* <span
                      style={{
                        color: 'red',
                        fontSize: '16px',
                        marginLeft: '2px'
                      }}
                    >
                      *
                    </span> */}
                  </span>
                }
                // {
                //   <span style={{ fontWeight: 500, fontSize: '14px' }}>
                //     Exclusivities *
                //   </span>
                // }
                //extra="Add email message"
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />
                <div className={styles.scrollableContainer}>
                  <Ip_exclusivities_table
                    setDataSource1={setDataSource1}
                    dataSource1={dataSource1}
                    generateKey1={generateKey1}
                    storedData1={storedData1}
                    dropDown_exclusivity_typeData={
                      dropDown_exclusivity_typeData
                    }
                    debounceExclusivity_type={debounceExclusivity_type}
                  />
                </div>
              </Panel>
            </CustomCollapse>
          </Row>
          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: '14px'
                    }}
                  >
                    Generic Launch Date
                    {/* <span
                      style={{
                        color: 'red',
                        fontSize: '16px',
                        marginLeft: '2px'
                      }}
                    >
                      *
                    </span> */}
                  </span>
                }
                // {
                //   <span style={{ fontWeight: 500, fontSize: '14px' }}>
                //     Generic Launch Date *
                //   </span>
                // }
                //extra="Add email message"
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />
                <div className={styles.scrollableContainer}>
                  <Ip_generic_launch_date
                    setDataSource2={setDataSource2}
                    dataSource2={dataSource2}
                    generateKey2={generateKey2}
                    storedData2={storedData2}
                    dropDown_countryData={dropDown_countryData}
                    debounceCountry={debounceCountry}
                  />
                </div>
              </Panel>
            </CustomCollapse>
          </Row>
          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: '14px'
                    }}
                  >
                    Granules Launch Date
                    {/* <span
                      style={{
                        color: 'red',
                        fontSize: '16px',
                        marginLeft: '2px'
                      }}
                    >
                      *
                    </span> */}
                  </span>
                }
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />
                <div className={styles.scrollableContainer}>
                  <Ip_granules_launch_date
                    setDataSource2={setDataSource3}
                    dataSource2={dataSource3}
                    generateKey2={generateKey3}
                    storedData2={storedData2}
                    dropDown_countryData={dropDown_countryData}
                    debounceCountry={debounceCountry}
                  />
                </div>
              </Panel>
            </CustomCollapse>
          </Row>
          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <Col span={24}>
              <FormItem
                label="Remarks"
                name="ip_remarks"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input.TextArea
                  rows={3}
                  allowClear
                  placeholder="Enter remarks"
                  onChange={(e) =>
                    handleFormDataOnChange('ip_remarks', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              marginTop: '10%'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/home`)}
              style={{
                fontSize: xs ? '0.6rem' : sm ? '0.7rem' : '0.9rem',
                paddingInline: xs ? '0.6rem' : sm ? '1rem' : '1.5rem'
              }}
            >
              Cancel
            </CustomButton>
            <FormItem>
              <CustomButton
                loading={createUpdateIPDetail_formLoading}
                type="primary"
                htmlType="submit"
                className={styles.post_btn_style}
                style={{
                  fontSize: xs ? '0.6rem' : sm ? '0.7rem' : '0.9rem',
                  paddingInline: xs ? '0.6rem' : sm ? '1rem' : '1.5rem'
                }}
              >
                Next
              </CustomButton>
            </FormItem>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default IP_details;
