import FormItem from '@/common/FormItem';
import CustomLayout from '@/common/Layout';
import { getPifFdNumbers, signFd } from '@/features/signatoryFlow/redux/slice';
import { Button, Col, Form, Popconfirm, Row, Select } from 'antd';
import { useResponsiveness } from 'hooks/useResponsiveness';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { history } from '@/app/history';

export default function AdminPage() {
  const { xs, md, sm } = useResponsiveness();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [approvers, setApprovers] = useState([]);
  const { pifFdNumbers, pifFdNumbersLoading, signLoading } = useSelector(
    (state) => state.signatory
  );

  const handleConfirm = () => {
    form.submit();
  };

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 30,
      search: searchTerm
    };
    dispatch(getPifFdNumbers(payload));
  }, [searchTerm]);

  const debouncePifNumbers = useMemo(() => {
    return debounce((value) => {
      setSearchTerm(value);
    }, 500);
  }, []);

  useEffect(() => {
    if (selectedId) {
      const pif = pifFdNumbers.find((item) => item.id === selectedId);
      setApprovers(pif ? pif.approvers : []);
      form.setFieldsValue({ approver_id: null });
    }
  }, [selectedId, pifFdNumbers]);

  const onFinish = (values) => {
    const payload = {
      pif_id: values.pif_no,
      approver_id: values.approver_id
    };

    dispatch(signFd(payload)).then((res) => {
      if (res.payload?.success) {
        history.push('/requester-list-view');
      }
    });
  };

  return (
    <CustomLayout sider={false}>
      <div
        style={{
          marginTop: '5rem',
          marginInline: '3rem',
          borderRadius: '0.8rem',
          backgroundColor: '#ffffff',
          boxShadow:
            '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
          paddingInline: xs ? '1rem' : md ? '1rem' : '6rem',
          paddingBlock: '5rem'
        }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row
            gutter={16}
            justify="center"
            style={{
              display: 'flex',
              gap: xs ? '0rem' : sm ? '1rem' : md ? '2rem' : '5rem'
            }}
          >
            <Col md={10} span={24}>
              <FormItem
                label="PIF Request No"
                name="pif_no"
                rules={[
                  { required: true, message: 'Please select a PIF Request No' }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select PIF Request No"
                  onSearch={debouncePifNumbers}
                  filterOption={false}
                  value={selectedId}
                  onChange={(value) => setSelectedId(value)}
                  loading={pifFdNumbersLoading}
                >
                  {pifFdNumbers?.map((item) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.request_id}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col md={10} span={24}>
              <FormItem
                label="List of Signatories"
                name="approver_id"
                rules={[
                  { required: true, message: 'Please select a signatory' }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select signatory"
                  filterOption={(input, option) =>
                    option?.children
                      ?.toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {approvers?.map((item) => (
                    <Select.Option
                      value={item.id}
                      key={item.id}
                      disabled={item.status === 'signed'}
                    >
                      {`${item.user?.first_name} (${item.user?.email})`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: '7rem' }}>
            <Col>
              <FormItem>
                <Button
                  style={{
                    width: '6rem'
                  }}
                  onClick={() => history.push('/home')}
                >
                  Cancel
                </Button>
                <Popconfirm
                  title="Please Confirm"
                  onConfirm={handleConfirm}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{
                      marginLeft: '1rem',
                      width: '6rem'
                    }}
                    type="primary"
                    loading={signLoading}
                  >
                    Sign
                  </Button>
                </Popconfirm>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
    </CustomLayout>
  );
}
