import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import styles from './index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { getPifAPIList } from '@/features/approverFlow/redux/slice';
//import { sizeChecker } from 'utilities/helpers';

import {
  AppstoreOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FilePptOutlined
} from '@ant-design/icons';

import CustomLayout from '@/common/Layout';
// import pending_icon from 'assets/images/list_view_icons/pending_icon.svg';
// import signed_icon from 'assets/images/list_view_icons/signed_icon.svg';
import All_ApproverList from './all_approverList';
import { useParams } from 'react-router-dom';

const FactorsData = [
  {
    key: 1,
    //path: '/pif-rd-information',
    icon: <AppstoreOutlined />,
    label: <span className={styles.sidebar_label}>All</span>,
    bg: '#667085'
  },
  {
    key: 2,
    // path: '/reference-product-details',
    icon: <FilePptOutlined />,
    label: <span className={styles.sidebar_label}>Pending</span>,
    bg: '#667085'
  },
  {
    key: 3,
    // path: '/reference-product-details',
    icon: <CheckCircleOutlined />,
    label: <span className={styles.sidebar_label}>Approved</span>,
    bg: '#667085'
  },
  {
    key: 4,
    //path: '/proposed-product-details',
    icon: <CloseCircleOutlined />,
    label: <span className={styles.sidebar_label}>Rejected</span>,
    bg: '#667085'
  }
];

const ApproverAPIListViewPage = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const [selectedTab, setSelectedTab] = useState();
  //const [hoveredTab, setHoveredTab] = useState(null);
  const [imageVisible, setImageVisible] = useState(false);
  const [completedTabs, setCompletedTabs] = useState();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // useEffect(() => {
  //   if (id === undefined) {
  //     setSelectedTab(1);
  //   } else {
  //     setSelectedTab(Number(id));
  //   }
  //   //setSelectedTab(id === undefined ? 1 : Number(id));
  // }, [id]);
  useEffect(() => {
    const storedTab = localStorage.getItem('selectedTab_api');
    setSelectedTab(
      storedTab ? Number(storedTab) : id === undefined ? 1 : Number(id)
    );
  }, [id]);

  useEffect(() => {
    if (selectedTab) {
      localStorage.setItem('selectedTab_api', selectedTab);
    }
  }, [selectedTab]);

  const { pifAPIListData, pagination, getPifAPIListLoading } = useSelector(
    (state) => state.approver
  );

  useEffect(() => {
    const payload = {
      status:
        selectedTab === 2
          ? 'pending'
          : selectedTab === 3
          ? 'approved'
          : selectedTab === 4
          ? 'rejected'
          : 'all',
      page: page,
      per_page: pageSize,
      search: search
    };
    selectedTab === undefined ? '' : dispatch(getPifAPIList(payload));
  }, [page, pageSize, search, selectedTab, id]);

  //console.log('pifFdListData', pifFdListData);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setCompletedTabs([1]);
  }, []);

  const handleImageVisible = () => {
    setImageVisible(!imageVisible);
  };
  useEffect(() => {
    // Trigger the animation when the selected tab changes
    handleImageVisible();
  }, [selectedTab]);

  const onClickDecider = (index) => {
    switch (index) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        // setSelectedChild(null); // Reset selectedChild when parent is clicked
        setSelectedTab(index);
        handleImageVisible();
        break;

      // Handle other cases if needed
      default:
        break;
    }
  };

  const isTabEnabled = (tabKey) => {
    const sequentialTab =
      tabKey === selectedTab || completedTabs?.includes(tabKey);
    return sequentialTab;
  };

  console.log('selectedTab', selectedTab);
  const allContent = () => {
    switch (selectedTab) {
      case 1: // Check for selectedChild instead of selectedTab
        return (
          <>
            <All_ApproverList
              pifFdListData={pifAPIListData}
              pagination={pagination}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              handleSearchChange={handleSearchChange}
              selectedTab={selectedTab}
              getPifFdListLoading={getPifAPIListLoading}
            />{' '}
          </>
        );
      case 2:
        return (
          <>
            <All_ApproverList
              pifFdListData={pifAPIListData}
              pagination={pagination}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              handleSearchChange={handleSearchChange}
              selectedTab={selectedTab}
              getPifFdListLoading={getPifAPIListLoading}
            />{' '}
          </>
        );
      case 3:
        return (
          <>
            <All_ApproverList
              pifFdListData={pifAPIListData}
              pagination={pagination}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              handleSearchChange={handleSearchChange}
              selectedTab={selectedTab}
              getPifFdListLoading={getPifAPIListLoading}
            />{' '}
          </>
        );
      case 4:
        return (
          <>
            <All_ApproverList
              pifFdListData={pifAPIListData}
              pagination={pagination}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              handleSearchChange={handleSearchChange}
              selectedTab={selectedTab}
              getPifFdListLoading={getPifAPIListLoading}
            />{' '}
          </>
        );
      default:
        return window?.scrollTo(0, document.body.scrollHeight);
    }
  };

  return (
    <CustomLayout sider={false}>
      <div className={styles.card_container}>
        <div className={styles.fixed_content}>
          <Space
            direction="vertical"
            size={0}
            style={{ marginLeft: '0px' }}
            className={styles.sidebar_margin}
          >
            {FactorsData?.map((item, index) => (
              <div
                key={index}
                className={
                  selectedTab === index + 1
                    ? ` ${styles.selected}`
                    : `${styles.factors_menu_style}`
                }
                style={{
                  cursor: isTabEnabled(item.key) ? 'pointer' : 'not-allowed',
                  backgroundColor:
                    selectedTab === item.key ? '#ffffff' : '#F5F7F9',
                  color: isTabEnabled(item.key) ? '#0098da' : '#333333',
                  transition: 'background-color 0.3s'
                }}
                onClick={() => {
                  /*  if (isTabEnabled(item.key)) { */
                  // for disabled tab
                  onClickDecider(item.key);
                  /*  } */
                }}
              >
                <div
                  className={`cursor-pointer ${styles.left_text}`}
                  //onClick={() => onClickDecider(index + 1)}
                >
                  <span style={{ marginRight: '3px' }}>{item.icon}</span>{' '}
                  <span>{item?.label}</span>
                </div>
              </div>
            ))}
          </Space>
        </div>
        <div className={styles.scrollable_content}>
          <div className={styles.scrollable_inner}>
            <div className={styles.right_container}>{allContent()}</div>
          </div>
        </div>
      </div>
    </CustomLayout>
  );
};

export default ApproverAPIListViewPage;
