import React, { useEffect, useState, useMemo } from 'react';
import { Space } from 'antd';
import styles from './Edit_PIF_Form.module.less';

//import { sizeChecker } from 'utilities/helpers';

import CustomLayout from '@/common/Layout';
import PIF_FD_Information from '../pif_fd_information/index';
import Reference_product_details from '../reference_product_details';
import Proposed_product_details from '../proposed_product_details';
import IP_details from '../ip_details';
import RD_details from '../rd_details';
import Business_justification from '../business_justification';
import CompetitiveScenario from '../competitive_scenario';
import MarketData from '../market_data';
//import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  getFormDetailsById_form,
  dropDown_GIL_GPI,
  dropDown_primary_market,
  dropDown_extension_market,
  createUpdateFdInfo_form,
  dropDown_market_segment,
  dropDown_therapeutic_category,
  dropDown_administration_route,
  dropDown_dosage_form,
  dropDown_approved_strengths,
  createUpdateRefProductDetail_form,
  createUpdatePropProductDetail_form,
  dropDown_API_source,
  dropDown_patent_type,
  dropDown_country,
  createUpdateIPDetail_form,
  dropDown_exclusivity_type,
  createUpdateRDDetail_form,
  dropDown_stages,
  dropDown_budget_items,
  dropDown_currency,
  createUpdateCompetitiveScenario_form,
  dropDown_genericized,
  dropDown_launch_type,
  dropDown_filing_type,
  createUpdateBJ_form,
  createUpdateMarketData_form,
  dropDown_fy_masters,
  // dropDown_bjc_forecast_items,
  dropDown_fpm_items,
  dropDown_gfpm_items,
  dropDown_product_rating,
  dropDown_table_headers,
  dropDown_md_global,
  //dropDown_md_ims,
  getSignatories,
  getApprovers,
  createUpdateApprovers_form,
  uploadAttachments_form,
  dropDown_key_row_markets,
  delete_uploaded_docs,
  initiateFdForm,
  dropDown_pif_types
} from '../../redux/slice';
import Steper_1 from './../../images/Step_1.svg';
import SignatoriesApprover from '../signatoriesApprover';
import UploadDocuments from '../uploadDocuments';
import Preview from '../preview';
import { useParams } from 'react-router-dom';

const FactorsData = [
  {
    key: 1,
    path: '/pif-rd-information',
    icon: '',
    label: <span className={styles.sidebar_label}>FD - PIF Details</span>,
    bg: '#667085'
  },
  {
    key: 2,
    path: '/reference-product-details',
    icon: '',
    label: (
      <span className={styles.sidebar_label}>Reference product details</span>
    ),
    bg: '#667085'
  },
  {
    key: 3,
    path: '/proposed-product-details',
    icon: '',
    label: (
      <span className={styles.sidebar_label}>Proposed product details</span>
    ),
    bg: '#667085'
  },
  {
    key: 4,
    path: '/IP-details',
    icon: '',
    label: <span className={styles.sidebar_label}>IP details</span>,
    bg: '#667085'
  },
  {
    key: 5,
    path: '/R-D-details',
    icon: '',
    label: <span className={styles.sidebar_label}>R&D details</span>,
    bg: '#667085'
  },
  {
    key: 6,
    path: '/business-justification',
    icon: '',
    label: <span className={styles.sidebar_label}>Business justification</span>,
    bg: '#667085'
  },
  {
    key: 7,
    path: '/competitive-scenario',
    icon: '',
    label: <span className={styles.sidebar_label}>Competitive scenario</span>,
    bg: '#667085'
  },
  {
    key: 8,
    path: '/market-data',
    icon: '',
    label: <span className={styles.sidebar_label}>Market data</span>,
    bg: '#667085'
  }
];
const ReviseEdit_PIF_FD_FormPage = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const {
    getFormDetailsByIdData,
    dropDown_GIL_GPILoading,
    dropDown_GIL_GPIData,
    dropDown_primary_marketData,
    dropDown_extension_marketData,
    //createUpdateFdInfo_formLoading,
    dropDown_market_segmentData,
    dropDown_therapeutic_categoryData,
    dropDown_administration_routeData,
    dropDown_dosage_formData,
    dropDown_approved_strengthsData,
    createUpdateRefProductDetail_formLoading,
    createUpdatePropProductDetail_formLoading,
    dropDown_API_sourceData,
    getFormDetailsByIdLoading,
    dropDown_patent_typeData,
    dropDown_countryData,
    createUpdateIPDetail_formLoading,
    dropDown_exclusivity_typeData,
    createUpdateRDDetail_formLoading,
    dropDown_budget_itemsData,
    dropDown_stagesData,
    dropDown_currencyData,
    createUpdateCompetitiveScenario_formLoading,
    dropDown_genericizedData,
    dropDown_launch_typeData,
    dropDown_filing_typeData,
    createUpdateBJ_formLoading,
    createUpdateMarketData_formLoading,
    dropDown_fy_mastersData,
    // dropDown_bjc_forecast_itemsData,
    dropDown_fpm_itemsData,
    dropDown_gfpm_itemsData,
    dropDown_product_ratingData,
    dropDown_table_headersData,
    //dropDown_md_ims_data,
    dropDown_md_global_data,
    dropDown_signatoriesData,
    dropDown_approversData,
    createUpdateApprovers_formLoading,
    uploadAttachments_formLoading,
    dropDown_key_row_marketsData,
    deleteDocsLoading,
    initiateFdFormLoading,
    dropDown_pif_typesData
  } = useSelector((state) => state.pif_fd_form);

  const [pageNum, setPageNum] = useState(1);
  const [selectedTab, setSelectedTab] = useState(1);
  //const [hoveredTab, setHoveredTab] = useState(null);
  const [imageVisible, setImageVisible] = useState(false);
  const [completedTabs, setCompletedTabs] = useState([1]);
  const [GIL_GPISearch, setGIL_GPISearch] = useState('');
  const [primary_marketSearch, setPrimary_marketSearch] = useState('');
  const [extension_marketSearch, setExtension_marketSearch] = useState('');
  const [market_Segment, setMarket_Segment] = useState('');
  const [therapeutic_category, setTherapeutic_category] = useState('');
  const [administration_route, setAdministration_route] = useState('');
  const [dosage_form, setDosage_form] = useState('');
  const [approved_strengths, setApproved_strengths] = useState('');
  const [administration_route2, setAdministration_route2] = useState('');
  const [dosage_form2, setDosage_form2] = useState('');
  const [approved_strengths2, setApproved_strengths2] = useState('');
  const [API_source, setAPI_source] = useState('');
  const [patent_type, setPatent_type] = useState('');
  const [exclusivity_type, setExclusivity_type] = useState('');
  const [country, setCountry] = useState('');
  const [stages, setStages] = useState('');
  const [budget_items, setBudget_items] = useState('');
  const [currency, setCurrency] = useState('');
  const [genericized, setGenericized] = useState('');
  const [launch_type, setLaunch_type] = useState('');
  const [filing_type, setFiling_type] = useState('');
  const [fy_masters, setFy_masters] = useState('');
  //const [bjc_forecast_items, setBjc_forecast_items] = useState('');
  const [fpm_items, setFpm_items] = useState('');
  const [gfpm_items, setGfpm_items] = useState('');
  const [product_rating, setProduct_rating] = useState('');
  //const [md_ims, setMd_ims] = useState('');
  const [md_global, setMd_global] = useState('');
  const [signatoriesSearch, setSignatoriesSearch] = useState('');
  const [approversSearch, setApproversSearch] = useState('');
  const [key_row_markets, setKey_row_markets] = useState('');
  const [pif_typesSearch, setPif_typesSearch] = useState('');

  //const PIF_form_id = localStorage.getItem('PIF_form_id');
  const edit_PIF_form_start = localStorage.getItem('Edit_PIF_form_start');

  useEffect(() => {
    let payload = {
      pif_id: params?.id
    };
    params?.id === null ? '' : dispatch(getFormDetailsById_form(payload));
  }, [params?.id, selectedTab, pageNum]);

  const handleImageVisible = () => {
    setImageVisible(!imageVisible);
  };
  useEffect(() => {
    // Trigger the animation when the selected tab changes
    handleImageVisible();
  }, [selectedTab]);

  useEffect(() => {
    let payload_GIL_GPI = { model_name: 'GIL_GPI' };
    if (GIL_GPISearch) payload_GIL_GPI['search'] = GIL_GPISearch;
    dispatch(dropDown_GIL_GPI(payload_GIL_GPI));
    setGIL_GPISearch('');
  }, [GIL_GPISearch, selectedTab === 1]);

  const debounceGIL_GPI = useMemo(() => {
    const loadOptions = (GIL_GPISearch) => {
      setGIL_GPISearch(GIL_GPISearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    let payload_primary_market = { model_name: 'primary_market' };
    if (primary_marketSearch)
      payload_primary_market['search'] = primary_marketSearch;
    primary_marketSearch || selectedTab === 1
      ? dispatch(dropDown_primary_market(payload_primary_market))
      : '';
    setPrimary_marketSearch('');
  }, [primary_marketSearch, selectedTab]);

  const debouncePrimary_market = useMemo(() => {
    const loadOptions = (primary_marketSearch) => {
      setPrimary_marketSearch(primary_marketSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    let payload_extension_market = { model_name: 'extension_market' };
    if (extension_marketSearch)
      payload_extension_market['search'] = extension_marketSearch;
    extension_marketSearch || selectedTab === 1
      ? dispatch(dropDown_extension_market(payload_extension_market))
      : '';
    setExtension_marketSearch('');
  }, [extension_marketSearch, selectedTab]);

  const debounceExtension_market = useMemo(() => {
    const loadOptions = (extension_marketSearch) => {
      setExtension_marketSearch(extension_marketSearch);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_therapeutic_category = { model_name: 'therapeutic_category' };
    if (therapeutic_category)
      payload_therapeutic_category['search'] = therapeutic_category;
    dispatch(dropDown_therapeutic_category(payload_therapeutic_category));
    setTherapeutic_category('');
  }, [therapeutic_category, selectedTab]);

  const debounceTherapeutic_category = useMemo(() => {
    const loadOptions = (therapeutic_category) => {
      setTherapeutic_category(therapeutic_category);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    let payload_market_segment = { model_name: 'market_segment' };
    if (market_Segment) payload_market_segment['search'] = market_Segment;
    dispatch(dropDown_market_segment(payload_market_segment));
    setMarket_Segment('');
  }, [market_Segment, selectedTab]);

  const debounceMarket_Segment = useMemo(() => {
    const loadOptions = (market_Segment) => {
      setMarket_Segment(market_Segment);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_administration_route = { model_name: 'administration_route' };
    if (administration_route)
      payload_administration_route['search'] = administration_route;
    dispatch(dropDown_administration_route(payload_administration_route));
    setAdministration_route('');
  }, [administration_route, selectedTab]);

  const debounceAdministration_route = useMemo(() => {
    const loadOptions = (administration_route) => {
      setAdministration_route(administration_route);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_dosage_form = { model_name: 'dosage_form' };
    if (dosage_form) payload_dosage_form['search'] = dosage_form;
    dispatch(dropDown_dosage_form(payload_dosage_form));
    setDosage_form('');
  }, [dosage_form, selectedTab]);

  const debounceDosage_form = useMemo(() => {
    const loadOptions = (dosage_form) => {
      setDosage_form(dosage_form);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_approved_strengths = { model_name: 'UoM' };
    if (approved_strengths)
      payload_approved_strengths['search'] = approved_strengths;
    dispatch(dropDown_approved_strengths(payload_approved_strengths));
    setApproved_strengths('');
  }, [approved_strengths, selectedTab]);
  const debounceApproved_strengths = useMemo(() => {
    const loadOptions = (approved_strengths) => {
      setApproved_strengths(approved_strengths);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_administration_route = { model_name: 'administration_route' };
    if (administration_route2)
      payload_administration_route['search'] = administration_route2;
    dispatch(dropDown_administration_route(payload_administration_route));
    setAdministration_route2('');
  }, [administration_route2, selectedTab]);
  const debounceAdministration_route2 = useMemo(() => {
    const loadOptions = (administration_route2) => {
      setAdministration_route2(administration_route2);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_dosage_form = { model_name: 'dosage_form' };
    if (dosage_form2) payload_dosage_form['search'] = dosage_form2;
    dispatch(dropDown_dosage_form(payload_dosage_form));
    setDosage_form2('');
  }, [dosage_form2, selectedTab]);
  const debounceDosage_form2 = useMemo(() => {
    const loadOptions = (dosage_form2) => {
      setDosage_form2(dosage_form2);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_approved_strengths = { model_name: 'UoM' };
    if (approved_strengths2)
      payload_approved_strengths['search'] = approved_strengths2;
    dispatch(dropDown_approved_strengths(payload_approved_strengths));
    setApproved_strengths2('');
  }, [approved_strengths2, selectedTab]);
  const debounceApproved_strengths2 = useMemo(() => {
    const loadOptions = (approved_strengths2) => {
      setApproved_strengths2(approved_strengths2);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_API_source = { model_name: 'API_source' };
    if (API_source) payload_API_source['search'] = API_source;
    dispatch(dropDown_API_source(payload_API_source));
    setAPI_source('');
  }, [API_source, selectedTab]);
  const debounceAPI_source = useMemo(() => {
    const loadOptions = (API_source) => {
      setAPI_source(API_source);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_patent_type = { model_name: 'patent_type' };
    if (patent_type) payload_patent_type['search'] = patent_type;
    dispatch(dropDown_patent_type(payload_patent_type));
    setPatent_type('');
  }, [patent_type, selectedTab]);
  const debouncePatent_type = useMemo(() => {
    const loadOptions = (patent_type) => {
      setPatent_type(patent_type);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_exclusivity = { model_name: 'exclusivity_type' };
    if (exclusivity_type) payload_exclusivity['search'] = exclusivity_type;
    dispatch(dropDown_exclusivity_type(payload_exclusivity));
    setExclusivity_type('');
  }, [exclusivity_type, selectedTab]);
  const debounceExclusivity_type = useMemo(() => {
    const loadOptions = (exclusivity_type) => {
      setExclusivity_type(exclusivity_type);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_country = { model_name: 'country' };
    if (country) payload_country['search'] = country;
    dispatch(dropDown_country(payload_country));
    setCountry('');
  }, [country, selectedTab]);
  const debounceCountry = useMemo(() => {
    const loadOptions = (country) => {
      setCountry(country);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_stages = { model_name: 'stages' };
    if (stages) payload_stages['search'] = stages;
    dispatch(dropDown_stages(payload_stages));
    setStages('');
  }, [stages, selectedTab]);
  const debounceStages = useMemo(() => {
    const loadOptions = (stages) => {
      setStages(stages);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_budget_items = { model_name: 'budget_items' };
    if (budget_items) payload_budget_items['search'] = budget_items;
    dispatch(dropDown_budget_items(payload_budget_items));
    setBudget_items('');
  }, [budget_items, selectedTab]);
  const debounceBudget_items = useMemo(() => {
    const loadOptions = (budget_items) => {
      setBudget_items(budget_items);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_currency = { model_name: 'currency' };
    if (currency) payload_currency['search'] = currency;
    dispatch(dropDown_currency(payload_currency));
    setCurrency('');
  }, [currency, selectedTab]);
  const debounceCurrency = useMemo(() => {
    const loadOptions = (currency) => {
      setCurrency(currency);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_genericized = { model_name: 'genericized' };
    if (genericized) payload_genericized['search'] = genericized;
    dispatch(dropDown_genericized(payload_genericized));
    setGenericized('');
  }, [genericized, selectedTab]);
  const debounceGenericized = useMemo(() => {
    const loadOptions = (genericized) => {
      setGenericized(genericized);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_filing_type = { model_name: 'filing_type' };
    if (filing_type) payload_filing_type['search'] = filing_type;
    dispatch(dropDown_filing_type(payload_filing_type));
    setFiling_type('');
  }, [filing_type, selectedTab]);
  const debounceFiling_type = useMemo(() => {
    const loadOptions = (filing_type) => {
      setFiling_type(filing_type);
    };
    return debounce(loadOptions, 500);
  }, []);
  useEffect(() => {
    let payload_launch_type = { model_name: 'launch_type' };
    if (launch_type) payload_launch_type['search'] = launch_type;
    dispatch(dropDown_launch_type(payload_launch_type));
    setLaunch_type('');
  }, [launch_type, selectedTab]);
  const debounceLaunch_type = useMemo(() => {
    const loadOptions = (launch_type) => {
      setLaunch_type(launch_type);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(
    () => {
      let payload_fy_masters = {
        model_name: 'fy_masters',
        page: 1,
        per_page: '100'
      };
      if (fy_masters) payload_fy_masters['search'] = fy_masters;
      dispatch(dropDown_fy_masters(payload_fy_masters));
      setFy_masters('');
    },
    [fy_masters],
    selectedTab
  );
  const debounceFy_masters = useMemo(() => {
    const loadOptions = (fy_masters) => {
      setFy_masters(fy_masters);
    };
    return debounce(loadOptions, 500);
  }, []);
  // useEffect(() => {
  //   let payload_bjc_forecast_items = {
  //     pif_id: PIF_form_id,
  //     model_name: 'units_and_others',
  //     type: 'bjc_forecast_items',
  //     page: 1,
  //     per_page: '100'
  //   };
  //   if (bjc_forecast_items)
  //     payload_bjc_forecast_items['search'] = bjc_forecast_items;
  //   dispatch(dropDown_bjc_forecast_items(payload_bjc_forecast_items));
  // }, [bjc_forecast_items, ref_table, prop_table]);

  // const debounceBjc_forecast_items = useMemo(() => {
  //   const loadOptions = (bjc_forecast_items) => {
  //     setBjc_forecast_items(bjc_forecast_items);
  //   };
  //   return debounce(loadOptions, 500);
  // }, []);

  useEffect(() => {
    let payload_fpm_items = {
      model_name: 'units_and_others',
      type: 'fpm_items',
      page: 1,
      per_page: '100'
    };
    payload_fpm_items['search'] = fpm_items;
    dispatch(dropDown_fpm_items(payload_fpm_items));
    setFpm_items('');
  }, [fpm_items]);

  const debounceFpm_items = useMemo(() => {
    const loadOptions = (fpm_items) => {
      setFpm_items(fpm_items);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    let payload_gfpm_items = {
      model_name: 'units_and_others',
      type: 'gfpm_items',
      page: 1,
      per_page: '100'
    };
    payload_gfpm_items['search'] = gfpm_items;
    dispatch(dropDown_gfpm_items(payload_gfpm_items));
    setGfpm_items('');
  }, [gfpm_items]);

  const debounceGfpm_items = useMemo(() => {
    const loadOptions = (gfpm_items) => {
      setGfpm_items(gfpm_items);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    let payload_product_rating = {
      model_name: 'product_rating',
      page: 1,
      per_page: '100'
    };
    payload_product_rating['search'] = product_rating;
    dispatch(dropDown_product_rating(payload_product_rating));
    setProduct_rating('');
  }, [product_rating]);

  const debounceProduct_rating = useMemo(() => {
    const loadOptions = (product_rating) => {
      setProduct_rating(product_rating);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    let payload_table_headers = {
      model_name: 'units_and_others',
      type: 'table_headers',
      page: 1,
      per_page: '100'
    };
    dispatch(dropDown_table_headers(payload_table_headers));
  }, []);

  useEffect(() => {
    let payload_md_global = {
      model_name: 'md_global_data',
      page: 1,
      per_page: '100'
    };
    payload_md_global['search'] = md_global;
    dispatch(dropDown_md_global(payload_md_global));
    setMd_global('');
  }, [md_global]);

  const debounceMd_global = useMemo(() => {
    const loadOptions = (md_global) => {
      setMd_global(md_global);
    };
    return debounce(loadOptions, 500);
  }, []);

  // useEffect(() => {
  //   let payload_md_ims = {
  //     pif_id: PIF_form_id,
  //     model_name: 'md_ims_data',
  //     page: 1,
  //     per_page: '100'
  //   };
  //   payload_md_ims['search'] = md_ims;
  //   dispatch(dropDown_md_ims(payload_md_ims));
  // }, [md_ims]);

  // const debounceMd_ims = useMemo(() => {
  //   const loadOptions = (md_ims) => {
  //     setMd_ims(md_ims);
  //   };
  //   return debounce(loadOptions, 500);
  // }, []);

  useEffect(() => {
    let payload_signatories = {
      page: 1,
      per_page: '100'
    };
    payload_signatories['search'] = signatoriesSearch;
    dispatch(getSignatories(payload_signatories));
    setSignatoriesSearch('');
  }, [signatoriesSearch]);
  const debounceSignatories = useMemo(() => {
    const loadOptions = (signatoriesSearch) => {
      setSignatoriesSearch(signatoriesSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    let payload_approvers = {
      page: 1,
      per_page: '100'
    };
    payload_approvers['search'] = approversSearch;
    dispatch(getApprovers(payload_approvers));
    setApproversSearch('');
  }, []);

  const debounceApprovers = useMemo(() => {
    const loadOptions = (approversSearch) => {
      setApproversSearch(approversSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    let payload_key_row_markets = {
      model_name: 'key_row_markets',
      page: 1,
      per_page: '100'
    };
    payload_key_row_markets['search'] = key_row_markets;
    key_row_markets || selectedTab === 1
      ? dispatch(dropDown_key_row_markets(payload_key_row_markets))
      : '';
    setKey_row_markets('');
  }, [key_row_markets, selectedTab]);

  const debounceKey_row_markets = useMemo(() => {
    const loadOptions = (key_row_markets) => {
      setKey_row_markets(key_row_markets);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    let payload_pif_types = {
      model_name: 'pif_types',
      page: 1,
      per_page: '100'
    };
    payload_pif_types['search'] = pif_typesSearch;
    pif_typesSearch || selectedTab === 1
      ? dispatch(dropDown_pif_types(payload_pif_types))
      : '';
    setPif_typesSearch('');
  }, [pif_typesSearch, selectedTab === 1]);

  const debouncePif_types = useMemo(() => {
    const loadOptions = (pif_typesSearch) => {
      setPif_typesSearch(pif_typesSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const onClickDecider = (index) => {
    switch (index) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        // setSelectedChild(null); // Reset selectedChild when parent is clicked
        setSelectedTab(index);
        handleImageVisible();
        break;

      // Handle other cases if needed
      default:
        break;
    }
  };

  const isTabEnabled = (tabKey) => {
    const sequentialTab =
      tabKey === selectedTab || completedTabs.includes(tabKey);
    return sequentialTab;
  };
  //console.log('completedTabs:', completedTabs);

  const onFinish_FD_Form = (payload) => {
    dispatch(createUpdateFdInfo_form({ payload }))?.then(function (response) {
      if (response?.payload?.success) {
        // localStorage.setItem('PIF_form_id', response?.payload?.data?.pif_id);

        //history.push(`/admin-quizzes`);
        setSelectedTab(2);

        if (!completedTabs.includes(2)) {
          setCompletedTabs([...completedTabs, 2]);
        }
        isTabEnabled(2);
      } else {
        console.log('response?.payload?.error', response?.payload?.error);
      }
    });
  };

  //console.log('PIF_ID:::::', PIF_id);
  //console.log('getFormDetailsByIdData', getFormDetailsByIdData);

  const allContent = () => {
    switch (selectedTab) {
      case 1: // Check for selectedChild instead of selectedTab
        return (
          <>
            <PIF_FD_Information
              //PIFRequestFormData={PIFRequestFormData}
              PIF_form_id={params?.id}
              dropDown_GIL_GPILoading={dropDown_GIL_GPILoading}
              dropDown_GIL_GPIData={dropDown_GIL_GPIData}
              dropDown_primary_marketData={dropDown_primary_marketData}
              dropDown_extension_marketData={dropDown_extension_marketData}
              createUpdateFdInfo_form={createUpdateFdInfo_form}
              setSelectedTab={setSelectedTab}
              completedTabs={completedTabs}
              setCompletedTabs={setCompletedTabs}
              isTabEnabled={isTabEnabled}
              getFormDetailsByIdData={getFormDetailsByIdData}
              getFormDetailsByIdLoading={getFormDetailsByIdLoading}
              debounceGIL_GPI={debounceGIL_GPI}
              debouncePrimary_market={debouncePrimary_market}
              debounceExtension_market={debounceExtension_market}
              onFinish_FD_Form={onFinish_FD_Form}
              dropDown_key_row_marketsData={dropDown_key_row_marketsData}
              debounceKey_row_markets={debounceKey_row_markets}
              dropDown_pif_typesData={dropDown_pif_typesData}
              debouncePif_types={debouncePif_types}
              edit_PIF_form_start={edit_PIF_form_start}
              // setPageNum={setPageNum}
            />
          </>
        );
      case 2:
        return (
          <>
            <Reference_product_details
              // PIFRequestFormData={PIFRequestFormData}
              PIF_form_id={params?.id}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
              dropDown_market_segmentData={dropDown_market_segmentData}
              dropDown_therapeutic_categoryData={
                dropDown_therapeutic_categoryData
              }
              dropDown_administration_routeData={
                dropDown_administration_routeData
              }
              dropDown_dosage_formData={dropDown_dosage_formData}
              dropDown_approved_strengthsData={dropDown_approved_strengthsData}
              createUpdateRefProductDetail_formLoading={
                createUpdateRefProductDetail_formLoading
              }
              createUpdateRefProductDetail_form={
                createUpdateRefProductDetail_form
              }
              completedTabs={completedTabs}
              setCompletedTabs={setCompletedTabs}
              isTabEnabled={isTabEnabled}
              getFormDetailsByIdData={getFormDetailsByIdData}
              getFormDetailsByIdLoading={getFormDetailsByIdLoading}
              // setActiveRefTab={setActiveRefTab}
              // activeRefTable={activeRefTable}
              debounceMarket_Segment={debounceMarket_Segment}
              debounceTherapeutic_category={debounceTherapeutic_category}
              debounceAdministration_route={debounceAdministration_route}
              debounceDosage_form={debounceDosage_form}
              debounceApproved_strengths={debounceApproved_strengths}
              edit_PIF_form_start={edit_PIF_form_start}
            />
          </>
        );
      case 3:
        return (
          <>
            <Proposed_product_details
              //PIFRequestFormData={PIFRequestFormData}
              PIF_form_id={params?.id}
              setSelectedTab={setSelectedTab}
              dropDown_administration_routeData={
                dropDown_administration_routeData
              }
              dropDown_dosage_formData={dropDown_dosage_formData}
              dropDown_approved_strengthsData={dropDown_approved_strengthsData}
              createUpdatePropProductDetail_formLoading={
                createUpdatePropProductDetail_formLoading
              }
              createUpdatePropProductDetail_form={
                createUpdatePropProductDetail_form
              }
              dropDown_API_sourceData={dropDown_API_sourceData}
              completedTabs={completedTabs}
              setCompletedTabs={setCompletedTabs}
              isTabEnabled={isTabEnabled}
              getFormDetailsByIdData={getFormDetailsByIdData}
              getFormDetailsByIdLoading={getFormDetailsByIdLoading}
              debounceAdministration_route={debounceAdministration_route2}
              debounceDosage_form={debounceDosage_form2}
              debounceApproved_strengths={debounceApproved_strengths2}
              debounceAPI_source={debounceAPI_source}
              edit_PIF_form_start={edit_PIF_form_start}
            />
          </>
        );
      case 4:
        return (
          <>
            <IP_details
              PIF_form_id={params?.id}
              setSelectedTab={setSelectedTab}
              dropDown_administration_routeData={
                dropDown_administration_routeData
              }
              dropDown_dosage_formData={dropDown_dosage_formData}
              dropDown_approved_strengthsData={dropDown_approved_strengthsData}
              createUpdateIPDetail_formLoading={
                createUpdateIPDetail_formLoading
              }
              createUpdateIPDetail_form={createUpdateIPDetail_form}
              dropDown_API_sourceData={dropDown_API_sourceData}
              completedTabs={completedTabs}
              setCompletedTabs={setCompletedTabs}
              isTabEnabled={isTabEnabled}
              getFormDetailsByIdData={getFormDetailsByIdData}
              getFormDetailsByIdLoading={getFormDetailsByIdLoading}
              dropDown_patent_typeData={dropDown_patent_typeData}
              dropDown_countryData={dropDown_countryData}
              dropDown_exclusivity_typeData={dropDown_exclusivity_typeData}
              debouncePatent_type={debouncePatent_type}
              debounceExclusivity_type={debounceExclusivity_type}
              debounceCountry={debounceCountry}
              edit_PIF_form_start={edit_PIF_form_start}
            />
          </>
        );
      case 5:
        return (
          <>
            <RD_details
              PIF_form_id={params?.id}
              setSelectedTab={setSelectedTab}
              dropDown_administration_routeData={
                dropDown_administration_routeData
              }
              dropDown_dosage_formData={dropDown_dosage_formData}
              dropDown_approved_strengthsData={dropDown_approved_strengthsData}
              createUpdateRDDetail_formLoading={
                createUpdateRDDetail_formLoading
              }
              createUpdateRDDetail_form={createUpdateRDDetail_form}
              dropDown_API_sourceData={dropDown_API_sourceData}
              completedTabs={completedTabs}
              setCompletedTabs={setCompletedTabs}
              isTabEnabled={isTabEnabled}
              getFormDetailsByIdData={getFormDetailsByIdData}
              getFormDetailsByIdLoading={getFormDetailsByIdLoading}
              // dropDown_patent_typeData={dropDown_patent_typeData}
              // dropDown_countryData={dropDown_countryData}
              // dropDown_exclusivity_typeData={dropDown_exclusivity_typeData}
              dropDown_budget_itemsData={dropDown_budget_itemsData}
              dropDown_stagesData={dropDown_stagesData}
              dropDown_currencyData={dropDown_currencyData}
              debounceStages={debounceStages}
              debounceBudget_items={debounceBudget_items}
              debounceCurrency={debounceCurrency}
              edit_PIF_form_start={edit_PIF_form_start}
            />
          </>
        );
      case 6:
        return (
          <>
            <Business_justification
              PIF_form_id={params?.id}
              setSelectedTab={setSelectedTab}
              dropDown_administration_routeData={
                dropDown_administration_routeData
              }
              dropDown_dosage_formData={dropDown_dosage_formData}
              dropDown_approved_strengthsData={dropDown_approved_strengthsData}
              dropDown_API_sourceData={dropDown_API_sourceData}
              completedTabs={completedTabs}
              setCompletedTabs={setCompletedTabs}
              isTabEnabled={isTabEnabled}
              getFormDetailsByIdData={getFormDetailsByIdData}
              getFormDetailsByIdLoading={getFormDetailsByIdLoading}
              dropDown_budget_itemsData={dropDown_budget_itemsData}
              dropDown_stagesData={dropDown_stagesData}
              dropDown_currencyData={dropDown_currencyData}
              createUpdateBJ_form={createUpdateBJ_form}
              createUpdateBJ_formLoading={createUpdateBJ_formLoading}
              dropDown_fy_mastersData={dropDown_fy_mastersData}
              debounceFy_masters={debounceFy_masters}
              //dropDown_bjc_forecast_itemsData={dropDown_bjc_forecast_itemsData}
              //debounceBjc_forecast_items={debounceBjc_forecast_items}
              dropDown_fpm_itemsData={dropDown_fpm_itemsData}
              debounceFpm_items={debounceFpm_items}
              dropDown_gfpm_itemsData={dropDown_gfpm_itemsData}
              debounceGfpm_items={debounceGfpm_items}
              dropDown_product_ratingData={dropDown_product_ratingData}
              debounceProduct_rating={debounceProduct_rating}
              edit_PIF_form_start={edit_PIF_form_start}
            />
            {/*  <EditableTable /> */}
          </>
        );
      case 7:
        return (
          <>
            <CompetitiveScenario
              PIF_form_id={params?.id}
              dropDown_GIL_GPILoading={dropDown_GIL_GPILoading}
              dropDown_GIL_GPIData={dropDown_GIL_GPIData}
              dropDown_primary_marketData={dropDown_primary_marketData}
              //dropDown_extension_marketData={dropDown_extension_marketData}
              createUpdateCompetitiveScenario_form={
                createUpdateCompetitiveScenario_form
              }
              setSelectedTab={setSelectedTab}
              completedTabs={completedTabs}
              setCompletedTabs={setCompletedTabs}
              isTabEnabled={isTabEnabled}
              getFormDetailsByIdData={getFormDetailsByIdData}
              getFormDetailsByIdLoading={getFormDetailsByIdLoading}
              createUpdateCompetitiveScenario_formLoading={
                createUpdateCompetitiveScenario_formLoading
              }
              dropDown_genericizedData={dropDown_genericizedData}
              dropDown_launch_typeData={dropDown_launch_typeData}
              dropDown_filing_typeData={dropDown_filing_typeData}
              debounceGenericized={debounceGenericized}
              debounceFiling_type={debounceFiling_type}
              debounceLaunch_type={debounceLaunch_type}
              edit_PIF_form_start={edit_PIF_form_start}
            />
          </>
        );
      case 8:
        return (
          <>
            <MarketData
              PIF_form_id={params?.id}
              setSelectedTab={setSelectedTab}
              dropDown_administration_routeData={
                dropDown_administration_routeData
              }
              dropDown_dosage_formData={dropDown_dosage_formData}
              dropDown_approved_strengthsData={dropDown_approved_strengthsData}
              createUpdateIPDetail_formLoading={
                createUpdateIPDetail_formLoading
              }
              createUpdateIPDetail_form={createUpdateIPDetail_form}
              dropDown_API_sourceData={dropDown_API_sourceData}
              completedTabs={completedTabs}
              setCompletedTabs={setCompletedTabs}
              isTabEnabled={isTabEnabled}
              getFormDetailsByIdData={getFormDetailsByIdData}
              getFormDetailsByIdLoading={getFormDetailsByIdLoading}
              dropDown_patent_typeData={dropDown_patent_typeData}
              dropDown_countryData={dropDown_countryData}
              dropDown_exclusivity_typeData={dropDown_exclusivity_typeData}
              createUpdateMarketData_form={createUpdateMarketData_form}
              createUpdateMarketData_formLoading={
                createUpdateMarketData_formLoading
              }
              setPageNum={setPageNum}
              dropDown_table_headersData={dropDown_table_headersData}
              dropDown_md_global_data={dropDown_md_global_data}
              //dropDown_md_ims_data={dropDown_md_ims_data}
              debounceMd_global={debounceMd_global}
              edit_PIF_form_start={edit_PIF_form_start}
            />
            {/* <EditableTable /> */}
            {/*  <DynamicInputField /> */}
          </>
        );
      default:
        return window?.scrollTo(0, document.body.scrollHeight);
    }
  };
  return (
    <CustomLayout sider={false}>
      {pageNum === 1 ? (
        <>
          {' '}
          <div className={styles.stepper_flow}>
            <img src={Steper_1} alt="Steper_1" />
          </div>
          <div className={styles.card_container}>
            <div className={styles.fixed_content}>
              <Space
                direction="vertical"
                size={0}
                style={{ marginLeft: '0px' }}
                className={styles.sidebar_margin}
              >
                {FactorsData?.map((item, index) => (
                  <div
                    key={index}
                    className={
                      selectedTab === index + 1
                        ? ` ${styles.selected}`
                        : `${styles.factors_menu_style}`
                    }
                    style={{
                      cursor: isTabEnabled(item.key)
                        ? 'pointer'
                        : 'not-allowed',
                      backgroundColor:
                        selectedTab === item.key ? '#ffffff' : '#F5F7F9',
                      color: isTabEnabled(item.key) ? '#0098da' : '#929297',
                      transition: 'background-color 0.3s'
                    }}
                    onClick={() => {
                      if (isTabEnabled(item.key)) {
                        // for disabled tab
                        onClickDecider(item.key);
                      }
                    }}
                  >
                    <div
                      className={`cursor-pointer ${styles.left_text}`}
                      //onClick={() => onClickDecider(index + 1)}
                    >
                      {item?.label}
                    </div>
                  </div>
                ))}
              </Space>
            </div>
            <div className={styles.scrollable_content}>
              <div className={styles.scrollable_inner}>
                <div className={styles.right_container}>{allContent()}</div>
              </div>
            </div>
          </div>
        </>
      ) : pageNum === 2 ? (
        <SignatoriesApprover
          PIF_form_id={params?.id}
          setPageNum={setPageNum}
          dropDown_signatoriesData={dropDown_signatoriesData}
          debounceSignatories={debounceSignatories}
          dropDown_approversData={dropDown_approversData}
          debounceApprovers={debounceApprovers}
          createUpdateApprovers_formLoading={createUpdateApprovers_formLoading}
          createUpdateApprovers_form={createUpdateApprovers_form}
          getFormDetailsByIdData={getFormDetailsByIdData}
          getFormDetailsByIdLoading={getFormDetailsByIdLoading}
          edit_PIF_form_start={edit_PIF_form_start}
        />
      ) : pageNum === 3 ? (
        <UploadDocuments
          PIF_form_id={params?.id}
          setPageNum={setPageNum}
          getFormDetailsByIdData={getFormDetailsByIdData}
          uploadAttachments_formLoading={uploadAttachments_formLoading}
          uploadAttachments_form={uploadAttachments_form}
          delete_uploaded_docs={delete_uploaded_docs}
          deleteDocsLoading={deleteDocsLoading}
          getFormDetailsById_form={getFormDetailsById_form}
          getFormDetailsByIdLoading={getFormDetailsByIdLoading}
          edit_PIF_form_start={edit_PIF_form_start}
        />
      ) : pageNum === 4 ? (
        <Preview
          PIF_form_id={params?.id}
          setPageNum={setPageNum}
          getFormDetailsByIdData={getFormDetailsByIdData}
          getFormDetailsByIdLoading={getFormDetailsByIdLoading}
          initiateFdFormLoading={initiateFdFormLoading}
          initiateFdForm={initiateFdForm}
          edit_PIF_form_start={edit_PIF_form_start}
        />
      ) : (
        ''
      )}
    </CustomLayout>
  );
};

export default ReviseEdit_PIF_FD_FormPage;
