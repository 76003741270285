import { useEffect, useState /* , useMemo */ } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Collapse,
  Divider,
  Spin,
  message,
  Tooltip,
  Space,
  Badge
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
//import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './proposed_product_details.module.less';
import { useDispatch } from 'react-redux';
import FormItem from 'common/FormItem';
import CustomCollapse from '@/common/CustomCollapse';
import API_Source_Table from '@/common/prop_prod_details/api_source_table/API_Source_Table';
import KSM_Source_Table from '@/common/prop_prod_details/ksm_source_table/KSM_Source_Table';
//import { debounce } from 'lodash';

import moment from 'moment';
import { history } from 'app/history';

import DynamicTable_Prop_prod from '@/common/dynamicTable_Prop_prod/DynamicTable_Prop_prod';
import { hasEmptyValue } from 'utilities/helpers';

const { Option } = Select;

const { Panel } = Collapse;

const Proposed_product_details = ({
  PIF_form_id,
  setSelectedTab,
  dropDown_administration_routeData,
  dropDown_dosage_formData,
  dropDown_approved_strengthsData,
  createUpdatePropProductDetail_formLoading,
  createUpdatePropProductDetail_form,
  //dropDown_API_sourceData,
  completedTabs,
  setCompletedTabs,
  isTabEnabled,
  getFormDetailsByIdLoading,
  getFormDetailsByIdData,
  debounceAdministration_route,
  debounceDosage_form,
  debounceApproved_strengths
  //debounceAPI_source
}) => {
  if (!completedTabs?.includes(3)) {
    setCompletedTabs([...completedTabs, 3]);
    //console.log('completedTabs::::', completedTabs);
  }
  isTabEnabled(3);

  const storedData = localStorage.getItem('Edit_PIF_form_start');

  //console.log('storedData@@:', storedData);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [dataSourceApi, setDataSourceApi] = useState();
  const [dataSourceKsm, setDataSourceKsm] = useState();
  const [tableError, setTableError] = useState(false);
  const [formData1, setFormData1] = useState({});
  //const [tagSearch, setTagSearch] = useState('');
  const [dataSource, setDataSource] = useState();
  const [product_name, setProduct_name] = useState('');
  const [isOthers_proposedStrengths, setIsOthers_proposedStrengths] =
    useState();
  const [isOthers_routeAdministration, setIsOthers_routeAdministration] =
    useState();
  const [isOthers_dosageForm, setIsOthers_dosageForm] = useState();

  useEffect(() => {
    setProduct_name(
      getFormDetailsByIdData?.ref_product_detail?.length === 0
        ? ''
        : getFormDetailsByIdData?.ref_product_detail?.product_name
    );
  }, [getFormDetailsByIdData]);

  //console.log('getFormDetailsByIdData', getFormDetailsByIdData);

  // useEffect(() => {
  //   console.log('storedData_FALSE:', storedData);
  //   console.log(
  //     'prop_strength?.length>>>',
  //     getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length
  //   );
  //   console.log('formData1?.Strength>>>', formData1?.Strength);
  //   if (
  //     storedData === 'false' ||
  //     getFormDetailsByIdData?.prop_product_detail === null ||
  //     getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length ===
  //       0 ||
  //     (getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length > 0 &&
  //       storedData === 'true' &&
  //       formData1?.Strength !== undefined)
  //   ) {
  //     setDataSource([
  //       {
  //         key: generateKey(),
  //         UoM_id:
  //           formData1?.Strength === undefined
  //             ? getFormDetailsByIdData?.prop_product_detail?.prop_UoM?.id
  //             : formData1?.Strength,
  //         size_shape_similarity: '',
  //         score: '',
  //         strength_value: ''
  //         //is_active: true
  //       } // Initial empty row
  //     ]);
  //   }
  // }, [getFormDetailsByIdData, storedData, formData1?.Strength]);

  // useEffect(() => {
  //   console.log('storedData_TRUE:', storedData);
  //   console.log(
  //     'prop_strength?.length>>>',
  //     getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length
  //   );
  //   console.log('formData1?.Strength>>>', formData1?.Strength);
  //   if (
  //     getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length > 0 &&
  //     storedData === 'true' &&
  //     formData1?.Strength === undefined
  //   ) {
  //     const newData =
  //       getFormDetailsByIdData?.prop_product_detail?.prop_strength?.map(
  //         (item) => ({
  //           key: item.id.toString(),
  //           id: item.id,
  //           UoM_id: item.prop_strength_UoM.id,
  //           size_shape_similarity: item.size_shape_similarity,
  //           score: item.score,
  //           strength_value: item.strength_value
  //           // is_active: item.is_active
  //         })
  //       );
  //     setDataSource(newData);
  //   }
  // }, [getFormDetailsByIdData, storedData === 'true']);

  // function generateKey() {
  //   return Math.random().toString(36).substr(2, 10);
  // }

  //<<----------Set data to dataSource for Strengths----------->>
  useEffect(() => {
    if (
      storedData === 'false' ||
      getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length ===
        0 ||
      (getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length > 0 &&
        storedData === 'true' &&
        formData1?.Strength !== undefined)
    ) {
      setDataSource([
        {
          key: generateKey(),
          UoM_id:
            formData1?.Strength === undefined
              ? getFormDetailsByIdData?.prop_product_detail?.prop_UoM?.id
              : formData1?.Strength,
          size_shape_similarity: '',
          score: '',
          strength_value: ''
        }
      ]);
    }
  }, [storedData === 'false', formData1?.Strength]);

  useEffect(() => {
    if (
      getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length > 0 &&
      storedData === 'true' &&
      formData1?.Strength === undefined
    ) {
      const newData =
        getFormDetailsByIdData?.prop_product_detail?.prop_strength?.map(
          (item) => ({
            key: item.id.toString(),
            id: item.id,
            UoM_id: item.prop_strength_UoM.id,
            size_shape_similarity: item.size_shape_similarity,
            score: item.score,
            strength_value: item.strength_value
            // is_active: item.is_active
          })
        );
      setDataSource(newData);
    }
  }, [getFormDetailsByIdData, storedData === 'true']);

  function generateKey() {
    return Math.random().toString(36).substr(2, 10);
  }

  //<<----------Set data to dataSourceApi for API Source----------->>
  useEffect(() => {
    if (
      storedData === 'false' ||
      getFormDetailsByIdData?.prop_product_detail?.api_sources?.length === 0 ||
      (getFormDetailsByIdData?.prop_product_detail?.api_sources?.length > 0 &&
        storedData === 'true')
    ) {
      setDataSourceApi([
        {
          key: generateKeyApi(),
          api_name: '',
          source_internal: '',
          source_external: ''
        }
      ]);
    }
  }, [storedData === 'false', formData1?.Strength]);

  useEffect(() => {
    if (
      getFormDetailsByIdData?.prop_product_detail?.api_sources?.length > 0 &&
      storedData === 'true'
    ) {
      const newData =
        getFormDetailsByIdData?.prop_product_detail?.api_sources?.map(
          (item) => ({
            key: item.id.toString(),
            id: item.id,
            api_name: item?.api_name,
            source_internal: item?.source_internal,
            source_external: item?.source_external
          })
        );
      setDataSourceApi(newData);
    }
  }, [getFormDetailsByIdData, storedData === 'true']);

  function generateKeyApi() {
    return Math.random().toString(36).substr(2, 10);
  }

  //<<----------Set data to dataSourceKsm for KSM Source----------->>
  useEffect(() => {
    if (
      storedData === 'false' ||
      getFormDetailsByIdData?.prop_product_detail?.ksm_sources?.length === 0 ||
      (getFormDetailsByIdData?.prop_product_detail?.ksm_sources?.length > 0 &&
        storedData === 'true')
    ) {
      setDataSourceKsm([
        {
          key: generateKeyKsm(),
          api_name: '',
          ksm_name: '',
          source_internal: '',
          source_external: ''
        }
      ]);
    }
  }, [storedData === 'false', formData1?.Strength]);

  useEffect(() => {
    if (
      getFormDetailsByIdData?.prop_product_detail?.ksm_sources?.length > 0 &&
      storedData === 'true'
    ) {
      const newData =
        getFormDetailsByIdData?.prop_product_detail?.ksm_sources?.map(
          (item) => ({
            key: item.id.toString(),
            id: item.id,
            api_name: item?.api_name,
            ksm_name: item?.ksm_name,
            source_internal: item?.source_internal,
            source_external: item?.source_external
          })
        );
      setDataSourceKsm(newData);
    }
  }, [getFormDetailsByIdData, storedData === 'true']);

  function generateKeyKsm() {
    return Math.random().toString(36).substr(2, 10);
  }

  console.log(
    'getFormDetailsByIdData?.prop_product_detail?.dosage_form?.id',
    getFormDetailsByIdData?.prop_product_detail
  );

  useEffect(() => {
    form.setFieldsValue({
      name_of_the_product:
        getFormDetailsByIdData?.ref_product_detail?.length === 0
          ? ''
          : getFormDetailsByIdData?.ref_product_detail?.product_name,
      api: getFormDetailsByIdData?.prop_product_detail?.API,
      RouteAdminisration:
        getFormDetailsByIdData?.prop_product_detail?.administration_route?.id,
      DosageForm: getFormDetailsByIdData?.prop_product_detail?.dosage_form?.id,
      ReleaseType: getFormDetailsByIdData?.prop_product_detail?.release_type,
      APISource: getFormDetailsByIdData?.prop_product_detail?.API_source?.id,
      mfg_site: getFormDetailsByIdData?.prop_product_detail?.mfg_site,
      ProposedPackDetails:
        getFormDetailsByIdData?.prop_product_detail?.proposed_pack_detail,
      ParkConfiguration:
        getFormDetailsByIdData?.prop_product_detail?.pack_config,
      Strength: getFormDetailsByIdData?.prop_product_detail?.prop_UoM?.id,
      Remarks: getFormDetailsByIdData?.prop_product_detail?.remarks
    });
  }, [getFormDetailsByIdData]);

  useEffect(() => {
    form.setFieldsValue({
      initiation_date: moment() || ''
    });
  });
  // useEffect(() => {
  //   const payload = {};
  //   if (tagSearch) payload['search'] = tagSearch;
  //   dispatch(getCategoryList(payload));
  // }, [tagSearch]);

  // const debounceFetcher = useMemo(() => {
  //   const loadOptions = (tagSearch) => {
  //     setTagSearch(tagSearch);
  //   };
  //   return debounce(loadOptions, 400);
  // }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    if (slug === 'Strength') {
      // setIsOthers_proposedStrengths(value);
      const approved_strengths_others = dropDown_approved_strengthsData?.find(
        (item) => item.id === value
      );

      setIsOthers_proposedStrengths(
        approved_strengths_others?.name === 'Others' ? 'Others' : false
      );
    }
    if (slug === 'DosageForm') {
      // setIsOthers_dosageForm(value);
      const dosage_form_others = dropDown_dosage_formData?.find(
        (item) => item.id === value
      );

      setIsOthers_dosageForm(
        dosage_form_others?.name === 'Others' ? 'Others' : false
      );
    }
    if (slug === 'RouteAdminisration') {
      // setIsOthers_routeAdministration(value);
      const administration_route_others =
        dropDown_administration_routeData?.find((item) => item.id === value);

      setIsOthers_routeAdministration(
        administration_route_others?.name === 'Others' ? 'Others' : false
      );
    }

    setFormData1({
      ...formData1,
      [slug]: value ? value : null
    });
  };
  // const isactiveArray = dataSource?.map((obj) => ({ ...obj, is_active: true }));
  // console.log('isactiveArray::::', isactiveArray);
  const onFinish = () => {
    if (dataSource.length < 1 || hasEmptyValue(dataSource)) {
      message.error('strengths table must have at least 1 entry');
      setTableError(true);
      return;
    } else {
      const payload = {
        edit_type: 'mandatory',
        pif_id: PIF_form_id,
        product_name:
          getFormDetailsByIdData?.ref_product_detail?.length === 0
            ? ''
            : getFormDetailsByIdData?.ref_product_detail?.product_name,
        // formData1?.name_of_the_product === undefined
        //   ? getFormDetailsByIdData?.ref_product_detail?.product_name
        //   : formData1?.name_of_the_product,
        API:
          formData1?.api === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.API
            : formData1?.api,
        administration_route_id:
          formData1?.RouteAdminisration === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.administration_route
                ?.id
            : isOthers_routeAdministration === 'Others'
            ? ''
            : formData1?.RouteAdminisration,
        administration_route_others:
          isOthers_routeAdministration === 'Others'
            ? [formData1?.route_administration_others]
            : [],
        dosage_form_id:
          formData1?.DosageForm === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.dosage_form?.id
            : isOthers_dosageForm === 'Others'
            ? ''
            : formData1?.DosageForm,
        dosage_form_others:
          isOthers_dosageForm === 'Others'
            ? [formData1?.dosage_form_others]
            : [],
        release_type:
          formData1?.ReleaseType === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.release_type
            : formData1?.ReleaseType,
        UoM_id:
          formData1?.Strength === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.prop_UoM?.id
            : isOthers_proposedStrengths === 'Others'
            ? ''
            : formData1?.Strength,
        UoM_others:
          isOthers_proposedStrengths === 'Others'
            ? [formData1?.proposed_strengths_others]
            : [],
        pack_config:
          formData1?.ParkConfiguration === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.pack_config
            : formData1?.ParkConfiguration,
        api_source_id:
          formData1?.APISource === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.API_source?.id
            : formData1?.APISource,
        mfg_site:
          formData1?.mfg_site === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.mfg_site
            : formData1?.mfg_site,
        proposed_pack_detail:
          formData1?.ProposedPackDetails === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.proposed_pack_detail
            : formData1?.ProposedPackDetails,
        remarks:
          formData1?.Remarks === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.remarks
            : formData1?.Remarks,
        prop_strength_list: dataSource?.map((obj) => ({
          ...obj
          //is_active: true
        })),
        api_sources:
          dataSourceApi.length &&
          (dataSourceApi[0].api_name ||
            dataSourceApi[0].source_external ||
            dataSourceApi[0].source_internal)
            ? dataSourceApi?.map((obj) => ({
                ...obj
              }))
            : [],
        ksm_sources:
          dataSourceKsm.length &&
          dataSourceKsm[0].api_name &&
          dataSourceKsm[0].ksm_name &&
          dataSourceKsm[0].source_external &&
          dataSourceKsm[0].source_internal
            ? dataSourceKsm?.map((obj) => ({
                ...obj
              }))
            : []
      };
      console.log('prop_payload::::', payload);
      dispatch(createUpdatePropProductDetail_form({ payload }))?.then(function (
        response
      ) {
        if (response?.payload?.success) {
          //history.push(`/admin-quizzes`);
          // localStorage.setItem('prop_table', true);
          setSelectedTab(4);
        } else {
          //message.error('Please fill required field');
          message.error(response?.payload?.message);
          console.log('response?.payload?.error', response?.payload?.error);
        }
      });
    }
  };
  //const defaultDate = moment().format('MMM DD, YYYY');
  return (
    <div className={`${styles.page_container}`}>
      <Spin spinning={getFormDetailsByIdLoading}>
        <Row className={styles.pif_page_titel_container}>
          <div className={`${styles.page_title}`}>
            <span className={styles.title_label}>Proposed Product Details</span>
          </div>
          {getFormDetailsByIdData?.changes_in_progress === true && (
            <Badge
              count={'Changes in progress'}
              style={{
                backgroundColor: '#ffffff',
                color: '#0098da',
                textTransform: 'none',
                borderColor: '#0098da',
                fontSize: '12px',
                //fontFamily: 'SFProText Regular',
                padding: '10px 15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '-20px'
              }}
            />
          )}
        </Row>
        <Form
          form={form}
          name="PIF_RD_Information"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={8}>
              <FormItem
                label="Name of the Product"
                // name="name_of_the_product"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Tooltip
                  title={product_name}
                  overlayClassName={styles.customTooltip}
                >
                  <Input
                    //disabled
                    readOnly
                    value={
                      getFormDetailsByIdData?.ref_product_detail?.length === 0
                        ? ''
                        : getFormDetailsByIdData?.ref_product_detail
                            ?.product_name
                    }
                    allowClear
                    placeholder="Enter name of the product"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'name_of_the_product',
                        e.target.value
                      )
                    }
                  />
                </Tooltip>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="API"
                name="api"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter API name"
                  onChange={(e) =>
                    handleFormDataOnChange('api', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Route of Adminisration"
                name="RouteAdminisration"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceAdministration_route}
                  placeholder="Select route of Adminisration"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('RouteAdminisration', value)
                  }
                >
                  {dropDown_administration_routeData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_routeAdministration === 'Others' && (
              <Col span={8}>
                <FormItem
                  label="Route of Administration - Others"
                  name="route_administration_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter route of Adminisration"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'route_administration_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            {' '}
            <Col span={8}>
              <FormItem
                label="Dosage Form"
                name="DosageForm"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceDosage_form}
                  placeholder="Select dosage Form"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('DosageForm', value)
                  }
                >
                  {dropDown_dosage_formData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_dosageForm === 'Others' && (
              <Col span={8}>
                <FormItem
                  label="Dosage Form - Others"
                  name="dosage_form_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter dosage Form"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'dosage_form_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
            <Col span={8}>
              <FormItem
                label="Release Type"
                name="ReleaseType"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter release type"
                  onChange={(e) =>
                    handleFormDataOnChange('ReleaseType', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Pack Configuration"
                name="ParkConfiguration"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter park configuration"
                  onChange={(e) =>
                    handleFormDataOnChange('ParkConfiguration', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            {/*   <Col span={8}>
              <FormItem
                label="API Source"
                name="APISource"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  showSearch
                  onSearch={debounceAPI_source}
                  placeholder="Select API source"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('APISource', value)
                  }
                >
                  {dropDown_API_sourceData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col> */}
            <Col span={8}>
              <FormItem
                label="Manufacturing Site"
                name="mfg_site"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter manufacturing site"
                  onChange={(e) =>
                    handleFormDataOnChange('mfg_site', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Proposed Pack Details"
                name="ProposedPackDetails"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter proposed pack details"
                  onChange={(e) =>
                    handleFormDataOnChange(
                      'ProposedPackDetails',
                      e.target.value
                    )
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Proposed Strength (UoM)"
                name="Strength"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  showSearch
                  onSearch={debounceApproved_strengths}
                  placeholder="Select proposed strengths"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('Strength', value)
                  }
                >
                  {dropDown_approved_strengthsData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_proposedStrengths === 'Others' && (
              <Col span={8}>
                <FormItem
                  label="Proposed Strengths(UoM) - Others"
                  name="proposed_strengths_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter proposed strengths"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'proposed_strengths_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>
          <Space direction="vertical" size={20} style={{ width: '100%' }}>
            <Row gutter={16}>
              <CustomCollapse
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? 180 : 0} />
                )}
                expandIconPosition="end"
                bordered={false}
                defaultActiveKey={['1']}
                style={
                  tableError
                    ? {
                        border: '1px solid red',
                        backgroundColor: '#f546460d',
                        borderRadius: '0.5rem'
                      }
                    : {}
                }
              >
                <Panel
                  header={
                    <span style={{ fontWeight: 500, fontSize: '14px' }}>
                      Strengths
                      <span style={{ color: 'red', fontSize: '16px' }}>*</span>
                    </span>
                  }
                  //extra="Add email message"
                  key="2"
                >
                  <Divider style={{ marginTop: '0px' }} />

                  <DynamicTable_Prop_prod
                    setDataSource={setDataSource}
                    dataSource={dataSource}
                    setError={setTableError}
                    generateKey={generateKey}
                    formData1={formData1}
                    getFormDetailsByIdData={getFormDetailsByIdData}
                    dropDown_approved_strengthsData={
                      dropDown_approved_strengthsData
                    }
                  />
                </Panel>
              </CustomCollapse>
            </Row>
            <Row gutter={16}>
              <CustomCollapse
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? 180 : 0} />
                )}
                expandIconPosition="end"
                bordered={false}
                defaultActiveKey={['1']}
              >
                <Panel
                  header={
                    <span style={{ fontWeight: 500, fontSize: '14px' }}>
                      API Source
                      {/*  <span style={{ color: 'red', fontSize: '16px' }}>*</span> */}
                    </span>
                  }
                  //extra="Add email message"
                  key="2"
                >
                  <Divider style={{ marginTop: '0px' }} />

                  <API_Source_Table
                    setDataSource={setDataSourceApi}
                    dataSource={dataSourceApi}
                    generateKey={generateKeyApi}
                    // formData1={formData1}
                    // getFormDetailsByIdData={getFormDetailsByIdData}
                  />
                </Panel>
              </CustomCollapse>
            </Row>
            <Row gutter={16}>
              <CustomCollapse
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? 180 : 0} />
                )}
                expandIconPosition="end"
                bordered={false}
                defaultActiveKey={['1']}
              >
                <Panel
                  header={
                    <span style={{ fontWeight: 500, fontSize: '14px' }}>
                      KSM Source
                      {/* <span style={{ color: 'red', fontSize: '16px' }}>*</span> */}
                    </span>
                  }
                  //extra="Add email message"
                  key="2"
                >
                  <Divider style={{ marginTop: '0px' }} />

                  <KSM_Source_Table
                    setDataSource={setDataSourceKsm}
                    dataSource={dataSourceKsm}
                    generateKey={generateKeyKsm}
                    // formData1={formData1}
                    // getFormDetailsByIdData={getFormDetailsByIdData}
                  />
                </Panel>
              </CustomCollapse>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  label="Remarks"
                  name="Remarks"
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter remarks"
                    onChange={(e) =>
                      handleFormDataOnChange('Remarks', e.target.value)
                    }
                  />
                </FormItem>
              </Col>
            </Row>
          </Space>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/home`)}
            >
              Cancel
            </CustomButton>
            <FormItem>
              <CustomButton
                loading={createUpdatePropProductDetail_formLoading}
                type="primary"
                htmlType="submit"
                className={styles.post_btn_style}
              >
                Next
              </CustomButton>
            </FormItem>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default Proposed_product_details;
