import { useEffect, useState /* , useMemo */ } from 'react';
import {
  Form,
  Input,
  DatePicker,
  Select,
  Row,
  Col,
  Collapse,
  Divider,
  Spin,
  message
  //message
} from 'antd';
import { DownOutlined, LinkOutlined } from '@ant-design/icons';
//import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './reference_product_details.module.less';
import { useDispatch } from 'react-redux';
import FormItem from 'common/FormItem';
import CustomCollapse from '@/common/CustomCollapse';
//import { debounce } from 'lodash';
import moment from 'moment';
import { history } from 'app/history';
import DynamicTable from '@/common/dynamicTable/DynamicTable';
import { hasEmptyValue } from 'utilities/helpers';

const { Option } = Select;
//const { TextArea } = Input;
const { Panel } = Collapse;

const Reference_product_details = ({
  PIF_form_id,
  setSelectedTab,
  dropDown_market_segmentData,
  dropDown_therapeutic_categoryData,
  dropDown_administration_routeData,
  dropDown_dosage_formData,
  dropDown_approved_strengthsData,
  createUpdateRefProductDetail_formLoading,
  createUpdateRefProductDetail_form,
  getFormDetailsByIdData,
  getFormDetailsByIdLoading,
  debounceMarket_Segment,
  debounceTherapeutic_category,
  debounceAdministration_route,
  debounceDosage_form,
  debounceApproved_strengths,
  draft_PIF_form_start
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tableError, setTableError] = useState(false);

  const storedData = localStorage.getItem('ref_table');

  const [formData1, setFormData1] = useState({});
  const [dataSource, setDataSource] = useState();
  const [isOthers_marketSegment, setIsOthers_marketSegment] = useState();
  const [isOthers_therapeuticCategory, setIsOthers_therapeuticCategory] =
    useState();
  const [isOthers_routeAdministration, setIsOthers_routeAdministration] =
    useState();
  const [isOthers_dosageForm, setIsOthers_dosageForm] = useState();
  const [isOthers_approvedStrengths, setIsOthers_approvedStrengths] =
    useState();

  // storedData !== 'true' &&
  // [
  //       {
  //         key: generateKey(),
  //         // '',
  //         UoM_id:
  //           formData1?.ApprovedStrengths === undefined
  //             ? getFormDetailsByIdData?.ref_product_detail?.UoM?.id
  //             : formData1?.ApprovedStrengths,
  //         trade_dress: '',
  //         size: '',
  //         shape: '',
  //         colour: '',
  //         making: '',
  //         score: ''
  //       } // Initial empty row
  //     ]
  // console.log('formData1?.ApprovedStrengths', formData1?.ApprovedStrengths);
  // console.log('dataSource', dataSource);
  useEffect(() => {
    //console.log('storedData === false:', storedData);
    if (
      storedData === 'false' ||
      getFormDetailsByIdData?.ref_product_detail?.ref_strength?.length === 0 ||
      (getFormDetailsByIdData?.ref_product_detail?.ref_strength?.length > 0 &&
        storedData === 'true' &&
        formData1?.ApprovedStrengths !== undefined) ||
      (getFormDetailsByIdData?.ref_product_detail?.ref_strength?.length > 0 &&
        draft_PIF_form_start === 'true' &&
        formData1?.ApprovedStrengths !== undefined)
    ) {
      setDataSource([
        {
          key: generateKey(),
          UoM_id:
            formData1?.ApprovedStrengths === undefined
              ? getFormDetailsByIdData?.ref_product_detail?.UoM?.id
              : formData1?.ApprovedStrengths,
          trade_dress: '',
          size: '',
          shape: '',
          colour: '',
          making: '',
          score: '',
          strength_value: ''
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [
    getFormDetailsByIdData,
    storedData,
    formData1?.ApprovedStrengths,
    draft_PIF_form_start
  ]);

  useEffect(() => {
    //console.log('storedData === true:', storedData);
    if (
      (getFormDetailsByIdData?.ref_product_detail?.ref_strength?.length > 0 &&
        storedData === 'true' &&
        formData1?.ApprovedStrengths === undefined) ||
      (getFormDetailsByIdData?.ref_product_detail?.ref_strength?.length > 0 &&
        draft_PIF_form_start === 'true' &&
        formData1?.ApprovedStrengths === undefined)
    ) {
      const newData =
        getFormDetailsByIdData?.ref_product_detail?.ref_strength.map(
          (item) => ({
            key: item.id.toString(),
            id: item.id,
            UoM_id: item?.ref_strength_UoM?.id,
            trade_dress: item.trade_dress,
            size: item.size,
            shape: item.shape,
            colour: item.colour,
            making: item.making,
            score: item.score,
            strength_value: item.strength_value
            // is_active: item.is_active
          })
        );
      setDataSource(newData);
    }
  }, [getFormDetailsByIdData, storedData, draft_PIF_form_start]);

  function generateKey() {
    return Math.random().toString(36).substr(2, 10);
  }
  useEffect(() => {
    form.setFieldsValue({
      name_of_the_product:
        getFormDetailsByIdData?.ref_product_detail?.product_name,
      api: getFormDetailsByIdData?.ref_product_detail?.API,
      // ref_prod_details:
      //   getFormDetailsByIdData?.ref_product_details?.ref_prod_detail,
      ref_prod_details:
        getFormDetailsByIdData?.ref_product_detail?.ref_prod_detail,
      innovator: getFormDetailsByIdData?.ref_product_detail?.innovator,
      brand_name: getFormDetailsByIdData?.ref_product_detail?.brand_name,
      ma_number: getFormDetailsByIdData?.ref_product_detail?.MA_number,
      approval_date:
        getFormDetailsByIdData?.ref_product_detail?.approval_date === null
          ? null
          : moment(getFormDetailsByIdData?.ref_product_detail?.approval_date),
      MarketSegment:
        getFormDetailsByIdData?.ref_product_detail?.market_segment?.id,
      KeyChannels: getFormDetailsByIdData?.ref_product_detail?.key_channel,
      TherapeuticCategory:
        getFormDetailsByIdData?.ref_product_detail?.therapeutic_category?.id,
      ApprovedIndication:
        getFormDetailsByIdData?.ref_product_detail?.approved_indication,
      RouteAdminisration:
        getFormDetailsByIdData?.ref_product_detail?.administration_route?.id,
      DosageForm: getFormDetailsByIdData?.ref_product_detail?.dosage_form?.id,
      ReleaseType: getFormDetailsByIdData?.ref_product_detail?.release_type,
      RSstrength: getFormDetailsByIdData?.ref_product_detail?.rs_strength,
      ParkConfiguration:
        getFormDetailsByIdData?.ref_product_detail?.pack_config,
      PackSize: getFormDetailsByIdData?.ref_product_detail?.pack_size,
      SecondaryPackdetails:
        getFormDetailsByIdData?.ref_product_detail?.secondary_pack_detail,
      ApprovedStrengths: getFormDetailsByIdData?.ref_product_detail?.UoM?.id,
      BCSClass: getFormDetailsByIdData?.ref_product_detail?.bcs_class,
      BioGuidance: getFormDetailsByIdData?.ref_product_detail?.bio_guidance,
      DosageRegimen: getFormDetailsByIdData?.ref_product_detail?.dosage_regimen,
      Remarks: getFormDetailsByIdData?.ref_product_detail?.remarks
      //initiation_date: getFormDetailsByIdData?.ref_product_detail?.initiation_date,
    });
  }, [getFormDetailsByIdData]);
  useEffect(() => {
    form.setFieldsValue({
      initiation_date: moment() || ''
    });
  });
  // useEffect(() => {
  //   const payload = {};
  //   if (tagSearch) payload['search'] = tagSearch;
  //   dispatch(getCategoryList(payload));
  // }, [tagSearch]);

  // const debounceFetcher = useMemo(() => {
  //   const loadOptions = (tagSearch) => {
  //     setTagSearch(tagSearch);
  //   };
  //   return debounce(loadOptions, 400);
  // }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    if (slug === 'MarketSegment') {
      //setIsOthers_marketSegment(value);
      const market_segment_others = dropDown_market_segmentData?.find(
        (item) => item.id === value
      );

      setIsOthers_marketSegment(
        market_segment_others?.name === 'Others' ? 'Others' : false
      );
    }
    if (slug === 'TherapeuticCategory') {
      // setIsOthers_therapeuticCategory(value);
      const therapeutic_categoryData_others =
        dropDown_therapeutic_categoryData?.find((item) => item.id === value);

      setIsOthers_therapeuticCategory(
        therapeutic_categoryData_others?.name === 'Others' ? 'Others' : false
      );
    }
    if (slug === 'RouteAdminisration') {
      // setIsOthers_routeAdministration(value);
      const administration_route_others =
        dropDown_administration_routeData?.find((item) => item.id === value);

      setIsOthers_routeAdministration(
        administration_route_others?.name === 'Others' ? 'Others' : false
      );
    }
    if (slug === 'DosageForm') {
      // setIsOthers_dosageForm(value);
      const dosage_others = dropDown_dosage_formData?.find(
        (item) => item.id === value
      );

      setIsOthers_dosageForm(
        dosage_others?.name === 'Others' ? 'Others' : false
      );
    }
    if (slug === 'ApprovedStrengths') {
      // setIsOthers_approvedStrengths(value);
      const approved_strengths_others = dropDown_approved_strengthsData?.find(
        (item) => item.id === value
      );

      setIsOthers_approvedStrengths(
        approved_strengths_others?.name === 'Others' ? 'Others' : false
      );
    }

    setFormData1({
      ...formData1,
      [slug]: value ? value : null
    });
  };

  const onFinish = () => {
    if (
      dataSource.length < 1 ||
      hasEmptyValue(dataSource, ['UoM_id:', 'size'])
    ) {
      message.error('Approved strengths table must have at least 1 entry');
      setTableError(true);
      return;
    } else {
      setTableError(false);

      const payload = {
        edit_type: 'draft',
        pif_id: PIF_form_id,
        product_name:
          formData1?.name_of_the_product === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.product_name
            : formData1?.name_of_the_product,
        API:
          formData1?.api === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.API
            : formData1?.api,

        ref_prod_detail:
          formData1?.ref_prod_details === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.ref_prod_detail
            : formData1?.ref_prod_details,
        innovator:
          formData1?.innovator === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.innovator
            : formData1?.innovator,
        brand_name:
          formData1?.brand_name === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.brand_name
            : formData1?.brand_name,
        MA_number:
          formData1?.ma_number === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.MA_number
            : formData1?.ma_number,
        approval_date:
          formData1?.approval_date === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.approval_date === null
              ? null
              : moment(
                  getFormDetailsByIdData?.ref_product_detail?.approval_date
                ).format('YYYY-MM-DD')
            : formData1?.approval_date === null
            ? null
            : moment(formData1?.approval_date).format('YYYY-MM-DD'),
        market_segment_id:
          formData1?.MarketSegment === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.market_segment?.id
            : isOthers_marketSegment === 'Others'
            ? ''
            : formData1?.MarketSegment,
        market_segment_others:
          isOthers_marketSegment === 'Others'
            ? [formData1?.market_segment_others]
            : [],
        key_channel:
          formData1?.KeyChannels === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.key_channel
            : formData1?.KeyChannels,
        therapeutic_category_id:
          formData1?.TherapeuticCategory === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.therapeutic_category
                ?.id
            : isOthers_therapeuticCategory === 'Others'
            ? ''
            : formData1?.TherapeuticCategory,
        therapeutic_category_others:
          isOthers_therapeuticCategory === 'Others'
            ? [formData1?.therapeutic_category_others]
            : [],
        approved_indication:
          formData1?.ApprovedIndication === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.approved_indication
            : formData1?.ApprovedIndication,
        administration_route_id:
          formData1?.RouteAdminisration === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.administration_route
                ?.id
            : isOthers_routeAdministration === 'Others'
            ? ''
            : formData1?.RouteAdminisration,
        administration_route_others:
          isOthers_routeAdministration === 'Others'
            ? [formData1?.route_administration_others]
            : [],
        dosage_form_id:
          formData1?.DosageForm === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.dosage_form?.id
            : isOthers_dosageForm === 'Others'
            ? ''
            : formData1?.DosageForm,
        dosage_form_others:
          isOthers_dosageForm === 'Others'
            ? [formData1?.dosage_form_others]
            : [],
        release_type:
          formData1?.ReleaseType === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.release_type
            : formData1?.ReleaseType,
        rs_strength:
          formData1?.RSstrength === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.rs_strength
            : formData1?.RSstrength,
        pack_config:
          formData1?.ParkConfiguration === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.pack_config
            : formData1?.ParkConfiguration,
        pack_size:
          formData1?.PackSize === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.pack_size
            : formData1?.PackSize,
        secondary_pack_detail:
          formData1?.SecondaryPackdetails === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.secondary_pack_detail
            : formData1?.SecondaryPackdetails,
        UoM_id:
          formData1?.ApprovedStrengths === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.UoM?.id
            : isOthers_approvedStrengths === 'Others'
            ? ''
            : formData1?.ApprovedStrengths,
        UoM_others:
          isOthers_approvedStrengths === 'Others'
            ? [formData1?.approved_strengths_others]
            : [],
        bcs_class:
          formData1?.BCSClass === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.bcs_class
            : formData1?.BCSClass,
        bio_guidance:
          formData1?.BioGuidance === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.bio_guidance
            : formData1?.BioGuidance,
        dosage_regimen:
          formData1?.DosageRegimen === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.dosage_regimen
            : formData1?.DosageRegimen,
        remarks:
          formData1?.Remarks === undefined
            ? getFormDetailsByIdData?.ref_product_detail?.remarks
            : formData1?.Remarks,
        ref_strength_list: dataSource?.map((obj) => ({
          ...obj
          //is_active: true,
          // UoM_id:
          //   formData1?.ApprovedStrengths === undefined
          //     ? getFormDetailsByIdData?.ref_product_detail?.UoM?.id
          //     : formData1?.ApprovedStrengths
        }))
      };
      // console.log('payload::::', payload);
      dispatch(createUpdateRefProductDetail_form({ payload }))?.then(function (
        response
      ) {
        if (response?.payload?.success) {
          //history.push(`/admin-quizzes`);

          localStorage.setItem('ref_table', true);
          //setActiveRefTable(true);
          setSelectedTab(3);
        } else {
          //message.error('Please fill required field');
          //console.log('response?.payload?.error', response?.payload?.error);
        }
      });
    }
  };
  //const defaultDate = moment().format('MMM DD, YYYY');
  return (
    <div className={`${styles.page_container}`}>
      <Spin spinning={getFormDetailsByIdLoading}>
        <div className={`${styles.page_title}`}>
          <span className={styles.title_label}>Reference Product Details</span>
        </div>

        <Form
          form={form}
          name="PIF_RD_Information"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={8}>
              <FormItem
                label="Name of the Product"
                name="name_of_the_product"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter name of the product"
                  onChange={(e) =>
                    handleFormDataOnChange(
                      'name_of_the_product',
                      e.target.value
                    )
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="API"
                name="api"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter API name"
                  onChange={(e) =>
                    handleFormDataOnChange('api', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Reference Product Details"
                name="ref_prod_details"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter reference product details"
                  onChange={(e) =>
                    handleFormDataOnChange('ref_prod_details', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={8}>
              <FormItem
                label="Innovator"
                name="innovator"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter innovator company name"
                  onChange={(e) =>
                    handleFormDataOnChange('innovator', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Brand Name"
                name="brand_name"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter brand name"
                  onChange={(e) =>
                    handleFormDataOnChange('brand_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label={
                  <sapn>
                    <sapn style={{ marginRight: '10px' }}>NDA number</sapn>
                    <a
                      href="https://www.fda.gov/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkOutlined />
                    </a>
                  </sapn>
                }
                name="ma_number"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  // type="number"
                  // step="0.01"
                  // min={0}
                  allowClear
                  placeholder="Enter MA number "
                  onChange={(e) =>
                    handleFormDataOnChange('ma_number', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={8}>
              <FormItem
                label="Approval Date"
                name="approval_date"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.date_styles}`}
                //className={`${styles.input_styles}`}
              >
                <DatePicker
                  //disabled
                  format={'DD MMM YYYY'}
                  onChange={(_, dateValue) =>
                    handleFormDataOnChange('approval_date', dateValue)
                  }
                  disabledDate={(current) => {
                    return (
                      // moment().add(-1, 'days') >= current
                      // ||
                      moment().add(0, 'month') <= current
                    );
                  }}
                  style={{ width: '100%' }}
                ></DatePicker>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Market Segment"
                name="MarketSegment"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceMarket_Segment}
                  placeholder="Select market segment"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('MarketSegment', value)
                  }
                >
                  {dropDown_market_segmentData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_marketSegment === 'Others' && (
              <Col span={8}>
                <FormItem
                  label="Market Segment - Others"
                  name="market_segment_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter market segment"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'market_segment_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
            <Col span={8}>
              <FormItem
                label="Key Channels"
                name="KeyChannels"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter key channels "
                  onChange={(e) =>
                    handleFormDataOnChange('KeyChannels', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={8}>
              <FormItem
                label="Therapeutic Category"
                name="TherapeuticCategory"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceTherapeutic_category}
                  placeholder="Select therapeutic category"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('TherapeuticCategory', value)
                  }
                >
                  {dropDown_therapeutic_categoryData.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_therapeuticCategory === 'Others' && (
              <Col span={8}>
                <FormItem
                  label="Therapeutic Category - Others"
                  name="therapeutic_category_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter therapeutic category"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'therapeutic_category_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
            <Col span={8}>
              <FormItem
                label="Approved Indication"
                name="ApprovedIndication"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter approved indication"
                  onChange={(e) =>
                    handleFormDataOnChange('ApprovedIndication', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Route of Administration"
                name="RouteAdminisration"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceAdministration_route}
                  placeholder="Select route of adminisration"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('RouteAdminisration', value)
                  }
                >
                  {dropDown_administration_routeData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_routeAdministration === 'Others' && (
              <Col span={8}>
                <FormItem
                  label="Route of Administration - Others"
                  name="route_administration_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter route of adminisration"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'route_administration_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={8}>
              <FormItem
                label="Dosage Form"
                name="DosageForm"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceDosage_form}
                  placeholder="Select dosage form"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('DosageForm', value)
                  }
                >
                  {dropDown_dosage_formData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_dosageForm === 'Others' && (
              <Col span={8}>
                <FormItem
                  label="Dosage Form - Others"
                  name="dosage_form_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter dosage form"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'dosage_form_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
            <Col span={8}>
              <FormItem
                label="Release Type"
                name="ReleaseType"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter release type"
                  onChange={(e) =>
                    handleFormDataOnChange('ReleaseType', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="RS Strength"
                name="RSstrength"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter RS strength"
                  onChange={(e) =>
                    handleFormDataOnChange('RSstrength', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={8}>
              <FormItem
                label="Pack Configuration"
                name="ParkConfiguration"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter pack configuration"
                  onChange={(e) =>
                    handleFormDataOnChange('ParkConfiguration', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Pack Size"
                name="PackSize"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter pack size"
                  onChange={(e) =>
                    handleFormDataOnChange('PackSize', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Secondary Pack Details"
                name="SecondaryPackdetails"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter secondary pack detail"
                  onChange={(e) =>
                    handleFormDataOnChange(
                      'SecondaryPackdetails',
                      e.target.value
                    )
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={8}>
              <FormItem
                label="Approved Strengths (UoM)"
                name="ApprovedStrengths"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  showSearch
                  onSearch={debounceApproved_strengths}
                  placeholder="Select approved strengths "
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('ApprovedStrengths', value)
                  }
                >
                  {dropDown_approved_strengthsData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_approvedStrengths === 'Others' && (
              <Col span={8}>
                <FormItem
                  label="Approved Strengths(UoM) - Others"
                  name="approved_strengths_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter approved strengths"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'approved_strengths_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>
          <Row
            gutter={16}
            style={{ marginBottom: '15px' }}
            className={`${styles.row_2}`}
          >
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
              style={
                tableError
                  ? {
                      border: '1px solid red',
                      backgroundColor: '#f546460d',
                      borderRadius: '0.5rem'
                    }
                  : {}
              }
            >
              <Panel
                header={
                  <span style={{ fontWeight: 500, fontSize: '14px' }}>
                    Approved Strengths
                    <span style={{ color: 'red', fontSize: '16px' }}>*</span>
                  </span>
                }
                //extra="Add email message"
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />

                <DynamicTable
                  setError={setTableError}
                  setDataSource={setDataSource}
                  dataSource={dataSource}
                  generateKey={generateKey}
                  formData1={formData1}
                  getFormDetailsByIdData={getFormDetailsByIdData}
                  storedData={storedData}
                  dropDown_approved_strengthsData={
                    dropDown_approved_strengthsData
                  }
                />
              </Panel>
            </CustomCollapse>
          </Row>
          <Row gutter={16} className={`${styles.row_2}`}>
            <Col span={24}>
              <FormItem
                label="Bio Guidance"
                name="BioGuidance"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter bio guidance"
                  onChange={(e) =>
                    handleFormDataOnChange('BioGuidance', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_2}`}>
            <Col span={24}>
              <FormItem
                label="BCS Class"
                name="BCSClass"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter BCS class"
                  onChange={(e) =>
                    handleFormDataOnChange('BCSClass', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_2}`}>
            <Col span={24}>
              <FormItem
                label="Dosage Regimen"
                name="DosageRegimen"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter dosage regimen"
                  onChange={(e) =>
                    handleFormDataOnChange('DosageRegimen', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_2}`}>
            <Col span={24}>
              <FormItem
                label="Remarks"
                name="Remarks"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter remarks"
                  onChange={(e) =>
                    handleFormDataOnChange('Remarks', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/home`)}
            >
              Cancel
            </CustomButton>
            <FormItem>
              <CustomButton
                loading={createUpdateRefProductDetail_formLoading}
                type="primary"
                htmlType="submit"
                className={styles.post_btn_style}
              >
                Next
              </CustomButton>
            </FormItem>
          </div>
        </Form>
      </Spin>{' '}
    </div>
  );
};

export default Reference_product_details;
