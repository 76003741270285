import CustomLayout from '@/common/Layout';
import React from 'react';
import SignatoryAPIListViewPage from './components';

const SignatoryAPIListView = () => {
  return (
    <CustomLayout sider={false}>
      <SignatoryAPIListViewPage />
    </CustomLayout>
  );
};

export default SignatoryAPIListView;
