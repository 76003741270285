import React, { useState } from 'react';
import { Row, Col, Typography, Input, Button, Form, message } from 'antd';
import styles from './index.module.less';

// import microsoft from 'features/login/images/microsoft_login_button.png';
// import { Message, Icon } from 'semantic-ui-react';
// import { useMsal, useIsAuthenticated } from '@azure/msal-react';
// import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
// import { login } from '../../redux/slice';
// import { useLocation } from 'react-router-dom';

import left_image from '../../images/granule-banner.svg';
import leftInside_image from '../../images/leftInside_image.svg';
import granules_logo from '../../images/granules-logo.png';
import granules_logo_mobile from 'assets/images/granules-mobile.png';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetLink } from '../../redux/slice';
import { useResponsiveness } from 'hooks/useResponsiveness';
// import rightIcon_image from '../../images/rightIcon_image.svg';
//import { getLoggedInUser } from 'utilities/helpers';

const ForgetPassword = () => {
  // const isAuthenticated = useIsAuthenticated();
  // const { instance } = useMsal();
  const [payload, setPayload] = useState({ email: '' });
  const { xs, sm, md } = useResponsiveness();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.login);
  const [form] = Form.useForm();
  // let location = useLocation();
  const navigate = useNavigate();

  //signatory, //approver ,creator
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSendResetLink = () => {
    dispatch(sendPasswordResetLink(payload))?.then(function (response) {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push(`/login`);
      } else {
        //message.error(response?.payload?.error);
        console.log('response?.payload?.error', response?.payload?.error);
      }
    });
  };

  return (
    <Row>
      <Col
        md={{ span: 12 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
        style={{
          position: 'relative',
          width: '100%',
          objectFit: 'cover',
          height: xs || sm ? '40vh' : '100vh'
        }}
      >
        {(xs || sm) && (
          <img
            src={granules_logo_mobile}
            style={{
              height: xs ? '2rem' : '3rem',
              width: xs ? '6rem' : '7rem',
              objectFit: 'contain',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              left: '20%',
              top: '10%'
            }}
          />
        )}
        <img
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            zIndex: -1
          }}
          src={left_image}
        />
        <img
          src={leftInside_image}
          style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: xs ? '50%' : '55%',
            height: xs ? '60%' : '70%',
            width: '100%'
          }}
          alt="Left Inside Image"
        />
        {!xs && !sm && (
          <div
            style={{
              position: 'absolute',
              margin: '1rem',
              top: 0,
              opacity: 0.8
            }}
          >
            <Typography.Title
              level={2}
              style={{ color: 'white', fontSize: md && '1.2rem' }}
            >
              Manage PIF approvals
            </Typography.Title>
            <Typography.Text
              style={{ fontSize: md ? '1rem' : '1.2rem', color: 'white' }}
            >
              Streamline PIF approvals for FDs and APIs with intuitive workflows
            </Typography.Text>
          </div>
        )}
      </Col>
      <Col
        style={{
          position: 'relative',
          width: '100%'
        }}
        md={{ span: 12 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <Form
          form={form}
          onFinish={handleSendResetLink}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: !xs && !sm && 'center',
            alignItems: !xs && !sm && 'center',
            width: '100%',
            height: xs || sm ? '60vh' : '100vh'
          }}
        >
          {!xs && !sm && (
            <img
              src={granules_logo}
              style={{ height: '4rem', width: '12rem', objectFit: 'contain' }}
            />
          )}
          <div
            style={{
              marginTop: '2rem',
              width: '100%',
              paddingInline: xs ? '1rem' : '2rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <div>
              <Typography.Title
                level={4}
                style={{ fontSize: '1.4rem', color: '#0098da', margin: 0 }}
                className="sfprotext-regular"
              >
                Forgot Password
              </Typography.Title>
              <Typography.Text style={{ fontSize: '1rem' }}>
                {
                  "Enter your email and we'll send you a link to reset your password"
                }
              </Typography.Text>
            </div>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Email is required'
                },
                {
                  pattern: emailPattern,
                  message: 'Please enter a valid email address'
                }
              ]}
              className={styles.input_field}
              style={{ padding: 0, margin: 0 }}
            >
              <div className={styles.input_field}>
                <label>Email</label>
                <Input
                  onChange={(e) =>
                    setPayload({ ...payload, email: e.target.value })
                  }
                  value={payload.email}
                  allowClear
                  placeholder="Enter your email id"
                />
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  gap: '0.5rem'
                }}
              >
                <Button
                  htmlType="submit"
                  style={{ float: 'left', width: xs && '100%' }}
                  type="primary"
                  disabled={loading}
                  className="sfprotext-regular"
                >
                  Request reset link
                </Button>

                <Button
                  icon={<LeftOutlined />}
                  onClick={() => navigate(-1)}
                  type="text"
                  style={{ padding: 0 }}
                  className="sfprotext-regular"
                >
                  Back to login
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default ForgetPassword;
