import React /* , { useEffect, useState } */ from 'react';
//import { Space, Row, Col, Image, Card } from 'antd';
import styles from './factorsModules.module.less';

import CustomLayout from '@/common/Layout';

// const FactorsData = [
//   {
//     key: 1,
//     title: 'Employee Central',
//     bg: '#667085'
//   },
//   {
//     key: 2,
//     title: 'Talent Acquisition',
//     bg: '#667085'
//   },
//   {
//     key: 3,
//     title: 'Learning',
//     bg: '#667085'
//   },
//   {
//     key: 4,
//     title: 'Compensation',
//     bg: '#667085'
//   },
//   {
//     key: 5,
//     title: 'Performance & Goals Management',
//     bg: '#667085'
//   },
//   {
//     key: 6,
//     title: 'Succession & Career Development Planning',
//     bg: '#667085'
//   },
//   {
//     key: 7,
//     title: 'Payroll',
//     bg: '#667085'
//   },
//   {
//     key: 8,
//     title: 'Employee Experience',
//     bg: '#667085'
//   }
// ];

function FactorsModules() {
  // const [selectedTab, setSelectedTab] = useState(1);
  // const [hoveredTab, setHoveredTab] = useState(null);
  // const [imageVisible, setImageVisible] = useState(false);

  // const handleImageVisible = () => {
  //   setImageVisible(!imageVisible);
  // };
  // useEffect(() => {
  //   // Trigger the animation when the selected tab changes
  //   handleImageVisible();
  // }, [selectedTab]);

  // const onClickDecider = (index) => {
  //   switch (index) {
  //     case 1:
  //     case 2:
  //     case 3:
  //     case 4:
  //     case 5:
  //     case 6:
  //     case 7:
  //     case 8:
  //       // setSelectedChild(null); // Reset selectedChild when parent is clicked
  //       setSelectedTab(index);
  //       handleImageVisible();
  //       break;

  //     // Handle other cases if needed
  //     default:
  //       break;
  //   }
  // };
  // //console.log('selectedTab', selectedTab);

  // const allContent = () => {
  //   switch (selectedTab) {
  //     case 1: // Check for selectedChild instead of selectedTab
  //       return (
  //         <>
  //           <div>
  //             1111111111111111111111111111
  //             <div>EEEEEEEEEEEE</div>
  //           </div>
  //         </>
  //       );
  //     case 2:
  //       return (
  //         <>
  //           {/*  <Fade right when={imageVisible} duration={2000}> */}
  //           <Image
  //             // src={talentAcquisition}
  //             preview={false}
  //             alt={'img'}
  //             className={styles.imageVisible}
  //           />
  //           <div>22222222222222</div>
  //           {/*  </Fade> */}
  //         </>
  //       );
  //     case 3:
  //       return (
  //         <>
  //           {/*  <Fade right when={imageVisible} duration={2000}> */}
  //           <Image /* src={learning} */ preview={false} alt={'img'} />
  //           {/*   </Fade> */}
  //           <div>3333333333333</div>
  //         </>
  //       );

  //     case 4:
  //       return (
  //         <>
  //           {/*  <Fade right when={imageVisible} duration={2000}> */}
  //           <Image /* src={compensation} */ preview={false} alt={'img'} />
  //           {/*  </Fade> */}
  //           <div>44444444444</div>
  //         </>
  //       );
  //     case 5:
  //       return (
  //         <>
  //           {/*  <Fade right when={imageVisible} duration={2000}> */}
  //           <Image /* src={goalsManagement} */ preview={false} alt={'img'} />
  //           {/*  </Fade> */}
  //           <div>555555555555555</div>
  //         </>
  //       );
  //     case 6:
  //       return (
  //         <>
  //           {/*  <Fade right when={imageVisible} duration={2000}> */}
  //           <Image
  //             //src={careerDevelopmentPlanning}
  //             preview={false}
  //             alt={'img'}
  //           />
  //           {/*   </Fade> */}
  //           <div>66666666666661</div>
  //         </>
  //       );
  //     case 7:
  //       return (
  //         <>
  //           {/*  <Fade right when={imageVisible} duration={2000}> */}
  //           <Image /* src={payroll} */ preview={false} alt={'img'} />
  //           {/*   </Fade> */}
  //           <div>888888888888888</div>
  //         </>
  //       );
  //     case 8:
  //       return (
  //         <>
  //           {/*  <Fade right when={imageVisible} duration={2000}> */}
  //           <Image /* src={employeeExperience} */ preview={false} alt={'img'} />
  //           {/*  </Fade> */}
  //           <div>gggggggggggggggggggggggg1111111111111111</div>
  //         </>
  //       );
  //     default:
  //       return window?.scrollTo(0, document.body.scrollHeight);
  //   }
  // };
  return (
    <CustomLayout sider={false}>
      <div className={styles.factors_modules_bg}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            fontSize: '20px'
          }}
        >
          Coming soon...
        </div>{' '}
        {/*  <Row className={styles.row_container}>
          <Col span={6} className={styles.sidebar_bg}>
            <Space
              direction="vertical"
              size={0}
              style={{ marginLeft: '0px' }}
              className={styles.sidebar_margin}
            >
              {FactorsData?.map((item, index) => (
                <div
                  key={index}
                  className={
                    selectedTab === index + 1
                      ? ` ${styles.selected}`
                      : `${styles.factors_menu_style}`
                  }
                  onMouseEnter={() => {
                    setHoveredTab(item.key);
                  }}
                  onMouseLeave={() => {
                    setHoveredTab(null);
                  }}
                  style={{
                    cursor: 'pointer',
                    backgroundColor:
                      selectedTab === item.key || hoveredTab === item.key
                        ? `#ffffff`
                        : '#F5F7F9',
                    transition: 'background-color 0.3s'
                  }}
                >
                  <div
                    className={`cursor-pointer ${styles.left_text}`}
                    onClick={() => onClickDecider(index + 1)}
                  >
                    {item.title}
                  </div>
                </div>
              ))}
            </Space>
          </Col>
          <Col span={18}>
            <Card className={styles.pif_fd_container_card}>
              <div className={styles.right_container}>{allContent()}</div>
            </Card>
          </Col>
        </Row> */}
        {/* <Divider />
        <p>
          <Button type="primary">Button</Button>
        </p> */}
      </div>
    </CustomLayout>
  );
}
export default FactorsModules;
