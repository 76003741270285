import { useEffect, useState /* , useMemo */ } from 'react';
import { Form, Input, DatePicker, Select, Row, Col, Spin } from 'antd';

import CustomButton from '@/common/CustomButton';
import styles from './pif_fd_info.module.less';
//import { useDispatch } from 'react-redux';
import FormItem from 'common/FormItem';

//import { debounce } from 'lodash';

import moment from 'moment';
import { history } from 'app/history';

const { Option } = Select;

const PIF_FD_Information = ({
  draft_PIF_form_start,
  fd_info_status,
  PIF_form_id,
  dropDown_GIL_GPIData,
  dropDown_primary_marketData,
  dropDown_extension_marketData,
  // createUpdateFdInfo_form,
  createUpdateFdInfo_formLoading,
  // completedTabs,
  // setCompletedTabs,
  // isTabEnabled,
  getFormDetailsByIdData,
  getFormDetailsByIdLoading,
  debounceGIL_GPI,
  debouncePrimary_market,
  debounceExtension_market,
  onFinish_FD_Form,
  dropDown_key_row_marketsData,
  debounceKey_row_markets,
  dropDown_pif_typesData,
  debouncePif_types
  //setPageNum
}) => {
  //const dispatch = useDispatch();
  const [form] = Form.useForm();

  // const PIF_form_id = localStorage.getItem('PIF_form_id');

  const [formData1, setFormData1] = useState({});
  const [external_market, setExternal_market] = useState([]);
  const [key_row_markets, setKey_row_markets] = useState([]);
  const [external_market_edit, setExternal_market_edit] = useState([]);
  const [key_row_markets_edit, setKey_row_markets_edit] = useState([]);
  const [isOthers_em, setIsOthers_em] = useState(false);
  const [isOthers_krm, setIsOthers_krm] = useState(false);
  const [isOthers_typeOfPif, setIsOthers_typeOfPif] = useState(false);

  useEffect(() => {
    const extension_market =
      getFormDetailsByIdData?.fd_info?.extension_market?.map(
        (item) => item.em_master_id
      );
    const key_row_markets =
      getFormDetailsByIdData?.fd_info?.key_row_markets?.map(
        (item) => item.uao_id
      );

    form.setFieldsValue({
      initiation_date: moment().format('YYYY MM DD'),
      gil_gpi: getFormDetailsByIdData?.fd_info?.GIL_GPI?.id,
      molecule_salt: getFormDetailsByIdData?.fd_info?.molecule_salt,
      pif_type_id: getFormDetailsByIdData?.fd_info?.pif_type?.uao_id,
      primary_market: getFormDetailsByIdData?.fd_info?.primary_market?.id,
      external_market: extension_market,
      key_row_markets: key_row_markets
    });

    if (PIF_form_id !== null) {
      setExternal_market_edit(
        getFormDetailsByIdData?.fd_info?.extension_market?.map((item) => ({
          em_master_id: item?.em_master_id
        }))
      );

      setKey_row_markets_edit(
        getFormDetailsByIdData?.fd_info?.key_row_markets?.map((item) => ({
          units_and_others_id: item?.uao_id
        }))
      );
    }
  }, [getFormDetailsByIdData]);
  useEffect(() => {
    form.setFieldsValue({
      initiation_date: moment() || ''
    });
  });

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    if (slug === 'pif_type_id') {
      const pif_type_others = dropDown_pif_typesData?.find(
        (item) => item.id === value
      );
      setIsOthers_typeOfPif(
        pif_type_others?.name === 'Others' ? 'Others' : false
      );
    }

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const handleExternal_market = (value) => {
    const external_market_names = value?.map((item) => {
      const foundItem = dropDown_extension_marketData.find((data) => {
        return data.id === item;
      });
      return { name: foundItem?.name || 'Name not found', id: item };
    });

    const external_market_selected = external_market_names?.filter(
      (item) => item.name !== 'Others'
    );

    const isValueOthers = external_market_names?.some(
      (item) => item.name === 'Others'
    );
    setIsOthers_em(isValueOthers);

    const external_market_id = external_market_selected?.map((item) => ({
      em_master_id: item?.id
    }));
    setExternal_market_edit([]);
    setExternal_market(external_market_id);
  };
  //console.log('external_market>>>>', external_market);

  const handle_Key_row_markets = (value) => {
    const key_row_markets_names = value?.map((item) => {
      const foundItem = dropDown_key_row_marketsData?.find((data) => {
        return data.id === item;
      });
      return { name: foundItem?.name || 'Name not found', id: item };
    });

    const key_row_markets_selected = key_row_markets_names?.filter(
      (item) => item.name !== 'Others'
    );

    const isValueOthers = key_row_markets_names?.some(
      (item) => item.name === 'Others'
    );

    setIsOthers_krm(isValueOthers);

    const Key_row_markets_id = key_row_markets_selected?.map((item) => ({
      units_and_others_id: item?.id
    }));
    setKey_row_markets_edit([]);
    setKey_row_markets(Key_row_markets_id);
  };

  //console.log('key_row_markets>>>', key_row_markets);

  const onFinish = (values) => {
    console.log('values:', values);
    const payload = {
      pif_id:
        fd_info_status === 'true' || draft_PIF_form_start === 'true'
          ? PIF_form_id
          : null,
      edit_type: 'draft',
      initiation_date: moment().format('YYYY MM DD'),
      GIL_GPI_id:
        formData1?.gil_gpi === undefined ? values?.gil_gpi : formData1?.gil_gpi,
      molecule_salt:
        formData1?.molecule_salt === undefined
          ? values?.molecule_salt
          : formData1?.molecule_salt,
      pif_type_id:
        formData1?.pif_type_id === undefined
          ? values?.pif_type_id
          : isOthers_typeOfPif === 'Others'
          ? ''
          : formData1?.pif_type_id,

      pif_type_others:
        isOthers_typeOfPif === 'Others' ? [formData1?.type_of_PIF_others] : [],

      primary_market_id:
        formData1?.primary_market === undefined
          ? values?.primary_market
          : formData1?.primary_market,

      extension_market_id:
        external_market?.length === 0 ? external_market_edit : external_market,

      extension_market_others: isOthers_em
        ? [formData1?.extension_market_others]
        : [],

      key_row_markets:
        key_row_markets?.length === 0 ? key_row_markets_edit : key_row_markets,
      key_row_market_others: isOthers_krm
        ? [formData1?.key_row_market_others]
        : []
      // formData1?.key_row_markets === undefined
      //   ? values?.key_row_markets
      //   : formData1?.key_row_markets
    };
    onFinish_FD_Form(payload);
    // dispatch(createUpdateFdInfo_form({ payload }))?.then(function (response) {
    //   if (response?.payload?.success) {
    //     //history.push(`/admin-quizzes`);
    //     setSelectedTab(2);

    //     if (!completedTabs.includes(2)) {
    //       setCompletedTabs([...completedTabs, 2]);
    //     }
    //     isTabEnabled(2);
    //   } else {
    //     console.log('response?.payload?.error', response?.payload?.error);
    //   }
    // });
  };
  //const defaultDate = moment().format('MMM DD, YYYY');
  return (
    <div className={`${styles.page_container}`}>
      <Spin spinning={getFormDetailsByIdLoading}>
        <div className={`${styles.page_title}`}>
          <span className={styles.title_label}>FD - PIF Details</span>
        </div>

        <Form
          form={form}
          name="PIF_RD_Information"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={8}>
              <FormItem
                label="Date of Initiation"
                name="initiation_date"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.date_styles}`}
                //className={`${styles.input_styles}`}
              >
                <DatePicker
                  disabled
                  format={'DD MMM YYYY'}
                  onChange={(_, dateValue) =>
                    handleFormDataOnChange('initiation_date', dateValue)
                  }
                  disabledDate={(current) => {
                    return (
                      moment().add(-1, 'days') >= current ||
                      moment().add(0, 'month') <= current
                    );
                  }}
                  style={{ width: '100%' }}
                ></DatePicker>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="GIL/GPI"
                name="gil_gpi"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  showSearch
                  onSearch={debounceGIL_GPI}
                  placeholder="Select GIL/GPI"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) => handleFormDataOnChange('gil_gpi', value)}
                >
                  {dropDown_GIL_GPIData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Product Name"
                name="molecule_salt"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter product name"
                  onChange={(e) =>
                    handleFormDataOnChange('molecule_salt', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={8}>
              <FormItem
                label="Type of PIF"
                name="pif_type_id"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debouncePif_types}
                  placeholder="Select type of PIF"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('pif_type_id', value)
                  }
                >
                  {dropDown_pif_typesData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_typeOfPif === 'Others' && (
              <Col span={8}>
                <FormItem
                  label="Type of PIF - Others"
                  name="type_of_PIF_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter type of PIF"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'type_of_PIF_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
            <Col span={8}>
              <FormItem
                label="Primary Market"
                name="primary_market"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debouncePrimary_market}
                  placeholder="Select primary market"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('primary_market', value)
                  }
                >
                  {dropDown_primary_marketData.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                label="Extension Market"
                name="external_market"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  mode="multiple"
                  showSearch
                  onSearch={debounceExtension_market}
                  placeholder="Select extension market"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={handleExternal_market}
                  // onChange={(value) =>
                  //   handleFormDataOnChange('external_market', value)
                  // }
                >
                  {dropDown_extension_marketData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_em && (
              <Col span={8}>
                <FormItem
                  label="Extension Market - Others"
                  name="extension_market_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter extension market"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'extension_market_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={8}>
              <FormItem
                label="Market of Interest"
                name="key_row_markets"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  mode="multiple"
                  showSearch
                  onSearch={debounceKey_row_markets}
                  placeholder="Select market of interest"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={handle_Key_row_markets}
                  // onChange={(value) =>
                  //   handleFormDataOnChange('external_market', value)
                  // }
                >
                  {dropDown_key_row_marketsData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_krm && (
              <Col span={8}>
                <FormItem
                  label="Market of Interest - Others"
                  name="key_row_market_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter market of interest"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'key_row_market_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              marginTop: '11%'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/home`)}
            >
              Cancel
            </CustomButton>
            <FormItem>
              <CustomButton
                loading={createUpdateFdInfo_formLoading}
                type="primary"
                htmlType="submit"
                className={styles.post_btn_style}
              >
                Next
              </CustomButton>
            </FormItem>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default PIF_FD_Information;
