import React from 'react';
import HomePage from './components/HomePage';
import CustomLayout from '@/common/Layout';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardCounts, getDashboardApiCounts } from './redux/slice';

const Home = () => {
  const dispatch = useDispatch();

  const {
    getDashboardCountsLoading,
    dashboardCountsData,
    dashboardApiCountsData
  } = useSelector((state) => state.home);

  useEffect(() => {
    let payload = {};
    dispatch(getDashboardCounts(payload));
  }, []);

  useEffect(() => {
    let payload = {};
    dispatch(getDashboardApiCounts(payload));
  }, []);

  return (
    <CustomLayout sider={false}>
      <HomePage
        getDashboardCountsLoading={getDashboardCountsLoading}
        dashboardCountsData={dashboardCountsData}
        dashboardApiCountsData={dashboardApiCountsData}
      />
    </CustomLayout>
  );
};

export default Home;
