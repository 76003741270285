import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Col, Row, Card, Layout, Spin, Tooltip } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import CustomButton from '@/common/CustomButton';
import PDFViewer from './Pdf_view';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFormDetailsById_form } from 'features/PIF_FD_form/redux/slice';
import { signOrApprove_form } from 'features/signatoryFlow/redux/slice';
import SignModal from '../signModal';
import checked from 'assets/images/icons/checked.svg';
import waitting from 'assets/images/icons/waitting.svg';
import rejected from 'assets/images/icons/rejected.svg';
import RejectModal from '../rejectModal';
import { getLoggedInUser } from 'utilities/helpers';
import LocalStorage from 'utilities/localStorage';
import { sizeChecker } from 'utilities/helpers';
import moment from 'moment';
// import PdfViewUserManual from './pdfViewUserManual';
// import { Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const PreviewApprover = () => {
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // // Update windowWidth when the window is resized
  // useEffect(() => {
  //   const handleWindowResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   // Add event listener for window resize
  //   window.addEventListener('resize', handleWindowResize);

  //   // Remove event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);

  //---------------------
  const dispatch = useDispatch();
  const params = useParams();

  const isMobile = sizeChecker('xs');

  const [signatories, setSignatories] = useState([]);
  const [approvers, setApprovers] = useState([]);

  const [signModal, setSignModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const { getFormDetailsByIdData, getFormDetailsByIdLoading } = useSelector(
    (state) => state.pif_fd_form
  );
  const { signOrApprove_formLoading } = useSelector((state) => state.signatory);

  //----------
  const user = getLoggedInUser();
  let loggedIn_User = user?.user_details;

  const userDetails = LocalStorage.getItem('granules_PIF');
  console.log('userDetails%%%%%', userDetails?.user_details);

  const urlParams = new URLSearchParams(window.location.search);
  const hash = urlParams.get('hash');

  console.log('paramsID', params?.id);
  console.log('hash', hash);

  useEffect(() => {
    if (userDetails?.user_details === undefined || loggedIn_User === null) {
      const payload = {
        pif_id: params?.id,
        hash: hash
      };

      dispatch(getFormDetailsById_form(payload));
    } else {
      let payload = {
        pif_id: params?.id,
        hash: ''
      };
      dispatch(getFormDetailsById_form(payload));
    }
  }, [params]);
  //----------

  // useEffect(() => {
  //   let payload = {
  //     pif_id: params?.id
  //   };
  //   params?.id === null ? '' : dispatch(getFormDetailsById_form(payload));
  // }, [params]);

  //console.log('getFormDetailsByIdData:', getFormDetailsByIdData);

  useEffect(() => {
    const signatoriesData = getFormDetailsByIdData?.all_approvers?.filter(
      (item) => item.approver_type === 'signatory'
    );

    setSignatories(signatoriesData);
  }, [getFormDetailsByIdData]);

  useEffect(() => {
    const approversData = getFormDetailsByIdData?.all_approvers?.filter(
      (item) => item.approver_type === 'approver'
    );
    setApprovers(approversData);
  }, [getFormDetailsByIdData]);

  const Header = ({ getFormDetailsByIdLoading }) => {
    return (
      <>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Spin spinning={getFormDetailsByIdLoading}>
            <Row>
              <Col span={isMobile ? 8 : 15}>
                <div className={`ml-3 sfprotext-bold font-18 mb-3 `}>
                  {' '}
                  <CustomButton
                    onClick={() => history.push('/approver-list-view')}
                    // className={`mr-2`}
                    className={styles.back_btn_style}
                  >
                    {' '}
                    Go Back
                  </CustomButton>
                </div>
              </Col>
              <Col
                span={isMobile ? 16 : 9}
                style={{ display: 'flex', justifyContent: 'end' }}
                className={styles.approve_btn_cont_style}
              >
                <Row
                  gutter={isMobile ? 8 : 16}
                  className={
                    isMobile
                      ? styles.row_btn_style_isMobile
                      : styles.row_btn_style
                  }
                >
                  <Col className={`text-right`}>
                    <CustomButton
                      className={styles.reject_btn_style}
                      onClick={() => setRejectModal(true)}
                      disabled={
                        getFormDetailsByIdData?.approvers?.length > 0 &&
                        getFormDetailsByIdData?.approvers[0]
                          ?.sign_or_apprv_btn === false
                      }
                    >
                      {getFormDetailsByIdData?.approvers?.length > 0 &&
                      getFormDetailsByIdData?.approvers[0]?.reject_button ===
                        false &&
                      getFormDetailsByIdData?.approvers[0]?.status ===
                        'rejected'
                        ? 'Rejected'
                        : 'Reject'}
                    </CustomButton>
                  </Col>
                  <Col className={`text-right`}>
                    <CustomButton
                      className={styles.post_btn_style}
                      onClick={() => setSignModal(true)}
                      disabled={
                        getFormDetailsByIdData?.approvers?.length > 0 &&
                        getFormDetailsByIdData?.approvers[0]
                          ?.sign_or_apprv_btn === false
                      }
                    >
                      {getFormDetailsByIdData?.approvers?.length > 0 &&
                      getFormDetailsByIdData?.approvers[0]
                        ?.sign_or_apprv_btn === false &&
                      getFormDetailsByIdData?.approvers[0]?.status ===
                        'approved'
                        ? 'Approved'
                        : 'Approve'}
                    </CustomButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </div>
      </>
    );
  };
  return (
    <>
      <Layout className={`${styles.layout}`}>
        <Header getFormDetailsByIdLoading={getFormDetailsByIdLoading} />

        <div
          className={
            isMobile ? `${styles.container_isMobile}` : `${styles.container}`
          }
        >
          <Row>
            <Col className={` mr-4 text-left `} span={isMobile ? 24 : 17}>
              <Card
                hoverable
                className={` text-center ${styles.image_viewer_card}`}
                title={
                  <div className={`${styles.pdf_title_color_margin}`}>
                    Preview: Project Initiation Form
                  </div>
                }
                bordered={false}
              >
                <PDFViewer getFormDetailsByIdData={getFormDetailsByIdData} />

                {/*  <div
                  style={{
                    border: '1px solid #eaeaea',
                    height: '750px',
                    width: 'auto'
                  }}
                >
                  <Viewer
                    defaultScale={windowWidth < 768 ? 0.4 : 1.2}
                    plugins={[defaultLayoutPluginInstance]}
                    //fileUrl={`${getFormDetailsByIdData?.pdf_pif?.fileUrl}`}
                    fileUrl={
                      'https://tataproject.blob.core.windows.net/documents/newblob1717154619477.pdf'
                    }
                  />
                </div> */}
              </Card>
              <Card
                hoverable
                className={`mt-3 text-center ${styles.image_viewer_card}`}
                bordered={false}
              >
                <div className={`${styles.att_bg}`}>
                  <div
                    className={`ml-3 text-left ${styles.pdf_title_color_margin}`}
                  >
                    Message:{' '}
                    <spam className={`ml-2 text-left ${styles.message_text}`}>
                      {getFormDetailsByIdData?.message === null ||
                      getFormDetailsByIdData?.message === ''
                        ? 'NA'
                        : getFormDetailsByIdData?.message}
                    </spam>
                  </div>
                </div>
              </Card>
              <Card
                hoverable
                className={`mt-3 text-center ${styles.image_viewer_card}`}
                bordered={false}
              >
                <div className={`${styles.att_bg}`}>
                  <div
                    className={`ml-3 text-left ${styles.pdf_title_color_margin}`}
                  >
                    Attachments:
                  </div>
                  {getFormDetailsByIdData?.attachments?.length === 0 ? (
                    <div className="ml-5 text-center">
                      {' '}
                      No attachments to display
                    </div>
                  ) : (
                    <ul className="ml-5 text-left mt-2">
                      {getFormDetailsByIdData?.attachments?.map(
                        (item, index) => (
                          <li key={index}>
                            <span>
                              <a
                                className={`${styles.uploadedDoc_style}`}
                                href={item?.fileUrl}
                                download
                              >
                                {item?.originalName}
                              </a>
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </div>
              </Card>
            </Col>

            <Col
              className={isMobile ? `text-center` : `ml-2 text-center`}
              span={isMobile ? 24 : 6}
            >
              {isMobile ? (
                <Card
                  hoverable
                  className={`mt-3 text-center ${styles.image_viewer_card}`}
                  bordered={false}
                >
                  <div className={`${styles.att_bg}`}>
                    <div className={`${styles.pdf_title_color_margin}`}>
                      Manage PIF approvals
                    </div>
                    <div className={`${styles.container1_isMobile}`}>
                      <div
                        className={` ${styles.approver_container_margin_isMobile}`}
                      >
                        <div className={`${styles.pdf_title_color}`}>
                          Signatories
                        </div>

                        <div className={`${styles.container1}`}>
                          {signatories?.map((item, index) => (
                            <div className={`${styles.step} `} key={index}>
                              <div className={`${styles.v_stepper}`}>
                                {item?.status === 'signed' ? (
                                  <img src={checked} alt="checked" />
                                ) : item?.status === 'rejected' ? (
                                  <img src={rejected} alt="rejected" />
                                ) : item?.status === 'approved' ? (
                                  <img src={checked} alt="checked" />
                                ) : (
                                  <img src={waitting} alt=" waitting" />
                                )}
                                <div className={`${styles.line}`}></div>
                              </div>

                              <div className={`${styles.content}`}>
                                <b className="font-13">
                                  {item?.status === 'signed' ? (
                                    <div>
                                      <span style={{ color: '#52C41A' }}>
                                        Signed
                                      </span>
                                    </div>
                                  ) : item?.status === 'rejected' ? (
                                    <span style={{ color: '#FF4D4F' }}>
                                      Rejected
                                    </span>
                                  ) : item?.status === 'approved' ? (
                                    <div>
                                      <span style={{ color: '#52C41A' }}>
                                        Signed
                                      </span>
                                    </div>
                                  ) : (
                                    <span style={{ color: '#666687' }}>
                                      Yet to Sign
                                    </span>
                                  )}
                                </b>
                                <div className={`${styles.small_text}`}>
                                  {/*  {item?.status ? (
                                  <> */}
                                  <div>
                                    {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                    <span>
                                      {item?.action_taken_on === null
                                        ? ''
                                        : ` on ${moment(
                                            item?.action_taken_on
                                          ).format('DD MMM YY')}`}
                                    </span>
                                  </div>
                                  {/*   </>
                                ) : (
                                  ''
                                )} */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={` ${styles.approver_container_margin}`}>
                        {' '}
                        <div className={`${styles.pdf_title_color}`}>
                          Approvers
                        </div>
                        {/* {approvers?.map((item, index) => (
                        <div
                          key={index}
                          className={`${styles.step_container} `}
                        >
                          <div className={`${styles.step} `}>
                            <div className={`${styles.v_stepper}`}>
                              <div className={`${styles.circle}`}>
                                <p>{index + 1}</p>
                              </div>
                            </div>
                            <div
                              className={` font-14 ${styles.signatories_name}`}
                            >
                              {`${item?.user?.first_name} ${item?.user?.last_name}`}
                            </div>
                          </div>
                        </div>
                      ))} */}
                        <div className={`${styles.container1}`}>
                          {approvers?.map((item, index) => (
                            <div className={`${styles.step} `} key={index}>
                              <div className={`${styles.v_stepper}`}>
                                {item?.status === 'signed' ? (
                                  <img src={checked} alt="checked" />
                                ) : item?.status === 'rejected' ? (
                                  <img src={rejected} alt="rejected" />
                                ) : item?.status === 'approved' ? (
                                  <img src={checked} alt="checked" />
                                ) : (
                                  <img src={waitting} alt=" waitting" />
                                )}
                                <div className={`${styles.line}`}></div>
                              </div>

                              <div className={`${styles.content}`}>
                                <b className="font-13">
                                  {item?.status === 'signed' ? (
                                    <div>
                                      <span style={{ color: '#52C41A' }}>
                                        Approved
                                      </span>
                                    </div>
                                  ) : item?.status === 'rejected' ? (
                                    <span style={{ color: '#FF4D4F' }}>
                                      Rejected{' '}
                                      <Tooltip title={item?.remarks}>
                                        <MessageOutlined
                                          style={{
                                            color: '#8C8C8C',
                                            marginLeft: '5px'
                                          }}
                                        />
                                      </Tooltip>
                                    </span>
                                  ) : item?.status === 'approved' ? (
                                    <div>
                                      <span style={{ color: '#52C41A' }}>
                                        Approved
                                      </span>
                                    </div>
                                  ) : (
                                    <span style={{ color: '#666687' }}>
                                      Yet to Sign
                                    </span>
                                  )}
                                </b>
                                <div className={`${styles.small_text}`}>
                                  <div>
                                    {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                    <span>
                                      {item?.action_taken_on === null
                                        ? ''
                                        : ` on ${moment(
                                            item?.action_taken_on
                                          ).format('DD MMM YY')}`}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              ) : (
                <Card hoverable className={` text-left ${styles.env_flow}`}>
                  <div className={`${styles.pdf_title_color_margin}`}>
                    Approval Flow
                  </div>
                  <div className={`${styles.container1}`}>
                    <div className={` ${styles.approver_container_margin}`}>
                      <div className={`${styles.pdf_title_color}`}>
                        Signatories
                      </div>
                      {/* {signatories?.map((item, index) => (
                        <div
                          key={index}
                          className={`${styles.step_container} `}
                        >
                          <div className={`${styles.step} `}>
                            <div className={`${styles.v_stepper}`}>
                              <div className={`${styles.circle}`}>
                                <p>{index + 1}</p>
                              </div>
                            </div>
                            <div
                              className={` font-14 ${styles.signatories_name}`}
                            >
                              {`${item?.user?.first_name} ${item?.user?.last_name}`}
                            </div>
                          </div>
                        </div>
                      ))} */}
                      <div className={`${styles.container1}`}>
                        {signatories?.map((item, index) => (
                          <div className={`${styles.step} `} key={index}>
                            <div className={`${styles.v_stepper}`}>
                              {item?.status === 'signed' ? (
                                <img src={checked} alt="checked" />
                              ) : item?.status === 'rejected' ? (
                                <img src={rejected} alt="rejected" />
                              ) : item?.status === 'approved' ? (
                                <img src={checked} alt="checked" />
                              ) : (
                                <img src={waitting} alt=" waitting" />
                              )}
                              <div className={`${styles.line}`}></div>
                            </div>

                            <div className={`${styles.content}`}>
                              <b className="font-13">
                                {item?.status === 'signed' ? (
                                  <div>
                                    <span style={{ color: '#52C41A' }}>
                                      Signed
                                    </span>
                                  </div>
                                ) : item?.status === 'rejected' ? (
                                  <span style={{ color: '#FF4D4F' }}>
                                    Rejected
                                  </span>
                                ) : item?.status === 'approved' ? (
                                  <div>
                                    <span style={{ color: '#52C41A' }}>
                                      Signed
                                    </span>
                                  </div>
                                ) : (
                                  <span style={{ color: '#666687' }}>
                                    Yet to Sign
                                  </span>
                                )}
                              </b>
                              <div className={`${styles.small_text}`}>
                                <div>
                                  {`${item?.user?.first_name} ${item?.user?.last_name}`}{' '}
                                  <span>
                                    {item?.action_taken_on === null
                                      ? ''
                                      : ` on ${moment(
                                          item?.action_taken_on
                                        ).format('DD MMM YY')}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={` ${styles.approver_container_margin}`}>
                      {' '}
                      <div className={`${styles.pdf_title_color}`}>
                        Approvers
                      </div>
                      {/* {approvers?.map((item, index) => (
                        <div
                          key={index}
                          className={`${styles.step_container} `}
                        >
                          <div className={`${styles.step} `}>
                            <div className={`${styles.v_stepper}`}>
                              <div className={`${styles.circle}`}>
                                <p>{index + 1}</p>
                              </div>
                            </div>
                            <div
                              className={` font-14 ${styles.signatories_name}`}
                            >
                              {`${item?.user?.first_name} ${item?.user?.last_name}`}
                            </div>
                          </div>
                        </div>
                      ))} */}
                      <div className={`${styles.container1}`}>
                        {approvers?.map((item, index) => (
                          <div className={`${styles.step} `} key={index}>
                            <div className={`${styles.v_stepper}`}>
                              {item?.status === 'signed' ? (
                                <img src={checked} alt="checked" />
                              ) : item?.status === 'rejected' ? (
                                <img src={rejected} alt="rejected" />
                              ) : item?.status === 'approved' ? (
                                <img src={checked} alt="checked" />
                              ) : (
                                <img src={waitting} alt=" waitting" />
                              )}
                              <div className={`${styles.line}`}></div>
                            </div>

                            <div className={`${styles.content}`}>
                              <b className="font-13">
                                {item?.status === 'signed' ? (
                                  <div>
                                    <span style={{ color: '#52C41A' }}>
                                      Approved
                                    </span>
                                  </div>
                                ) : item?.status === 'rejected' ? (
                                  <span style={{ color: '#FF4D4F' }}>
                                    Rejected{' '}
                                    <Tooltip title={item?.remarks}>
                                      <MessageOutlined
                                        style={{
                                          color: '#8C8C8C',
                                          marginLeft: '5px'
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                ) : item?.status === 'approved' ? (
                                  <div>
                                    <span style={{ color: '#52C41A' }}>
                                      Approved
                                    </span>
                                  </div>
                                ) : (
                                  <span style={{ color: '#666687' }}>
                                    Yet to Sign
                                  </span>
                                )}
                              </b>
                              <div className={`${styles.small_text}`}>
                                <div>
                                  {`${item?.user?.first_name} ${item?.user?.last_name}`}{' '}
                                  <span>
                                    {item?.action_taken_on === null
                                      ? ''
                                      : ` on ${moment(
                                          item?.action_taken_on
                                        ).format('DD MMM YY')}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Card>
              )}
            </Col>
          </Row>
        </div>

        {signModal ? (
          <SignModal
            signModal={signModal}
            setSignModal={setSignModal}
            signOrApprove_form={signOrApprove_form}
            signOrApprove_formLoading={signOrApprove_formLoading}
            userDetails={userDetails?.user_details}
            loggedIn_User={loggedIn_User}
            hash={hash}
            PIF_id={params?.id}
          />
        ) : (
          ''
        )}
        {rejectModal ? (
          <RejectModal
            rejectModal={rejectModal}
            setRejectModal={setRejectModal}
            userDetails={userDetails?.user_details}
            loggedIn_User={loggedIn_User}
            hash={hash}
            PIF_id={params?.id}
          />
        ) : (
          ''
        )}
      </Layout>
    </>
  );
};
export default PreviewApprover;
