import React from 'react';
import { Form, Input, Table, Space, Select } from 'antd';
import styles from './Ip_exclusivities_table.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';

const { Option } = Select;

const Ip_exclusivities_table = ({
  dataSource1,
  setDataSource1,
  generateKey1,
  //storedData1,
  dropDown_exclusivity_typeData,
  debounceExclusivity_type
  //edit
}) => {
  console.log('dataSource1_Ip_ex::: ', dataSource1);
  console.log(
    'dropDown_exclusivity_typeData::: ',
    dropDown_exclusivity_typeData
  );

  // Function to add a new row
  const addRow = () => {
    const newData = {
      key: generateKey1(),
      exclusivity_type_id: '',
      us: '',
      eu: '',
      row: ''
      // is_active: true
    };
    setDataSource1([...dataSource1, newData]);
  };

  // Function to delete a row by key
  // const deleteRow = (key) => {
  //   // // setDataSource1(dataSource1.filter((item) => item.key !== key));

  //   console.log('deleteKey: ', key);

  //   // Create a copy of the original data source
  //   const updatedDataSource1 = [...dataSource1];

  //   // Find the index of the object with the specified key
  //   const index = updatedDataSource1.findIndex((item) => item.key === key);

  //   // If the object with the specified key is found, update its is_active property
  //   if (index !== -1) {
  //     updatedDataSource1[index].is_active = false;

  //     // Update the state with the modified data source
  //     setDataSource1(updatedDataSource1);
  //   } else {
  //     console.log('Object with key', key, 'not found in dataSource1.');
  //   }
  // };
  const deleteRow = (key) => {
    // if (storedData1 === 'true') {
    //   const updatedDataSource = dataSource1?.map((item) =>
    //     item.key === key ? { ...item, is_active: false } : item
    //   );
    //   setDataSource1(updatedDataSource);
    // } else {
    const updatedData = dataSource1?.filter((item) => item.key !== key);
    setDataSource1(updatedData);
    // }
  };

  const columns = [
    {
      title: 'Exclusivity Type',
      dataIndex: 'exclusivity_type_id',
      render: (_, record) => (
        <FormItem
          // label="Approved Strengths"
          // name="ApprovedStrengths"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          className={`${styles.select_styles}`}
        >
          <Select
            //disabled={edit === 'optional'}
            showSearch
            onSearch={debounceExclusivity_type}
            placeholder="Select Exclusivity Type"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            defaultValue={record?.exclusivity_type_id}
            onChange={(value) =>
              handleCellChange(record.key, 'exclusivity_type_id', value)
            }
            className={styles.select_styles} // Add this class for custom styling
            popupClassName={styles.select_dropdown} // Add this class for custom dropdown styling
          >
            {dropDown_exclusivity_typeData?.map((item, index) => {
              return (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      dataIndex: 'us',
      title: 'US',
      render: (_, record) => (
        <FormItem
          // name={['us', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            //disabled={edit === 'optional'}
            value={record.us}
            onChange={(e) => handleCellChange(record.key, 'us', e.target.value)}
          />
        </FormItem>
      )
    },
    {
      title: 'EU',
      dataIndex: 'eu',

      render: (_, record) => (
        <FormItem
          // name={['eu', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            //disabled={edit === 'optional'}
            value={record.eu}
            onChange={(e) => handleCellChange(record.key, 'eu', e.target.value)}
          />
        </FormItem>
      )
    },
    {
      dataIndex: 'row',
      title: 'RoW',
      render: (_, record) => (
        <FormItem
          //  name={['row', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            //disabled={edit === 'optional'}
            value={record.row}
            onChange={(e) =>
              handleCellChange(record.key, 'row', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Space
            eu="middle"
            style={{
              display: 'flex',
              justifyContent: 'center' /* paddingLeft: '16px' */
            }}
          >
            <PlusOutlined
              className={`${styles.plusIcon}`}
              onClick={/* edit === 'optional' ? '' : */ addRow}
            />

            {dataSource1.length > 1 ? (
              <DeleteOutlined
                className={`${styles.deleteIcon}`}
                onClick={() =>
                  /* edit === 'optional' ? '' : */ deleteRow(record.key)
                }
              />
            ) : null}
          </Space>
        </FormItem>
      )
    }
  ];

  // Function to handle changes in cell values
  const handleCellChange = (key, dataIndex, value) => {
    const newData = [...dataSource1];
    console.log('newData', newData);
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData[index] = { ...item, [dataIndex]: value };
      setDataSource1(newData);
    }
  };

  //const filteredDataSource1 = dataSource1?.filter((item) => item.is_active);

  return (
    <div>
      <Form>
        <Table
          dataSource={dataSource1}
          // {
          //   storedData1 === 'true' ? filteredDataSource1 : dataSource1
          // }
          columns={columns}
          pagination={false}
          scroll={{
            x: 1100
          }}
          className={`${styles.table_styles}`}
        />
      </Form>
    </div>
  );
};

export default Ip_exclusivities_table;
