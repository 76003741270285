import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  dashboardCounts,
  pifFdList,
  signOrApprove,
  dashboardApiCounts,
  signOrApproveAPI,
  deleteDraft,
  pifApiNumbers,
  signPif,
  pifFdNumbers,
  signPifFd
} from './api';
//import { history } from '@/app/history';

const initialState = {
  getDashboardCountsLoading: false,
  dashboardCountsData: [],
  getPifFdListLoading: false,
  pifFdListData: [],
  pifApiNumbers: [],
  pifFdNumbers: [],
  pifApiNumbersLoading: false,
  pifFdNumbersLoading: false,
  signLoading: false,
  pagination: {},
  signOrApprove_formLoading: false,
  getDashboardApiCountsLoading: false,
  dashboardApiCountsData: [],
  removeDraftLoading: false
};

const actions = {
  GET_DASHBOARD_COUNT: 'getDashboardCounts/GET_DASHBOARD_COUNT',
  GET_PIF_FD_LIST: 'getPifFdList/GET_PIF_FD_LIST',
  DELETE_DRAFT: 'getPifFdList/DELETE_DRAFT',
  PIF_API_NUMBERS: 'getPifFdList/PIF_API_NUMBERS',
  PIF_FD_NUMBERS: 'getPifFdList/PIF_FD_NUMBERS',
  SIGN: 'getPifFdList/SIGN',
  SIGN_FD: 'getPifFdList/SIGN_FD',
  SIGN_OR_APPROVE: 'signOrApprove_form/SIGN_OR_APPROVE',
  SIGN_OR_APPROVE_API: 'signOrApprove_api/SIGN_OR_APPROVE_API',
  GET_DASHBOARD_API_COUNT: 'getDashboardApiCounts/GET_DASHBOARD_API_COUNT'
};

export const getDashboardApiCounts = createAsyncThunk(
  actions.GET_DASHBOARD_API_COUNT,
  async (payload) => {
    const response = await dashboardApiCounts(payload);
    return response;
  }
);

export const getPifFdList = createAsyncThunk(
  actions.GET_PIF_FD_LIST,
  async (payload) => {
    const response = await pifFdList(payload);
    return response;
  }
);

export const removeDraft = createAsyncThunk(
  actions.DELETE_DRAFT,
  async (payload) => {
    const response = await deleteDraft(payload);
    return response;
  }
);

export const getPifApiNumbers = createAsyncThunk(
  actions.PIF_API_NUMBERS,
  async (payload) => {
    const response = await pifApiNumbers(payload);
    return response;
  }
);

export const getPifFdNumbers = createAsyncThunk(
  actions.PIF_FD_NUMBERS,
  async (payload) => {
    const response = await pifFdNumbers(payload);
    return response;
  }
);

export const sign = createAsyncThunk(actions.SIGN, async (payload) => {
  const response = await signPif(payload);
  return response;
});

export const signFd = createAsyncThunk(actions.SIGN_FD, async (payload) => {
  const response = await signPifFd(payload);
  return response;
});

export const getDashboardCounts = createAsyncThunk(
  actions.GET_DASHBOARD_COUNT,
  async (payload) => {
    const response = await dashboardCounts(payload);
    return response;
  }
);
export const signOrApprove_form = createAsyncThunk(
  actions.SIGN_OR_APPROVE,
  async (payload) => {
    const response = await signOrApprove(
      `?pif_id=${payload?.pif_id}&hash=${payload?.hash}`,
      payload
    );
    return response;
  }
);

export const signOrApproveAPI_form = createAsyncThunk(
  actions.SIGN_OR_APPROVE_API,
  async (payload) => {
    const response = await signOrApproveAPI(
      `?pif_id=${payload?.pif_id}&hash=${payload?.hash}`,
      payload
    );
    return response;
  }
);
export const signatorySlice = createSlice({
  name: 'signatory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardCounts.pending, (state) => {
        state.getDashboardCountsLoading = true;
      })
      .addCase(getDashboardCounts.fulfilled, (state, action) => {
        state.getDashboardCountsLoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dashboardCountsData = data ? data : [];
        } else {
          state.dashboardCountsData = [];
          //message.error(msg);
        }
      })
      .addCase(getDashboardCounts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getDashboardCountsLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPifFdList.pending, (state) => {
        state.getPifFdListLoading = true;
      })
      .addCase(getPifFdList.fulfilled, (state, action) => {
        state.getPifFdListLoading = false;
        const { success, data, pagination } = action.payload;

        if (success) {
          state.pifFdListData = data ? data : [];
          state.pagination = pagination || null;
        } else {
          state.pifFdListData = [];
          //message.error(msg);
        }
      })
      .addCase(getPifFdList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getPifFdListLoading = false;

        message.error(msg);
      });
    builder
      .addCase(removeDraft.pending, (state) => {
        state.removeDraftLoading = true;
      })
      .addCase(removeDraft.fulfilled, (state, action) => {
        state.removeDraftLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(removeDraft.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.removeDraftLoading = false;

        message.error(msg);
      });
    builder
      .addCase(sign.pending, (state) => {
        state.signLoading = true;
      })
      .addCase(sign.fulfilled, (state, action) => {
        state.signLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(sign.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.signLoading = false;

        message.error(msg);
      });
    builder
      .addCase(signFd.pending, (state) => {
        state.signLoading = true;
      })
      .addCase(signFd.fulfilled, (state, action) => {
        state.signLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(signFd.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.signLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getPifApiNumbers.pending, (state) => {
        state.pifApiNumbersLoading = true;
      })
      .addCase(getPifApiNumbers.fulfilled, (state, action) => {
        state.pifApiNumbersLoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.pifApiNumbers = data ? data : [];
        } else {
          state.pifApiNumbers = [];
        }
      })
      .addCase(getPifApiNumbers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.pifApiNumbersLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getPifFdNumbers.pending, (state) => {
        state.pifFdNumbersLoading = true;
      })
      .addCase(getPifFdNumbers.fulfilled, (state, action) => {
        state.pifFdNumbersLoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.pifFdNumbers = data ? data : [];
        } else {
          state.pifFdNumbers = [];
        }
      })
      .addCase(getPifFdNumbers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.pifFdNumbersLoading = false;

        message.error(msg);
      });
    builder
      .addCase(signOrApprove_form.pending, (state) => {
        state.signOrApprove_formLoading = true;
      })
      .addCase(signOrApprove_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.signOrApprove_formLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(signOrApprove_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.signOrApprove_formLoading = false;

        message.error(msg);
      });
    builder
      .addCase(signOrApproveAPI_form.pending, (state) => {
        state.signOrApprove_formLoading = true;
      })
      .addCase(signOrApproveAPI_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.signOrApprove_formLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(signOrApproveAPI_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.signOrApprove_formLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getDashboardApiCounts.pending, (state) => {
        state.getDashboardApiCountsLoading = true;
      })
      .addCase(getDashboardApiCounts.fulfilled, (state, action) => {
        state.getDashboardApiCountsLoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dashboardApiCountsData = data ? data : [];
        } else {
          state.dashboardApiCountsData = [];
          //message.error(msg);
        }
      })
      .addCase(getDashboardApiCounts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getDashboardApiCountsLoading = false;

        message.error(msg);
      });
  }
});

export default signatorySlice.reducer;
