import React /* , { useState, useEffect }  */ from 'react';
import { Form, Input, Table, Space, Select, message } from 'antd';
import styles from './dynamicTable_Prop_prod.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';
import { getNullValue } from 'utilities/helpers';

const { Option } = Select;

const APIDynamicTable_Prop_prod = ({
  dataSource,
  setDataSource,
  generateKey,
  formData1,
  getFormDetailsByIdData,
  dropDown_approved_strengthsData,
  edit,
  setError
}) => {
  console.log('dataSource_PROPOSED: ', dataSource);
  console.log('..prop table', getFormDetailsByIdData?.prop_product_detail);
  console.log('..prop form data', formData1);
  // const [proStrength, setPropStrength] = useState('');
  // useEffect(() => {
  //   const newPropStrength = dropDown_approved_strengthsData?.filter(
  //     (item) => item.id === formData1?.Strength
  //   );
  //   setPropStrength(newPropStrength[0]?.id);
  // }, [formData1?.formData1?.Strength]);
  // Function to add a new row

  const addRow = () => {
    const emptyKeys = getNullValue(dataSource.at(-1), ['UoM_id']);
    if (emptyKeys && edit !== 'optional') {
      const msg = `${emptyKeys} are required fields`;
      message.error(msg);
      setError(true);
      return;
    }
    setError(false);
    const newData = {
      key: generateKey(),
      UoM_id:
        formData1?.ApprovedStrengths === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.prop_UoM.id
          : formData1?.ApprovedStrengths,

      size_shape_similarity: '',
      score: '',
      strength_value: ''
      // is_active: true
    };
    console.log('newData@@@@@@: ', newData);
    setDataSource([...dataSource, newData]);
  };

  // Function to delete a row by key
  const deleteRow = (key) => {
    // // // setDataSource(dataSource.filter((item) => item.key !== key));

    // console.log('deleteKey: ', key);

    // // Create a copy of the original data source
    // const updatedDataSource = [...dataSource];

    // // Find the index of the object with the specified key
    // const index = updatedDataSource.findIndex((item) => item.key === key);

    // // If the object with the specified key is found, update its is_active property
    // if (index !== -1) {
    //   updatedDataSource[index].is_active = false;

    //   // Update the state with the modified data source
    //   setDataSource(updatedDataSource);
    // } else {
    //   console.log('Object with key', key, 'not found in dataSource.');
    // }
    const updatedData = dataSource?.filter((item) => item.key !== key);
    setDataSource(updatedData);
  };

  const columns = [
    {
      title: 'Strengths',
      dataIndex: 'strength_value',
      //width: '50px',
      render: (_, record) => (
        <FormItem
          //name={['strength_value', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            // type="number"
            // step="0.01"
            // min={0}
            value={record.strength_value}
            onChange={(e) =>
              handleCellChange(record.key, 'strength_value', e.target.value)
            }
          />
        </FormItem>
      )
    },
    {
      title: 'UoM',
      dataIndex: 'UoM_id',
      width: '50px',
      render: () => (
        //_, record
        <FormItem
          // name={['UoM_id', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
          className={`${styles.select_styles}`}
        >
          {/* <Input
            //className={`${styles.table_input_styles}`}
            disabled
            value={
              formData1?.Strength === undefined
                ? getFormDetailsByIdData?.prop_product_detail?.prop_UoM?.id
                : proStrength
            } //{record.UoM_id}
            // placeholder="Ex: 5 mg or 10 ml"
            onChange={(e) =>
              handleCellChange(record.key, 'UoM_id', e.target.value)
            }
          /> */}
          <Select
            // showSearch
            // onSearch={debounceApproved_strengths}
            disabled
            placeholder=""
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            // onChange={(value) =>
            //   handleFormDataOnChange('Strength', value)
            // }
            defaultValue={
              formData1?.ApprovedStrengths === undefined
                ? getFormDetailsByIdData?.prop_product_detail?.prop_UoM?.id
                : formData1?.ApprovedStrengths
            }
          >
            {dropDown_approved_strengthsData?.map((item, index) => {
              return (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      title: 'Size Shape Similarity',
      dataIndex: 'size_shape_similarity',
      render: (_, record) => (
        <FormItem
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            value={record.size_shape_similarity}
            onChange={(e) =>
              handleCellChange(
                record.key,
                'size_shape_similarity',
                e.target.value
              )
            }
          />
        </FormItem>
      )
    },

    {
      title: 'Score Line',
      dataIndex: 'score',
      editable: true,
      render: (_, record) => (
        <FormItem
          //  name={['score', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            // type="number"
            // step="0.01"
            // min={0}
            disabled={edit === 'optional'}
            value={record.score}
            onChange={(e) =>
              handleCellChange(record.key, 'score', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Space
            size="middle"
            style={{
              display: 'flex',
              justifyContent: 'center' /* paddingLeft: '16px' */
            }}
          >
            <PlusOutlined
              className={`${styles.plusIcon}`}
              onClick={edit === 'optional' ? '' : addRow}
            />

            {dataSource.length > 1 ? (
              <DeleteOutlined
                className={`${styles.deleteIcon}`}
                onClick={() =>
                  edit === 'optional' ? '' : deleteRow(record.key)
                }
              />
            ) : null}
          </Space>
        </FormItem>
      )
    }
  ];

  // Function to handle changes in cell values
  const handleCellChange = (key, dataIndex, value) => {
    const newData = [...dataSource];
    console.log('newData', newData);
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData[index] = { ...item, [dataIndex]: value };
      setDataSource(newData);
    }
  };

  //const filteredDataSource = dataSource?.filter((item) => item.is_active);

  return (
    <div>
      <Form>
        <Table
          dataSource={dataSource} //{filteredDataSource} //{dataSource}
          columns={columns}
          pagination={false}
          scroll={{
            x: 600
          }}
          className={`${styles.table_styles}`}
        />
      </Form>
    </div>
  );
};

export default APIDynamicTable_Prop_prod;
