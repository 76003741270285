import React, { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import styles from './index.module.less';
import { Progress } from 'antd';

const PDFViewer = ({ getFormDetailsByIdData }) => {
  const [numPages, setNumPages] = useState(null);

  const containerRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      {getFormDetailsByIdData?.pdf_pif?.fileUrl ? (
        <div ref={containerRef}>
          <Document
            file={getFormDetailsByIdData?.pdf_pif?.fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                width={window.innerWidth}
                key={`page_${index + 1}`}
                wrap={true}
                pageNumber={index + 1}
                className={`${styles.document_viewer_page}`}
                size="A6"
              />
            ))}
          </Document>
        </div>
      ) : (
        <Progress percent={30} size="small" />
      )}
    </div>
  );
};

export default PDFViewer;
