import React from 'react';
import { Form, Table, Space, Input, Select } from 'antd';
import styles from './ip_generic_launch_date.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
//import moment from 'moment';
import FormItem from 'common/FormItem';

const { Option } = Select;

const Ip_generic_launch_date = ({
  dataSource2,
  setDataSource2,
  generateKey2,
  // formData1,
  // getFormDetailsByIdData,
  //storedData2,
  dropDown_countryData,
  debounceCountry
  //edit
}) => {
  console.log('dataSource2$$$$$$$$$$$: ', dataSource2);

  // Function to add a new row
  const addRow = () => {
    const newData = {
      key: generateKey2(),
      //id: '',
      country_id: '',
      launch_date: '' //moment().format('YYYY-MM-DD'),
      //is_active: true
    };
    setDataSource2([...dataSource2, newData]);
  };

  // Function to delete a row by key
  // const deleteRow = (key) => {
  //   // // setDataSource2(dataSource2.filter((item) => item.key !== key));

  //   console.log('deleteKey: ', key);

  //   // Create a copy of the original data source
  //   const updatedDataSource2 = [...dataSource2];

  //   // Find the index of the object with the specified key
  //   const index = updatedDataSource2.findIndex((item) => item.key === key);

  //   // If the object with the specified key is found, update its is_active property
  //   if (index !== -1) {
  //     updatedDataSource2[index].is_active = false;

  //     // Update the state with the modified data source
  //     setDataSource2(updatedDataSource2);
  //   } else {
  //     console.log('Object with key', key, 'not found in dataSource2.');
  //   }
  // };
  const deleteRow = (key) => {
    // if (storedData2 === 'true') {
    //   const updatedDataSource = dataSource2?.map((item) =>
    //     item.key === key ? { ...item, is_active: false } : item
    //   );
    //   setDataSource2(updatedDataSource);
    // } else {
    const updatedData = dataSource2?.filter((item) => item.key !== key);
    setDataSource2(updatedData);
    // }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'country_id',
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          className={`${styles.select_styles}`}
          // popupClassName={styles.select_dropdown} // Add this class for custom dropdown styling
        >
          <Select
            // disabled={edit === 'optional'}
            showSearch
            onSearch={debounceCountry}
            placeholder="Select Country"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            defaultValue={record.country_id}
            onChange={(value) =>
              handleCellChange(record.key, 'country_id', value)
            }
          >
            {dropDown_countryData?.map((item, index) => {
              return (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      title: '',
      dataIndex: 'launch_date',

      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className={`${styles.date_styles}`}
        >
          {/*  <DatePicker
            format={'YYYY-MM-DD'}
            defaultValue={moment(record.launch_date)}
            onChange={(_, dateValue) =>
              handleCellChange(record.key, 'launch_date', dateValue)
            }
            // disabledDate={(current) => {
            //   return (
            //     moment().add(-1, 'days') >= current ||
            //     moment().add(0, 'month') <= current
            //   );
            // }}
            style={{ width: '100%' }}
          ></DatePicker> */}
          <Input
            // disabled={edit === 'optional'}
            value={record.launch_date}
            onChange={(e) =>
              handleCellChange(record.key, 'launch_date', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: '',
      dataIndex: 'action',
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Space
            size="middle"
            style={{
              display: 'flex',
              justifyContent: 'center' /* paddingLeft: '16px' */
            }}
          >
            <PlusOutlined
              className={`${styles.plusIcon}`}
              onClick={/* edit === 'optional' ? '' : */ addRow}
            />

            {dataSource2.length > 1 ? (
              <DeleteOutlined
                className={`${styles.deleteIcon}`}
                onClick={() =>
                  /* edit === 'optional' ? '' : */ deleteRow(record.key)
                }
              />
            ) : null}
          </Space>
        </FormItem>
      )
    }
  ];

  // Function to handle changes in cell values
  const handleCellChange = (key, dataIndex, value) => {
    const newData = [...dataSource2];
    console.log('newData', newData);
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData[index] = { ...item, [dataIndex]: value };
      setDataSource2(newData);
    }
  };

  //const filteredDataSource2 = dataSource2?.filter((item) => item.is_active);

  return (
    <div>
      <Form>
        <Table
          dataSource={dataSource2}
          // {
          //   storedData2 === 'true' ? filteredDataSource2 : dataSource2
          // } //{dataSource2}
          columns={columns}
          pagination={false}
          // scroll={{
          //   x: 1300
          // }}
          className={`${styles.table_styles}`}
        />
      </Form>
    </div>
  );
};

export default Ip_generic_launch_date;
