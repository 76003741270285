import apiClient from 'utilities/apiClient';

export const dashboardCounts = (payload) => {
  return apiClient.get(apiClient.Urls.dashboardCounts, payload, true);
};

export const pifAPIList = (payload) => {
  return apiClient.get(apiClient.Urls.API.pifAPIList, payload, true);
};

export const reject = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.reject}/${url}`,
    payload,
    true
    //'file'
  );
};

export const rejectAPI = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.API.reject}/${url}`,
    payload,
    true
    //'file'
  );
};
export const dashboardApiCounts = (payload) => {
  return apiClient.get(apiClient.Urls.dashboardApiCounts, payload, true);
};
