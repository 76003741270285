import React from 'react';
import { Modal, Typography, Button } from 'antd';

import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';

//import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { history } from '@/app/history';
//import FormData from 'form-data';
import { reject_API_form } from 'features/approverFlow/redux/slice';
import TextArea from 'antd/lib/input/TextArea';

const RejectModal = ({
  rejectModal,
  setRejectModal,
  userDetails,
  loggedIn_User,
  hash,
  PIF_id
}) => {
  //const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { reject_formLoading } = useSelector((state) => state.approver);

  const onFinish = (value) => {
    if (userDetails === undefined || loggedIn_User === null) {
      const payload = {
        pif_id: PIF_id,
        hash: hash,
        remarks: value?.comments
      };
      dispatch(reject_API_form(payload)).then(function (response) {
        if (response) {
          setRejectModal(false);
          if (response?.payload?.success) {
            history.push('/one-click-reject');
          }
        }
      });
    } else {
      const payload = {
        pif_id: PIF_id,
        hash: '',
        remarks: value?.comments
      };

      dispatch(reject_API_form(payload)).then(function (response) {
        if (response) {
          setRejectModal(false);
          if (response?.payload?.success) {
            history.push('/approver-api-list-view');
          }
        }
      });
    }
    // let formdata = new FormData();

    // formdata.append('pif_id', params.id);
    // formdata.append('remarks', value?.comments);

    // dispatch(reject_form(formdata)).then(function (response) {
    //   if (response) {
    //     setRejectModal(false);
    //     if (response?.payload?.success) {
    //       history.push('/approver-list-view');
    //     }
    //   }
    // });
  };

  return (
    <>
      <Modal
        title={''}
        visible={rejectModal}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'620px'}
        onCancel={() => setRejectModal(false)}
      >
        <Typography
          style={{
            fontSize: '16px',
            marginTop: '-6px',
            fontFamily: 'SFProText Regular',
            fontWeight: '600',
            lineHeight: '16px'
          }}
        >
          Reject the document
        </Typography>
        <br />
        <br />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <FormItem
            label="Comments"
            name="comments"
            rules={[
              {
                required: true,
                message: 'Please enter comments'
              }
            ]}
          >
            <TextArea
              style={{ width: '100%' }}
              placeholder="Add any comments"
              rows={4}
              allowClear
            />
          </FormItem>

          <FormItem className={`${styles.bottom_margin}`}>
            <Space className={`${styles.button_container}`}>
              <Button
                //className={styles.back_btn_style}
                className={styles.import_style_cancel}
                size="small"
                onClick={() => setRejectModal(false)}
              >
                Cancel
              </Button>
              <Button
                loading={reject_formLoading}
                // className={styles.post_btn_style}
                className={styles.reject_modal_button}
                htmlType="submit"
                size="small"
              >
                Reject
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default RejectModal;
