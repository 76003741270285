import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '@/features/login/redux/slice';
import homeReduccer from 'features/home/redux/slice';
import pif_fd_formReduccer from 'features/PIF_FD_form/redux/slice';
import pif_API_formReducer from '@/features/PIF_API_form/redux/slice';
import signatoryReducer from 'features/signatoryFlow/redux/slice';
import approverReducer from 'features/approverFlow/redux/slice';

//import dashboardReducer from 'features/welcome/redux/slice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    home: homeReduccer,
    pif_fd_form: pif_fd_formReduccer,
    pif_API_form: pif_API_formReducer,
    signatory: signatoryReducer,
    approver: approverReducer
    //dashboard: dashboardReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
