import CustomLayout from '@/common/Layout';
import React from 'react';
import SignatoryListViewPage from './components';

const SignatoryListView = () => {
  return (
    <CustomLayout sider={false}>
      <SignatoryListViewPage />
    </CustomLayout>
  );
};

export default SignatoryListView;
