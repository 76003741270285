import React, { useState } from 'react';
import { Form, Input, Table, Space, Select, message } from 'antd';
import styles from './dynamicTable.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';
import { getNullValue } from 'utilities/helpers';

const { Option } = Select;

const APIDynamicTable = ({
  dataSource,
  setDataSource,
  generateKey,
  formData1,
  getFormDetailsByIdData,
  storedData,
  dropDown_approved_strengthsData,
  edit,
  setError
}) => {
  //console.log('dataSource$$$$$$$$$$$: ', dataSource);
  const [disabledOptions, setDisabledOptions] = useState([]);
  //const [proStrength, setPropStrength] = useState('');

  // useEffect(() => {
  //   const newPropStrength = dropDown_approved_strengthsData?.filter(
  //     (item) => item.id === formData1?.ApprovedStrengths
  //   );
  //   console.log('newPropStrength: ', newPropStrength);
  //   setPropStrength(newPropStrength[0]?.id);
  // }, [formData1?.ApprovedStrengths]);

  // Function to add a new row
  const addRow = () => {
    const emptyKeys = getNullValue(dataSource.at(-1), ['size']);
    if (emptyKeys && edit !== 'optional') {
      const msg = `${emptyKeys} are required fields`;
      message.error(msg);
      setError(true);
      return;
    }
    setError(false);
    const newData = {
      key: generateKey(),
      UoM_id:
        formData1?.ApprovedStrengths === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.ref_UoM.id
          : formData1?.ApprovedStrengths,
      trade_dress: '',
      size: '',
      shape: '',
      colour: '',
      making: '',
      score: '',
      strength_value: ''
      //is_active: true
    };
    setDataSource([...dataSource, newData]);
  };

  // Function to delete a row by key
  // const deleteRow = (key) => {
  //   // // setDataSource(dataSource.filter((item) => item.key !== key));
  //   // Create a copy of the original data source
  //   const updatedDataSource = [...dataSource];

  //   // Find the index of the object with the specified key
  //   const index = updatedDataSource.findIndex((item) => item.key === key);

  //   // If the object with the specified key is found, update its is_active property
  //   if (index !== -1) {
  //     updatedDataSource[index].is_active = false;

  //     // Update the state with the modified data source
  //     setDataSource(updatedDataSource);
  //   } else {
  //     console.log('Object with key', key, 'not found in dataSource.');
  //   }
  // };
  const deleteRow = (key) => {
    console.log('key:::', key);
    // if (storedData === 'true') {
    //   const updatedDataSource = dataSource.map((item) =>
    //     item.key === key ? { ...item, is_active: false } : item
    //   );
    //   setDataSource(updatedDataSource);

    //   // Enable the deleted option in the dropdown
    //   const deletedRow = dataSource.find((item) => item.key === key);
    //   if (deletedRow) {
    //     // Enable the deleted option in the dropdown
    //     setDisabledOptions(
    //       disabledOptions.filter((id) => id !== deletedRow.stage_id)
    //     );
    //   }
    // } else {
    console.log('storedData===false:', storedData);
    const updatedData = dataSource?.filter((item) => item.key !== key);
    setDataSource(updatedData);

    // Enable the deleted option in the dropdown
    const deletedRow = dataSource.find((item) => item.key === key);
    if (deletedRow) {
      // Enable the deleted option in the dropdown
      setDisabledOptions(
        disabledOptions.filter((id) => id !== deletedRow.stage_id)
      );
    }
    //}
  };

  const columns = [
    {
      title: 'Strengths',
      dataIndex: 'strength_value',
      render: (_, record) => (
        <FormItem
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            // type="number"
            // step="0.01"
            //min={0}
            value={record.strength_value}
            onChange={(e) =>
              handleCellChange(record.key, 'strength_value', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: 'UoM',
      dataIndex: 'UoM_id',
      width: '50px',
      render: () => (
        //_, record
        <FormItem
          rules={[{ required: true, message: 'This is required field' }]}
          className={`${styles.select_styles}`}
        >
          <Select
            disabled
            placeholder=""
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            defaultValue={
              formData1?.ApprovedStrengths === undefined
                ? getFormDetailsByIdData?.ref_product_detail?.ref_UoM?.id
                : formData1?.ApprovedStrengths
            }
          >
            {dropDown_approved_strengthsData?.map((item, index) => {
              return (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      dataIndex: 'trade_dress',
      title: 'Trade Dress',
      render: (_, record) => (
        <FormItem
          // name={['trade_dress', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            value={record.trade_dress}
            onChange={(e) =>
              handleCellChange(record.key, 'trade_dress', e.target.value)
            }
          />
        </FormItem>
      )
    },
    // {
    //   dataIndex: 'size',
    //   title: 'Size',
    //   render: (_, record) => (
    //     <FormItem
    //       // name={['size', record.key]}
    //       rules={[{ required: true, message: 'This is required field' }]}
    //     >
    //       <Input
    //         value={record.size}
    //         onChange={(e) =>
    //           handleCellChange(record.key, 'size', e.target.value)
    //         }
    //       />
    //     </FormItem>
    //   )
    // },
    {
      dataIndex: 'shape',
      title: 'Shape',
      render: (_, record) => (
        <FormItem
          //  name={['shape', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            value={record.shape}
            onChange={(e) =>
              handleCellChange(record.key, 'shape', e.target.value)
            }
          />
        </FormItem>
      )
    },
    {
      dataIndex: 'colour',
      title: 'Colour',
      render: (_, record) => (
        <FormItem
          // name={['colour', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            value={record.colour}
            onChange={(e) =>
              handleCellChange(record.key, 'colour', e.target.value)
            }
          />
        </FormItem>
      )
    },
    {
      dataIndex: 'making',
      title: 'Marking',
      render: (_, record) => (
        <FormItem
          //  name={['making', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            value={record.making}
            onChange={(e) =>
              handleCellChange(record.key, 'making', e.target.value)
            }
          />
        </FormItem>
      )
    },
    {
      dataIndex: 'score',
      title: 'Score Line',
      editable: true,
      render: (_, record) => (
        <FormItem
          //  name={['score', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            // type="number"
            // step="0.01"
            // min={0}
            disabled={edit === 'optional'}
            value={record.score}
            onChange={(e) =>
              handleCellChange(record.key, 'score', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Space
            size="middle"
            style={{
              display: 'flex',
              justifyContent: 'center' /* ,paddingLeft: '14px' */
            }}
          >
            <PlusOutlined
              className={`${styles.plusIcon}`}
              onClick={edit === 'optional' ? '' : addRow}
            />

            {dataSource?.length > 1 ? (
              <DeleteOutlined
                className={`${styles.deleteIcon}`}
                onClick={() =>
                  edit === 'optional' ? '' : deleteRow(record.key)
                }
              />
            ) : null}
          </Space>
        </FormItem>
      )
    }
  ];

  // Function to handle changes in cell values
  const handleCellChange = (key, dataIndex, value) => {
    const newData = [...dataSource];
    console.log('newData', newData);
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData[index] = { ...item, [dataIndex]: value };
      setDataSource(newData);
    }
  };

  //const filteredDataSource = dataSource?.filter((item) => item.is_active);

  return (
    <div>
      <Form>
        <Table
          dataSource={dataSource} //{storedData === 'true' ? filteredDataSource : dataSource} //{dataSource}
          columns={columns}
          pagination={false}
          scroll={{
            x: 1400
          }}
          className={`${styles.table_styles}`}
        />
      </Form>
    </div>
  );
};

export default APIDynamicTable;
