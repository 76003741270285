import React from 'react';
import { Form, Input, Table, Space, Select } from 'antd';
import styles from './FPM_US.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';

const { Option } = Select;

const FinancialPerformanceMetricsUS = ({
  dataSource_FPM_US,
  setDataSource_FPM_US,
  generateKey_fpm_us,
  //storedData_fpm_us,
  //dropDown_budget_itemsData,
  dropDown_fpm_itemsData,
  debounceFpm_items
  // edit
}) => {
  console.log('dataSource_FPM_US$$$$$$$$$$$: ', dataSource_FPM_US);

  //const [filteredDataSource, setFilteredDataSource] = useState([]);

  // Function to add a new row
  const addRow = () => {
    const newData = {
      key: generateKey_fpm_us(),
      units_and_others_id: '',
      best_case_value: '',
      base_case_value: ''
      // is_active: true
    };
    setDataSource_FPM_US([...dataSource_FPM_US, newData]);
  };

  // Function to delete a row by key
  // const deleteRow = (key) => {
  //   // // setDataSource_FPM_US(dataSource_FPM_US.filter((item) => item.key !== key));

  //   console.log('deleteKey: ', key);

  //   // Create a copy of the original data source
  //   const updatedDataSource = [...dataSource_FPM_US];

  //   // Find the index of the object with the specified key
  //   const index = updatedDataSource.findIndex((item) => item.key === key);

  //   // If the object with the specified key is found, update its is_active property
  //   if (index !== -1) {
  //     updatedDataSource[index].is_active = false;

  //     // Update the state with the modified data source
  //    setDataSource_FPM_US(updatedDataSource);
  //   } else {
  //     console.log('Object with key', key, 'not found in dataSource_FPM_US.');
  //   }
  // };
  const deleteRow = (key) => {
    const updatedData = dataSource_FPM_US?.filter((item) => item.key !== key);
    setDataSource_FPM_US(updatedData);
    // if (storedData_fpm_us === 'true') {
    //   const updatedDataSource = dataSource_FPM_US?.map((item) =>
    //     item.key === key ? { ...item, is_active: false } : item
    //   );
    //   setDataSource_FPM_US(updatedDataSource);
    // } else {
    //   const updatedData = dataSource_FPM_US?.filter((item) => item.key !== key);
    //   setDataSource_FPM_US(updatedData);
    // }
  };

  // const headerStyle = () => ({
  //   style: { backgroundColor: '#65bade44', padding: '0.5rem' }
  // });

  const columns = [
    {
      title: 'Metric',
      dataIndex: 'units_and_others_id',
      // onHeaderCell: headerStyle,
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          className={`${styles.select_styles}`}
        >
          <Select
            //disabled={edit === 'optional'}
            showSearch
            onSearch={debounceFpm_items}
            placeholder="Select Patent Type"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            defaultValue={record?.units_and_others_id}
            onChange={(value) =>
              handleCellChange(record.key, 'units_and_others_id', value)
            }
            className={styles.select_styles} // Add this class for custom styling
            popupClassName={styles.select_dropdown} // Add this class for custom dropdown styling
          >
            {dropDown_fpm_itemsData?.map((item, index) => {
              return (
                <Option value={item?.id} key={index}>
                  {item?.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      title: 'Best Case Scenario',
      dataIndex: 'best_case_value',
      // onHeaderCell: headerStyle,
      render: (_, record) => (
        <FormItem
          // name={['best_case_value', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
          // className="margin-0"
        >
          <Input
            // type="number"
            // step="0.01"
            // min={0}
            //disabled={edit === 'optional'}
            // className="border-none"
            placeholder="CLick to add"
            value={record.best_case_value}
            onChange={(e) =>
              handleCellChange(record.key, 'best_case_value', e.target.value)
            }
          />
        </FormItem>
      )
    },
    {
      title: 'Base Case Scenario',
      dataIndex: 'base_case_value',
      // onHeaderCell: headerStyle,
      render: (_, record) => (
        <FormItem
          // name={['base_case_value', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
          // className="margin-0"
        >
          <Input
            // type="number"
            // step="0.01"
            // min={0}
            //disabled={edit === 'optional'}
            // className="border-none"
            placeholder="CLick to add"
            value={record.base_case_value}
            onChange={(e) =>
              handleCellChange(record.key, 'base_case_value', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: 'Action',
      dataIndex: 'action',
      // onHeaderCell: headerStyle,
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          <Space
            size="middle"
            style={{
              display: 'flex',
              justifyContent: 'center' /* paddingLeft: '16px' */
            }}
          >
            <PlusOutlined
              className={`${styles.plusIcon}`}
              onClick={/* edit === 'optional' ? '' : */ addRow}
            />

            {dataSource_FPM_US?.length > 1 ? (
              <DeleteOutlined
                className={`${styles.deleteIcon}`}
                onClick={() =>
                  /* edit === 'optional' ? '' : */ deleteRow(record.key)
                }
              />
            ) : null}
          </Space>
        </FormItem>
      )
    }
  ];

  // Function to handle changes in cell values
  const handleCellChange = (key, dataIndex, value) => {
    // console.log('key', key);
    // console.log('dataIndex', dataIndex);
    // console.log('value', value);
    const newData = [...dataSource_FPM_US];
    // console.log('newData', newData);
    const index = newData?.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData[index] = { ...item, [dataIndex]: value };
      setDataSource_FPM_US(newData);
    }
  };

  // useEffect(() => {
  //   setFilteredDataSource(dataSource_FPM_US?.filter((item) => item.is_active));
  // }, [dataSource_FPM_US]);

  // const filteredDataSource = dataSource_FPM_US?.filter(
  //   (item) => item.is_active
  // );

  // console.log('filteredDataSource: ', filteredDataSource);
  return (
    <div>
      <Form>
        <Table
          dataSource={dataSource_FPM_US}
          // {
          //   storedData_fpm_us === 'true'
          //     ? filteredDataSource
          //     : dataSource_FPM_US
          // } //{dataSource_FPM_US}
          columns={columns}
          pagination={false}
          scroll={{
            x: 900
          }}
          className={`${styles.table_styles}`}
        />
      </Form>
    </div>
  );
};

export default FinancialPerformanceMetricsUS;
