import React from 'react';
import { Form, Input, Table, Space, Select } from 'antd';
import styles from './ip_patentScenario_table.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';

const { Option } = Select;

const Ip_patentScenario_table = ({
  dataSource,
  setDataSource,
  generateKey,
  // formData1,
  // getFormDetailsByIdData,
  //storedData,
  dropDown_patent_typeData,
  debouncePatent_type
  //edit
}) => {
  console.log('dataSource$$$$$$$$$$$: ', dataSource);

  //const [filteredDataSource, setFilteredDataSource] = useState([]);

  // Function to add a new row
  const addRow = () => {
    const newData = {
      key: generateKey(),
      //ip_detail_id: '',
      patent_type_id: '',
      patent_expiration_us: '',
      patent_expiration_eu: '',
      patent_expiration_row: '',
      strategy: ''
      //is_active: true
    };
    setDataSource([...dataSource, newData]);
  };

  // Function to delete a row by key
  // const deleteRow = (key) => {
  //   // // setDataSource(dataSource.filter((item) => item.key !== key));

  //   console.log('deleteKey: ', key);

  //   // Create a copy of the original data source
  //   const updatedDataSource = [...dataSource];

  //   // Find the index of the object with the specified key
  //   const index = updatedDataSource.findIndex((item) => item.key === key);

  //   // If the object with the specified key is found, update its is_active property
  //   if (index !== -1) {
  //     updatedDataSource[index].is_active = false;

  //     // Update the state with the modified data source
  //     setDataSource(updatedDataSource);
  //   } else {
  //     console.log('Object with key', key, 'not found in dataSource.');
  //   }
  // };
  const deleteRow = (key) => {
    // if (storedData === 'true') {
    //   const updatedDataSource = dataSource?.map((item) =>
    //     item.key === key ? { ...item, is_active: false } : item
    //   );
    //   setDataSource(updatedDataSource);
    // } else {
    const updatedData = dataSource?.filter((item) => item.key !== key);
    setDataSource(updatedData);
    // }
  };

  const columns = [
    {
      title: 'Patent Type',
      dataIndex: 'patent_type_id',
      render: (_, record) => (
        // <FormItem
        //   // name={['patent_type_id', record.key]}
        //   rules={[{ required: true, message: 'This is required field' }]}
        // >
        //   <Input
        //     value={record.patent_type_id}
        //     onChange={(e) =>
        //       handleCellChange(record.key, 'patent_type_id', e.target.value)
        //     }
        //   />
        // </FormItem>
        <FormItem
          // label="Approved Strengths"
          // name="ApprovedStrengths"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          className={`${styles.select_styles}`}
        >
          <Select
            //disabled={edit === 'optional'}
            showSearch
            onSearch={debouncePatent_type}
            placeholder="Select Patent Type"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            defaultValue={record?.patent_type_id}
            onChange={(value) =>
              handleCellChange(record.key, 'patent_type_id', value)
            }
            className={styles.select_styles} // Add this class for custom styling
            popupClassName={styles.select_dropdown} // Add this class for custom dropdown styling
          >
            {dropDown_patent_typeData?.map((item, index) => {
              return (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      title: 'Patent Expiration - US',
      dataIndex: 'patent_expiration_us',
      render: (_, record) => (
        <FormItem
          // name={['patent_expiration_us', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            //disabled={edit === 'optional'}
            value={record.patent_expiration_us}
            onChange={(e) =>
              handleCellChange(
                record.key,
                'patent_expiration_us',
                e.target.value
              )
            }
          />
        </FormItem>
      )
    },
    {
      title: 'Patent Expiration - EU',
      dataIndex: 'patent_expiration_eu',
      render: (_, record) => (
        <FormItem
          // name={['patent_expiration_eu', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            //disabled={edit === 'optional'}
            value={record.patent_expiration_eu}
            onChange={(e) =>
              handleCellChange(
                record.key,
                'patent_expiration_eu',
                e.target.value
              )
            }
          />
        </FormItem>
      )
    },
    {
      title: 'Patent Expiration - RoW',
      dataIndex: 'patent_expiration_row',
      render: (_, record) => (
        <FormItem
          //  name={['patent_expiration_row', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            //disabled={edit === 'optional'}
            value={record.patent_expiration_row}
            onChange={(e) =>
              handleCellChange(
                record.key,
                'patent_expiration_row',
                e.target.value
              )
            }
          />
        </FormItem>
      )
    },
    {
      title: 'Strategy',
      dataIndex: 'strategy',
      render: (_, record) => (
        <FormItem
          // name={['strategy', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            //disabled={edit === 'optional'}
            value={record.strategy}
            onChange={(e) =>
              handleCellChange(record.key, 'strategy', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Space
            size="middle"
            style={{
              display: 'flex',
              justifyContent: 'center' /* paddingLeft: '16px' */
            }}
          >
            <PlusOutlined
              className={`${styles.plusIcon}`}
              onClick={/* edit === 'optional' ? '' : */ addRow}
            />

            {dataSource?.length > 1 ? (
              <DeleteOutlined
                className={`${styles.deleteIcon}`}
                onClick={() =>
                  /*  edit === 'optional' ? '' : */ deleteRow(record.key)
                }
              />
            ) : null}
          </Space>
        </FormItem>
      )
    }
  ];

  // Function to handle changes in cell values
  const handleCellChange = (key, dataIndex, value) => {
    // console.log('key', key);
    // console.log('dataIndex', dataIndex);
    // console.log('value', value);
    const newData = [...dataSource];
    // console.log('newData', newData);
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData[index] = { ...item, [dataIndex]: value };
      setDataSource(newData);
    }
  };

  // useEffect(() => {
  //   setFilteredDataSource(dataSource?.filter((item) => item.is_active));
  // }, [dataSource]);

  // const filteredDataSource = dataSource?.filter((item) => item.is_active);

  //console.log('filteredDataSource: ', filteredDataSource);
  return (
    <div>
      <Form>
        <Table
          dataSource={dataSource} //{storedData === 'true' ? filteredDataSource : dataSource} //{dataSource}
          columns={columns}
          pagination={false}
          scroll={{
            x: 1300
          }}
          className={`${styles.table_styles}`}
        />
      </Form>
    </div>
  );
};

export default Ip_patentScenario_table;
