import { useEffect, useState /* , useMemo */ } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Collapse,
  Divider,
  Spin,
  Typography
  //message
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
//import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './reference_product_details.module.less';
import { useDispatch } from 'react-redux';
import FormItem from 'common/FormItem';
import CustomCollapse from '@/common/CustomCollapse';
//import { debounce } from 'lodash';
import moment from 'moment';
import { history } from 'app/history';
// import { hasEmptyValue } from 'utilities/helpers';
import { useResponsiveness } from 'hooks/useResponsiveness';
import APIDynamicTable from '@/common/APIdynamicTable/DynamicTable';

const { Option } = Select;
//const { TextArea } = Input;
const { Panel } = Collapse;

const Reference_product_details = ({
  PIF_form_id,
  setSelectedTab,
  // dropDown_market_segmentData,
  dropDown_therapeutic_categoryData,
  // dropDown_administration_routeData,
  dropDown_dosage_formData,
  dropDown_approved_strengthsData,
  createUpdateRefProductDetail_formLoading,
  createUpdateRefProductDetail_form,
  getFormDetailsByIdData,
  getFormDetailsByIdLoading,
  // debounceMarket_Segment,
  debounceTherapeutic_category,
  // debounceAdministration_route,
  debounceDosage_form,
  debounceApproved_strengths,
  draft_PIF_form_start
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tableError, setTableError] = useState(false);
  const { xs, sm } = useResponsiveness();

  const storedData = localStorage.getItem('ref_table');

  const [formData1, setFormData1] = useState({});
  const [dataSource, setDataSource] = useState();
  // const [isOthers_marketSegment, setIsOthers_marketSegment] = useState();
  const [isOthers_therapeuticCategory, setIsOthers_therapeuticCategory] =
    useState();
  // const [isOthers_routeAdministration, setIsOthers_routeAdministration] =
  //   useState();
  const [isOthers_dosageForm, setIsOthers_dosageForm] = useState();
  const [isOthers_approvedStrengths, setIsOthers_approvedStrengths] =
    useState();

  // storedData !== 'true' &&
  // [
  //       {
  //         key: generateKey(),
  //         // '',
  //         UoM_id:
  //           formData1?.ApprovedStrengths === undefined
  //             ? getFormDetailsByIdData?.ref_product_detail?.UoM?.id
  //             : formData1?.ApprovedStrengths,
  //         trade_dress: '',
  //         size: '',
  //         shape: '',
  //         colour: '',
  //         making: '',
  //         score: ''
  //       } // Initial empty row
  //     ]
  // console.log('formData1?.ApprovedStrengths', formData1?.ApprovedStrengths);
  // console.log('dataSource', dataSource);
  useEffect(() => {
    //console.log('storedData === false:', storedData);
    if (
      storedData === 'false' ||
      getFormDetailsByIdData?.ref_product_detail?.ref_strength?.length === 0 ||
      (getFormDetailsByIdData?.ref_product_detail?.ref_strength?.length > 0 &&
        storedData === 'true' &&
        formData1?.ApprovedStrengths !== undefined) ||
      (getFormDetailsByIdData?.ref_product_detail?.ref_strength?.length > 0 &&
        draft_PIF_form_start === 'true' &&
        formData1?.ApprovedStrengths !== undefined)
    ) {
      setDataSource([
        {
          key: generateKey(),
          UoM_id:
            formData1?.ApprovedStrengths === undefined
              ? getFormDetailsByIdData?.ref_product_detail?.ref_UoM?.id
              : formData1?.ApprovedStrengths,
          trade_dress: '',
          size: '',
          shape: '',
          colour: '',
          making: '',
          score: '',
          strength_value: ''
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [
    getFormDetailsByIdData,
    storedData,
    formData1?.ApprovedStrengths,
    draft_PIF_form_start
  ]);

  useEffect(() => {
    //console.log('storedData === true:', storedData);
    if (
      (getFormDetailsByIdData?.ref_product_detail?.ref_strength?.length > 0 &&
        storedData === 'true' &&
        formData1?.ApprovedStrengths === undefined) ||
      (getFormDetailsByIdData?.ref_product_detail?.ref_strength?.length > 0 &&
        draft_PIF_form_start === 'true' &&
        formData1?.ApprovedStrengths === undefined)
    ) {
      const newData =
        getFormDetailsByIdData?.ref_product_detail?.ref_strength.map(
          (item) => ({
            key: item.id.toString(),
            id: item.id,
            UoM_id: item?.ref_strength_UoM?.id,
            trade_dress: item.trade_dress,
            size: item.size,
            shape: item.shape,
            colour: item.colour,
            making: item.making,
            score: item.score,
            strength_value: item.strength_value
            // is_active: item.is_active
          })
        );
      setDataSource(newData);
    }
  }, [getFormDetailsByIdData, storedData === 'true', draft_PIF_form_start]);

  function generateKey() {
    return Math.random().toString(36).substr(2, 10);
  }

  useEffect(() => {
    form.setFieldsValue({
      active_ingredient:
        getFormDetailsByIdData?.ref_product_detail?.active_ingredient,
      product_name: getFormDetailsByIdData?.ref_product_detail?.product_name,
      api: getFormDetailsByIdData?.ref_product_detail?.API,
      // ref_prod_details:
      //   getFormDetailsByIdData?.ref_product_details?.ref_prod_detail,
      ref_prod_details:
        getFormDetailsByIdData?.ref_product_detail?.ref_prod_detail,
      innovator: getFormDetailsByIdData?.ref_product_detail?.innovator,
      brand_name: getFormDetailsByIdData?.ref_product_detail?.brand_name,
      ma_number: getFormDetailsByIdData?.ref_product_detail?.MA_number,
      approval_date:
        getFormDetailsByIdData?.ref_product_detail?.approval_date === null
          ? ''
          : moment(getFormDetailsByIdData?.ref_product_detail?.approval_date),
      MarketSegment:
        getFormDetailsByIdData?.ref_product_detail?.market_segment?.id,
      KeyChannels: getFormDetailsByIdData?.ref_product_detail?.key_channel,
      TherapeuticCategory:
        getFormDetailsByIdData?.ref_product_detail?.therapeutic_category?.id,
      ApprovedIndication:
        getFormDetailsByIdData?.ref_product_detail?.approved_indication,
      RouteAdminisration:
        getFormDetailsByIdData?.ref_product_detail?.administration_route?.id,
      DosageForm: getFormDetailsByIdData?.ref_product_detail?.dosage_form_id,
      ReleaseType: getFormDetailsByIdData?.ref_product_detail?.release_type,
      RSstrength: getFormDetailsByIdData?.ref_product_detail?.rs_strength,
      ParkConfiguration:
        getFormDetailsByIdData?.ref_product_detail?.pack_config,
      PackSize: getFormDetailsByIdData?.ref_product_detail?.pack_size,
      SecondaryPackdetails:
        getFormDetailsByIdData?.ref_product_detail?.secondary_pack_detail,
      ApprovedStrengths:
        getFormDetailsByIdData?.ref_product_detail?.ref_UoM?.id,
      BCSClass: getFormDetailsByIdData?.ref_product_detail?.bcs_class,
      dea_category: getFormDetailsByIdData?.ref_product_detail?.dea_category,
      BioGuidance: getFormDetailsByIdData?.ref_product_detail?.bio_guidance,
      DosageRegimen: getFormDetailsByIdData?.ref_product_detail?.dosage_regimen,
      remarks: getFormDetailsByIdData?.ref_product_detail?.remarks,
      salt: getFormDetailsByIdData?.ref_product_detail?.salt,
      api_form_details:
        getFormDetailsByIdData?.ref_product_detail?.api_form_details,
      polymorphic_form:
        getFormDetailsByIdData?.ref_product_detail?.polymorphic_form,
      api_type: getFormDetailsByIdData?.ref_product_detail?.api_type
      //initiation_date: getFormDetailsByIdData?.ref_product_detail?.initiation_date,
    });
  }, [getFormDetailsByIdData]);
  useEffect(() => {
    form.setFieldsValue({
      initiation_date: moment() || ''
    });
  });
  // useEffect(() => {
  //   const payload = {};
  //   if (tagSearch) payload['search'] = tagSearch;
  //   dispatch(getCategoryList(payload));
  // }, [tagSearch]);

  // const debounceFetcher = useMemo(() => {
  //   const loadOptions = (tagSearch) => {
  //     setTagSearch(tagSearch);
  //   };
  //   return debounce(loadOptions, 400);
  // }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    if (slug === 'TherapeuticCategory') {
      // setIsOthers_therapeuticCategory(value);
      const therapeutic_categoryData_others =
        dropDown_therapeutic_categoryData?.find((item) => item.id === value);

      setIsOthers_therapeuticCategory(
        therapeutic_categoryData_others?.name === 'Others' ? 'Others' : false
      );
    }
    if (slug === 'DosageForm') {
      // setIsOthers_dosageForm(value);
      const dosage_others = dropDown_dosage_formData?.find(
        (item) => item.id === value
      );

      setIsOthers_dosageForm(
        dosage_others?.name === 'Others' ? 'Others' : false
      );
    }
    if (slug === 'ApprovedStrengths') {
      // setIsOthers_approvedStrengths(value);
      const approved_strengths_others = dropDown_approved_strengthsData?.find(
        (item) => item.id === value
      );

      setIsOthers_approvedStrengths(
        approved_strengths_others?.name === 'Others' ? 'Others' : false
      );
    }

    setFormData1({
      ...formData1,
      [slug]: value ? value : null
    });
  };

  const onFinish = () => {
    // if (dataSource.length < 1 || hasEmptyValue(dataSource, ['size'])) {
    //   message.error('Approved strengths table must have at least 1 entry');
    //   setTableError(true);
    //   return;
    // } else {
    //   setTableError(false);
    console.log('...formdata', formData1);
    const payload = {
      edit_type: 'optional',
      pif_id: PIF_form_id,
      product_name:
        formData1?.product_name === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.product_name
          : formData1?.product_name,
      active_ingredient:
        formData1?.active_ingredient === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.active_ingredient
          : formData1?.active_ingredient,
      API:
        formData1?.api === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.API
          : formData1?.api,
      api_type:
        formData1?.api_type === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.api_type
          : formData1?.api_type,
      api_form_details:
        formData1?.api_form_details === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.api_form_details
          : formData1?.api_form_details,
      polymorphic_form:
        formData1?.polymorphic_form === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.polymorphic_form
          : formData1?.polymorphic_form,

      ref_prod_detail:
        formData1?.ref_prod_details === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.ref_prod_detail
          : formData1?.ref_prod_details,
      innovator:
        formData1?.innovator === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.innovator
          : formData1?.innovator,
      brand_name:
        formData1?.brand_name === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.brand_name
          : formData1?.brand_name,
      MA_number:
        formData1?.ma_number === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.MA_number
          : formData1?.ma_number,
      approval_date:
        formData1?.approval_date === undefined
          ? moment(
              getFormDetailsByIdData?.ref_product_detail?.approval_date
            ).format('YYYY-MM-DD')
          : moment(formData1?.approval_date).format('YYYY-MM-DD'),
      // market_segment_id:
      //   formData1?.MarketSegment === undefined
      //     ? getFormDetailsByIdData?.ref_product_detail?.market_segment?.id
      //     : isOthers_marketSegment === 'Others'
      //     ? ''
      //     : formData1?.MarketSegment,
      // market_segment_others:
      //   isOthers_marketSegment === 'Others'
      //     ? [formData1?.market_segment_others]
      //     : [],
      key_channel:
        formData1?.KeyChannels === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.key_channel
          : formData1?.KeyChannels,
      salt:
        formData1?.salt === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.salt
          : formData1?.salt,
      therapeutic_category_id:
        formData1?.TherapeuticCategory === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.therapeutic_category?.id
          : isOthers_therapeuticCategory === 'Others'
          ? ''
          : formData1?.TherapeuticCategory,
      therapeutic_category_others:
        isOthers_therapeuticCategory === 'Others'
          ? [formData1?.therapeutic_category_others]
          : [],
      approved_indication:
        formData1?.ApprovedIndication === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.approved_indication
          : formData1?.ApprovedIndication,
      // administration_route_id:
      //   formData1?.RouteAdminisration === undefined
      //     ? getFormDetailsByIdData?.ref_product_detail?.administration_route
      //         ?.id
      //     : isOthers_routeAdministration === 'Others'
      //     ? ''
      //     : formData1?.RouteAdminisration,
      // administration_route_others:
      //   isOthers_routeAdministration === 'Others'
      //     ? [formData1?.route_administration_others]
      //     : [],
      dosage_form_id:
        formData1?.DosageForm === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.dosage_form_id
          : isOthers_dosageForm === 'Others'
          ? ''
          : formData1?.DosageForm,
      dosage_form_others:
        isOthers_dosageForm === 'Others' ? [formData1?.dosage_form_others] : [],
      release_type:
        formData1?.ReleaseType === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.release_type
          : formData1?.ReleaseType,
      rs_strength:
        formData1?.RSstrength === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.rs_strength
          : formData1?.RSstrength,
      pack_config:
        formData1?.ParkConfiguration === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.pack_config
          : formData1?.ParkConfiguration,
      pack_size:
        formData1?.PackSize === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.pack_size
          : formData1?.PackSize,
      secondary_pack_detail:
        formData1?.SecondaryPackdetails === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.secondary_pack_detail
          : formData1?.SecondaryPackdetails,
      UoM_id:
        formData1?.ApprovedStrengths === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.ref_UoM?.id
          : isOthers_approvedStrengths === 'Others'
          ? ''
          : formData1?.ApprovedStrengths,
      UoM_others:
        isOthers_approvedStrengths === 'Others'
          ? [formData1?.approved_strengths_others]
          : [],
      bcs_class:
        formData1?.BCSClass === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.bcs_class
          : formData1?.BCSClass,
      dea_category:
        formData1?.dea_category === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.dea_category
          : formData1?.dea_category,
      bio_guidance:
        formData1?.BioGuidance === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.bio_guidance
          : formData1?.BioGuidance,
      dosage_regimen:
        formData1?.DosageRegimen === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.dosage_regimen
          : formData1?.DosageRegimen,
      remarks:
        formData1?.remarks === undefined
          ? getFormDetailsByIdData?.ref_product_detail?.remarks
          : formData1?.remarks,
      ref_strength_list: dataSource?.map((obj) => ({
        ...obj
        //is_active: true,
        // UoM_id:
        //   formData1?.ApprovedStrengths === undefined
        //     ? getFormDetailsByIdData?.ref_product_detail?.UoM?.id
        //     : formData1?.ApprovedStrengths
      }))
    };
    console.log('....payload:', payload);
    // console.log('payload::::', payload);
    dispatch(createUpdateRefProductDetail_form({ payload }))?.then(function (
      response
    ) {
      if (response?.payload?.success) {
        //history.push(`/admin-quizzes`);

        // localStorage.setItem('ref_table', true);
        //setActiveRefTable(true);
        setSelectedTab(3);
      } else {
        //message.error('Please fill required field');
        //console.log('response?.payload?.error', response?.payload?.error);
      }
    });
    // }
  };

  //const defaultDate = moment().format('MMM DD, YYYY');
  return (
    <div
      className={`${styles.page_container}`}
      style={{
        marginBlock: xs ? '0.5rem' : sm ? '0.7rem' : '1.2rem',
        marginInline: xs ? 0 : sm ? '0.5rem' : '1.3rem'
      }}
    >
      <Spin spinning={getFormDetailsByIdLoading}>
        <div className={`${styles.page_title}`}>
          <Typography.Title
            level={2}
            style={{ fontSize: xs ? '1rem' : sm ? '1.1rem' : '1.2rem' }}
            className={styles.title_label}
          >
            Reference Product Details
          </Typography.Title>
        </div>

        <Form
          form={form}
          name="PIF_RD_Information"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Active Ingredient"
                name="active_ingredient"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter active ingredient"
                  onChange={(e) =>
                    handleFormDataOnChange('active_ingredient', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Product Name"
                name="product_name"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter product name"
                  onChange={(e) =>
                    handleFormDataOnChange('product_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Salt"
                name="salt"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter salt"
                  onChange={(e) =>
                    handleFormDataOnChange('salt', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="API Form Details"
                name="api_form_details"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter API form details"
                  onChange={(e) =>
                    handleFormDataOnChange('api_form_details', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Polymorphic Form"
                name="polymorphic_form"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter polymorphic form"
                  onChange={(e) =>
                    handleFormDataOnChange('polymorphic_form', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="API Type"
                name="api_type"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter API type"
                  onChange={(e) =>
                    handleFormDataOnChange('api_type', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Brand Name"
                name="brand_name"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter Brand name"
                  onChange={(e) =>
                    handleFormDataOnChange('brand_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Innovator"
                name="innovator"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter Innovator"
                  onChange={(e) =>
                    handleFormDataOnChange('innovator', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Therapeutic Category"
                name="TherapeuticCategory"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceTherapeutic_category}
                  placeholder="Select therapeutic category"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('TherapeuticCategory', value)
                  }
                >
                  {dropDown_therapeutic_categoryData.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_therapeuticCategory === 'Others' && (
              <Col span={24} md={{ span: 8 }}>
                <FormItem
                  label="Therapeutic Category - Others"
                  name="therapeutic_category_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter therapeutic category"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'therapeutic_category_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Approved Dosage Forms"
                name="DosageForm"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceDosage_form}
                  placeholder="Select approved dosage form"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('DosageForm', value)
                  }
                >
                  {dropDown_dosage_formData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_dosageForm === 'Others' && (
              <Col span={24} md={{ span: 8 }}>
                <FormItem
                  label="Dosage Form - Others"
                  name="dosage_form_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter dosage form"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'dosage_form_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Approved Strengths (UoM)"
                name="ApprovedStrengths"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  disabled
                  showSearch
                  onSearch={debounceApproved_strengths}
                  placeholder="Select Proposed strengths "
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('ApprovedStrengths', value)
                  }
                >
                  {dropDown_approved_strengthsData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_approvedStrengths === 'Others' && (
              <Col span={24} md={{ span: 8 }}>
                <FormItem
                  label="Approved Strengths(UoM) - Others"
                  name="approved_strengths_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter Proposed strengths"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'approved_strengths_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>

          <Row
            gutter={16}
            style={{ marginBottom: '15px' }}
            className={`${styles.row_2}`}
          >
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
              style={
                tableError
                  ? {
                      border: '1px solid red',
                      backgroundColor: '#f546460d',
                      borderRadius: '0.5rem'
                    }
                  : {}
              }
            >
              <Panel
                header={
                  <span style={{ fontWeight: 500, fontSize: '14px' }}>
                    Approved Strengths
                    <span style={{ color: 'red', fontSize: '16px' }}>*</span>
                  </span>
                }
                //extra="Add email message"
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />

                <APIDynamicTable
                  setError={setTableError}
                  setDataSource={setDataSource}
                  dataSource={dataSource}
                  generateKey={generateKey}
                  formData1={formData1}
                  getFormDetailsByIdData={getFormDetailsByIdData}
                  storedData={storedData}
                  dropDown_approved_strengthsData={
                    dropDown_approved_strengthsData
                  }
                  edit={'optional'}
                />
              </Panel>
            </CustomCollapse>
          </Row>

          <Row gutter={16} className={`${styles.row_2}`}>
            <Col span={24}>
              <FormItem
                label="BCS Class"
                name="BCSClass"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter BCS class"
                  onChange={(e) =>
                    handleFormDataOnChange('BCSClass', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_2}`}>
            <Col span={24}>
              <FormItem
                label="DEA Category"
                name="dea_category"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter DEA category"
                  onChange={(e) =>
                    handleFormDataOnChange('dea_category', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_2}`}>
            <Col span={24}>
              <FormItem
                label="Remarks"
                name="remarks"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input.TextArea
                  rows={3}
                  allowClear
                  placeholder="Enter remarks"
                  onChange={(e) =>
                    handleFormDataOnChange('remarks', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/home`)}
              style={{
                fontSize: xs ? '0.6rem' : sm ? '0.7rem' : '0.9rem',
                paddingInline: xs ? '0.6rem' : sm ? '1rem' : '1.5rem'
              }}
            >
              Cancel
            </CustomButton>
            <FormItem>
              <CustomButton
                loading={createUpdateRefProductDetail_formLoading}
                type="primary"
                htmlType="submit"
                className={styles.post_btn_style}
                style={{
                  fontSize: xs ? '0.6rem' : sm ? '0.7rem' : '0.9rem',
                  paddingInline: xs ? '0.6rem' : sm ? '1rem' : '1.5rem'
                }}
              >
                Next
              </CustomButton>
            </FormItem>
          </div>
        </Form>
      </Spin>{' '}
    </div>
  );
};

export default Reference_product_details;
