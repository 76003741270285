import React, { useState, useEffect } from 'react';
import { Form, Input, Table, Space, Select, message } from 'antd';
import styles from './rd_Budget_table.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';
import { getNullValue } from 'utilities/helpers';
import { formatNumberToIndianSystem } from 'utilities/helpers';

const { Option } = Select;

const RD_Budget_table = ({
  dataSource1,
  setDataSource1,
  generateKey1,
  //storedData1,
  dropDown_budget_itemsData,
  totalRow,
  setTotalRow,
  debounceBudget_items,
  edit,
  setError
  //getFormDetailsByIdData
}) => {
  console.log('dataSource_budget_$$: ', dataSource1);

  const [dropDown_budget_itemsDataNew, setDropDown_budget_itemsDataNew] =
    useState([]);

  useEffect(() => {
    const newDropdown = dropDown_budget_itemsData?.filter(
      (item) => item?.id !== 20
    );
    setDropDown_budget_itemsDataNew(newDropdown);
  }, [dropDown_budget_itemsData]);

  //console.log('dropDown_budget_itemsDataNew: ', dropDown_budget_itemsDataNew);

  // Function to add a new row
  const addRow = () => {
    const emptyKeys = getNullValue(dataSource1.at(-1), ['description']);
    if (emptyKeys && edit !== 'optional') {
      const msg = `${emptyKeys} are required fields`;
      message.error(msg);
      setError(true);
      return;
    }
    setError(false);
    const newData = {
      key: generateKey1(),
      item_id: '',
      description: '',
      project_cost_inr_lakhs_us: '',
      project_cost_inr_lakhs_eu: '',
      project_cost_inr_lakhs: '0'
      //is_active: true
    };
    setDataSource1([...dataSource1, newData]);
  };

  const calculateTotalRow = () => {
    //let totalDescription = 0;
    let totalCostUS = 0;
    let totalCostEU = 0;
    let totalCost = 0;

    dataSource1?.forEach((item) => {
      // if (item?.is_active) {
      // totalDescription += parseFloat(item?.description || 0);
      totalCostUS += parseFloat(item?.project_cost_inr_lakhs_us || 0);
      totalCostEU += parseFloat(item?.project_cost_inr_lakhs_eu || 0);
      totalCost += parseFloat(item?.project_cost_inr_lakhs || 0);
      // }
    });

    const newTotalRow = {
      key: 'total',
      item_id: 20,
      description: '', //totalDescription?.toFixed(2),
      project_cost_inr_lakhs_us: totalCostUS?.toFixed(2),
      project_cost_inr_lakhs_eu: totalCostEU?.toFixed(2),
      project_cost_inr_lakhs: totalCost?.toFixed(2)
      // is_active: true // To prevent deletion
    };

    setTotalRow(newTotalRow);
  };

  useEffect(() => {
    calculateTotalRow();
  }, [dataSource1]);

  // Function to delete a row by key
  const deleteRow = (key) => {
    const updatedData = dataSource1?.filter((item) => item.key !== key);
    setDataSource1(updatedData);
    // if (storedData1 === 'true') {
    //   const updatedDataSource = dataSource1?.map((item) =>
    //     item.key === key ? { ...item, is_active: false } : item
    //   );
    //   setDataSource1(updatedDataSource);

    //   // Enable the deleted option in the dropdown
    //   // const deletedRow = dataSource1.find((item) => item.key === key);
    //   // if (deletedRow) {
    //   //   // Enable the deleted option in the dropdown
    //   //   setDisabledOptions(
    //   //     disabledOptions.filter((id) => id !== deletedRow.stages)
    //   //   );
    //   // }
    // } else {
    //   const updatedData = dataSource1?.filter((item) => item.key !== key);
    //   setDataSource1(updatedData);

    //   // Enable the deleted option in the dropdown
    //   // const deletedRow = dataSource1.find((item) => item.key === key);
    //   // if (deletedRow) {
    //   //   // Enable the deleted option in the dropdown
    //   //   setDisabledOptions(
    //   //     disabledOptions.filter((id) => id !== deletedRow.stages)
    //   //   );
    //   // }
    //}
  };

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item_id',
      width: '200px',
      render: (_, record) => (
        <FormItem
          // label="Approved Strengths"
          // name="ApprovedStrengths"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          className={`${styles.select_styles}`}
        >
          <Select
            disabled={edit === 'optional'}
            showSearch
            onSearch={debounceBudget_items}
            className={styles.select_styles}
            popupClassName={styles.select_dropdown}
            placeholder="Select item"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            defaultValue={record?.item_id}
            onChange={(value) => handleCellChange(record.key, 'item_id', value)}
          >
            {dropDown_budget_itemsDataNew?.map((item, index) => {
              return (
                <Option value={item?.id} key={index}>
                  {item.name}{' '}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '160px',
      render: (_, record) => (
        <Form.Item
          // name={['us', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            value={record.description}
            // type="number"
            // step="0.01"
            // min={0}
            onChange={(e) =>
              handleCellChange(record.key, 'description', e.target.value)
            }
          />
        </Form.Item>
      )
    },
    {
      title: 'Project Cost in INR (US)',
      dataIndex: 'project_cost_inr_lakhs_us',
      width: '160px',
      render: (_, record) => (
        <Form.Item
          // name={['project_cost_inr_lakhs_us', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            type="number"
            step="0.01"
            min={0}
            value={record.project_cost_inr_lakhs_us}
            onChange={(e) =>
              handleCellChange(
                record.key,
                'project_cost_inr_lakhs_us',
                e.target.value
              )
            }
          />
        </Form.Item>
      )
    },
    {
      title: 'Project Cost in INR (EU)',
      dataIndex: 'project_cost_inr_lakhs_eu',
      width: '160px',
      render: (_, record) => (
        <Form.Item
          //  name={['row', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            type="number"
            step="0.01"
            min={0}
            value={record.project_cost_inr_lakhs_eu}
            onChange={(e) =>
              handleCellChange(
                record.key,
                'project_cost_inr_lakhs_eu',
                e.target.value
              )
            }
          />
        </Form.Item>
      )
    },
    {
      title: 'Total Project Cost in INR',
      dataIndex: 'project_cost_inr_lakhs',
      width: '160px',
      render: (_, record) => (
        <Form.Item
          //  name={['row', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          {console.log(
            'record.project_cost_inr_lakhs',
            record.project_cost_inr_lakhs
          )}
          <Input
            disabled
            // type="number"
            // step="0.01"
            // min={0}
            value={formatNumberToIndianSystem(
              parseFloat(record.project_cost_inr_lakhs)
            )}
            // onChange={(e) =>
            //   handleCellChange(
            //     record.key,
            //     'project_cost_inr_lakhs',
            //     e.target.value
            //   )
            // }
          />
        </Form.Item>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Space
            project_cost_inr_lakhs_us="middle"
            style={{
              display: 'flex',
              justifyContent: 'center' /* paddingLeft: '16px' */
            }}
          >
            <PlusOutlined
              className={`${styles.plusIcon}`}
              onClick={edit === 'optional' ? '' : addRow}
            />

            {dataSource1.length > 1 ? (
              <DeleteOutlined
                className={`${styles.deleteIcon}`}
                onClick={() =>
                  edit === 'optional' ? '' : deleteRow(record.key)
                }
              />
            ) : null}
          </Space>
        </FormItem>
      )
    }
  ];

  // Function to handle changes in cell values
  // const handleCellChange = (key, dataIndex, value) => {
  //   const newData = [...dataSource1];
  //   console.log('newData', newData);
  //   const index = newData?.findIndex((item) => key === item?.key);
  //   if (index > -1) {
  //     const item = newData[index];
  //     newData[index] = { ...item, [dataIndex]: value };
  //     setDataSource1(newData);
  //   }
  // };

  const handleCellChange = (key, dataIndex, value) => {
    const newData = [...dataSource1];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData[index] = { ...item, [dataIndex]: value };

      // Calculate project_cost_inr_lakhs whenever project_cost_inr_lakhs_us or project_cost_inr_lakhs_eu changes
      const projectCostUS = parseFloat(
        newData[index].project_cost_inr_lakhs_us || 0
      );
      const projectCostEU = parseFloat(
        newData[index].project_cost_inr_lakhs_eu || 0
      );
      newData[index].project_cost_inr_lakhs = (
        projectCostUS + projectCostEU
      ).toFixed(2);

      setDataSource1(newData);
    }
  };

  //const filteredDataSource1 = dataSource1?.filter((item) => item?.is_active);

  // console.log('dataSource1:::', dataSource1);
  // console.log('totalRow:::', totalRow);
  //console.log('totalRow_description:::', totalRow?.description);

  return (
    <div>
      <Form>
        <Table
          dataSource={dataSource1}
          // {
          //   storedData1 === 'true' ? filteredDataSource1 : dataSource1
          // }
          // dataSource={
          //   storedData1 === 'true'
          //     ? dataSource1.filter((item) => item.is_active)
          //     : dataSource1.concat(totalRow ? [totalRow] : [])
          // }

          columns={columns}
          pagination={false}
          scroll={{
            x: 600
          }}
          className={`${styles.table_styles}`}
          footer={() => (
            <tr className={styles.footerRow}>
              <Space>
                <td colSpan={2} className={styles.footerCell_1st}>
                  <Input value={'Total Development Cost'} />
                </td>

                <td className={styles.footerCell}>
                  <Input value={'' /* totalRow?.description */} disabled />
                </td>
                <td className={styles.footerCell}>
                  <Input
                    value={formatNumberToIndianSystem(
                      parseFloat(totalRow?.project_cost_inr_lakhs_us)
                    )}
                  />
                </td>
                <td className={styles.footerCell}>
                  <Input
                    value={formatNumberToIndianSystem(
                      parseFloat(totalRow?.project_cost_inr_lakhs_eu)
                    )}
                  />
                </td>
                <td className={styles.footerCell}>
                  <Input
                    value={formatNumberToIndianSystem(
                      parseFloat(totalRow?.project_cost_inr_lakhs)
                    )}
                  />
                </td>
                {/* <td className={styles.footerCell}></td> */}
              </Space>
            </tr>
          )}
        />
      </Form>
    </div>
  );
};

export default RD_Budget_table;
