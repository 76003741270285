import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { forgetPass, resetPass, signin, signout } from './api';
import LocalStorage from 'utilities/localStorage';

const initialState = {
  loading: false,
  success: false,
  accessToken: '',
  user: null
};

const actions = {
  LOGIN: 'login/LOGIN',
  FORGOT_PASS: 'login/FORGOT_PASS',
  RESET_PASS: 'login/RESET_PASS',
  LOGOUT: 'login/LOGOUT'
};

export const login = createAsyncThunk(actions.LOGIN, async (payload) => {
  const response = await signin(payload);
  if (response?.accessToken) {
    const { accessToken, user_details } = response;
    let newUser = {
      accessToken,
      user_details
    };

    LocalStorage.setItem('granules_PIF', JSON.stringify(newUser));
  }

  return response;
});

export const sendPasswordResetLink = createAsyncThunk(
  actions.FORGOT_PASS,
  async (payload) => {
    const response = await forgetPass(payload);
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  actions.RESET_PASS,
  async (payload) => {
    const response = await resetPass(payload);
    return response;
  }
);

export const logoutUser = createAsyncThunk(actions.LOGOUT, async (payload) => {
  const response = await signout(payload);
  return response;
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLoginDetails: (state) => {
      state.accessToken = '';
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    ///////signIn//////////////
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { accessToken, user } = action.payload;
        state.loading = false;
        state.accessToken = accessToken;
        state.user = user;

        if (accessToken) {
          message.success(action.payload?.message);
        } else {
          message.error(action.payload?.message);
        }
      })
      .addCase(login.rejected, (state, action) => {
        const { msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    //.........sign out..............
    builder.addCase(logoutUser.fulfilled, async (state, action) => {
      console.log('logout payload::', action.payload);
      if (action.payload.success) {
        await message.success(action.payload?.message);
        window.location.reload();
      } else {
        message.error(action.payload?.message);
      }
    });

    //.........send password reset link..............
    builder
      .addCase(sendPasswordResetLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendPasswordResetLink.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.success) {
          // message.success(action.payload?.message);
        } else {
          message.error(action.payload?.message);
        }
      })
      .addCase(sendPasswordResetLink.rejected, (state, action) => {
        const { msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    //.........reset password..............
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.success) {
          message.success(action.payload?.message);
          state.success = true;
        } else {
          state.success = false;
          message.error(action.payload?.message);
        }
      })
      .addCase(resetPassword.rejected, (state, action) => {
        const { msg } = action.error;

        state.loading = false;
        state.success = false;

        message.error(msg);
      });
  }
});

export const { resetLoginDetails } = loginSlice.actions;

export const logout = () => (dispatch) => {
  dispatch(logoutUser());
  localStorage.clear();
  dispatch(resetLoginDetails());
  // window.location.reload();
};

export default loginSlice.reducer;
