import React from 'react';
import Edit_PIF_FD_FormPage from './components/edit_PIF_FD_FormPage';
import CustomLayout from '@/common/Layout';

const Edit_PIF_FD_Form = () => {
  return (
    <CustomLayout sider={false}>
      <Edit_PIF_FD_FormPage />
    </CustomLayout>
  );
};

export default Edit_PIF_FD_Form;
