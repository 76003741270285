import React from 'react';
import PIF_FD_FormPage from './components/PIF_FD_FormPage';
import CustomLayout from '@/common/Layout';

const PIF_FD_Form = () => {
  return (
    <CustomLayout sider={false}>
      <PIF_FD_FormPage />
    </CustomLayout>
  );
};

export default PIF_FD_Form;
