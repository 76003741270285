import apiClient from 'utilities/apiClient';

export const pif_FD_DropDownData = (payload) => {
  return apiClient.get(apiClient.Urls.API.pif_API_DropDownData, payload, true);
  //return apiClient.get(`${apiClient.Urls.baseUrl}/${url}`, payload, true);
};
export const getFormDetailsById = (payload) => {
  return apiClient.get(apiClient.Urls.API.getFormDetailsById, payload, true);
};
/* export const requests = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.requester}/${url}`, payload, true);
}; */

export const createUpdateFdInfo = (payload) => {
  return apiClient.post(
    apiClient.Urls.API.createUpdateAPIInfo,
    payload,
    true
    // 'file'
  );
};
export const createUpdateRefProductDetail = (payload) => {
  return apiClient.post(
    apiClient.Urls.API.createUpdateRefProductDetail,
    payload,
    true
    // 'file'
  );
};

export const createUpdatePropProductDetail = (payload) => {
  return apiClient.post(
    apiClient.Urls.API.createUpdatePropProductDetail,
    payload,
    true
    // 'file'
  );
};

export const createUpdateIPDetail = (payload) => {
  return apiClient.post(
    apiClient.Urls.API.createUpdateIPDetail,
    payload,
    true
    // 'file'
  );
};

export const createUpdateRDDetail = (payload) => {
  return apiClient.post(
    apiClient.Urls.API.createUpdateRDDetail,
    payload,
    true
    // 'file'
  );
};
export const createUpdateCompetitiveScenario = (payload) => {
  return apiClient.post(
    apiClient.Urls.API.createUpdateCompetitiveScenario,
    payload,
    true
    // 'file'
  );
};

export const createUpdateBJ = (payload) => {
  return apiClient.post(
    apiClient.Urls.API.createUpdateBJ,
    payload,
    true
    // 'file'
  );
};
export const createUpdateMarketData = (payload) => {
  return apiClient.post(
    apiClient.Urls.API.createUpdateMarketData,
    payload,
    true
    // 'file'
  );
};
export const signatories = (payload) => {
  return apiClient.get(apiClient.Urls.API.signatories, payload, true);
};
export const approvers = (payload) => {
  return apiClient.get(apiClient.Urls.API.approvers, payload, true);
};
export const createUpdateApprovers = (payload) => {
  return apiClient.post(
    apiClient.Urls.API.createUpdateApprovers,
    payload,
    true
    // 'file'
  );
};
export const uploadAttachments = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.API.uploadAttachments}`,
    payload,
    true,
    'file'
  );
};

export const deleteUploadedDocs = (payload) => {
  return apiClient.delete(
    `${apiClient.Urls.API.deleteDocuments}`,
    payload,
    true
  );
};

export const initiateFdFormApi = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.API.initiateFdFormApi}`,
    payload,
    true
    // 'file'
  );
};
