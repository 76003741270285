import styles from './stepper.module.less';
import React, { useEffect, useState } from 'react';
import { Space, Steps, Row, Col, Input } from 'antd';
//import { PlusCircleFilled, DeleteOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';
//import miss_icon from '../../images/miss_icon.svg';

//const { Option } = Select;
const { Step } = Steps;

const ApproverStepper = ({ dropDown_approversData }) => {
  //console.log('dropDown_approversData:', dropDown_approversData);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (dropDown_approversData) {
      setFields(
        dropDown_approversData.map((item) => ({
          key: item.id.toString(),
          user_id: `${item.first_name} ${item.last_name}`
        }))
      );
    }
  }, [dropDown_approversData]);

  // function generateKey_bs() {
  //   return Math.random().toString(36).substr(2, 10);
  // }

  // const addField = () => {
  //   const newField = {
  //     key: generateKey_bs(),
  //     user_id: ''
  //   };
  //   setFields([...fields, newField]);
  // };

  // const deleteField = (key) => {
  //   const updatedFields = fields.filter((field) => field.key !== key);
  //   setFields(updatedFields);
  // };

  const handleInputChange = (key, value) => {
    const updatedFields = fields.map((field) =>
      field.key === key ? { ...field, user_id: value } : field
    );
    setFields(updatedFields);
  };

  return (
    <Space
      direction="vertical"
      size={5}
      className={`${styles.Space_container}`}
    >
      <Row className={`${styles.row_container}`}>
        <Col span={2}>
          <Steps direction="vertical" size="small">
            {fields.map((field, index) => (
              <Step
                key={index}
                //title={`Step ${index + 1}`}
                status={index === fields.length - 1 ? 'process' : 'wait'}
              />
            ))}
          </Steps>
        </Col>

        <Col span={22}>
          {fields?.map((field, index) => (
            <Row key={index}>
              {/*  <Col span={2}>
                <img
                  src={miss_icon}
                  alt="miss_icon"
                  style={{ marginTop: '7px' }}
                />
              </Col> */}
              <Col span={12} style={{ marginLeft: '10px' }}>
                <FormItem
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    disabled
                    value={field.user_id}
                    onChange={(e) =>
                      handleInputChange(field.key, e.target.value)
                    }
                    placeholder="Approver Name"
                  />{' '}
                </FormItem>
              </Col>
              {/* <Col span={4}>
                {fields.length > 1 && (
                  <span
                    //style={{ marginLeft: '10px' }}
                    className={`${styles.container_deleteIcon}`}
                  >
                    <DeleteOutlined
                      onClick={() => deleteField(field.key)}
                      className={`${styles.inputList_deleteIcon}`}
                    />
                  </span>
                )}
              </Col> */}
            </Row>
          ))}
        </Col>
      </Row>
      {/* <div onClick={addField} className={`${styles.add_container}`}>
        <PlusCircleFilled className={`${styles.add_plusIcon}`} />
        <span className={`${styles.add_plusTex}`}>Add approver</span>
      </div> */}
    </Space>
  );
};

export default ApproverStepper;
