import React /* , { useState, useEffect }  */ from 'react';
import { Form, Input, Table, Space } from 'antd';
import styles from './dynamicTable_Prop_prod.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';

const API_Source_Table = ({
  dataSource,
  setDataSource,
  generateKey,
  // formData1,
  // getFormDetailsByIdData,
  edit
}) => {
  console.log('dataSource_PROPOSED: ', dataSource);

  const addRow = () => {
    const newData = {
      key: generateKey(),
      api_name: '',
      source_internal: '',
      source_external: ''
    };
    console.log('newData@@@@@@: ', newData);
    setDataSource([...dataSource, newData]);
  };

  // Function to delete a row by key
  const deleteRow = (key) => {
    const updatedData = dataSource?.filter((item) => item.key !== key);
    setDataSource(updatedData);
  };

  const columns = [
    {
      title: 'Name of the API',
      dataIndex: 'api_name',
      //width: '50px',
      render: (_, record) => (
        <FormItem
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            value={record.api_name}
            onChange={(e) =>
              handleCellChange(record.key, 'api_name', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: 'API Source - Internal',
      dataIndex: 'source_internal',
      render: (_, record) => (
        <FormItem
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            value={record.source_internal}
            onChange={(e) =>
              handleCellChange(record.key, 'source_internal', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: 'API Source - External',
      dataIndex: 'source_external',
      editable: true,
      render: (_, record) => (
        <FormItem
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled={edit === 'optional'}
            value={record.source_external}
            onChange={(e) =>
              handleCellChange(record.key, 'source_external', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Space
            size="middle"
            style={{
              display: 'flex',
              justifyContent: 'center' /* paddingLeft: '16px' */
            }}
          >
            <PlusOutlined
              className={`${styles.plusIcon}`}
              onClick={edit === 'optional' ? '' : addRow}
            />

            {dataSource.length > 1 ? (
              <DeleteOutlined
                className={`${styles.deleteIcon}`}
                onClick={() =>
                  edit === 'optional' ? '' : deleteRow(record.key)
                }
              />
            ) : null}
          </Space>
        </FormItem>
      )
    }
  ];

  // Function to handle changes in cell values
  const handleCellChange = (key, dataIndex, value) => {
    const newData = [...dataSource];
    console.log('newData', newData);
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData[index] = { ...item, [dataIndex]: value };
      setDataSource(newData);
    }
  };

  //const filteredDataSource = dataSource?.filter((item) => item.is_active);

  return (
    <div>
      <Form>
        <Table
          dataSource={dataSource} //{filteredDataSource} //{dataSource}
          columns={columns}
          pagination={false}
          // scroll={{
          //   x: 1300
          // }}
          className={`${styles.table_styles}`}
        />
      </Form>
    </div>
  );
};

export default API_Source_Table;
