import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  pif_FD_DropDownData,
  createUpdateFdInfo,
  createUpdateRefProductDetail,
  getFormDetailsById,
  createUpdatePropProductDetail,
  createUpdateIPDetail,
  createUpdateRDDetail,
  createUpdateCompetitiveScenario,
  createUpdateMarketData,
  createUpdateBJ,
  signatories,
  approvers,
  createUpdateApprovers,
  uploadAttachments,
  deleteUploadedDocs,
  initiateFdFormApi
} from './api';
//import { history } from '@/app/history';

const initialState = {
  dropDown_GIL_GPILoading: false,
  createUpdateFdInfo_formLoading: false,
  dropDown_GIL_GPIData: [],
  dropDown_primary_marketData: [],
  dropDown_extension_marketData: [],
  dropDown_market_segmentData: [],
  dropDown_therapeutic_categoryData: [],
  dropDown_administration_routeData: [],
  dropDown_dosage_formData: [],
  dropDown_approved_strengthsData: [],
  createUpdateRefProductDetail_formLoading: false,
  getFormDetailsByIdLoading: false,
  getFormDetailsByIdData: [],
  createUpdatePropProductDetail_formLoading: false,
  dropDown_API_sourceData: [],
  dropDown_patent_typeData: [],
  dropDown_countryData: [],
  createUpdateIPDetail_formLoading: false,
  dropDown_exclusivity_typeData: [],
  createUpdateRDDetail_formLoading: false,
  dropDown_budget_itemsData: [],
  dropDown_budget_items_base_best_caseData: [],
  dropDown_region_base_caseData: [],
  dropDown_region_best_caseData: [],
  dropDown_stagesData: [],
  dropDown_currencyData: [],
  createUpdateCompetitiveScenario_formLoading: false,
  dropDown_genericizedData: [],
  dropDown_launch_typeData: [],
  dropDown_filing_typeData: [],
  createUpdateBJ_formLoading: false,
  createUpdateMarketData_formLoading: false,
  dropDown_fy_mastersData: [],
  dropDown_bjc_forecast_itemsData: [],
  dropDown_fpm_itemsData: [],
  dropDown_gfpm_itemsData: [],
  dropDown_product_ratingData: [],
  dropDown_table_headersData: [],
  dropDown_md_ims_data: [],
  dropDown_md_global_data: [],
  dropDown_signatoriesData: [],
  dropDown_approversData: [],
  createUpdateApprovers_formLoading: false,
  uploadAttachments_formLoading: false,
  dropDown_key_row_marketsData: [],
  deleteDocsLoading: false,
  initiateFdFormLoading: false,
  dropDown_pif_typesData: []
};

const actions = {
  DROP_DOWN_GIL_GPI: 'dropDown_GIL_GPI/DROP_DOWN_GIL_GPI',
  DROP_DOWN_PRIMARY_MARKET: 'dropDown_primary_market/DROP_DOWN_PRIMARY_MARKET',
  DROP_DOWN_EXTENSION_MARKRT:
    'dropDown_extension_market/DROP_DOWN_EXTENSION_MARKRT',
  CREATE_UPDATE_FD_INFO: 'createUpdateFdInfo_form/CREATE_UPDATE_FD_INFO',
  DROP_DOWN_MARKET_SEGMENT: 'dropDown_market_segment/DROP_DOWN_MARKET_SEGMENT',
  DROP_DOWN_THERAPEUTIC_CAT:
    'dropDown_therapeutic_category/DROP_DOWN_THERAPEUTIC_CAT',
  DROP_DOWN_ADMINISTRATION_ROUTE:
    'dropDown_administration_route/DROP_DOWN_ADMINISTRATION_ROUTE',
  DROP_DOWN_DOSAGE_FORM: 'dropDown_dosage_form/DROP_DOWN_DOSAGE_FORM',
  DROP_DOWN_APPROVED_STRENGTHS:
    'dropDown_approved_strengths/DROP_DOWN_DOSAGE_FORM',
  CREATE_UPDATE_REF_PRO_DETAILS:
    'createUpdateRefProductDetail_form/CREATE_UPDATE_REF_PRO_DETAILS',
  GET_FORM_DETAILS_BY_ID: 'getFormDetailsById_form/GET_FORM_DETAILS_BY_ID',
  CREATE_UPDATE_PROP_PRO_DETAILS:
    'createUpdatePropProductDetail_form/CREATE_UPDATE_PROP_PRO_DETAILS',
  DROP_DOWN_API_SOURCE: 'dropDown_API_source/DROP_DOWN_API_SOURCE',
  DROP_DOWN_PATENT_TYPE: 'dropDown_patent_type/DROP_DOWN_PATENT_TYPE',
  DROP_DOWN_COUNTRY: 'dropDown_country/ DROP_DOWN_COUNTRY',
  CREATE_UPDATE_IP_DETAILS:
    'createUpdateIPDetail_form/CREATE_UPDATE_IP_DETAILS',
  DROP_DOWN_EXCLISIVITY_TYPE:
    'dropDown_exclusivity_type/DROP_DOWN_EXCLISIVITY_TYPE',
  CREATE_UPDATE_RD_DETAILS:
    'createUpdateRDDetail_form/CREATE_UPDATE_RD_DETAILS',
  DROP_DOWN_STAGES: 'dropDown_stages/ DROP_DOWN_STAGES',
  DROP_DOWN_BUDGET_ITEMS: 'dropDown_budget_items/ DROP_DOWN_BUDGET_ITEMS',
  DROP_DOWN_BUDGET_ITEMS_BASE_BEST_CASE:
    'dropDown_budget_items/ DROP_DOWN_BUDGET_ITEMS_BASE_BEST_CASE',
  DROP_DOWN_REGION_BASE_CASE:
    'dropDown_budget_items/ DROP_DOWN_REGION_BASE_CASE',
  DROP_DOWN_REGION_BEST_CASE:
    'dropDown_budget_items/ DROP_DOWN_REGION_BEST_CASE',
  DROP_DOWN_CURRENCY: 'dropDown_currency/ DROP_DOWN_CURRENCY',
  CREATE_UPDATE_CS_DETAILS:
    'createUpdateCompetitiveScenario_form/CREATE_UPDATE_CS_DETAILS',
  DROP_DOWN_GENERICIZED: 'dropDown_genericized/ DROP_DOWN_GENERICIZED',
  DROP_DOWN_LAUNCH_TYPE: 'dropDown_launch_type/ DROP_DOWN_LAUNCH_TYPE',
  DROP_DOWN_FILING_TYPE: 'dropDown_filing_type/ DROP_DOWN_FILING_TYPE',
  CREATE_UPDATE_BJ: 'createUpdateBJ_form/CREATE_UPDATE_BJ',
  CREATE_UPDATE_MD: 'createUpdateMarketData_form/CREATE_UPDATE_MD',
  DROP_DOWN_FY_MASTERS: 'dropDown_fy_masters/DROP_DOWN_FY_MASTERS',
  DROP_DOWN_BJC_FORECAST_ITEMS:
    'dropDown_bjc_forecast_items/DROP_DOWN_BJC_FORECAST_ITEMS',
  DROP_DOWN_FPM_ITEMS: 'dropDown_fpm_items/DROP_DOWN_FPM_ITEMS',
  DROP_DOWN_GFPM_ITEMS: 'dropDown_gfpm_items/DROP_DOWN_GFPM_ITEMS',
  DROP_DOWN_PRODUCT_RATING: 'dropDown_product_rating/DROP_DOWN_PRODUCT_RATING',
  DROP_DOWN_TABLE_HEADERS: 'dropDown_table_headers/DROP_DOWN_TABLE_HEADERS',
  DROP_DOWN_IMS: 'dropDown_md_ims/DROP_DOWN_IMS',
  DROP_DOWN_MD_GLOBAL: 'dropDown_md_global/DROP_DOWN_MD_GLOBAL',
  DROP_DOWN_SEGNATORIES: 'getSignatories/DROP_DOWN_SEGNATORIES',
  DROP_DOWN_APPROVERS: 'getApprovers/DROP_DOWN_APPROVERS',
  CREATE_UPDATE_APPROVERS: 'createUpdateApprovers_form/CREATE_UPDATE_APPROVERS',
  UPLOAD_ATTACHMENTS: 'uploadAttachments_form/ UPLOAD_ATTACHMENTS',
  DROP_DOWN_KEY_ROW_MARKET: 'dropDown_key_row_markets/DROP_DOWN_KEY_ROW_MARKET',
  DELETE_UPLOADED_DOCS: 'delete_uploaded_docs/DELETE_UPLOADED_DOCS',
  INITIATE_FD_FORM: 'initiateFdForm/INITIATE_FD_FORM',
  DROP_DOWN_PIF_TYPES: 'dropDown_pif_types/DROP_DOWN_PIF_TYPES'
};

export const dropDown_pif_types = createAsyncThunk(
  actions.DROP_DOWN_PIF_TYPES,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const initiateFdForm = createAsyncThunk(
  actions.INITIATE_FD_FORM,
  async ({ payload }) => {
    const response = await initiateFdFormApi(payload);
    return response;
  }
);

export const delete_uploaded_docs = createAsyncThunk(
  actions.DELETE_UPLOADED_DOCS,
  async ({ payload }) => {
    console.log('payload_slice', payload);
    const response = await deleteUploadedDocs(payload);
    return response;
  }
);

export const dropDown_key_row_markets = createAsyncThunk(
  actions.DROP_DOWN_KEY_ROW_MARKET,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const uploadAttachments_form = createAsyncThunk(
  actions.UPLOAD_ATTACHMENTS,
  async (payload) => {
    const response = await uploadAttachments(payload);
    return response;
  }
);

export const createUpdateApprovers_form = createAsyncThunk(
  actions.CREATE_UPDATE_APPROVERS,
  async ({ payload }) => {
    const response = await createUpdateApprovers(payload);
    return response;
  }
);

export const getSignatories = createAsyncThunk(
  actions.DROP_DOWN_SEGNATORIES,
  async (payload) => {
    const response = await signatories(payload);
    return response;
  }
);
export const getApprovers = createAsyncThunk(
  actions.DROP_DOWN_APPROVERS,
  async (payload) => {
    const response = await approvers(payload);
    return response;
  }
);

export const dropDown_md_ims = createAsyncThunk(
  actions.DROP_DOWN_IMS,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_md_global = createAsyncThunk(
  actions.DROP_DOWN_MD_GLOBAL,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_table_headers = createAsyncThunk(
  actions.DROP_DOWN_TABLE_HEADERS,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_product_rating = createAsyncThunk(
  actions.DROP_DOWN_PRODUCT_RATING,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_fpm_items = createAsyncThunk(
  actions.DROP_DOWN_FPM_ITEMS,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_gfpm_items = createAsyncThunk(
  actions.DROP_DOWN_GFPM_ITEMS,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_bjc_forecast_items = createAsyncThunk(
  actions.DROP_DOWN_BJC_FORECAST_ITEMS,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_fy_masters = createAsyncThunk(
  actions.DROP_DOWN_FY_MASTERS,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_launch_type = createAsyncThunk(
  actions.DROP_DOWN_LAUNCH_TYPE,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_filing_type = createAsyncThunk(
  actions.DROP_DOWN_FILING_TYPE,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_genericized = createAsyncThunk(
  actions.DROP_DOWN_GENERICIZED,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_currency = createAsyncThunk(
  actions.DROP_DOWN_CURRENCY,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_stages = createAsyncThunk(
  actions.DROP_DOWN_STAGES,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_budget_items = createAsyncThunk(
  actions.DROP_DOWN_BUDGET_ITEMS,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_budget_items_base_best_case = createAsyncThunk(
  actions.DROP_DOWN_BUDGET_ITEMS_BASE_BEST_CASE,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_region_base_case = createAsyncThunk(
  actions.DROP_DOWN_REGION_BASE_CASE,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_region_best_case = createAsyncThunk(
  actions.DROP_DOWN_REGION_BEST_CASE,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_exclusivity_type = createAsyncThunk(
  actions.DROP_DOWN_EXCLISIVITY_TYPE,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_patent_type = createAsyncThunk(
  actions.DROP_DOWN_PATENT_TYPE,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_country = createAsyncThunk(
  actions.DROP_DOWN_COUNTRY,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_GIL_GPI = createAsyncThunk(
  actions.DROP_DOWN_GIL_GPI,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_primary_market = createAsyncThunk(
  actions.DROP_DOWN_PRIMARY_MARKET,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_extension_market = createAsyncThunk(
  actions.DROP_DOWN_EXTENSION_MARKRT,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const createUpdateFdInfo_form = createAsyncThunk(
  actions.CREATE_UPDATE_FD_INFO,
  async ({ payload }) => {
    const response = await createUpdateFdInfo(payload);
    return response;
  }
);
export const dropDown_market_segment = createAsyncThunk(
  actions.DROP_DOWN_MARKET_SEGMENT,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_administration_route = createAsyncThunk(
  actions.DROP_DOWN_ADMINISTRATION_ROUTE,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_therapeutic_category = createAsyncThunk(
  actions.DROP_DOWN_THERAPEUTIC_CAT,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const dropDown_dosage_form = createAsyncThunk(
  actions.DROP_DOWN_DOSAGE_FORM,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const dropDown_approved_strengths = createAsyncThunk(
  actions.DROP_DOWN_APPROVED_STRENGTHS,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);
export const createUpdateRefProductDetail_form = createAsyncThunk(
  actions.CREATE_UPDATE_REF_PRO_DETAILS,
  async ({ payload }) => {
    const response = await createUpdateRefProductDetail(payload);
    return response;
  }
);
export const getFormDetailsById_form = createAsyncThunk(
  actions.GET_FORM_DETAILS_BY_ID,
  async (payload) => {
    const response = await getFormDetailsById(payload);
    return response;
  }
);

export const createUpdatePropProductDetail_form = createAsyncThunk(
  actions.CREATE_UPDATE_PROP_PRO_DETAILS,
  async ({ payload }) => {
    const response = await createUpdatePropProductDetail(payload);
    return response;
  }
);
export const createUpdateIPDetail_form = createAsyncThunk(
  actions.CREATE_UPDATE_IP_DETAILS,
  async ({ payload }) => {
    const response = await createUpdateIPDetail(payload);
    return response;
  }
);
export const dropDown_API_source = createAsyncThunk(
  actions.DROP_DOWN_API_SOURCE,
  async (payload) => {
    const response = await pif_FD_DropDownData(payload);
    return response;
  }
);

export const createUpdateRDDetail_form = createAsyncThunk(
  actions.CREATE_UPDATE_RD_DETAILS,
  async ({ payload }) => {
    const response = await createUpdateRDDetail(payload);
    return response;
  }
);
export const createUpdateCompetitiveScenario_form = createAsyncThunk(
  actions.CREATE_UPDATE_CS_DETAILS,
  async ({ payload }) => {
    const response = await createUpdateCompetitiveScenario(payload);
    return response;
  }
);
export const createUpdateBJ_form = createAsyncThunk(
  actions.CREATE_UPDATE_BJ,
  async ({ payload }) => {
    const response = await createUpdateBJ(payload);
    return response;
  }
);
export const createUpdateMarketData_form = createAsyncThunk(
  actions.CREATE_UPDATE_MD,
  async ({ payload }) => {
    const response = await createUpdateMarketData(payload);
    return response;
  }
);
export const pif_API_formSlice = createSlice({
  name: 'pif_API_form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(dropDown_pif_types.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_pif_types.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;
        if (success) {
          state.dropDown_pif_typesData = data ? data : [];
        } else {
          state.dropDown_pif_typesData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_pif_types.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(dropDown_key_row_markets.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_key_row_markets.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;
        if (success) {
          state.dropDown_key_row_marketsData = data ? data : [];
        } else {
          state.dropDown_key_row_marketsData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_key_row_markets.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(getSignatories.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(getSignatories.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_signatoriesData = data ? data : [];
        } else {
          state.dropDown_signatoriesData = [];
          //message.error(msg);
        }
      })
      .addCase(getSignatories.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(getApprovers.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(getApprovers.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;
        if (success) {
          state.dropDown_approversData = data ? data : [];
        } else {
          state.dropDown_approversData = [];
          //message.error(msg);
        }
      })
      .addCase(getApprovers.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(dropDown_md_global.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_md_global.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;
        if (success) {
          state.dropDown_md_global_data = data ? data : [];
        } else {
          state.dropDown_md_global_data = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_md_global.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(dropDown_md_ims.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_md_ims.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;
        if (success) {
          state.dropDown_md_ims_data = data ? data : [];
        } else {
          state.dropDown_md_ims_data = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_md_ims.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(dropDown_table_headers.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_table_headers.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_table_headersData = data ? data : [];
        } else {
          state.dropDown_table_headersData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_table_headers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(dropDown_filing_type.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_filing_type.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_filing_typeData = data ? data : [];
        } else {
          state.dropDown_filing_typeData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_filing_type.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();

        message.error(msg);
      });
    builder
      .addCase(dropDown_launch_type.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_launch_type.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_launch_typeData = data ? data : [];
        } else {
          state.dropDown_launch_typeData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_launch_type.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_genericized.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_genericized.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_genericizedData = data ? data : [];
        } else {
          state.dropDown_genericizedData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_genericized.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_currency.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_currency.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_currencyData = data ? data : [];
        } else {
          state.dropDown_currencyData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_currency.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_stages.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_stages.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_stagesData = data ? data : [];
        } else {
          state.dropDown_stagesData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_stages.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_budget_items.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_budget_items.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_budget_itemsData = data ? data : [];
        } else {
          state.dropDown_budget_itemsData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_budget_items.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_budget_items_base_best_case.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(
        dropDown_budget_items_base_best_case.fulfilled,
        (state, action) => {
          state.dropDown_GIL_GPILoading = false;
          const { success, data } = action.payload;

          if (success) {
            state.dropDown_budget_items_base_best_caseData = data ? data : [];
          } else {
            state.dropDown_budget_items_base_best_caseData = [];
            //message.error(msg);
          }
        }
      )
      .addCase(
        dropDown_budget_items_base_best_case.rejected,
        (state, action) => {
          const { message: msg } = action.error;

          state.dropDown_GIL_GPILoading = false;
          message.destroy();
          message.error(msg);
        }
      );
    builder
      .addCase(dropDown_region_base_case.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_region_base_case.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_region_base_caseData = data ? data : [];
        } else {
          state.dropDown_region_base_caseData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_region_base_case.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_region_best_case.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_region_best_case.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_region_best_caseData = data ? data : [];
        } else {
          state.dropDown_region_best_caseData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_region_best_case.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_country.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_country.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_countryData = data ? data : [];
        } else {
          state.dropDown_countryData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_country.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_exclusivity_type.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_exclusivity_type.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_exclusivity_typeData = data ? data : [];
        } else {
          state.dropDown_exclusivity_typeData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_exclusivity_type.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_patent_type.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_patent_type.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_patent_typeData = data ? data : [];
        } else {
          state.dropDown_patent_typeData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_patent_type.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_API_source.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_API_source.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_API_sourceData = data ? data : [];
        } else {
          state.dropDown_API_sourceData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_API_source.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_approved_strengths.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_approved_strengths.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_approved_strengthsData = data ? data : [];
        } else {
          state.dropDown_approved_strengthsData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_approved_strengths.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_dosage_form.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_dosage_form.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_dosage_formData = data ? data : [];
        } else {
          state.dropDown_dosage_formData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_dosage_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_administration_route.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_administration_route.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_administration_routeData = data ? data : [];
        } else {
          state.dropDown_administration_routeData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_administration_route.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_therapeutic_category.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_therapeutic_category.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_therapeutic_categoryData = data ? data : [];
        } else {
          state.dropDown_therapeutic_categoryData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_therapeutic_category.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_GIL_GPI.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_GIL_GPI.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_GIL_GPIData = data ? data : [];
        } else {
          state.dropDown_GIL_GPIData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_GIL_GPI.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_primary_market.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_primary_market.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_primary_marketData = data ? data : [];
        } else {
          state.dropDown_primary_marketData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_primary_market.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_extension_market.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_extension_market.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_extension_marketData = data ? data : [];
        } else {
          state.dropDown_extension_marketData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_extension_market.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(createUpdateFdInfo_form.pending, (state) => {
        state.createUpdateFdInfo_formLoading = true;
      })
      .addCase(createUpdateFdInfo_form.fulfilled, (state, action) => {
        state.createUpdateFdInfo_formLoading = false;

        const { success, message: msg } = action.payload;

        message.destroy();
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUpdateFdInfo_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createUpdateFdInfo_formLoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(dropDown_market_segment.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_market_segment.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_market_segmentData = data ? data : [];
        } else {
          state.dropDown_market_segmentData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_market_segment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(createUpdateRefProductDetail_form.pending, (state) => {
        state.createUpdateRefProductDetail_formLoading = true;
      })
      .addCase(createUpdateRefProductDetail_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createUpdateRefProductDetail_formLoading = false;
        message.destroy();
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUpdateRefProductDetail_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createUpdateRefProductDetail_formLoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(getFormDetailsById_form.pending, (state) => {
        state.getFormDetailsByIdLoading = true;
      })
      .addCase(getFormDetailsById_form.fulfilled, (state, action) => {
        state.getFormDetailsByIdLoading = false;

        const { success, data /* , message: msg */ } = action.payload;

        if (success) {
          state.getFormDetailsByIdData = data ? data : [];
        } else {
          state.getFormDetailsByIdData = [];
          // message?.error(msg);
        }
      })
      .addCase(getFormDetailsById_form.rejected, (state /* , action */) => {
        // const {
        //   /* message: message */
        // } = action.error;

        state.getFormDetailsByIdLoading = false;

        // message?.error(message);
      });
    builder
      .addCase(createUpdatePropProductDetail_form.pending, (state) => {
        state.createUpdatePropProductDetail_formLoading = true;
      })
      .addCase(
        createUpdatePropProductDetail_form.fulfilled,
        (state, action) => {
          const { success, message: msg } = action.payload;
          state.createUpdatePropProductDetail_formLoading = false;
          message.destroy();
          if (success) {
            message.success(msg);
          } else {
            message.error(msg);
          }
        }
      )
      .addCase(createUpdatePropProductDetail_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createUpdatePropProductDetail_formLoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(createUpdateIPDetail_form.pending, (state) => {
        state.createUpdateIPDetail_formLoading = true;
      })
      .addCase(createUpdateIPDetail_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createUpdateIPDetail_formLoading = false;
        message.destroy();
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUpdateIPDetail_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createUpdateIPDetail_formLoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(createUpdateRDDetail_form.pending, (state) => {
        state.createUpdateRDDetail_formLoading = true;
      })
      .addCase(createUpdateRDDetail_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createUpdateRDDetail_formLoading = false;
        message.destroy();
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUpdateRDDetail_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createUpdateRDDetail_formLoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(createUpdateCompetitiveScenario_form.pending, (state) => {
        state.createUpdateCompetitiveScenario_formLoading = true;
      })
      .addCase(
        createUpdateCompetitiveScenario_form.fulfilled,
        (state, action) => {
          const { success, message: msg } = action.payload;
          state.createUpdateCompetitiveScenario_formLoading = false;
          message.destroy();
          if (success) {
            message.success(msg);
          } else {
            message.error(msg);
          }
        }
      )
      .addCase(
        createUpdateCompetitiveScenario_form.rejected,
        (state, action) => {
          const { message: msg } = action.error;

          state.createUpdateCompetitiveScenario_formLoading = false;
          message.destroy();
          message.error(msg);
        }
      );

    builder
      .addCase(createUpdateBJ_form.pending, (state) => {
        state.createUpdateBJ_formLoading = true;
      })
      .addCase(createUpdateBJ_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createUpdateBJ_formLoading = false;
        message.destroy();
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUpdateBJ_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createUpdateBJ_formLoading = false;
        message.destroy();
        message.error(msg);
      });

    builder
      .addCase(createUpdateMarketData_form.pending, (state) => {
        state.createUpdateMarketData_formLoading = true;
      })
      .addCase(createUpdateMarketData_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createUpdateMarketData_formLoading = false;
        message.destroy();
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUpdateMarketData_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createUpdateMarketData_formLoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_fy_masters.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_fy_masters.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_fy_mastersData = data ? data : [];
        } else {
          state.dropDown_fy_mastersData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_fy_masters.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_bjc_forecast_items.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_bjc_forecast_items.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_bjc_forecast_itemsData = data ? data : [];
        } else {
          state.dropDown_bjc_forecast_itemsData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_bjc_forecast_items.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_fpm_items.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_fpm_items.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_fpm_itemsData = data ? data : [];
        } else {
          state.dropDown_fpm_itemsData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_fpm_items.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_gfpm_items.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_gfpm_items.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_gfpm_itemsData = data ? data : [];
        } else {
          state.dropDown_gfpm_itemsData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_gfpm_items.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(dropDown_product_rating.pending, (state) => {
        state.dropDown_GIL_GPILoading = true;
      })
      .addCase(dropDown_product_rating.fulfilled, (state, action) => {
        state.dropDown_GIL_GPILoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dropDown_product_ratingData = data ? data : [];
        } else {
          state.dropDown_product_ratingData = [];
          //message.error(msg);
        }
      })
      .addCase(dropDown_product_rating.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dropDown_GIL_GPILoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(createUpdateApprovers_form.pending, (state) => {
        state.createUpdateApprovers_formLoading = true;
      })
      .addCase(createUpdateApprovers_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createUpdateApprovers_formLoading = false;
        message.destroy();
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUpdateApprovers_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createUpdateApprovers_formLoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(uploadAttachments_form.pending, (state) => {
        state.uploadAttachments_formLoading = true;
      })
      .addCase(uploadAttachments_form.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.uploadAttachments_formLoading = false;
        message.destroy();
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(uploadAttachments_form.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.uploadAttachments_formLoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(delete_uploaded_docs.pending, (state) => {
        state.deleteDocsLoading = true;
      })
      .addCase(delete_uploaded_docs.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.deleteDocsLoading = false;
        message.destroy();
        if (success) {
          message.success(msg);
        }
      })
      .addCase(delete_uploaded_docs.rejected, (state, action) => {
        const { msg: msg } = action.error;
        state.deleteDocsLoading = false;
        message.destroy();
        message.error(msg);
      });
    builder
      .addCase(initiateFdForm.pending, (state) => {
        state.initiateFdFormLoading = true;
      })
      .addCase(initiateFdForm.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.initiateFdFormLoading = false;
        message.destroy();
        if (success) {
          message.success(msg);
        }
      })
      .addCase(initiateFdForm.rejected, (state, action) => {
        const { msg: msg } = action.error;
        state.initiateFdFormLoading = false;
        message.destroy();
        message.error(msg);
      });
  }
});

export default pif_API_formSlice.reducer;
