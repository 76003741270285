import React from 'react';
import { Table, Input, Select, Space, message } from 'antd';
import FormItem from 'common/FormItem';
import styles from './BJ_US_BaseCase.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getNullValue } from 'utilities/helpers';

const { Option } = Select;

const BusinessJustification_BaseCase = ({
  dropDown_fy_mastersData,
  dropDown_bjc_forecast_itemsData,
  debounceBjc_forecast_items,
  selectedYear,
  setSelectedYear,
  data,
  setData,
  edit,
  setError,
  error
}) => {
  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const handleCellValueChange = (value, key, year) => {
    const newData = [...data];
    const index = newData.findIndex((item) => item.key === key);
    newData[index][year] = value;
    setData(newData);
  };

  const handleAddRow = () => {
    console.log('data', data);

    const emptyKeys = getNullValue(data.at(-1), [], true);
    if (emptyKeys) {
      const msg = `${emptyKeys /* || 'FY_wise_values' */} are required fields`;
      message.error(msg);
      setError({ ...error, base: true });
      return;
    }
    setError({ ...error, base: false });
    const newData = [...data];
    newData.push({
      key: (Number(newData.at(-1).key) + 1).toString(),
      units_and_others_id: '',
      cummulative: '',
      FY_wise_values: []
    });
    console.log('...new Data:', newData);
    setData(newData);
  };

  const handleDeleteRow = (key) => {
    const prevData = [...data];
    const newData = prevData.filter((item) => item.key !== key);
    setData(newData);
  };

  // const headerStyle = () => ({
  //   style: { backgroundColor: '#65bade44', padding: '0.5rem' }
  // });

  console.log(
    '..dropDown_fy_mastersData',
    dropDown_fy_mastersData[selectedYear]?.name
  );

  const columns = [
    {
      title: 'Commercial Forecast',
      dataIndex: 'units_and_others_id',
      // onHeaderCell: headerStyle,
      render: (text, record) => (
        <FormItem className={`${styles.select_styles}`}>
          <Select
            disabled={edit === 'optional'}
            showSearch
            onSearch={debounceBjc_forecast_items}
            placeholder="Select commercial forecast"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            value={text}
            defaultValue={record?.units_and_others_id}
            onChange={(value) => {
              const newData = [...data];
              const index = newData.findIndex(
                (item) => item.key === record.key
              );
              newData[index].units_and_others_id = value;
              setData(newData);
            }}
            className={styles.select_styles}
            popupClassName={styles.select_dropdown}
          >
            {dropDown_bjc_forecast_itemsData?.map((item, index) => {
              return (
                <Option value={item?.id} key={index}>
                  {item?.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      title: (
        <span>
          {/* Year&nbsp; */}
          <Select
            value={selectedYear}
            onChange={handleYearChange}
            style={{ width: 120 }}
            className={styles.year_select_styles}
          >
            {dropDown_fy_mastersData.map((item) => {
              const IDToExclude = [42, 43, 44, 45];
              return (
                <Option
                  disabled={IDToExclude.includes(item.id)}
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </span>
      ),
      dataIndex: 'year',
      // onHeaderCell: headerStyle,

      render: (_, record) => (
        <FormItem
          rules={[{ required: true, message: 'This is required field' }]}
          // className="margin-0"
        >
          <Input
            // type="number"
            // step="0.01"
            // min={0}
            disabled={edit === 'optional'}
            // className="border-none"
            placeholder="Click to add"
            value={record[selectedYear]}
            onChange={(e) =>
              handleCellValueChange(e.target.value, record.key, selectedYear)
            }
          />
        </FormItem>
      )
    },
    ...(selectedYear
      ? Array.from({ length: 4 }, (_, i) => selectedYear + i + 1).map(
          (year, index) => ({
            title: dropDown_fy_mastersData?.find(
              (fy) => fy.id === selectedYear + index + 1
            )?.name,
            dataIndex: year.toString(),
            // onHeaderCell: headerStyle,

            render: (text, record) => (
              <FormItem
                rules={[{ required: true, message: 'This is required field' }]}
                // className="margin-0"
              >
                <Input
                  // className="border-none"
                  placeholder="Click to add"
                  // type="number"
                  // step="0.01"
                  // min={0}
                  disabled={edit === 'optional'}
                  value={text}
                  onChange={(e) =>
                    handleCellValueChange(e.target.value, record.key, year)
                  }
                />
              </FormItem>
            )
          })
        )
      : []),

    {
      title: 'Cumulative L+5 Yr',
      dataIndex: 'cummulative',
      // onHeaderCell: headerStyle,

      render: (text, record) => (
        <FormItem
          rules={[{ required: true, message: 'This is required field' }]}
          // className="margin-0"
        >
          <Input
            // type="number"
            // step="0.01"
            // min={0}
            disabled={edit === 'optional'}
            // className="border-none"
            placeholder="Click to add"
            value={text}
            onChange={(e) => {
              const newData = [...data];
              const index = newData.findIndex(
                (item) => item.key === record.key
              );
              newData[index].cummulative = e.target.value;
              setData(newData);
            }}
          />
        </FormItem>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      // onHeaderCell: headerStyle,

      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          <Space
            project_cost_inr_lakhs_us="middle"
            style={{
              display: 'flex',
              justifyContent: 'center' /* paddingLeft: '16px' */
            }}
          >
            <PlusOutlined
              className={`${styles.plusIcon}`}
              onClick={() => (edit === 'optional' ? '' : handleAddRow())}
            />

            {data.length > 1 ? (
              <DeleteOutlined
                className={`${styles.deleteIcon}`}
                onClick={() =>
                  edit === 'optional' ? '' : handleDeleteRow(record.key)
                }
              />
            ) : null}
          </Space>
        </FormItem>
      )
    }
  ];

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        bordered
        rowKey="key"
        scroll={{
          x: 1400
        }}
        // className="custom-table"
      />
    </div>
  );
};

export default BusinessJustification_BaseCase;
