import React, { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
// import { getLoggedInUser } from 'utilities/helpers';
// import { apiUrl } from 'utilities/apiClient';
import styles from './index.module.less';
import { Progress } from 'antd';

const PDFViewer = ({ getFormDetailsByIdData }) => {
  //const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(null);

  //const [loading, setLoading] = useState(false);

  const containerRef = useRef(null);

  // const user = getLoggedInUser();
  // const authToken = user?.accessToken;

  // useEffect(() => {
  //   setLoading(true);

  //   fetch(`${apiUrl}/preview?pif_id=${PIF_form_id}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/pdf',
  //       Authorization: `Bearer ${authToken}`
  //     }
  //   })
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       setLoading(false);
  //       const url = window.URL.createObjectURL(blob);
  //       setPdfData(url);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching PDF:', error);
  //       setLoading(false);
  //     });
  // }, [PIF_form_id, authToken]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  /*  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = getFormDetailsByIdData?.pdf_pif?.fileUrl;
    link.download = 'filename.ext';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }; */
  return (
    <div>
      {/*   <button onClick={downloadFile}>
      Download File
    </button> */}
      {getFormDetailsByIdData?.pdf_pif?.fileUrl ? (
        <div ref={containerRef}>
          <Document
            file={getFormDetailsByIdData?.pdf_pif?.fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            // className={`${styles.document_viewer}`}
          >
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                width={window.innerWidth}
                key={`page_${index + 1}`}
                wrap={true}
                pageNumber={index + 1}
                className={`${styles.document_viewer_page}`}
                size="A6"
              />
            ))}
          </Document>
        </div>
      ) : (
        <Progress percent={30} size="small" />
      )}
    </div>
  );
};

export default PDFViewer;
