import { Grid } from 'antd';
const { useBreakpoint } = Grid;
const useResponsiveness = () => {
  const screen = useBreakpoint();

  return {
    xs: screen.xs,
    sm: screen.sm && !screen.md,
    md: screen.md && !screen.lg,
    lg: screen.lg && !screen.xl,
    xl: screen.xl && !screen.xxl,
    xxl: screen.xxl
  };
};

export { useResponsiveness };
