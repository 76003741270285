import { useEffect, useState /* , useMemo */ } from 'react';
import { Form, Input, Select, Row, Col, Spin, Typography } from 'antd';

import CustomButton from '@/common/CustomButton';
import styles from './competitive_scenario.module.less';
import { useDispatch } from 'react-redux';
import FormItem from 'common/FormItem';

//import { debounce } from 'lodash';

import moment from 'moment';
import { history } from 'app/history';
import { useResponsiveness } from 'hooks/useResponsiveness';

const { Option } = Select;

const CompetitiveScenario = ({
  PIF_form_id,
  setSelectedTab,
  //dropDown_GIL_GPIData,
  completedTabs,
  setCompletedTabs,
  isTabEnabled,
  getFormDetailsByIdData,
  getFormDetailsByIdLoading,
  createUpdateCompetitiveScenario_formLoading,
  createUpdateCompetitiveScenario_form,
  dropDown_genericizedData,
  dropDown_launch_typeData,
  dropDown_filing_typeData,
  debounceGenericized,
  debounceFiling_type,
  debounceLaunch_type
}) => {
  if (!completedTabs.includes(7)) {
    setCompletedTabs([...completedTabs, 7]);
  }
  isTabEnabled(7);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { xs, sm } = useResponsiveness();

  const [formData1, setFormData1] = useState({});
  //const [tagSearch, setTagSearch] = useState('');

  useEffect(() => {
    form.setFieldsValue({
      genericized:
        getFormDetailsByIdData?.competitive_scenario?.genericized?.id,
      type_of_filing:
        getFormDetailsByIdData?.competitive_scenario?.filing_type?.id,
      type_of_launch:
        getFormDetailsByIdData?.competitive_scenario?.launch_type?.id,
      potential_competition:
        getFormDetailsByIdData?.competitive_scenario?.potential_competition,
      dmf_holders: getFormDetailsByIdData?.competitive_scenario?.DMF_holder,
      vertical_integrated_players:
        getFormDetailsByIdData?.competitive_scenario
          ?.vertical_integrated_players,
      piv_filers: getFormDetailsByIdData?.competitive_scenario?.PIV_fillers,
      generic_approvals:
        getFormDetailsByIdData?.competitive_scenario?.generic_approvals,
      launched_players:
        getFormDetailsByIdData?.competitive_scenario?.launched_players,
      inactive_players:
        getFormDetailsByIdData?.competitive_scenario?.inactive_players,
      discontinued_players:
        getFormDetailsByIdData?.competitive_scenario?.discontinued_players,
      remarks: getFormDetailsByIdData?.competitive_scenario?.cs_remarks
    });
  }, [getFormDetailsByIdData]);
  useEffect(() => {
    form.setFieldsValue({
      initiation_date: moment() || ''
    });
  });

  // useEffect(() => {
  //   const payload = {};
  //   if (tagSearch) payload['search'] = tagSearch;
  //   dispatch(getCategoryList(payload));
  // }, [tagSearch]);

  // const debounceFetcher = useMemo(() => {
  //   const loadOptions = (tagSearch) => {
  //     setTagSearch(tagSearch);
  //   };
  //   return debounce(loadOptions, 400);
  // }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value ? value : null
    });
  };

  const onFinish = () => {
    const payload = {
      edit_type: 'draft',
      pif_id: PIF_form_id,
      genericized_id:
        formData1?.genericized === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.genericized?.id
          : formData1?.genericized,
      filing_type_id:
        formData1?.type_of_filing === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.filing_type?.id
          : formData1?.type_of_filing,
      launch_type_id:
        formData1?.type_of_launch === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.launch_type?.id
          : formData1?.type_of_launch,
      DMF_holder:
        formData1?.dmf_holders === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.DMF_holder
          : formData1?.dmf_holders,
      potential_competition:
        formData1?.potential_competition === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.potential_competition
          : formData1?.potential_competition,
      vertical_integrated_players:
        formData1?.vertical_integrated_players === undefined
          ? getFormDetailsByIdData?.competitive_scenario
              ?.vertical_integrated_players
          : formData1?.vertical_integrated_players,
      PIV_fillers:
        formData1?.piv_filers === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.PIV_fillers
          : formData1?.piv_filers,
      generic_approvals:
        formData1?.generic_approvals === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.generic_approvals
          : formData1?.generic_approvals,
      lanched_players:
        formData1?.launched_players === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.launched_players
          : formData1?.launched_players,
      inactive_players:
        formData1?.inactive_players === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.inactive_players
          : formData1?.inactive_players,
      discontinued_players:
        formData1?.discontinued_players === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.discontinued_players
          : formData1?.discontinued_players,
      cs_remarks:
        formData1?.remarks === undefined
          ? getFormDetailsByIdData?.competitive_scenario?.cs_remarks
          : formData1?.remarks
    };

    dispatch(createUpdateCompetitiveScenario_form({ payload }))?.then(function (
      response
    ) {
      if (response?.payload?.success) {
        //history.push(`/admin-quizzes`);
        setSelectedTab(8);
      } else {
        console.log('response?.payload?.error', response?.payload?.error);
      }
    });
  };
  //const defaultDate = moment().format('MMM DD, YYYY');
  return (
    <div
      className={`${styles.page_container}`}
      style={{
        marginBlock: xs ? '0.5rem' : sm ? '0.7rem' : '1.2rem',
        marginInline: xs ? 0 : sm ? '0.5rem' : '1.3rem'
      }}
    >
      <Spin spinning={getFormDetailsByIdLoading}>
        <div className={`${styles.page_title}`}>
          <Typography.Title
            level={2}
            style={{ fontSize: xs ? '1rem' : sm ? '1.1rem' : '1.2rem' }}
            className={styles.title_label}
          >
            Competitive Scenario
          </Typography.Title>
        </div>

        <Form
          form={form}
          name="PIF_RD_Information"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Type of Filing"
                name="type_of_filing"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceFiling_type}
                  placeholder="Select type of filing"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('type_of_filing', value)
                  }
                >
                  {dropDown_filing_typeData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Type of Launch"
                name="type_of_launch"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceLaunch_type}
                  placeholder="Select type of launch"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('type_of_launch', value)
                  }
                >
                  {dropDown_launch_typeData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Genericized"
                name="genericized"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceGenericized}
                  placeholder="Select genericized"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('genericized', value)
                  }
                >
                  {dropDown_genericizedData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Potential Competition"
                name="potential_competition"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Please enter potential competition"
                  onChange={(e) =>
                    handleFormDataOnChange(
                      'potential_competition',
                      e.target.value
                    )
                  }
                />
              </FormItem>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Potential Competition"
                name="potential_competition"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter potential competition"
                  onChange={(e) =>
                    handleFormDataOnChange(
                      'potential_competition',
                      e.target.value
                    )
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="DMF Holders"
                name="dmf_holders"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter DMF holders"
                  onChange={(e) =>
                    handleFormDataOnChange('dmf_holders', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Vertical Integrated Players"
                name="vertical_integrated_players"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter vertical integrated players"
                  onChange={(e) =>
                    handleFormDataOnChange(
                      'vertical_integrated_players',
                      e.target.value
                    )
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="PIV Filers"
                name="piv_filers"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter PIV filers"
                  onChange={(e) =>
                    handleFormDataOnChange('piv_filers', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Generic Approvals"
                name="generic_approvals"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter generic approvals"
                  onChange={(e) =>
                    handleFormDataOnChange('generic_approvals', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Launched Players"
                name="launched_players"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter launched players"
                  onChange={(e) =>
                    handleFormDataOnChange('launched_players', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Inactive Players"
                name="inactive_players"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter inactive players"
                  onChange={(e) =>
                    handleFormDataOnChange('inactive_players', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Discontinued Players"
                name="discontinued_players"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter discontinued players"
                  onChange={(e) =>
                    handleFormDataOnChange(
                      'discontinued_players',
                      e.target.value
                    )
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Remarks"
                name="remarks"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input.TextArea
                  rows={3}
                  allowClear
                  placeholder="Enter remarks"
                  onChange={(e) =>
                    handleFormDataOnChange('remarks', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/home`)}
            >
              Cancel
            </CustomButton>
            <FormItem>
              <CustomButton
                loading={createUpdateCompetitiveScenario_formLoading}
                type="primary"
                htmlType="submit"
                className={styles.post_btn_style}
              >
                Next
              </CustomButton>
            </FormItem>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default CompetitiveScenario;
