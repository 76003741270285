import { useEffect, useState /* , useMemo */ } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Collapse,
  Divider,
  Spin,
  message,
  Space,
  Typography
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
//import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './proposed_product_details.module.less';
import { useDispatch } from 'react-redux';
import FormItem from 'common/FormItem';
import CustomCollapse from '@/common/CustomCollapse';
//import { debounce } from 'lodash';
import moment from 'moment';
import { history } from 'app/history';
// import API_Source_Table from '@/common/prop_prod_details/api_source_table/API_Source_Table';
import KSM_Source_Table from '@/common/prop_prod_details/ksm_source_table/KSM_Source_Table';
// import { hasEmptyValue } from 'utilities/helpers';
import { useResponsiveness } from 'hooks/useResponsiveness';
import APIDynamicTable_Prop_prod from '@/common/APIdynamicTable_Prop_prod copy/DynamicTable_Prop_prod';

const { Option } = Select;

const { Panel } = Collapse;

const Proposed_product_details = ({
  PIF_form_id,
  setSelectedTab,
  // dropDown_administration_routeData,
  dropDown_dosage_formData,
  dropDown_approved_strengthsData,
  createUpdatePropProductDetail_formLoading,
  createUpdatePropProductDetail_form,
  //dropDown_API_sourceData,
  completedTabs,
  setCompletedTabs,
  isTabEnabled,
  getFormDetailsByIdLoading,
  getFormDetailsByIdData,
  // debounceAdministration_route,
  debounceDosage_form,
  debounceApproved_strengths,
  draft_PIF_form_start
  //debounceAPI_source
}) => {
  if (!completedTabs?.includes(3)) {
    setCompletedTabs([...completedTabs, 3]);
    //console.log('completedTabs::::', completedTabs);
  }
  isTabEnabled(3);

  const storedData = localStorage.getItem('prop_table');
  const [isOthers_approvedStrengths, setIsOthers_approvedStrengths] =
    useState();
  //console.log('storedData@@:', storedData);

  const dispatch = useDispatch();
  const [tableError, setTableError] = useState(false);
  const { xs, sm } = useResponsiveness();
  const [form] = Form.useForm();

  const [formData1, setFormData1] = useState({});
  const [dataSource, setDataSource] = useState();
  // const [dataSourceApi, setDataSourceApi] = useState();
  const [dataSourceKsm, setDataSourceKsm] = useState();
  // const [product_name, setProduct_name] = useState('');

  const [isOthers_dosageForm, setIsOthers_dosageForm] = useState();

  // useEffect(() => {
  //   setProduct_name(
  //     getFormDetailsByIdData?.prop_product_detail?.length === 0
  //       ? ''
  //       : getFormDetailsByIdData?.prop_product_detail?.product_name
  //   );
  // }, [getFormDetailsByIdData]);

  //<<----------Set data to dataSource for Strengths----------->>
  useEffect(() => {
    if (
      storedData === 'false' ||
      getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length ===
        0 ||
      (getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length > 0 &&
        storedData === 'true' &&
        formData1?.ApprovedStrengths !== undefined) ||
      (getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length > 0 &&
        draft_PIF_form_start === 'true' &&
        formData1?.ApprovedStrengths !== undefined)
    ) {
      setDataSource([
        {
          key: generateKey(),
          UoM_id:
            formData1?.ApprovedStrengths === undefined
              ? getFormDetailsByIdData?.prop_product_detail?.prop_UoM?.id
              : formData1?.ApprovedStrengths,
          size_shape_similarity: '',
          score: '',
          strength_value: ''
        }
      ]);
    }
  }, [storedData === 'false', formData1?.Strength, draft_PIF_form_start]);

  useEffect(() => {
    if (
      (getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length > 0 &&
        storedData === 'true' &&
        formData1?.ApprovedStrengths === undefined) ||
      (getFormDetailsByIdData?.prop_product_detail?.prop_strength?.length > 0 &&
        draft_PIF_form_start === 'true' &&
        formData1?.ApprovedStrengths === undefined)
    ) {
      const newData =
        getFormDetailsByIdData?.prop_product_detail?.prop_strength?.map(
          (item) => ({
            key: item.id.toString(),
            id: item.id,
            UoM_id: item.prop_strength_UoM.id,
            size_shape_similarity: item.size_shape_similarity,
            score: item.score,
            strength_value: item.strength_value
            // is_active: item.is_active
          })
        );
      setDataSource(newData);
    }
  }, [getFormDetailsByIdData, storedData === 'true', draft_PIF_form_start]);

  function generateKey() {
    return Math.random().toString(36).substr(2, 10);
  }

  //<<----------Set data to dataSourceApi for API Source----------->>
  // useEffect(() => {
  //   if (
  //     storedData === 'false' ||
  //     getFormDetailsByIdData?.prop_product_detail?.api_sources?.length === 0 ||
  //     (getFormDetailsByIdData?.prop_product_detail?.api_sources?.length > 0 &&
  //       storedData === 'true') ||
  //     (getFormDetailsByIdData?.prop_product_detail?.api_sources?.length > 0 &&
  //       draft_PIF_form_start === 'true')
  //   ) {
  //     setDataSourceApi([
  //       {
  //         key: generateKeyApi(),
  //         api_name: '',
  //         source_internal: '',
  //         source_external: ''
  //       }
  //     ]);
  //   }
  // }, [storedData === 'false', formData1?.Strength, draft_PIF_form_start]);

  // useEffect(() => {
  //   if (
  //     (getFormDetailsByIdData?.prop_product_detail?.api_sources?.length > 0 &&
  //       storedData === 'true') ||
  //     (getFormDetailsByIdData?.prop_product_detail?.api_sources?.length > 0 &&
  //       draft_PIF_form_start === 'true')
  //   ) {
  //     const newData =
  //       getFormDetailsByIdData?.prop_product_detail?.api_sources?.map(
  //         (item) => ({
  //           key: item.id.toString(),
  //           id: item.id,
  //           api_name: item?.api_name,
  //           source_internal: item?.source_internal,
  //           source_external: item?.source_external
  //         })
  //       );
  //     setDataSourceApi(newData);
  //   }
  // }, [getFormDetailsByIdData, storedData === 'true', draft_PIF_form_start]);

  // function generateKeyApi() {
  //   return Math.random().toString(36).substr(2, 10);
  // }

  //<<----------Set data to dataSourceKsm for KSM Source----------->>
  useEffect(() => {
    if (
      storedData === 'false' ||
      getFormDetailsByIdData?.prop_product_detail?.ksm_sources?.length === 0 ||
      (getFormDetailsByIdData?.prop_product_detail?.ksm_sources?.length > 0 &&
        storedData === 'true') ||
      (getFormDetailsByIdData?.prop_product_detail?.ksm_sources?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      setDataSourceKsm([
        {
          key: generateKeyKsm(),
          api_name: '',
          ksm_name: '',
          source_internal: '',
          source_external: ''
        }
      ]);
    }
  }, [storedData === 'false', formData1?.Strength, draft_PIF_form_start]);

  useEffect(() => {
    if (
      (getFormDetailsByIdData?.prop_product_detail?.ksm_sources?.length > 0 &&
        storedData === 'true') ||
      (getFormDetailsByIdData?.prop_product_detail?.ksm_sources?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      const newData =
        getFormDetailsByIdData?.prop_product_detail?.ksm_sources?.map(
          (item) => ({
            key: item.id.toString(),
            id: item.id,
            api_name: item?.api_name,
            ksm_name: item?.ksm_name,
            source_internal: item?.source_internal,
            source_external: item?.source_external
          })
        );
      setDataSourceKsm(newData);
    }
  }, [getFormDetailsByIdData, storedData === 'true', draft_PIF_form_start]);

  function generateKeyKsm() {
    return Math.random().toString(36).substr(2, 10);
  }

  useEffect(() => {
    form.setFieldsValue({
      active_ingredient:
        getFormDetailsByIdData?.prop_product_detail?.active_ingredient,
      product_name: getFormDetailsByIdData?.prop_product_detail?.product_name,
      api: getFormDetailsByIdData?.prop_product_detail?.API,
      // ref_prod_details:
      //   getFormDetailsByIdData?.prop_product_details?.ref_prod_detail,
      ref_prod_details:
        getFormDetailsByIdData?.prop_product_detail?.ref_prod_detail,
      innovator: getFormDetailsByIdData?.prop_product_detail?.innovator,
      brand_name: getFormDetailsByIdData?.prop_product_detail?.brand_name,
      ma_number: getFormDetailsByIdData?.prop_product_detail?.MA_number,
      approval_date:
        getFormDetailsByIdData?.prop_product_detail?.approval_date === null
          ? ''
          : moment(getFormDetailsByIdData?.prop_product_detail?.approval_date),
      MarketSegment:
        getFormDetailsByIdData?.prop_product_detail?.market_segment?.id,
      KeyChannels: getFormDetailsByIdData?.prop_product_detail?.key_channel,
      TherapeuticCategory:
        getFormDetailsByIdData?.prop_product_detail?.therapeutic_category?.id,
      ApprovedIndication:
        getFormDetailsByIdData?.prop_product_detail?.approved_indication,
      RouteAdminisration:
        getFormDetailsByIdData?.prop_product_detail?.administration_route?.id,
      DosageForm: getFormDetailsByIdData?.prop_product_detail?.dosage_form_id,
      ReleaseType: getFormDetailsByIdData?.prop_product_detail?.release_type,
      RSstrength: getFormDetailsByIdData?.prop_product_detail?.rs_strength,
      ParkConfiguration:
        getFormDetailsByIdData?.prop_product_detail?.pack_config,
      PackSize: getFormDetailsByIdData?.prop_product_detail?.pack_size,
      SecondaryPackdetails:
        getFormDetailsByIdData?.prop_product_detail?.secondary_pack_detail,
      ApprovedStrengths:
        getFormDetailsByIdData?.prop_product_detail?.prop_UoM?.id,
      BCSClass: getFormDetailsByIdData?.prop_product_detail?.bcs_class,
      dea_category: getFormDetailsByIdData?.prop_product_detail?.dea_category,
      BioGuidance: getFormDetailsByIdData?.prop_product_detail?.bio_guidance,
      DosageRegimen:
        getFormDetailsByIdData?.prop_product_detail?.dosage_regimen,
      remarks: getFormDetailsByIdData?.prop_product_detail?.remarks,
      salt: getFormDetailsByIdData?.prop_product_detail?.salt,
      api_form_details:
        getFormDetailsByIdData?.prop_product_detail?.api_form_details,
      polymorphic_form:
        getFormDetailsByIdData?.prop_product_detail?.polymorphic_form,
      api_type: getFormDetailsByIdData?.prop_product_detail?.api_type,
      mfg_site: getFormDetailsByIdData?.prop_product_detail?.mfg_site,
      api_complexity:
        getFormDetailsByIdData?.prop_product_detail?.api_complexity,
      bmc_as_prov_by_rnd:
        getFormDetailsByIdData?.prop_product_detail?.bmc_as_prov_by_rnd
    });
  }, [getFormDetailsByIdData]);

  useEffect(() => {
    form.setFieldsValue({
      initiation_date: moment() || ''
    });
  });

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    if (slug === 'DosageForm') {
      // setIsOthers_dosageForm(value);
      const dosage_form_others = dropDown_dosage_formData?.find(
        (item) => item.id === value
      );

      setIsOthers_dosageForm(
        dosage_form_others?.name === 'Others' ? 'Others' : false
      );
    }

    if (slug === 'ApprovedStrengths') {
      // setIsOthers_approvedStrengths(value);
      const approved_strengths_others = dropDown_approved_strengthsData?.find(
        (item) => item.id === value
      );

      setIsOthers_approvedStrengths(
        approved_strengths_others?.name === 'Others' ? 'Others' : false
      );

      const newData = {
        key: generateKey(),
        UoM_id:
          formData1?.ApprovedStrengths === undefined
            ? getFormDetailsByIdData?.prop_product_detail?.prop_UoM.id
            : formData1?.ApprovedStrengths,

        size_shape_similarity: '',
        score: '',
        strength_value: ''
        // is_active: true
      };
      setDataSource([newData]);
    }

    setFormData1({
      ...formData1,
      [slug]: value ? value : null
    });
  };

  console.log('....API prop', getFormDetailsByIdData?.prop_product_detail);

  const onFinish = () => {
    // if (dataSource.length < 1 || hasEmptyValue(dataSource, ['UoM_id'])) {
    //   message.error('strengths table must have at least 1 entry');
    //   setTableError(true);
    //   return;
    // } else {
    setTableError(false);
    const payload = {
      edit_type: 'draft',
      pif_id: PIF_form_id,
      product_name:
        formData1?.product_name === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.product_name
          : formData1?.product_name,
      active_ingredient:
        formData1?.active_ingredient === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.active_ingredient
          : formData1?.active_ingredient,
      API:
        formData1?.api === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.API
          : formData1?.api,
      api_type:
        formData1?.api_type === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.api_type
          : formData1?.api_type,
      mfg_site:
        formData1?.mfg_site === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.mfg_site
          : formData1?.mfg_site,
      api_complexity:
        formData1?.api_complexity === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.api_complexity
          : formData1?.api_complexity,
      bmc_as_prov_by_rnd:
        formData1?.bmc_as_prov_by_rnd === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.bmc_as_prov_by_rnd
          : formData1?.bmc_as_prov_by_rnd,
      api_form_details:
        formData1?.api_form_details === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.api_form_details
          : formData1?.api_form_details,
      polymorphic_form:
        formData1?.polymorphic_form === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.polymorphic_form
          : formData1?.polymorphic_form,

      ref_prod_detail:
        formData1?.ref_prod_details === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.ref_prod_detail
          : formData1?.ref_prod_details,
      innovator:
        formData1?.innovator === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.innovator
          : formData1?.innovator,
      brand_name:
        formData1?.brand_name === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.brand_name
          : formData1?.brand_name,
      MA_number:
        formData1?.ma_number === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.MA_number
          : formData1?.ma_number,
      approval_date:
        formData1?.approval_date === undefined
          ? moment(
              getFormDetailsByIdData?.prop_product_detail?.approval_date
            ).format('YYYY-MM-DD')
          : moment(formData1?.approval_date).format('YYYY-MM-DD'),
      // market_segment_id:
      //   formData1?.MarketSegment === undefined
      //     ? getFormDetailsByIdData?.prop_product_detail?.market_segment?.id
      //     : isOthers_marketSegment === 'Others'
      //     ? ''
      //     : formData1?.MarketSegment,
      // market_segment_others:
      //   isOthers_marketSegment === 'Others'
      //     ? [formData1?.market_segment_others]
      //     : [],
      key_channel:
        formData1?.KeyChannels === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.key_channel
          : formData1?.KeyChannels,
      salt:
        formData1?.salt === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.salt
          : formData1?.salt,
      // therapeutic_category_id:
      //   formData1?.TherapeuticCategory === undefined
      //     ? getFormDetailsByIdData?.prop_product_detail?.therapeutic_category
      //         ?.id
      //     : isOthers_therapeuticCategory === 'Others'
      //     ? ''
      //     : formData1?.TherapeuticCategory,
      // therapeutic_category_others:
      //   isOthers_therapeuticCategory === 'Others'
      //     ? [formData1?.therapeutic_category_others]
      //     : [],
      approved_indication:
        formData1?.ApprovedIndication === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.approved_indication
          : formData1?.ApprovedIndication,
      // administration_route_id:
      //   formData1?.RouteAdminisration === undefined
      //     ? getFormDetailsByIdData?.prop_product_detail?.administration_route
      //         ?.id
      //     : isOthers_routeAdministration === 'Others'
      //     ? ''
      //     : formData1?.RouteAdminisration,
      // administration_route_others:
      //   isOthers_routeAdministration === 'Others'
      //     ? [formData1?.route_administration_others]
      //     : [],
      dosage_form_id:
        formData1?.DosageForm === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.dosage_form_id
          : isOthers_dosageForm === 'Others'
          ? ''
          : formData1?.DosageForm,
      dosage_form_others:
        isOthers_dosageForm === 'Others' ? [formData1?.dosage_form_others] : [],
      release_type:
        formData1?.ReleaseType === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.release_type
          : formData1?.ReleaseType,
      rs_strength:
        formData1?.RSstrength === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.rs_strength
          : formData1?.RSstrength,
      pack_config:
        formData1?.ParkConfiguration === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.pack_config
          : formData1?.ParkConfiguration,
      pack_size:
        formData1?.PackSize === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.pack_size
          : formData1?.PackSize,
      secondary_pack_detail:
        formData1?.SecondaryPackdetails === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.secondary_pack_detail
          : formData1?.SecondaryPackdetails,
      UoM_id:
        formData1?.ApprovedStrengths === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.prop_UoM?.id
          : isOthers_approvedStrengths === 'Others'
          ? ''
          : formData1?.ApprovedStrengths,
      UoM_others:
        isOthers_approvedStrengths === 'Others'
          ? [formData1?.approved_strengths_others]
          : [],
      bcs_class:
        formData1?.BCSClass === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.bcs_class
          : formData1?.BCSClass,
      dea_category:
        formData1?.dea_category === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.dea_category
          : formData1?.dea_category,
      bio_guidance:
        formData1?.BioGuidance === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.bio_guidance
          : formData1?.BioGuidance,
      dosage_regimen:
        formData1?.DosageRegimen === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.dosage_regimen
          : formData1?.DosageRegimen,
      remarks:
        formData1?.remarks === undefined
          ? getFormDetailsByIdData?.prop_product_detail?.remarks
          : formData1?.remarks,
      prop_strength_list: dataSource?.map((obj) => ({
        ...obj
        //is_active: true,
        // UoM_id:
        //   formData1?.ApprovedStrengths === undefined
        //     ? getFormDetailsByIdData?.prop_product_detail?.UoM?.id
        //     : formData1?.ApprovedStrengths
      })),
      ksm_sources:
        dataSourceKsm.length &&
        dataSourceKsm[0].api_name &&
        dataSourceKsm[0].ksm_name &&
        dataSourceKsm[0].source_external &&
        dataSourceKsm[0].source_internal
          ? dataSourceKsm?.map((obj) => ({
              ...obj
            }))
          : []
    };
    console.log('prop_payload::::', payload);
    dispatch(createUpdatePropProductDetail_form({ payload }))?.then(function (
      response
    ) {
      if (response?.payload?.success) {
        //history.push(`/admin-quizzes`);
        localStorage.setItem('prop_table', true);
        setSelectedTab(4);
      } else {
        //message.error('Please fill required field');
        message.error(response?.payload?.message);
        console.log('response?.payload?.error', response?.payload?.error);
      }
    });
    // }
  };

  return (
    <div
      className={`${styles.page_container}`}
      style={{
        marginBlock: xs ? '0.5rem' : sm ? '0.7rem' : '1.2rem',
        marginInline: xs ? 0 : sm ? '0.5rem' : '1.3rem'
      }}
    >
      <Spin spinning={getFormDetailsByIdLoading}>
        <div className={`${styles.page_title}`}>
          <Typography.Title
            level={2}
            style={{ fontSize: xs ? '1rem' : sm ? '1.1rem' : '1.2rem' }}
            className={styles.title_label}
          >
            Proposed Product Details
          </Typography.Title>
        </div>

        <Form
          form={form}
          name="PIF_RD_Information"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Active Ingredient"
                name="active_ingredient"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter active ingredient"
                  onChange={(e) =>
                    handleFormDataOnChange('active_ingredient', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Product Name"
                name="product_name"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter product name"
                  onChange={(e) =>
                    handleFormDataOnChange('product_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Salt"
                name="salt"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter salt"
                  onChange={(e) =>
                    handleFormDataOnChange('salt', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="API Form Details"
                name="api_form_details"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter API form details"
                  onChange={(e) =>
                    handleFormDataOnChange('api_form_details', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Polymorphic Form"
                name="polymorphic_form"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter polymorphic form"
                  onChange={(e) =>
                    handleFormDataOnChange('polymorphic_form', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Approved Dosage Forms"
                name="DosageForm"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={debounceDosage_form}
                  placeholder="Select approved dosage form"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('DosageForm', value)
                  }
                >
                  {dropDown_dosage_formData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_dosageForm === 'Others' && (
              <Col span={24} md={{ span: 8 }}>
                <FormItem
                  label="Dosage Form - Others"
                  name="dosage_form_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter dosage form"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'dosage_form_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={24} md={{ span: 8 }}>
              <FormItem
                label="Proposed Strengths (UoM)"
                name="ApprovedStrengths"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  showSearch
                  onSearch={debounceApproved_strengths}
                  placeholder="Select Proposed strengths "
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('ApprovedStrengths', value)
                  }
                >
                  {dropDown_approved_strengthsData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {isOthers_approvedStrengths === 'Others' && (
              <Col span={24} md={{ span: 8 }}>
                <FormItem
                  label="Proposed Strengths(UoM) - Others"
                  name="approved_strengths_others"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter Proposed strengths"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'approved_strengths_others',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>

          <Space direction="vertical" size={20} style={{ width: '100%' }}>
            <Row gutter={16}>
              <CustomCollapse
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? 180 : 0} />
                )}
                expandIconPosition="end"
                bordered={false}
                defaultActiveKey={['1']}
                style={
                  tableError
                    ? {
                        border: '1px solid red',
                        backgroundColor: '#f546460d',
                        borderRadius: '0.5rem'
                      }
                    : {}
                }
              >
                <Panel
                  header={
                    <span style={{ fontWeight: 500, fontSize: '14px' }}>
                      Strengths
                      {/* <span style={{ color: 'red', fontSize: '16px' }}>*</span> */}
                    </span>
                  }
                  //extra="Add email message"
                  key="2"
                >
                  <Divider style={{ marginTop: '0px' }} />
                  <div className={styles.scrollableContainer}>
                    <APIDynamicTable_Prop_prod
                      setError={setTableError}
                      setDataSource={setDataSource}
                      dataSource={dataSource}
                      generateKey={generateKey}
                      formData1={formData1}
                      getFormDetailsByIdData={getFormDetailsByIdData}
                      // storedData={storedData}
                      dropDown_approved_strengthsData={
                        dropDown_approved_strengthsData
                      }
                    />
                  </div>
                </Panel>
              </CustomCollapse>
            </Row>
            <Row gutter={16}>
              <Col span={24} md={{ span: 8 }}>
                <FormItem
                  label="API Complexity"
                  name="api_complexity"
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter API complexity"
                    onChange={(e) =>
                      handleFormDataOnChange('api_complexity', e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col span={24} md={{ span: 8 }}>
                <FormItem
                  label="Manufacturing Unit"
                  name="mfg_site"
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter manufacturing unit"
                    onChange={(e) =>
                      handleFormDataOnChange('mfg_site', e.target.value)
                    }
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <CustomCollapse
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? 180 : 0} />
                )}
                expandIconPosition="end"
                bordered={false}
                defaultActiveKey={['1']}
              >
                <Panel
                  header={
                    <span style={{ fontWeight: 500, fontSize: '14px' }}>
                      KSM Source
                      {/* <span style={{ color: 'red', fontSize: '16px' }}>*</span> */}
                    </span>
                  }
                  //extra="Add email message"
                  key="2"
                >
                  <Divider style={{ marginTop: '0px' }} />
                  <div className={styles.scrollableContainer}>
                    <KSM_Source_Table
                      setDataSource={setDataSourceKsm}
                      dataSource={dataSourceKsm}
                      generateKey={generateKeyKsm}
                      // formData1={formData1}
                      // getFormDetailsByIdData={getFormDetailsByIdData}
                    />
                  </div>
                </Panel>
              </CustomCollapse>
            </Row>
            <Row gutter={16} className={`${styles.row_2}`}>
              <Col span={24}>
                <FormItem
                  label="RMC as provided by R&D"
                  name="bmc_as_prov_by_rnd"
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Enter RMC as provided by R&D"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'bmc_as_prov_by_rnd',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_2}`}>
              <Col span={24}>
                <FormItem
                  label="Remarks"
                  name="remarks"
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input.TextArea
                    rows={3}
                    allowClear
                    placeholder="Enter remarks"
                    onChange={(e) =>
                      handleFormDataOnChange('remarks', e.target.value)
                    }
                  />
                </FormItem>
              </Col>
            </Row>
          </Space>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/home`)}
              style={{
                fontSize: xs ? '0.6rem' : sm ? '0.7rem' : '0.9rem',
                paddingInline: xs ? '0.6rem' : sm ? '1rem' : '1.5rem'
              }}
            >
              Cancel
            </CustomButton>
            <FormItem>
              <CustomButton
                loading={createUpdatePropProductDetail_formLoading}
                type="primary"
                htmlType="submit"
                className={styles.post_btn_style}
                style={{
                  fontSize: xs ? '0.6rem' : sm ? '0.7rem' : '0.9rem',
                  paddingInline: xs ? '0.6rem' : sm ? '1rem' : '1.5rem'
                }}
              >
                Next
              </CustomButton>
            </FormItem>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default Proposed_product_details;
