import React from 'react';
import { Input, Space, Form } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './business_justification.module.less';
import FormItem from 'common/FormItem';

const SelectRationales_DynamicInput = ({
  fields,
  setFields,
  generateKey_sr
  //storedData_sr
}) => {
  const addField = () => {
    const newField = {
      key: generateKey_sr(),
      item: ''
      //is_active: true
    };
    setFields([...fields, newField]);
  };

  const deleteField = (key) => {
    const updatedFields = fields.filter((field) => field.key !== key);
    setFields(updatedFields);
    // if (storedData_sr === 'true') {
    //   //console.log('storedData_sr_TRUE: ', storedData_sr);
    //   const updatedFields = fields?.map((item) =>
    //     item.key === key ? { ...item, is_active: false } : item
    //   );
    //   // console.log('updatedFields: ', updatedFields);
    //   setFields(updatedFields);
    // } else {
    //   // console.log('storedData_sr_FALSE: ', storedData_sr);
    //   const updatedFields = fields.filter((field) => field.key !== key);
    //   setFields(updatedFields);
    // }
  };

  const handleInputChange = (key, e) => {
    const updatedFields = fields.map((field) =>
      field.key === key ? { ...field, item: e.target.value } : field
    );
    setFields(updatedFields);
  };
  //console.log('fields: ', fields);
  return (
    <Form>
      <FormItem
        label="Selection Rationale"
        rules={[
          {
            required: false,
            message: 'Required field'
          }
        ]}
        className={`${styles.input_styles}`}
      >
        {fields
          // ?.filter((field) => field.is_active)
          ?.map((field, index) => (
            <Space
              key={index}
              direction="horizontal"
              className={`${styles.inputList_styles}`}
            >
              <Input
                value={field.item}
                onChange={(e) => handleInputChange(field.key, e)}
                placeholder="Enter selection rationale"
              />
              <div>
                {index === fields.length - 1 && (
                  <PlusOutlined
                    onClick={addField}
                    className={`${styles.inputList_plusIcon}`}
                  />
                )}
                {fields.length > 1 && (
                  <DeleteOutlined
                    onClick={() => deleteField(field.key)}
                    className={`${styles.inputList_deleteIcon}`}
                  />
                )}
              </div>
            </Space>
          ))}
      </FormItem>
    </Form>
  );
};

export default SelectRationales_DynamicInput;
