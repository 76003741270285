import { Image, Layout, Menu } from 'antd';
import styles from './index.module.less';
import granules_logo from './images/granules-logo.png';
import UserMenu from './components/UserMenu';
import { Link, NavLink } from 'react-router-dom';
import { getLoggedInUser } from 'utilities/helpers';
import {
  HomeOutlined,
  RadarChartOutlined,
  FileDoneOutlined,
  UserOutlined
} from '@ant-design/icons';

const { Header, Content } = Layout;
const { SubMenu } = Menu;

const CustomLayout = ({ children, sider = false, header = true }) => {
  const userMenu = [
    {
      key: 'home',
      path: '/home',
      icon: <HomeOutlined />,
      label: 'Home'
    },
    {
      key: 'requester-list',
      icon: <RadarChartOutlined />,
      label: 'PIF',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'requester-list-view',
              path: '/requester-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - FD'
            },
            {
              key: 'requester-api-list-view',
              path: '/requester-api-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - API'
            }
          ]
        }
      ]
    },
    {
      key: 'reports',
      icon: <FileDoneOutlined />,
      label: 'Reports',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'reports-fd',
              path: '/reports-fd',
              icon: <FileDoneOutlined />,
              label: 'Reports - FD'
            },
            {
              key: 'reports-api',
              path: '/reports-api',
              icon: <FileDoneOutlined />,
              label: 'Reports - API'
            }
          ]
        }
      ]
    }
  ];

  const userMenu_signatory = [
    {
      key: 'signatory-dashboard',
      path: '/signatory-dashboard',
      icon: <HomeOutlined />,
      label: 'Home'
    },
    {
      key: 'signatory-list',
      // path: '/signatory-list-view',
      icon: <RadarChartOutlined />,
      label: 'PIF',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'signatory-list-view',
              path: '/signatory-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - FD'
            },
            {
              key: 'signatory-api-list-view',
              path: '/signatory-api-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - API'
            }
          ]
        }
      ]
    }
  ];
  const userMenu_approver = [
    {
      key: 'approver-dashboard',

      path: '/approver-dashboard',

      icon: <HomeOutlined />, // <img src={home_icon} alt="home" style={{ width: '26%' }} />,

      label: 'Home'
    },

    {
      key: 'approver-list',

      //path: '/approver-list-view',

      icon: <RadarChartOutlined />, // <img src={manage_icon} alt="approver-list-view" />,

      label: 'PIF',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'approver-list-view',
              path: '/approver-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - FD'
            },
            {
              key: 'approver-api-list-view',
              path: '/approver-api-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - API'
            }
          ]
        }
      ]
    }
    /*  {
      key: 'reports',

      path: '/reports',

      icon: <LayoutOutlined />, // <img src={reports_icon} alt="reports" style={{ width: '26%' }} />,

      label: 'Reports'
    } */
  ];

  const userMenuAdmin = [
    {
      key: 'home',
      path: '/home',
      icon: <HomeOutlined />,
      label: 'Home'
    },
    {
      key: 'requester-list',
      icon: <RadarChartOutlined />,
      label: 'PIF',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'requester-list-view',
              path: '/requester-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - FD'
            },
            {
              key: 'requester-api-list-view',
              path: '/requester-api-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - API'
            }
          ]
        }
      ]
    },
    {
      key: 'reports',
      icon: <FileDoneOutlined />,
      label: 'Reports',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'reports-fd',
              path: '/reports-fd',
              icon: <FileDoneOutlined />,
              label: 'Reports - FD'
            },
            {
              key: 'reports-api',
              path: '/reports-api',
              icon: <FileDoneOutlined />,
              label: 'Reports - API'
            }
          ]
        }
      ]
    },
    {
      key: 'admin',
      icon: <UserOutlined />,
      label: 'Admin',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'admin-fd',
              path: '/admin-fd',
              icon: <UserOutlined />,
              label: 'Admin - FD'
            },
            {
              key: 'admin-api',
              path: '/admin-api',
              icon: <UserOutlined />,
              label: 'Admin - API'
            }
          ]
        }
      ]
    }
  ];
  const userMenu_signatoryAdmin = [
    {
      key: 'signatory-dashboard',
      path: '/signatory-dashboard',
      icon: <HomeOutlined />,
      label: 'Home'
    },
    {
      key: 'signatory-list',
      // path: '/signatory-list-view',
      icon: <RadarChartOutlined />,
      label: 'PIF',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'signatory-list-view',
              path: '/signatory-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - FD'
            },
            {
              key: 'signatory-api-list-view',
              path: '/signatory-api-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - API'
            }
          ]
        }
      ]
    },
    {
      key: 'admin',
      icon: <UserOutlined />,
      label: 'Admin',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'admin-fd',
              path: '/admin-fd',
              icon: <UserOutlined />,
              label: 'Admin - FD'
            },
            {
              key: 'admin-api',
              path: '/admin-api',
              icon: <UserOutlined />,
              label: 'Admin - API'
            }
          ]
        }
      ]
    }
  ];
  const userMenu_approverAdmin = [
    {
      key: 'approver-dashboard',

      path: '/approver-dashboard',

      icon: <HomeOutlined />, // <img src={home_icon} alt="home" style={{ width: '26%' }} />,

      label: 'Home'
    },

    {
      key: 'approver-list',

      //path: '/approver-list-view',

      icon: <RadarChartOutlined />, // <img src={manage_icon} alt="approver-list-view" />,

      label: 'PIF',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'approver-list-view',
              path: '/approver-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - FD'
            },
            {
              key: 'approver-api-list-view',
              path: '/approver-api-list-view',
              icon: <RadarChartOutlined />,
              label: 'PIF - API'
            }
          ]
        }
      ]
    },
    {
      key: 'admin',
      icon: <UserOutlined />,
      label: 'Admin',
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'admin-fd',
              path: '/admin-fd',
              icon: <UserOutlined />,
              label: 'Admin - FD'
            },
            {
              key: 'admin-api',
              path: '/admin-api',
              icon: <UserOutlined />,
              label: 'Admin - API'
            }
          ]
        }
      ]
    }
  ];
  const user = getLoggedInUser();
  let role = user?.user_details?.role;
  let isAdmin = user?.user_details?.is_admin;

  console.log('ADMIN', typeof isAdmin);

  const headerbar =
    role === 'creator' && isAdmin === true
      ? userMenuAdmin
      : role === 'creator' && isAdmin === false
      ? userMenu
      : role === 'signatory' && user?.user_details?.is_admin === true
      ? userMenu_signatoryAdmin
      : role === 'signatory' && isAdmin === false
      ? userMenu_signatory
      : role === 'approver' && user?.user_details?.is_admin === true
      ? userMenu_approverAdmin
      : role === 'approver' && isAdmin === false
      ? userMenu_approver
      : '';

  const renderMenuItems = (items) => {
    return items.map((item) => {
      if (item.children) {
        return (
          <SubMenu
            key={item.key}
            /* icon={item.icon} title={item.label} */ title={
              <span className={`${styles.pif_hover_style}`}>
                {item.icon} {item.label}
              </span>
            }
          >
            {item.children.map((childGroup) =>
              childGroup.children.map((child) => (
                <Menu.Item key={child.key}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `${styles.active}` : `${styles.inactive}`
                    }
                    to={child.path}
                  >
                    <span>
                      <span style={{ marginRight: '0px' }}> {child.icon} </span>
                      {child.label}
                    </span>
                  </NavLink>
                </Menu.Item>
              ))
            )}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={item.key} /* icon={item.icon} */>
          <NavLink
            className={({ isActive }) =>
              isActive ? `${styles.active}` : `${styles.inactive}`
            }
            to={item.path}
          >
            {item.icon} {item.label}
          </NavLink>
        </Menu.Item>
      );
    });
  };

  return (
    <Layout
      className={`${
        sider ? styles.custom_layout_container : styles.custom_layout_container1
      }`}
      hasSider={sider}
    >
      <Layout className={styles.site_layout}>
        {header ? (
          <Header className={`df-jb-ac ${styles.site_layout_header}`}>
            <div className="sfprotext-medium font-16">
              <Link to={role === 'creator' ? '/home' : ''}>
                <Image
                  className={`${styles.logo_header}`}
                  alt="logo"
                  src={granules_logo}
                  preview={false}
                />
              </Link>
            </div>

            <Menu className={` df-jc-ac ${styles.menu}`} mode="horizontal">
              {renderMenuItems(headerbar)}
            </Menu>
            <UserMenu />
          </Header>
        ) : null}

        <Content className={styles.site_layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
