import React from 'react';
import CustomLayout from '@/common/Layout';
import Edit_PIF_API_FormPage from './components/edit_PIF_API_FormPage';

const Edit_PIF_API_Form = () => {
  return (
    <CustomLayout>
      <Edit_PIF_API_FormPage />
    </CustomLayout>
  );
};

export default Edit_PIF_API_Form;
