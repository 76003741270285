import React, { useEffect, useState } from 'react';
import { Form, Input, Table, Space, Select, DatePicker, message } from 'antd';
import moment from 'moment'; // Import moment library
import styles from './rd_Timeline_table.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';
import { getNullValue } from 'utilities/helpers';

const { Option } = Select;

const RD_Timeline_table = ({
  dataSource,
  setDataSource,
  generateKey,
  //storedData,
  dropDown_stagesData,
  debounceStages,
  edit,
  setError
}) => {
  const [timelineDifferences, setTimelineDifferences] = useState([]); // Define timelineDifferences state variable
  const [timelineDifferences2, setTimelineDifferences2] = useState([]);
  //const [disabledOptions, setDisabledOptions] = useState([]);

  // Function to add a new row
  const addRow = () => {
    const emptyKeys = getNullValue(dataSource.at(-1), [
      'timeline_best_case',
      'timeline_base_case'
    ]);
    if (emptyKeys && edit !== 'optional') {
      const msg = `${emptyKeys} are required fields`;
      message.error(msg);
      setError(true);
      return;
    }
    setError(false);
    const newData = {
      key: generateKey(),
      stage_id: '',
      date_best_case: moment().format('YYYY-MM-DD'),
      timeline_best_case: '',
      date_base_case: moment().format('YYYY-MM-DD'),
      timeline_base_case: ''
      //is_active: true
    };
    setDataSource([...dataSource, newData]);
  };

  // Function to delete a row by key
  const deleteRow = (key) => {
    const updatedData = dataSource?.filter((item) => item.key !== key);
    setDataSource(updatedData);
    // if (storedData === 'true') {
    //   const updatedDataSource = dataSource.map((item) =>
    //     item.key === key ? { ...item, is_active: false } : item
    //   );
    //   setDataSource(updatedDataSource);

    //   // Enable the deleted option in the dropdown
    //   const deletedRow = dataSource.find((item) => item.key === key);
    //   if (deletedRow) {
    //     // Enable the deleted option in the dropdown
    //     setDisabledOptions(
    //       disabledOptions.filter((id) => id !== deletedRow.stage_id)
    //     );
    //   }

    //   // const updatedDataSource = [...dataSource];
    //   // const index = updatedDataSource.findIndex((item) => item.key === key);
    //   // if (index !== -1) {
    //   //   updatedDataSource[index].//is_active = false;
    //   //   setDataSource(updatedDataSource);
    //   // } else {
    //   //   console.log('Object with key', key, 'not found in dataSource.');
    //   // }
    // } else {
    //   const updatedData = dataSource?.filter((item) => item.key !== key);
    //   setDataSource(updatedData);

    //   // Enable the deleted option in the dropdown
    //   const deletedRow = dataSource.find((item) => item.key === key);
    //   if (deletedRow) {
    //     // Enable the deleted option in the dropdown
    //     setDisabledOptions(
    //       disabledOptions.filter((id) => id !== deletedRow.stage_id)
    //     );
    //   }
    // }
  };

  // Function to handle changes in cell values
  const handleCellChange = (key, dataIndex, value) => {
    // console.log('key:', key);
    // console.log('dataIndex:', dataIndex);
    // console.log('value:', value);

    //--------newly added code
    if (dataIndex === 'date_best_case' || dataIndex === 'date_base_case') {
      value = moment(value)?.format('YYYY-MM-DD');
    }
    //----------------------

    const newData = dataSource?.map((item) =>
      item?.key === key ? { ...item, [dataIndex]: value } : item
    );
    setDataSource(newData);

    // // Retrieve the previously disabled option related to the changed row
    // const prevDisabledOption = dataSource?.find(
    //   (item) => item?.key === key
    // )?.stage_id;

    // // Filter out the previously disabled option related to the same key
    // const updatedDisabledOptions = disabledOptions.filter(
    //   (id) => id !== prevDisabledOption
    // );

    // // Add the selected option to the disabledOptions array
    // setDisabledOptions([...updatedDisabledOptions, value]);
  };

  // Function to compare two arrays for equality
  const isEqual = (array1, array2) => {
    if (array1?.length !== array2?.length) {
      return false;
    }
    for (let i = 0; i < array1?.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    // Calculate the differences between 'date_best_case' of each row and the next row
    const differences = [];
    for (let i = 0; i < dataSource?.length - 1; i++) {
      const currentRow = dataSource[i];
      const nextRow = dataSource[i + 1];
      if (currentRow?.date_best_case && nextRow?.date_best_case) {
        console.log(
          'currentRow?.date_best_case:::',
          currentRow?.date_best_case
        );
        console.log('nextRow?.date_best_case:::', nextRow?.date_best_case);
        const dateCurrentRow =
          currentRow?.date_best_case === 'Invalid date'
            ? moment().format('YYYY-MM-DD')
            : moment(currentRow?.date_best_case); // Convert date string to moment object
        const dateNextRow =
          nextRow?.date_best_case === 'Invalid date'
            ? moment().format('YYYY-MM-DD')
            : moment(nextRow?.date_best_case); // Convert date string to moment object

        const differenceInMonths =
          nextRow?.date_best_case !== 'Invalid date'
            ? dateNextRow?.diff(dateCurrentRow, 'months')
            : 0; // Calculate difference in months
        console.log('differenceInMonths:::', differenceInMonths);
        //const positiveDifferenceInMonths = Math?.abs(differenceInMonths);
        differences?.push(differenceInMonths);
        // console.log('differences111:::', differences);
      } else {
        differences?.push(null);
        // console.log('differences222:::', differences);
      }
    }
    differences?.push(null);

    // Check if the differences have changed
    if (!isEqual(differences, timelineDifferences)) {
      setTimelineDifferences(differences);

      // Update 'timeline_best_case' of each row in the dataSource
      const updatedDataSource = dataSource?.map((item, index) => {
        if (index > 0) {
          return { ...item, timeline_best_case: differences[index - 1] };
        }
        return item;
      });

      // Check if there's only one row left and set 'timeline_best_case' to null
      if (updatedDataSource?.length === 1) {
        updatedDataSource[0].timeline_best_case = null;
      }

      setDataSource(updatedDataSource);
    }
  }, [dataSource, timelineDifferences]);

  useEffect(() => {
    // Calculate the differences between 'date_base_case' of each row and the next row
    const differences2 = [];
    for (let i = 0; i < dataSource?.length - 1; i++) {
      const currentRow = dataSource[i];
      const nextRow = dataSource[i + 1];
      if (currentRow?.date_base_case && nextRow?.date_base_case) {
        const dateCurrentRow =
          currentRow?.date_best_case === 'Invalid date'
            ? moment().format('YYYY-MM-DD')
            : moment(currentRow?.date_base_case); // Convert date string to moment object
        const dateNextRow =
          nextRow?.date_base_case === 'Invalid date'
            ? moment().format('YYYY-MM-DD')
            : moment(nextRow?.date_base_case); // Convert date string to moment object
        const differenceInMonths =
          nextRow?.date_base_case !== 'Invalid date'
            ? dateNextRow?.diff(dateCurrentRow, 'months')
            : 0; // Calculate difference in months
        // const positiveDifferenceInMonths = Math?.abs(differenceInMonths);
        differences2?.push(differenceInMonths);
      } else {
        differences2?.push(null);
      }
    }

    differences2?.push(null);
    // Check if the differences have changed
    if (!isEqual(differences2, timelineDifferences2)) {
      setTimelineDifferences2(differences2);

      // Update 'timeline_base_case' of each row in the dataSource
      const updatedDataSource = dataSource?.map((item, index) => {
        if (index > 0) {
          return { ...item, timeline_base_case: differences2[index - 1] };
        }
        return item;
      });

      // Check if there's only one row left and set 'timeline_base_case' to null
      if (updatedDataSource?.length === 1) {
        updatedDataSource[0].timeline_base_case = null;
      }

      setDataSource(updatedDataSource);
    }
  }, [dataSource, timelineDifferences2]);

  // timelineDifferences[index]
  // : record.timeline_best_case
  const columns = [
    // {
    //   title: 'Stages',
    //   dataIndex: 'stages',
    //   render: (_, record) => (
    //     <Select
    //       placeholder="Select stages"
    //       onChange={(value) => handleCellChange(record.key, 'stages', value)}
    //     >
    //       {dropDown_patent_typeData?.map((item) => (
    //         <Option key={item.id} value={item.id}>
    //           {item.name}
    //         </Option>
    //       ))}
    //     </Select>
    //   )
    // },
    // {
    //   title: 'Date (Best Case)',
    //   dataIndex: 'date_best_case',
    //   render: (_, record) => (
    //     <DatePicker
    //       format={'YYYY-MMM-DD'}
    //       onChange={(dateValue) =>
    //         handleCellChange(record.key, 'date_best_case', dateValue)
    //       }
    //     />
    //   )
    // },
    {
      title: 'Stages',
      dataIndex: 'stage_id',
      render: (_, record) => (
        <FormItem
          // label="Approved Strengths"
          // name="ApprovedStrengths"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          className={`${styles.select_styles}`}
        >
          <Select
            disabled={edit === 'optional'}
            showSearch
            onSearch={debounceStages}
            className={styles.select_styles}
            popupClassName={styles.select_dropdown}
            placeholder="Select stages"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            defaultValue={record?.stage_id}
            onChange={(value) =>
              handleCellChange(record.key, 'stage_id', value)
            }
          >
            {dropDown_stagesData?.map((item, index) => {
              return (
                <Option
                  value={item.id}
                  key={index}
                  // disabled={disabledOptions.includes(item.id)}
                >
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      title: 'Date (Best Case)',
      dataIndex: 'date_best_case',
      render: (_, record) => (
        <FormItem
          // name={['patent_expiration_us', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <DatePicker
            disabled={edit === 'optional'}
            //format={'YYYY MMM DD'}
            format={'DD MMM YYYY'}
            defaultValue={moment(record?.date_best_case)}
            onChange={(_, dateValue) =>
              handleCellChange(record.key, 'date_best_case', dateValue)
            }
            style={{ width: '100%' }}
          ></DatePicker>
        </FormItem>
      )
    },
    {
      title: 'Timeline (Best Case)',
      dataIndex: 'timeline_best_case',
      render: (_, record, index) => (
        <FormItem
          rules={[{ required: true, message: 'This is required field' }]}
        >
          {console.log(
            'timelineDifferences[index]::',
            timelineDifferences[index]
          )}
          {console.log(
            'record.timeline_best_case::',
            record.timeline_best_case
          )}
          <Input
            disabled
            value={
              timelineDifferences[index - 1] !== null
                ? timelineDifferences[index - 1]
                : record.timeline_best_case
            }
          />
        </FormItem>
        //   {

        //   if (timelineDifferences[index] !== null) {
        //     return timelineDifferences[index];
        //   }
        //   return record.timeline_best_case;
        // }
      )
    },
    {
      title: 'Date (Base Case)',
      dataIndex: 'date_base_case',
      render: (_, record) => (
        <FormItem
          //  name={['patent_expiration_row', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <DatePicker
            disabled={edit === 'optional'}
            //format={'YYYY-MMM-DD'}
            format={'DD MMM YYYY'}
            defaultValue={moment(record?.date_base_case)}
            onChange={(_, dateValue) =>
              handleCellChange(record.key, 'date_base_case', dateValue)
            }
            style={{ width: '100%' }}
          ></DatePicker>
        </FormItem>
      )
    },
    {
      title: 'Timeline (Base case)',
      dataIndex: 'timeline_base_case',
      render: (_, record, index) => (
        // <FormItem
        //   // name={['strategy', record.key]}
        //   rules={[{ required: true, message: 'This is required field' }]}
        // >
        //   <Input
        //     value={record.timeline_base_case}
        //     onChange={(e) =>
        //       handleCellChange(record.key, 'timeline_base_case', e.target.value)
        //     }
        //   />
        // </FormItem>
        <FormItem
          rules={[{ required: true, message: 'This is required field' }]}
        >
          <Input
            disabled
            value={
              timelineDifferences2[index - 1] !== null
                ? timelineDifferences2[index - 1]
                : record.timeline_base_case
            }
          />
        </FormItem>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Space
            size="middle"
            style={{
              display: 'flex',
              justifyContent: 'center' /* paddingLeft: '16px' */
            }}
          >
            <PlusOutlined onClick={edit === 'optional' ? '' : addRow} />
            {dataSource.length > 1 && (
              <DeleteOutlined
                onClick={() =>
                  edit === 'optional' ? '' : deleteRow(record.key)
                }
              />
            )}
          </Space>
        </FormItem>
      )
    }
  ];

  // const filteredDataSource = dataSource?.filter((item) => item.//is_active);

  // console.log('dataSource:::', dataSource);
  //console.log('filteredDataSource:::', filteredDataSource);

  return (
    <Form>
      <Table
        dataSource={dataSource} //{storedData === 'true' ? filteredDataSource : dataSource}
        columns={columns}
        pagination={false}
        className={styles.table_styles}
        scroll={{
          x: 1200
        }}
      />
    </Form>
  );
};

export default RD_Timeline_table;
