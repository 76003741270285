import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { history } from './app/history';
import { getLoggedInUser } from 'utilities/helpers';
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { useSelector } from 'react-redux';
import Loader from './common/Loader';

import Login from './features/login';
import Home from './features/home';
import Manage from './features/manage';
import Reports from './features/reports';
import PIF_FD_Form from './features/PIF_FD_form';
import PIF_API_Form from './features/PIF_API_form';
import FactorsModules from './features/PIF_FD_form/components/FactorsModules';
import Reference_product_details from './features/PIF_FD_form/components/proposed_product_details';
import SignatoryDashboard from './features/signatoryFlow/index';
import SignatoryListView from './features/signatoryFlow/components/signatoryListView';
import ApproverDashboard from './features/approverFlow';
import ApproverListView from './features/approverFlow/components/approverListView';
import Preview from './features/signatoryFlow/components/preview';
import PreviewApprover from './features/approverFlow/components/preview';
import PreviewRequester from './features/requesterListView/preview';
import RequesterListView from 'features/requesterListView/components';
import Edit_PIF_FD_Form from 'features/Edit_PIF_FD_form/index';
//import Draft_PIF_FD_Form from 'features/Draft_PIF_FD_form/index';
import OneClickApprove from '@/features/oneClickPublicAccess';
import OneClickReject from '@/features/oneClickPublicAccess/OneClickReject';
import ReviseEdit_PIF_FD_Form from 'features/ReviseEdit_PIF_FD_form';
import ForgetPassword from './features/login/components/FogotPassword';
import ResetPassword from './features/login/components/ResetPassword';
import ApproverAPiListView from './features/approverFlow/components/approverAPIListView';
import SignatoryAPIListView from './features/signatoryFlow/components/signatoryAPIListView';
import RequesterAPIListView from './features/requesterAPIListView/index';
import PreviewAPIRequester from './features/requesterAPIListView/preview';
import PreviewAPISignatory from './features/signatoryFlow/components/signatoryAPIListView/preview';
import PreviewAPIApprover from './features/approverFlow/components/approverAPIListView/preview';
import Revise_PIF_API_Form from './features/Revise_PIF_API_form';
import Edit_PIF_API_Form from './features/Edit_PIF_API_form';
import Reports_Api from './features/reports_Api';
import Admin from './features/admin';
import Admin_Api from './features/admin_api';

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_URL
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};
const pca = new PublicClientApplication(configuration);
pca.addEventCallback((event) => {
  // console.log(event);
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});
//const pca = new PublicClientApplication(configuration);

const roleBasedNavigation = (id) => {
  switch (id) {
    case 'requester':
      return '/dashboard';
    case 'admin':
      return '/gatepass-requests';
    default:
      return '/';
  }
};

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return <Loader />;
  }

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const AuthChecker = ({ children }) => {
  let location = useLocation();
  const { loggedIn, user } = getLoggedInUser();
  let goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : roleBasedNavigation(user?.user_details?.role || '');

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return children;
};

function App() {
  const { user } = getLoggedInUser();

  let goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : user?.user_details?.role
    ? roleBasedNavigation(user?.user_details?.role || '')
    : '/login';

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route path="one-click-approve" element={<OneClickApprove />} />
        <Route path="one-click-reject" element={<OneClickReject />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <Login />
              </MsalProvider>
            </AuthChecker>
          }
        />
        <Route
          path="forget-password"
          element={
            <MsalProvider instance={pca}>
              <ForgetPassword />
            </MsalProvider>
          }
        />

        <Route
          path="reset-password"
          element={
            <MsalProvider instance={pca}>
              <ResetPassword />
            </MsalProvider>
          }
        />

        <Route
          path="home"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Home />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="manage"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Manage />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="pif-fd-form"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <PIF_FD_Form />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="pif-api-form"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <PIF_API_Form />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="reference-product-details"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Reference_product_details />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="factors-modules"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <FactorsModules />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="signatory-dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SignatoryDashboard />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="signatory-list-view"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SignatoryListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="signatory-list-view/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SignatoryListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route path="signatory-preview/:id" element={<Preview />} />
        <Route
          path="signatory-api-preview/:id"
          element={<PreviewAPISignatory />}
        />
        {/* <AuthChecker>
              <MsalProvider instance={pca}>
                <Preview />
              </MsalProvider>
            </AuthChecker> */}
        <Route
          path="signatory-api-list-view"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SignatoryAPIListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="signatory-api-list-view/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SignatoryAPIListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="approver-dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ApproverDashboard />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="approver-list-view"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ApproverListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="approver-list-view/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ApproverListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route path="approver-preview/:id" element={<PreviewApprover />} />
        <Route
          path="approver-api-preview/:id"
          element={<PreviewAPIApprover />}
        />
        {/* <PrivateRoute>
              <MsalProvider instance={pca}>
                <PreviewApprover />
              </MsalProvider>
            </PrivateRoute> */}
        <Route
          path="approver-api-list-view"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ApproverAPiListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="approver-api-list-view/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ApproverAPiListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="requester-list-view"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <RequesterListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        {/* <Route
          path="requester-list-view-approver"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <RequesterListView props={'approver'} />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="requester-list-view-pending"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <RequesterListView props={'waiting_for_others'} />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="requester-list-view-rejected"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <RequesterListView props={'rejected'} />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="requester-list-view-draft"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <RequesterListView props={'draft'} />
              </MsalProvider>
            </PrivateRoute>
          }
        /> */}
        <Route
          path="requester-list-view/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <RequesterListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="requester-api-list-view"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <RequesterAPIListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="requester-api-list-view/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <RequesterAPIListView />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="requester-api-preview/:id"
          element={<PreviewAPIRequester />}
        />
        <Route path="requester-preview/:id" element={<PreviewRequester />} />
        {/* <PrivateRoute>
              <MsalProvider instance={pca}>
              <PreviewRequester />
               </MsalProvider>
            </PrivateRoute> */}
        <Route
          path="edit-pif-API-form/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Edit_PIF_API_Form />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="revise_edit-pif-API-form/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Revise_PIF_API_Form />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="edit-pif-fd-form/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Edit_PIF_FD_Form />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="revise_edit-pif-fd-form/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ReviseEdit_PIF_FD_Form />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="draft-pif-fd-form/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                {/* <Draft_PIF_FD_Form /> */}
                <PIF_FD_Form />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="draft-pif-API-form/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                {/* <Draft_PIF_FD_Form /> */}
                <PIF_API_Form />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="reports-fd"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Reports />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="reports-api"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Reports_Api />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="admin-fd"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Admin />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="admin-api"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Admin_Api />
              </MsalProvider>
            </PrivateRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
