import React from 'react';
import ReportsPage from './components/ReportsPage';
import CustomLayout from '@/common/Layout';

const Reports = () => {
  return (
    <CustomLayout>
      <div>
        <ReportsPage />
      </div>
    </CustomLayout>
  );
};

export default Reports;
