import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Layout, message, Tooltip } from 'antd';
import { history } from 'app/history';
import { getLoggedInUser } from 'utilities/helpers';
import { apiUrl } from 'utilities/apiClient';
import { useDispatch } from 'react-redux';
import CustomButton from '@/common/CustomButton';
import PDFViewer from './Pdf_view';
import checked from 'assets/images/icons/checked.svg';
import waitting from 'assets/images/icons/waitting.svg';
import rejected from 'assets/images/icons/rejected.svg';
import styles from './index.module.less';
import { sizeChecker } from 'utilities/helpers';
import { MessageOutlined } from '@ant-design/icons';
import moment from 'moment';

const Preview = ({
  PIF_form_id,
  setPageNum,
  getFormDetailsByIdData,
  initiateFdFormLoading,
  initiateFdForm
  //getFormDetailsByIdLoading
}) => {
  const dispatch = useDispatch();
  const isMobile = sizeChecker('xs');

  const [signatories, setSignatories] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = getLoggedInUser();
  const authToken = user?.accessToken;

  useEffect(() => {
    const signatoriesData = getFormDetailsByIdData?.approvers?.filter(
      (item) => item.approver_type === 'signatory'
    );

    setSignatories(signatoriesData);
  }, [getFormDetailsByIdData]);

  useEffect(() => {
    const approversData = getFormDetailsByIdData?.approvers?.filter(
      (item) => item.approver_type === 'approver'
    );
    setApprovers(approversData);
  }, [getFormDetailsByIdData]);

  const handelDownloadForm = () => {
    setLoading(true);

    fetch(`${apiUrl}/preview?pif_id=${PIF_form_id}&edit_type=${'draft'}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${authToken}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([blob]));
        console.log('url:::', url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `download.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  const onFinish = () => {
    const payload = {
      pif_id: PIF_form_id,
      edit_type: 'draft'
    };
    console.log('payload:', payload);

    dispatch(initiateFdForm({ payload }))?.then(function (response) {
      if (response?.payload?.success) {
        history.push(`/requester-list-view`);
      } else {
        message.error(response?.payload?.error);
        console.log('response?.payload?.error', response?.payload?.error);
      }
    });
  };
  const Header = () => {
    return (
      <>
        {/* <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <Col span={12}>
              <div className={`ml-3 sfprotext-bold font-18 mb-3 `}>
                {' '}
                <CustomButton
                  onClick={() => setPageNum(3)}
                  // className={`mr-2`}
                  className={styles.back_btn_style}
                >
                  {' '}
                  Go Back
                </CustomButton>
              </div>
            </Col>
            <Col className={`text-right`} span={12}>
              <CustomButton
                //  className={`mr-2`}
                className={styles.back_btn_style}
                onClick={() => history.push(`/home`)}
              >
                {' '}
                Cancel
              </CustomButton>
              <CustomButton
                //  className={`mr-2`}
                className={styles.back_btn_style}
                onClick={() => handelDownloadForm()}
                loading={loading}
              >
                {' '}
                Download Form
              </CustomButton>
              <CustomButton
                className={styles.post_btn_style}
                onClick={() => onFinish()}
                loading={initiateFdFormLoading}
              >
                Initiate Approval
              </CustomButton>
            </Col>
          </Row>
        </div> */}
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <Col span={12}>
              <div
                className={`ml-3 sfprotext-bold font-18 mb-3 ${styles.buttonContainer}`}
              >
                <CustomButton
                  onClick={() => setPageNum(3)}
                  className={styles.back_btn_style}
                >
                  Go Back
                </CustomButton>
                {isMobile && (
                  <CustomButton
                    className={styles.back_btn_style}
                    onClick={() => history.push(`/home`)}
                  >
                    Cancel
                  </CustomButton>
                )}
              </div>
            </Col>
            <Col className={`text-right ${styles.buttonContainer}`} span={12}>
              {!isMobile && (
                <CustomButton
                  className={styles.back_btn_style}
                  onClick={() => history.push(`/home`)}
                >
                  Cancel
                </CustomButton>
              )}
              <CustomButton
                className={styles.back_btn_style}
                onClick={() => handelDownloadForm()}
                loading={loading}
              >
                Download Form
              </CustomButton>
              <CustomButton
                className={styles.post_btn_style}
                onClick={() => onFinish()}
                loading={initiateFdFormLoading}
              >
                Initiate Approval
              </CustomButton>
            </Col>
          </Row>
        </div>
        ;
      </>
    );
  };
  return (
    <>
      <Layout className={`${styles.layout}`}>
        <Header />

        <div
          className={
            isMobile ? `${styles.container_isMobile}` : `${styles.container}`
          }
        >
          <Row>
            <Col className={` mr-4 text-left `} span={isMobile ? 24 : 17}>
              <Card
                hoverable
                className={` text-center ${styles.image_viewer_card}`}
                title={
                  <div className={`${styles.pdf_title_color_margin}`}>
                    Preview: Project Initiation Form
                  </div>
                }
                bordered={false}
              >
                <PDFViewer PIF_form_id={PIF_form_id} />
              </Card>
              <Card
                hoverable
                className={`mt-3 text-center ${styles.image_viewer_card}`}
                bordered={false}
              >
                <div className={`${styles.att_bg}`}>
                  <div
                    className={`ml-3 text-left ${styles.pdf_title_color_margin}`}
                  >
                    Message:{' '}
                    <spam className={`ml-2 text-left ${styles.message_text}`}>
                      {getFormDetailsByIdData?.message === null ||
                      getFormDetailsByIdData?.message === 'null' ||
                      getFormDetailsByIdData?.message === ''
                        ? 'NA'
                        : getFormDetailsByIdData?.message}
                    </spam>
                  </div>
                </div>
              </Card>
              <Card
                hoverable
                className={`mt-3 text-center ${styles.image_viewer_card}`}
                bordered={false}
              >
                <div className={`${styles.att_bg}`}>
                  <div
                    className={`ml-3 text-left  ${styles.pdf_title_color_margin}`}
                  >
                    Attachments:
                  </div>
                  {getFormDetailsByIdData?.attachments?.length === 0 ? (
                    <div className="ml-5 text-center">
                      {' '}
                      No attachments to display
                    </div>
                  ) : (
                    <ul className="ml-5 text-left">
                      {getFormDetailsByIdData?.attachments?.map(
                        (item, index) => (
                          <li key={index}>
                            <span>
                              <a
                                className={`${styles.uploadedDoc_style}`}
                                href={item?.fileUrl}
                                download
                              >
                                {item?.originalName}
                              </a>
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </div>
              </Card>
            </Col>

            <Col
              className={isMobile ? `text-center` : `ml-2 text-center`}
              span={isMobile ? 24 : 6}
            >
              {isMobile ? (
                <Card
                  hoverable
                  className={`mt-3 text-center ${styles.image_viewer_card}`}
                  bordered={false}
                >
                  <div className={`${styles.att_bg}`}>
                    <div className={`${styles.pdf_title_color_margin}`}>
                      Approval Flow
                    </div>
                    <div className={`${styles.container1_isMobile}`}>
                      <div
                        className={` ${styles.approver_container_margin_isMobile}`}
                      >
                        <div className={`${styles.pdf_title_color}`}>
                          Signatories
                        </div>

                        <div className={`${styles.container1}`}>
                          {signatories?.map((item, index) => (
                            <div className={`${styles.step} `} key={index}>
                              <div className={`${styles.v_stepper}`}>
                                {item?.status === 'signed' ? (
                                  <img src={checked} alt="checked" />
                                ) : item?.status === 'rejected' ? (
                                  <img src={rejected} alt="rejected" />
                                ) : item?.status === 'approved' ? (
                                  <img src={checked} alt="checked" />
                                ) : (
                                  <img src={waitting} alt=" waitting" />
                                )}
                                <div className={`${styles.line}`}></div>
                              </div>

                              <div className={`${styles.content}`}>
                                <b className="font-13">
                                  {item?.status === 'signed' ? (
                                    <div>
                                      <span style={{ color: '#52C41A' }}>
                                        Signed
                                      </span>
                                    </div>
                                  ) : item?.status === 'rejected' ? (
                                    <span style={{ color: '#FF4D4F' }}>
                                      Rejected{' '}
                                      <Tooltip title={item?.remarks}>
                                        <MessageOutlined
                                          style={{
                                            color: '#8C8C8C',
                                            marginLeft: '5px'
                                          }}
                                        />
                                      </Tooltip>
                                    </span>
                                  ) : item?.status === 'approved' ? (
                                    <div>
                                      <span style={{ color: '#52C41A' }}>
                                        Signed
                                      </span>
                                    </div>
                                  ) : (
                                    <span style={{ color: '#666687' }}>
                                      Yet to Sign
                                    </span>
                                  )}
                                </b>
                                <div className={`${styles.small_text}`}>
                                  {/*  {item?.status ? (
                              <> */}
                                  <div>{`${item?.user?.first_name} ${item?.user?.last_name}`}</div>
                                  {/*   </>
                            ) : (
                              ''
                            )} */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={` ${styles.approver_container_margin}`}>
                        {' '}
                        <div className={`${styles.pdf_title_color}`}>
                          Approvers
                        </div>
                        {/* {approvers?.map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.step_container} `}
                    >
                      <div className={`${styles.step} `}>
                        <div className={`${styles.v_stepper}`}>
                          <div className={`${styles.circle}`}>
                            <p>{index + 1}</p>
                          </div>
                        </div>
                        <div
                          className={` font-14 ${styles.signatories_name}`}
                        >
                          {`${item?.user?.first_name} ${item?.user?.last_name}`}
                        </div>
                      </div>
                    </div>
                  ))} */}
                        <div className={`${styles.container1}`}>
                          {approvers?.map((item, index) => (
                            <div className={`${styles.step} `} key={index}>
                              <div className={`${styles.v_stepper}`}>
                                {item?.status === 'signed' ? (
                                  <img src={checked} alt="checked" />
                                ) : item?.status === 'rejected' ? (
                                  <img src={rejected} alt="rejected" />
                                ) : item?.status === 'approved' ? (
                                  <img src={checked} alt="checked" />
                                ) : (
                                  <img src={waitting} alt=" waitting" />
                                )}
                                <div className={`${styles.line}`}></div>
                              </div>

                              <div className={`${styles.content}`}>
                                <b className="font-13">
                                  {item?.status === 'signed' ? (
                                    <div>
                                      <span style={{ color: '#52C41A' }}>
                                        Approved
                                      </span>
                                    </div>
                                  ) : item?.status === 'rejected' ? (
                                    <span style={{ color: '#FF4D4F' }}>
                                      Rejected{' '}
                                      <Tooltip title={item?.remarks}>
                                        <MessageOutlined
                                          style={{
                                            color: '#8C8C8C',
                                            marginLeft: '5px'
                                          }}
                                        />
                                      </Tooltip>
                                    </span>
                                  ) : item?.status === 'approved' ? (
                                    <div>
                                      <span style={{ color: '#52C41A' }}>
                                        Approved
                                      </span>
                                    </div>
                                  ) : (
                                    <span style={{ color: '#666687' }}>
                                      Yet to Sign
                                    </span>
                                  )}
                                </b>
                                <div className={`${styles.small_text} font-14`}>
                                  <div>{`${item?.user?.first_name} ${item?.user?.last_name}`}</div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* getFormDetailsByIdData?.approvers */}
                    </div>
                  </div>
                </Card>
              ) : (
                <Card hoverable className={` text-left ${styles.env_flow}`}>
                  <div className={`${styles.pdf_title_color_margin}`}>
                    Approval Flow
                  </div>
                  <div className={`${styles.container1}`}>
                    <div className={` ${styles.approver_container_margin}`}>
                      <div className={`${styles.pdf_title_color}`}>
                        Signatories
                      </div>
                      {/* {signatories?.map((item, index) => (
                        <div
                          key={index}
                          className={`${styles.step_container} `}
                        >
                          <div className={`${styles.step} `}>
                            <div className={`${styles.v_stepper}`}>
                              <div className={`${styles.circle}`}>
                                <p>{index + 1}</p>
                              </div>
                            </div>
                            <div
                              className={` font-14 ${styles.signatories_name}`}
                            >
                              {`${item?.user?.first_name} ${item?.user?.last_name}`}
                            </div>
                          </div>
                        </div>
                      ))} */}
                      <div className={`${styles.container1}`}>
                        {signatories?.map((item, index) => (
                          <div className={`${styles.step} `} key={index}>
                            <div className={`${styles.v_stepper}`}>
                              {item?.status === 'signed' ? (
                                <img src={checked} alt="checked" />
                              ) : item?.status === 'rejected' ? (
                                <img src={rejected} alt="rejected" />
                              ) : item?.status === 'approved' ? (
                                <img src={checked} alt="checked" />
                              ) : (
                                <img src={waitting} alt=" waitting" />
                              )}
                              <div className={`${styles.line}`}></div>
                            </div>

                            <div className={`${styles.content}`}>
                              <b className="font-13">
                                {item?.status === 'signed' ? (
                                  <div>
                                    <span style={{ color: '#52C41A' }}>
                                      Signed
                                    </span>
                                  </div>
                                ) : item?.status === 'rejected' ? (
                                  <span style={{ color: '#FF4D4F' }}>
                                    Rejected
                                    <Tooltip title={item?.remarks}>
                                      <MessageOutlined
                                        style={{
                                          color: '#8C8C8C',
                                          marginLeft: '5px'
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                ) : item?.status === 'approved' ? (
                                  <div>
                                    <span style={{ color: '#52C41A' }}>
                                      Signed
                                    </span>
                                  </div>
                                ) : (
                                  <span style={{ color: '#666687' }}>
                                    Yet to Sign
                                  </span>
                                )}
                              </b>
                              <div className={`${styles.small_text} `}>
                                {/*  {item?.status ? (
                                  <> */}
                                <div>
                                  {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                  <span>
                                    {item?.action_taken_on === null
                                      ? ''
                                      : ` on ${moment(
                                          item?.action_taken_on
                                        ).format('DD MMM YY')}`}
                                  </span>
                                </div>
                                {/*   </>
                                ) : (
                                  ''
                                )} */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={` ${styles.approver_container_margin}`}>
                      {' '}
                      <div className={`${styles.pdf_title_color}`}>
                        Approvers
                      </div>
                      {/* {approvers?.map((item, index) => (
                        <div
                          key={index}
                          className={`${styles.step_container} `}
                        >
                          <div className={`${styles.step} `}>
                            <div className={`${styles.v_stepper}`}>
                              <div className={`${styles.circle}`}>
                                <p>{index + 1}</p>
                              </div>
                            </div>
                            <div
                              className={` font-14 ${styles.signatories_name}`}
                            >
                              {`${item?.user?.first_name} ${item?.user?.last_name}`}
                            </div>
                          </div>
                        </div>
                      ))} */}
                      <div className={`${styles.container1}`}>
                        {approvers?.map((item, index) => (
                          <div className={`${styles.step} `} key={index}>
                            <div className={`${styles.v_stepper}`}>
                              {item?.status === 'signed' ? (
                                <img src={checked} alt="checked" />
                              ) : item?.status === 'rejected' ? (
                                <img src={rejected} alt="rejected" />
                              ) : item?.status === 'approved' ? (
                                <img src={checked} alt="checked" />
                              ) : (
                                <img src={waitting} alt=" waitting" />
                              )}
                              <div className={`${styles.line}`}></div>
                            </div>

                            <div className={`${styles.content}`}>
                              <b className="font-13">
                                {item?.status === 'signed' ? (
                                  <div>
                                    <span style={{ color: '#52C41A' }}>
                                      Approved
                                    </span>
                                  </div>
                                ) : item?.status === 'rejected' ? (
                                  <span style={{ color: '#FF4D4F' }}>
                                    Rejected
                                    <Tooltip title={item?.remarks}>
                                      <MessageOutlined
                                        style={{
                                          color: '#8C8C8C',
                                          marginLeft: '5px'
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                ) : item?.status === 'approved' ? (
                                  <div>
                                    <span style={{ color: '#52C41A' }}>
                                      Approved
                                    </span>
                                  </div>
                                ) : (
                                  <span style={{ color: '#666687' }}>
                                    Yet to Sign
                                  </span>
                                )}
                              </b>
                              <div className={`${styles.small_text}`}>
                                <div>
                                  {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                  <span>
                                    {item?.action_taken_on === null
                                      ? ''
                                      : ` on ${moment(
                                          item?.action_taken_on
                                        ).format('DD MMM YY')}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* getFormDetailsByIdData?.approvers */}
                  </div>
                </Card>
              )}
            </Col>
          </Row>
        </div>

        {/*  {signModal ? (
          <SignModal
            signModal={signModal}
            setSignModal={setSignModal}
            signOrApprove_form={signOrApprove_form}
            signOrApprove_formLoading={signOrApprove_formLoading}
          />
        ) : (
          ''
        )} */}
      </Layout>
    </>
  );
};
export default Preview;
