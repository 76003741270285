import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  dashboardCounts,
  PIFRequest,
  pollsResult,
  dashboardApiCounts
} from './api';
import { history } from '@/app/history';

const initialState = {
  getDashboardCountsLoading: false,
  dashboardCountsData: [],
  createPIFRequestLoading: false,
  getPollsResultLoading: false,
  pollsResultData: [],
  getDashboardApiCountsLoading: false,
  dashboardApiCountsData: []
};

const actions = {
  GET_DASHBOARD_COUNT: 'getDashboardCounts/GET_DASHBOARD_COUNT',
  CREATE_PIF_REQUEST: 'createPIFRequest/CREATE_PIF_REQUEST',
  GET_POLLS_RESULT: 'getPollsResult/GET_POLLS_RUSELT',
  GET_DASHBOARD_API_COUNT: 'getDashboardApiCounts/GET_DASHBOARD_API_COUNT'
};

export const getDashboardApiCounts = createAsyncThunk(
  actions.GET_DASHBOARD_API_COUNT,
  async (payload) => {
    const response = await dashboardApiCounts(payload);
    return response;
  }
);

export const getDashboardCounts = createAsyncThunk(
  actions.GET_DASHBOARD_COUNT,
  async (payload) => {
    const response = await dashboardCounts(payload);
    return response;
  }
);

export const createPIFRequest = createAsyncThunk(
  actions.CREATE_PIF_REQUEST,
  async ({ payload }) => {
    const response = await PIFRequest(payload);
    return response;
  }
);

export const getPollsResult = createAsyncThunk(
  actions.GET_POLLS_RESULT,
  async (payload) => {
    const response = await pollsResult(payload);

    return response;
  }
);

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardCounts.pending, (state) => {
        state.getDashboardCountsLoading = true;
      })
      .addCase(getDashboardCounts.fulfilled, (state, action) => {
        state.getDashboardCountsLoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dashboardCountsData = data ? data : [];
        } else {
          state.dashboardCountsData = [];
          //message.error(msg);
        }
      })
      .addCase(getDashboardCounts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getDashboardCountsLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createPIFRequest.pending, (state) => {
        state.createPIFRequestLoading = true;
      })
      .addCase(createPIFRequest.fulfilled, (state, action) => {
        state.createPIFRequestLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createPIFRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createPIFRequestLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPollsResult.pending, (state) => {
        state.getPollsResultLoading = true;
      })
      .addCase(getPollsResult.fulfilled, (state, action) => {
        state.getPollsResultLoading = false;
        const { success, data, message: msg } = action.payload;

        if (success) {
          state.pollsResultData = data ? data : [];
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.pollsResultData = [];
          message.error(msg);
        }
      })
      .addCase(getPollsResult.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getPollsResultLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getDashboardApiCounts.pending, (state) => {
        state.getDashboardApiCountsLoading = true;
      })
      .addCase(getDashboardApiCounts.fulfilled, (state, action) => {
        state.getDashboardApiCountsLoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.dashboardApiCountsData = data ? data : [];
        } else {
          state.dashboardApiCountsData = [];
          //message.error(msg);
        }
      })
      .addCase(getDashboardApiCounts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getDashboardApiCountsLoading = false;

        message.error(msg);
      });
  }
});

export default homeSlice.reducer;
