// import React from 'react';
// import { Input, Space, Form } from 'antd';
// import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
// import styles from './rd_details.module.less';
// import FormItem from 'common/FormItem';

// const DynamicInputField = ({ fields, setFields, generateKey_bs }) => {
//   const addField = () => {
//     //const newId = fields.length;
//     const newField = {
//       id: generateKey_bs(),
//       batch_size: '',
//       is_active: true
//     }; /*  */
//     setFields([...fields, newField]);
//   };

//   const deleteField = (id) => {
//     const updatedFields = fields.filter((field) => field.id !== id);
//     setFields(updatedFields);
//     // console.log('deleteKey: ', id);
//     // const updatedFields = [...fields];
//     // const index = updatedFields.findIndex((item) => item.key === id);
//     // if (index !== -1) {
//     //   updatedFields[index].is_active = false;

//     //   // Update the state with the modified data source
//     //   setDataSource1(updatedFields);
//     // } else {
//     //   console.log('Object with key', key, 'not found in dataSource1.');
//     // }
//   };
//   //console.log('fields: ', fields);
//   const handleInputChange = (id, e) => {
//     const updatedFields = fields.map((field) =>
//       field.id === id ? { ...field, batch_size: e.target.value } : field
//     );
//     setFields(updatedFields);
//   };

//   return (
//     <Form>
//       {/*  <Form.Item key={field.id} label={`Field ${index + 1}`}>  */}
//       <FormItem
//         label="R&D Batch Size"
//         rules={[
//           {
//             required: false,
//             message: 'Required field'
//           }
//         ]}
//         className={`${styles.input_styles}`}
//       >
//         {' '}
//         {fields?.map((field, index) => (
//           <Space
//             key={index}
//             direction="horizontal"
//             className={`${styles.inputList_styles}`}
//           >
//             <Input
//               value={field.batch_size}
//               onChange={(e) => handleInputChange(field.id, e)}
//               placeholder="Enter R&D batch size"
//             />
//             <div>
//               {index === fields.length - 1 && (
//                 <PlusOutlined
//                   onClick={addField}
//                   className={`${styles.inputList_plusIcon}`}
//                 />
//               )}
//               {fields.length > 1 && (
//                 <DeleteOutlined
//                   onClick={() => deleteField(field.id)}
//                   className={`${styles.inputList_deleteIcon}`}
//                 />
//               )}
//             </div>
//           </Space>
//         ))}
//       </FormItem>

//       {/* ------Display Output---------
//       <Form.Item>
//         {fields.map((field) => (
//           <Input
//             key={field.id}
//             value={field.value}
//             disabled
//             style={{ marginTop: 8, width: '100%' }}
//           />
//         ))}
//       </Form.Item> */}
//     </Form>
//   );
// };

// export default DynamicInputField;

import React from 'react';
import { Input, Space, Form } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './rd_details.module.less';
import FormItem from 'common/FormItem';

const DynamicInputField = ({
  fields,
  setFields,
  generateKey_bs
  //storedData_bs
}) => {
  // function generateKey_bs() {
  //   return Math.random().toString(36).substr(2, 10);
  // }
  const addField = () => {
    const newField = {
      key: generateKey_bs(),
      batch_size: ''
      //is_active: true
    };
    setFields([...fields, newField]);
  };

  const deleteField = (key) => {
    const updatedFields = fields.filter((field) => field.key !== key);
    setFields(updatedFields);
    // if (storedData_bs === 'true') {
    //   //console.log('storedData_bs_TRUE: ', storedData_bs);
    //   const updatedFields = fields?.map((item) =>
    //     item.key === key ? { ...item, is_active: false } : item
    //   );
    //   // console.log('updatedFields: ', updatedFields);
    //   setFields(updatedFields);
    // } else {
    //   console.log('storedData_bs_FALSE: ', storedData_bs);
    //   const updatedFields = fields.filter((field) => field.key !== key);
    //   setFields(updatedFields);
    // }

    /*  if (storedData1 === 'true') {
      const updatedDataSource = dataSource1?.map((item) =>
        item.key === key ? { ...item, is_active: false } : item
      );
      setDataSource1(updatedDataSource);

      // Enable the deleted option in the dropdown
      // const deletedRow = dataSource1.find((item) => item.key === key);
      // if (deletedRow) {
      //   // Enable the deleted option in the dropdown
      //   setDisabledOptions(
      //     disabledOptions.filter((id) => id !== deletedRow.stages)
      //   );
      // }
    } else {
      const updatedData = dataSource1?.filter((item) => item.key !== key);
      setDataSource1(updatedData); */
  };

  // const deleteField = (id) => {
  //   const updatedFields = fields.filter((field) => field.id !== id);
  //   setFields(updatedFields);
  //   // console.log('deleteKey: ', id);
  //   // const updatedFields = [...fields];
  //   // const index = updatedFields.findIndex((item) => item.key === id);
  //   // if (index !== -1) {
  //   //   updatedFields[index].is_active = false;

  //   //   // Update the state with the modified data source
  //   //   setDataSource1(updatedFields);
  //   // } else {
  //   //   console.log('Object with key', key, 'not found in dataSource1.');
  //   // }
  // };

  const handleInputChange = (key, e) => {
    const updatedFields = fields.map((field) =>
      field.key === key ? { ...field, batch_size: e.target.value } : field
    );
    setFields(updatedFields);
  };
  console.log('fields: ', fields);
  // const inactiveFields = fields?.filter((field) => !field.is_active);
  //const activeFields = fields?.filter((field) => field.is_active);
  //console.log('activeFields: ', activeFields);
  return (
    <Form>
      <FormItem
        label="R&D Batch Size"
        rules={[
          {
            required: false,
            message: 'Required field'
          }
        ]}
        className={`${styles.input_styles}`}
      >
        {fields
          //  ?.filter((field) => field.is_active)
          ?.map((field, index) => (
            <Space
              key={index}
              direction="horizontal"
              className={`${styles.inputList_styles}`}
            >
              <Input
                value={field.batch_size}
                onChange={(e) => handleInputChange(field.key, e)}
                placeholder="Enter R&D batch size"
              />
              <div>
                <PlusOutlined
                  onClick={addField}
                  className={`${styles.inputList_plusIcon}`}
                />

                {fields?.length > 1 ? (
                  <DeleteOutlined
                    onClick={() => deleteField(field.key)}
                    className={`${styles.inputList_deleteIcon}`}
                  />
                ) : (
                  ''
                )}
              </div>
            </Space>
          ))}
        {/*  {activeFields?.map((field, index) => (
          <Space
            key={index}
            direction="horizontal"
            className={`${styles.inputList_styles}`}
          >
            <Input
              value={field.batch_size}
              onChange={(e) => handleInputChange(field.key, e)}
              placeholder="Enter R&D batch size"
            />
            <div>
              <PlusOutlined
                onClick={addField}
                className={`${styles.inputList_plusIcon}`}
              />
              {activeFields.length > 1 ? (
                <DeleteOutlined
                  onClick={() => deleteField(field.key)}
                  className={`${styles.inputList_deleteIcon}`}
                />
              ) : null}
            </div>
          </Space>
        ))} */}
      </FormItem>
    </Form>
  );
};

export default DynamicInputField;
