import { useEffect, useState, useMemo } from 'react';
import { Form, Input, Row, Col, Collapse, Divider, Spin, Badge } from 'antd';
import { DownOutlined } from '@ant-design/icons';
//import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './market_data.module.less';
import { useDispatch, useSelector } from 'react-redux';
import FormItem from 'common/FormItem';
import CustomCollapse from '@/common/CustomCollapse';
import { debounce } from 'lodash';

import moment from 'moment';
import { history } from 'app/history';

// import DynamicTable from './Table';
// import US_IMS_table from '@/common/MD_Tables/US_IMS_table/US_IMS_table';
import Global_table from '@/common/MD_Tables/Global_table/Global_table';
import { dropDown_md_ims } from '../../redux/slice';
import IMS__API_table from '@/common/MD_Tables/IMS__API_table/IMS__API_table';

const { Panel } = Collapse;

const MarketData = ({
  PIF_form_id,
  // createUpdateIPDetail_formLoading,
  // createUpdateIPDetail_form,
  completedTabs,
  setCompletedTabs,
  isTabEnabled,
  getFormDetailsByIdLoading,
  getFormDetailsByIdData,
  setPageNum,
  dropDown_table_headersData,
  dropDown_md_global_data,
  // dropDown_md_ims_data,
  debounceMd_global,
  // debounceMd_ims,
  createUpdateMarketData_form,
  createUpdateMarketData_formLoading
}) => {
  if (!completedTabs?.includes(8)) {
    setCompletedTabs([...completedTabs, 8]);
    //console.log('completedTabs::::', completedTabs);
  }
  isTabEnabled(8);
  useEffect(() => {
    setPageNum(1);
  }, []);
  const mk_edit = localStorage.getItem('Edit_PIF_form_start');

  const { dropDown_md_ims_data } = useSelector((state) => state.pif_fd_form);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [formData1, setFormData1] = useState({});
  const [dataSource, setDataSource] = useState();
  const [count, setCount] = useState();
  const [selectedYears, setSelectedYears] = useState({});
  const [dataSource1, setDataSource1] = useState();
  const [count1, setCount1] = useState();
  const [selectedYears1, setSelectedYears1] = useState({});
  const [md_ims, setMd_ims] = useState('');

  const [global_total, setGlobal_total] = useState('');
  const [totals, setTotals] = useState({
    sales1: 0,
    sales2: 0,
    vol1: 0,
    vol2: 0
  });
  //const dataSourceWithTotals = [];
  const [dataSourceWithTotals, setDataSourceWithTotals] = useState([]);

  const [ims_total, setIms_total] = useState('');
  const [dataSource1WithTotals, setDataSource1WithTotals] = useState([]);

  const [imsTotals, setImsTotals] = useState({
    vol1: 0,
    vol2: 0
  });

  useEffect(() => {
    const total_name = dropDown_md_global_data?.filter(
      (item) => item.name === 'Worldwide (Total)'
    );
    setGlobal_total(total_name);
  }, [dropDown_md_global_data]);

  useEffect(() => {
    const total_name = dropDown_md_ims_data?.filter(
      (item) => item.name === 'Total'
    );
    setIms_total(total_name);
  }, [dropDown_md_ims_data]);
  //console.log('global_total>>>:', global_total);
  console.log('dataSourceWithTotals@@@@@@@', dataSourceWithTotals);
  //console.log('totals@@@@@', totals);

  useEffect(() => {
    let payload_md_ims = {
      pif_id: PIF_form_id,
      model_name: 'md_ims_data',
      page: 1,
      per_page: '100'
    };
    payload_md_ims['search'] = md_ims;
    dispatch(dropDown_md_ims(payload_md_ims));
  }, [md_ims, getFormDetailsByIdData]);

  const debounceMd_ims = useMemo(() => {
    const loadOptions = (md_ims) => {
      setMd_ims(md_ims);
    };
    return debounce(loadOptions, 500);
  }, []);

  console.log('selectedYears::::::::', selectedYears);
  // ----MK_US IMS Data_table transfarform Payload according to API structure -------
  const transformData = (rawData) => {
    console.log('rawData:', rawData);
    const transformedData = rawData?.map((row) => ({
      units_and_others_id: row.units_and_others_id,
      ims_strength_values: [
        {
          fy_master_id: selectedYears.sales1 || '',
          value: parseFloat(row.sales1)
        },
        {
          fy_master_id: selectedYears.sales2 || '',
          value: parseFloat(row.sales2)
        },
        {
          fy_master_id: selectedYears.vol1 || '',
          value: parseFloat(row.vol1)
        },
        {
          fy_master_id: selectedYears.vol2 || '',
          value: parseFloat(row.vol2)
        },
        {
          fy_master_id: selectedYears.api1 || '',
          value: parseFloat(row.api1)
        },
        {
          fy_master_id: selectedYears.api2 || '',
          value: parseFloat(row.api2)
        },
        {
          fy_master_id: '',
          value: parseFloat(row.gr)
        }
      ]
    }));
    return transformedData;
  };

  //console.log('transformData::::', transformData);
  // ----MK_Global Data_table transfarform Payload according to API structure -------
  const transformData1 = (rawData) => {
    console.log('rawData1:', rawData);
    const transformedData = rawData?.map((row) => ({
      units_and_others_id: row.units_and_others_id,
      mdgd_strength_values: [
        {
          fy_master_id: selectedYears1.sales1 || '',
          value: parseFloat(row.sales1)
        },
        {
          fy_master_id: selectedYears1.sales2 || '',
          value: parseFloat(row.sales2)
        },
        {
          fy_master_id: '',
          value: parseFloat(row.gr2)
        },
        {
          fy_master_id: selectedYears1.vol1 || '',
          value: parseFloat(row.vol1)
        },
        {
          fy_master_id: selectedYears1.vol2 || '',
          value: parseFloat(row.vol2)
        },
        {
          fy_master_id: '',
          value: parseFloat(row.gr)
        }
      ]
    }));
    return transformedData;
  };
  // ----MK_US IMS Data_table transfarform Response Data for populate in table cells -------
  const transformResponse_ims_data_cells = (dataSourceEdit) => {
    const sortedData = dataSourceEdit?.map((item) => ({
      ...item,
      ims_strength_values: [...item.ims_strength_values]?.sort(
        (a, b) => a.id - b.id
      )
    }));
    return sortedData?.map((item, index) => {
      const transformed = {
        key: index + 1,
        units_and_others_id: item.units_and_others_id || '',
        sales1: '',
        sales2: '',
        vol1: '',
        vol2: '',
        api1: '',
        api2: '',
        gr: ''
      };
      item.ims_strength_values.forEach((valueItem, idx) => {
        console.log('valueItem:', valueItem);
        switch (idx) {
          case 0:
            transformed.sales1 = parseFloat(valueItem.value);
            break;
          case 1:
            transformed.sales2 = parseFloat(valueItem.value);
            break;
          case 2:
            transformed.vol1 = parseFloat(valueItem.value);
            break;
          case 3:
            transformed.vol2 = parseFloat(valueItem.value);
            break;
          case 4:
            transformed.api1 = parseFloat(valueItem.value);
            break;
          case 5:
            transformed.api2 = parseFloat(valueItem.value);
            break;
          case 6:
            transformed.gr = parseFloat(valueItem.value);
            break;
          default:
            break;
        }
      });
      return transformed;
    });
  };
  // ----MK_US IMS Data_table transfarform Response Data for populate in table header titels -------
  const transformResponse_ims_data_headerYears = (dataSourceEdit) => {
    const sortedData = dataSourceEdit?.map((item) => ({
      ...item,
      ims_strength_values: [...item.ims_strength_values]?.sort(
        (a, b) => a.id - b.id
      )
    }));
    return sortedData?.map((item, index) => {
      const transformed = {
        key: index + 1,
        units_and_others_id: item.units_and_others_id || '',
        sales1: '',
        sales2: '',
        vol1: '',
        vol2: '',
        api1: '',
        api2: '',
        gr: ''
      };
      item.ims_strength_values?.forEach((valueItem, idx) => {
        switch (idx) {
          case 0:
            transformed.sales1 = valueItem.units_and_others_id;
            break;
          case 1:
            transformed.sales2 = valueItem.units_and_others_id;
            break;
          case 2:
            transformed.vol1 = valueItem.units_and_others_id;
            break;
          case 3:
            transformed.vol2 = valueItem.units_and_others_id;
            break;
          case 4:
            transformed.api1 = valueItem.units_and_others_id;
            break;
          case 5:
            transformed.api2 = valueItem.units_and_others_id;
            break;
          case 6:
            transformed.gr = valueItem.units_and_others_id;
            break;
          default:
            break;
        }
      });
      return transformed;
    });
  };

  //----MK_Global Data_table transfarform Response Data for populate in table cells -------
  const transformResponse_global_data_cells = (dataSourceEdit) => {
    const sortedData = dataSourceEdit?.map((item) => ({
      ...item,
      mdgd_strength_values: [...item.mdgd_strength_values]?.sort(
        (a, b) => a.id - b.id
      )
    }));
    return sortedData?.map((item, index) => {
      const transformed = {
        key: index + 1,
        units_and_others_id: item.units_and_others_id || '',
        sales1: '',
        sales2: '',
        gr2: '',
        vol1: '',
        vol2: '',
        gr: ''
      };
      item.mdgd_strength_values?.forEach((valueItem, idx) => {
        console.log('valueItem:', valueItem);
        switch (idx) {
          case 0:
            transformed.sales1 = parseFloat(valueItem.value);
            break;
          case 1:
            transformed.sales2 = parseFloat(valueItem.value);
            break;
          case 2:
            transformed.gr2 = parseFloat(valueItem.value);
            break;
          case 3:
            transformed.vol1 = parseFloat(valueItem.value);
            break;
          case 4:
            transformed.vol2 = parseFloat(valueItem.value);
            break;
          case 5:
            transformed.gr = parseFloat(valueItem.value);
            break;
          default:
            break;
        }
      });
      return transformed;
    });
  };
  // ----MK_Global Data_table transfarform Response Data for populate in table header titels -------
  const transformResponse_global_data_headerYears = (dataSourceEdit) => {
    const sortedData = dataSourceEdit?.map((item) => ({
      ...item,
      mdgd_strength_values: [...item.mdgd_strength_values]?.sort(
        (a, b) => a.id - b.id
      )
    }));
    return sortedData?.map((item, index) => {
      const transformed = {
        key: index + 1,
        units_and_others_id: item.units_and_others_id || '',
        sales1: '',
        sales2: '',
        gr2: '',
        vol1: '',
        vol2: '',
        gr: ''
      };
      item.mdgd_strength_values?.forEach((valueItem, idx) => {
        const yearName = valueItem.units_and_other
          ? valueItem.units_and_other.id
          : '';
        switch (idx) {
          case 0:
            transformed.sales1 = yearName;
            break;
          case 1:
            transformed.sales2 = yearName;
            break;
          case 2:
            transformed.gr2 = yearName;
            break;
          case 3:
            transformed.vol1 = yearName;
            break;
          case 4:
            transformed.vol2 = yearName;
            break;
          case 5:
            transformed.gr = yearName;
            break;
          default:
            break;
        }
      });
      return transformed;
    });
  };

  //----MK_US IMS Data_table-------
  useEffect(() => {
    if (
      mk_edit === 'false' ||
      getFormDetailsByIdData?.market_data === null ||
      getFormDetailsByIdData?.market_data?.us_ims_strengths?.length === 0 ||
      (mk_edit === 'true' &&
        getFormDetailsByIdData?.market_data?.us_ims_strengths?.length === 0)
    ) {
      console.log('mk_edit_false:', mk_edit);
      setDataSource([
        {
          key: 1,
          units_and_others_id: '',
          sales1: '',
          sales2: '',
          vol1: '',
          vol2: '',
          api1: '',
          api2: '',
          gr: ''
        } // Initial empty row
      ]);
    }
  }, [getFormDetailsByIdData, mk_edit === 'false']);

  useEffect(() => {
    setCount(dataSource?.length + 1);
  }, [dataSource]);
  useEffect(() => {
    if (
      getFormDetailsByIdData?.market_data?.us_ims_strengths?.length > 0 &&
      mk_edit === 'true'
    ) {
      const rowsData = transformResponse_ims_data_cells(
        getFormDetailsByIdData?.market_data?.us_ims_strengths
      );

      setDataSource(rowsData);

      const yearsEdit = transformResponse_ims_data_headerYears(
        getFormDetailsByIdData?.market_data?.us_ims_strengths
      );
      //console.log('yearsEdit:', yearsEdit[0]);
      setSelectedYears(yearsEdit[0]);
    }
  }, [getFormDetailsByIdData, mk_edit === 'true']);

  const transformedData_US_IMS =
    dataSource1WithTotals?.length === 0 && mk_edit === 'true'
      ? transformData(dataSource)
      : transformData(dataSource1WithTotals);

  //----MK_Global Data_table-------
  function generateKey() {
    return Math.random().toString(36).substr(2, 10);
  }
  useEffect(() => {
    console.log('mk_edit_false!!!!!!!!!!!:', mk_edit);
    if (
      mk_edit === 'false' ||
      getFormDetailsByIdData?.market_data === null ||
      getFormDetailsByIdData?.market_data?.mdgd_strengths?.length === 0 ||
      (mk_edit === 'true' &&
        getFormDetailsByIdData?.market_data?.mdgd_strengths?.length === 0)
    ) {
      setDataSource1([
        {
          key: generateKey(), //1,
          units_and_others_id: '',
          sales1: '',
          sales2: '',
          gr2: '',
          vol1: '',
          vol2: '',
          gr: ''
        } // Initial empty row
      ]);
    }
  }, [getFormDetailsByIdData, mk_edit === 'false']);

  useEffect(() => {
    setCount1(dataSource1?.length + 1);
  }, [dataSource1]);

  useEffect(() => {
    console.log('mk_edit_TRUE2222222:', mk_edit);

    if (
      getFormDetailsByIdData?.market_data?.mdgd_strengths?.length > 0 &&
      mk_edit === 'true'
    ) {
      const rowsData = transformResponse_global_data_cells(
        getFormDetailsByIdData?.market_data?.mdgd_strengths
      );

      setDataSource1(rowsData);

      const yearsEdit = transformResponse_global_data_headerYears(
        getFormDetailsByIdData?.market_data?.mdgd_strengths
      );
      //console.log('yearsEdit:', yearsEdit[0]);
      setSelectedYears1(yearsEdit[0]);
    }
  }, [getFormDetailsByIdData, mk_edit === 'true']);

  const transformedData_Global =
    dataSourceWithTotals?.length === 0 && mk_edit === 'true'
      ? transformData1(dataSource1)
      : transformData1(dataSourceWithTotals);

  useEffect(() => {
    form.setFieldsValue({
      Remarks:
        getFormDetailsByIdData?.market_data !== null
          ? getFormDetailsByIdData?.market_data?.md_remarks || ''
          : ''
    });
  }, [getFormDetailsByIdData]);

  useEffect(() => {
    form.setFieldsValue({
      initiation_date: moment() || ''
    });
  });

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };
  console.log('dataSource1________', dataSource1);

  const onFinish = () => {
    const payload = {
      edit_type: 'mandatory',
      pif_id: PIF_form_id,
      md_remarks:
        formData1?.Remarks === undefined
          ? getFormDetailsByIdData?.market_data?.md_remarks
          : formData1?.Remarks,
      md_us_ims_strengths:
        transformedData_US_IMS.length &&
        transformedData_US_IMS[0].units_and_others_id &&
        transformedData_US_IMS[0].ims_strength_values.every(
          (obj) => obj.value !== null
        )
          ? transformedData_US_IMS
          : [],
      md_global_data_strengths:
        transformedData_Global?.length &&
        transformedData_Global[0].units_and_others_id &&
        transformedData_Global[0].mdgd_strength_values.every(
          (obj) => obj.value !== null
        )
          ? transformedData_Global
          : []
    };

    dispatch(createUpdateMarketData_form({ payload }))?.then(function (
      response
    ) {
      if (response?.payload?.success) {
        setPageNum(2);
      } else {
        //message.error('Please fill required field');
        console.log('response?.payload?.error', response?.payload?.error);
      }
    });
  };

  return (
    <div className={`${styles.page_container}`}>
      <Spin spinning={getFormDetailsByIdLoading}>
        <Row className={styles.pif_page_titel_container}>
          <div className={`${styles.page_title}`}>
            <span className={styles.title_label}>Market Data</span>
          </div>
          {getFormDetailsByIdData?.changes_in_progress === true && (
            <Badge
              count={'Changes in progress'}
              style={{
                backgroundColor: '#ffffff',
                color: '#0098da',
                textTransform: 'none',
                borderColor: '#0098da',
                fontSize: '12px',
                //fontFamily: 'SFProText Regular',
                padding: '10px 15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '-20px'
              }}
            />
          )}
        </Row>
        <Form
          form={form}
          name="PIF_RD_Information"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <span style={{ fontWeight: 500, fontSize: '14px' }}>
                    IMS Data
                  </span>
                }
                //extra="Add email message"
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />

                <IMS__API_table
                  dropDown_table_headersData={dropDown_table_headersData}
                  dataSource={dataSource}
                  setDataSource={setDataSource}
                  count={count}
                  setCount={setCount}
                  selectedYears={selectedYears}
                  setSelectedYears={setSelectedYears}
                  dropDown_md_ims_data={dropDown_md_ims_data}
                  debounceMd_ims={debounceMd_ims}
                  //
                  global_total={ims_total}
                  dataSourceWithTotals={dataSource1WithTotals}
                  totals={imsTotals}
                  setTotals={setImsTotals}
                  setDataSourceWithTotals={setDataSource1WithTotals}
                  generateKey={generateKey}
                />
              </Panel>
            </CustomCollapse>
          </Row>

          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <span style={{ fontWeight: 500, fontSize: '14px' }}>
                    Global Data
                  </span>
                }
                //extra="Add email message"
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />
                <Global_table
                  dropDown_table_headersData={dropDown_table_headersData}
                  dataSource={dataSource1}
                  setDataSource={setDataSource1}
                  count={count1}
                  setCount={setCount1}
                  selectedYears={selectedYears1}
                  setSelectedYears={setSelectedYears1}
                  dropDown_md_global_data={dropDown_md_global_data}
                  debounceMd_global={debounceMd_global}
                  global_total={global_total}
                  dataSourceWithTotals={dataSourceWithTotals}
                  totals={totals}
                  setTotals={setTotals}
                  setDataSourceWithTotals={setDataSourceWithTotals}
                  generateKey={generateKey}
                />
              </Panel>
            </CustomCollapse>
          </Row>
          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <Col span={24}>
              <FormItem
                label="Remarks"
                name="Remarks"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter remarks"
                  onChange={(e) =>
                    handleFormDataOnChange('Remarks', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              marginTop: '15%'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/home`)}
            >
              Cancel
            </CustomButton>
            <FormItem>
              <CustomButton
                loading={createUpdateMarketData_formLoading}
                type="primary"
                htmlType="submit"
                className={styles.post_btn_style}
                //onClick={() => setPageNum(2)}
              >
                Next
              </CustomButton>
            </FormItem>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default MarketData;
