import React, { useRef, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { getLoggedInUser } from 'utilities/helpers';
import { apiUrl } from 'utilities/apiClient';
import styles from './index.module.less';
import { Spin } from 'antd';

// import { Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const PDFViewer = ({ PIF_form_id }) => {
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  //const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const containerRef = useRef(null);

  const user = getLoggedInUser();
  const authToken = user?.accessToken;

  useEffect(() => {
    setLoading(true);

    fetch(`${apiUrl}/preview?pif_id=${PIF_form_id}&edit_type=${'mandatory'}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${authToken}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        setLoading(false);
        const url = window.URL.createObjectURL(blob);
        setPdfData(url);
      })
      .catch((error) => {
        console.error('Error fetching PDF:', error);
        setLoading(false);
      });
  }, [PIF_form_id, authToken]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  //   const goToPrevPage = () =>
  //     setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  //   const goToNextPage = () =>
  //     setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));

  return (
    <div>
      {/*  {pdfData ? (
        <div>
          <Document
            file={pdfData}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div>
            <button onClick={goToPrevPage} disabled={pageNumber <= 1}>
              Previous
            </button>
            <span>Page {pageNumber} of {numPages}</span>
            <button onClick={goToNextPage} disabled={pageNumber >= numPages}>
              Next
            </button>
          </div>
        </div>
      ) : (
        <p>Loading PDF...</p>
      )} */}
      {/* {pdfData ? ( */}
      <Spin spinning={loading}>
        <div ref={containerRef}>
          <Document
            file={pdfData}
            onLoadSuccess={onDocumentLoadSuccess}
            // className={`${styles.document_viewer}`}
          >
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                width={window.innerWidth}
                key={`page_${index + 1}`}
                wrap={true}
                pageNumber={index + 1}
                className={`${styles.document_viewer_page}`}
                size="A6"
              />
            ))}
          </Document>
        </div>
      </Spin>
      {/*    ) : (
        <p>Loading PDF...</p>
      )} */}
      {/* <div
        style={{
          border: '1px solid #eaeaea',
          height: '750px',
          width: 'auto'
        }}
      >
        <Viewer
          file={pdfData}
          defaultScale={windowWidth < 768 ? 0.4 : 1.2}
          plugins={[defaultLayoutPluginInstance]}
          fileUrl={`${all_file_data?.document_url}`}
        />
      </div> */}
    </div>
  );
};

export default PDFViewer;
