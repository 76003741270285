import React from 'react';
import ManagePage from './components/ManagePage';
import CustomLayout from '@/common/Layout';

const Manage = () => {
  return (
    <CustomLayout>
      <div>
        <ManagePage />
      </div>
    </CustomLayout>
  );
};

export default Manage;
