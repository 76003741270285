import React from 'react';
import CustomLayout from '@/common/Layout';
import AdminPage from './components/AdminPage';

const Admin = () => {
  return (
    <CustomLayout>
      <div>
        <AdminPage />
      </div>
    </CustomLayout>
  );
};

export default Admin;
