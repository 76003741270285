import apiClient from 'utilities/apiClient';

export const pif_FD_DropDownData = (payload) => {
  return apiClient.get(apiClient.Urls.pif_FD_DropDownData, payload, true);
  //return apiClient.get(`${apiClient.Urls.baseUrl}/${url}`, payload, true);
};
export const getFormDetailsById = (payload) => {
  return apiClient.get(apiClient.Urls.getFormDetailsById, payload, true);
};
/* export const requests = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.requester}/${url}`, payload, true);
}; */

export const createUpdateFdInfo = (payload) => {
  return apiClient.post(
    apiClient.Urls.createUpdateFdInfo,
    payload,
    true
    // 'file'
  );
};
export const createUpdateRefProductDetail = (payload) => {
  return apiClient.post(
    apiClient.Urls.createUpdateRefProductDetail,
    payload,
    true
    // 'file'
  );
};

export const createUpdatePropProductDetail = (payload) => {
  return apiClient.post(
    apiClient.Urls.createUpdatePropProductDetail,
    payload,
    true
    // 'file'
  );
};

export const createUpdateIPDetail = (payload) => {
  return apiClient.post(
    apiClient.Urls.createUpdateIPDetail,
    payload,
    true
    // 'file'
  );
};

export const createUpdateRDDetail = (payload) => {
  return apiClient.post(
    apiClient.Urls.createUpdateRDDetail,
    payload,
    true
    // 'file'
  );
};
export const createUpdateCompetitiveScenario = (payload) => {
  return apiClient.post(
    apiClient.Urls.createUpdateCompetitiveScenario,
    payload,
    true
    // 'file'
  );
};

export const createUpdateBJ = (payload) => {
  return apiClient.post(
    apiClient.Urls.createUpdateBJ,
    payload,
    true
    // 'file'
  );
};
export const createUpdateMarketData = (payload) => {
  return apiClient.post(
    apiClient.Urls.createUpdateMarketData,
    payload,
    true
    // 'file'
  );
};
export const signatories = (payload) => {
  return apiClient.get(apiClient.Urls.signatories, payload, true);
};
export const approvers = (payload) => {
  return apiClient.get(apiClient.Urls.approvers, payload, true);
};
export const createUpdateApprovers = (payload) => {
  return apiClient.post(
    apiClient.Urls.createUpdateApprovers,
    payload,
    true
    // 'file'
  );
};
export const uploadAttachments = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.uploadAttachments}`,
    payload,
    true,
    'file'
  );
};

export const deleteUploadedDocs = (payload) => {
  return apiClient.delete(`${apiClient.Urls.deleteDocuments}`, payload, true);
};

export const initiateFdFormApi = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.initiateFdFormApi}`,
    payload,
    true
    // 'file'
  );
};
