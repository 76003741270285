import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Input, Checkbox, Button, Form } from 'antd';
import styles from './index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import left_image from '../../images/granule-banner.svg';
import leftInside_image from '../../images/leftInside_image.svg';
import granules_logo from '../../images/granules-logo.png';
import granules_logo_mobile from 'assets/images/granules-mobile.png';
import { LoginOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { login } from '../../redux/slice';
import { useResponsiveness } from 'hooks/useResponsiveness';
import { getLoggedInUser } from 'utilities/helpers';

const LoginPage = () => {
  const user = getLoggedInUser();
  let role = user?.user_details?.role;

  console.log('role', role);

  const [payload, setPayload] = useState({ email: '', password: '' });
  const [form] = Form.useForm();
  const { xs, sm, md } = useResponsiveness();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, accessToken } = useSelector((state) => state.login);
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleLogin = (values) => {
    dispatch(login(values));
  };
  console.log('accessToken', accessToken);
  useEffect(() => {
    if (accessToken) {
      if (role === 'signatory') {
        navigate('/signatory-dashboard');
      } else if (role === 'approver') {
        navigate('/approver-dashboard');
      } else if (role === 'creator') {
        navigate('/home');
      }
    }
  }, [accessToken, navigate]);

  return (
    <Row>
      <Col
        md={{ span: 12 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
        style={{
          position: 'relative',
          width: '100%',
          objectFit: 'cover',
          height: xs || sm ? '40vh' : '100vh'
        }}
      >
        {(xs || sm) && (
          <img
            src={granules_logo_mobile}
            style={{
              height: xs ? '2rem' : '3rem',
              width: xs ? '6rem' : '7rem',
              objectFit: 'contain',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              left: '20%',
              top: '10%'
            }}
          />
        )}
        <img
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            zIndex: -1
          }}
          src={left_image}
        />
        <img
          src={leftInside_image}
          style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: xs ? '50%' : '55%',
            height: xs ? '60%' : '70%',
            width: '100%'
          }}
          alt="Left Inside Image"
        />
        {!xs && !sm && (
          <div
            style={{
              position: 'absolute',
              margin: '1rem',
              top: 0,
              opacity: 0.8
            }}
          >
            <Typography.Title
              level={2}
              style={{ color: 'white', fontSize: md && '1.2rem' }}
            >
              Manage PIF approvals
            </Typography.Title>
            <Typography.Text
              style={{ fontSize: md ? '1rem' : '1.2rem', color: 'white' }}
            >
              Streamline PIF approvals for FDs and APIs with intuitive workflows
            </Typography.Text>
          </div>
        )}
      </Col>
      <Col
        style={{
          position: 'relative',
          width: '100%'
        }}
        md={{ span: 12 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <Form
          form={form}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: !xs && !sm && 'center',
            alignItems: !xs && !sm && 'center',
            width: '100%',
            height: xs || sm ? '60vh' : '100vh'
          }}
          onFinish={handleLogin}
        >
          {!xs && !sm && (
            <img
              src={granules_logo}
              style={{ height: '4rem', width: '12rem', objectFit: 'contain' }}
            />
          )}
          <div
            style={{
              marginTop: '2rem',
              width: '100%',
              paddingInline: xs ? '1rem' : '2rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <Typography.Title
              level={4}
              style={{
                fontSize: '1.4rem',
                color: '#0098da',
                textAlign: (xs || sm) && 'center'
              }}
              className="sfprotext-regular"
            >
              {"Let's get started"}
            </Typography.Title>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Email is required'
                },
                {
                  pattern: emailPattern,
                  message: 'Please enter a valid email address'
                }
              ]}
              style={{ padding: 0, margin: 0 }}
            >
              <div className={styles.input_field}>
                <label>Email</label>
                <Input
                  autoFocus
                  onChange={(e) =>
                    setPayload({ ...payload, email: e.target.value })
                  }
                  value={payload.email}
                  allowClear
                  placeholder="Enter your email id"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Password is required'
                }
              ]}
              className={styles.input_field}
              style={{ padding: 0, margin: 0 }}
            >
              <div className={styles.input_field}>
                <label>Password</label>
                <Input.Password
                  onChange={(e) =>
                    setPayload({ ...payload, password: e.target.value })
                  }
                  value={payload.password}
                  placeholder="Enter your password"
                />
              </div>
            </Form.Item>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Checkbox>Remember me</Checkbox>
              <Typography.Link
                style={{
                  fontFamily: 'Cerebri Sans, SFProText Regular, sans-serif'
                }}
                onClick={() => navigate('/forget-password')}
              >
                Forget password
              </Typography.Link>
            </div>
            <Form.Item style={{ padding: 0, margin: 0 }}>
              <div
                style={{
                  marginTop: '1rem',
                  width: '100%',
                  display: 'flex',
                  justifyContent: (xs || sm) && 'center'
                }}
              >
                <Button
                  htmlType="submit"
                  style={{
                    float: 'left',
                    width: xs && '100%',
                    borderRadius: '0.5rem'
                  }}
                  type="primary"
                  disabled={loading}
                  className="sfprotext-regular"
                >
                  Sign in
                  <LoginOutlined />
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default LoginPage;
