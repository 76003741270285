import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import {
  Form,
  Col,
  Row,
  Card,
  Divider,
  Input,
  Upload,
  // message,
  Spin
} from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import CustomLayout from '@/common/Layout';
import Step_3 from './../../images/Step_3.svg';
import CustomButton from '@/common/CustomButton';
import FormItem from 'common/FormItem';
import FormData from 'form-data';
import { history } from 'app/history';

const { TextArea } = Input;
const { Dragger } = Upload;

const uploadDocuments = ({
  PIF_form_id,
  setPageNum,
  uploadAttachments_formLoading,
  uploadAttachments_form,
  getFormDetailsByIdData,
  delete_uploaded_docs,
  deleteDocsLoading,
  getFormDetailsById_form,
  getFormDetailsByIdLoading
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  //const upload_Edit = localStorage.getItem('upload_Edit');

  const [formData1, setFormData1] = useState({});
  const [fileList, setFileList] = useState([]);
  //const [fileListLength, setFileListLength] = useState();
  //const [doc, setDoc] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      message: getFormDetailsByIdData?.message
    });
  }, [getFormDetailsByIdData]);

  const handleFormDataOnChange = (slug, value) => {
    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const uploadButton = (
    <div>
      <UploadOutlined />
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        Click or drag file to this area to upload
      </p>
    </div>
  );

  // useEffect(() => {
  //   setFileListLength(fileList?.length);
  // }, [fileList, uploadButton]);

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const normFile = (e) => {
    // setDoc(e?.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = () => {
    /* if (fileList.length === 0) {
      message.error('Please upload at least one document');
    } else { */
    let formdata = new FormData();

    formdata.append('pif_id', PIF_form_id);
    formdata.append('edit_type', 'mandatory');
    formdata.append(
      'message',
      formData1?.message === undefined
        ? getFormDetailsByIdData?.message
        : formData1?.message
    );

    for (let i in fileList) {
      formdata.append(
        'attachments',
        fileList?.length === 0 ? '' : fileList[i]?.originFileObj
      );
    }

    dispatch(uploadAttachments_form(formdata)).then((response) => {
      if (response?.payload?.success) {
        localStorage.setItem('upload_Edit', true);
        setPageNum(4);
      }
    });
    // }
  };

  const handleDeleteUploadedDoc = (id) => {
    console.log('id', id); //12
    const payload = {
      id: id,
      edit_type: 'mandatory'
    };
    let payload_getById = {
      pif_id: PIF_form_id
    };
    console.log('payload', payload);
    dispatch(delete_uploaded_docs({ payload })).then((response) => {
      if (response?.payload?.success) {
        //setPageNum(3);
        dispatch(getFormDetailsById_form(payload_getById)).then((response) => {
          if (response.payload.success) {
            console.log('response.payload', response.payload);
            //setTempDocList(response?.payload?.data?.attachments);
          }
        });
      }
    });
  };
  console.log('attachments', getFormDetailsByIdData?.attachments);
  return (
    <CustomLayout sider={false}>
      <Spin spinning={getFormDetailsByIdLoading}>
        <div className={styles.stepper_flow}>
          <img src={Step_3} alt="Step_2" />
        </div>
        <div className={styles.homepage_container}>
          <div className={styles.lowerCard_container}>
            <Card hoverable className={`${styles.lower_card_container} mt-3`}>
              <Form
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Row gutter={0} className={styles.row_container}>
                  <Col span={12}>
                    <div className={styles.approver_container}>
                      <div className={styles.approver_title}>Attachments</div>
                      <div className={`${styles.dragger}`}>
                        <FormItem
                          name="files"
                          label=""
                          rules={[
                            {
                              validator: async (_, value) => {
                                console.log(value);
                                if (fileList.length > 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'This is a required field'
                                  );
                                }
                              }
                            }
                          ]}
                          className={styles.upload_styles}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                        >
                          <Dragger
                            className={styles.upload_styles}
                            beforeUpload={() => false}
                            customRequest={({ onChange }) =>
                              setTimeout(() => {
                                onChange('ok', null);
                              }, 0)
                            }
                            action="false"
                            listType="text" // Changed to 'text' to remove the preview thumbnail
                            fileList={fileList}
                            onChange={handleChange}
                          >
                            {fileList.length >= 8 ? null : uploadButton}
                          </Dragger>
                        </FormItem>
                      </div>
                      <div>
                        {getFormDetailsByIdData?.attachments?.length > 0 ? (
                          <>
                            <div
                              style={{
                                fontWeight: 600,
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontSize: '12px',
                                marginBottom: '8px',
                                flexDirection: 'row',
                                fontFamily: 'SFProText Bold',
                                marginTop: '20%'
                              }}
                            >
                              <div className={`${styles.upload_title}`}>
                                <span> Uploaded documents</span>
                              </div>
                              <div
                                className={styles.uploadedDocumentsContainer}
                              >
                                {getFormDetailsByIdData?.attachments?.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={styles.attached_container}
                                    >
                                      <span>
                                        <a
                                          className={`${styles.uploadedDoc_style}`}
                                          href={item?.fileUrl}
                                          download
                                        >
                                          {item?.originalName}
                                        </a>
                                      </span>
                                      <span>
                                        <DeleteOutlined
                                          loading={deleteDocsLoading}
                                          className={`${styles.uploadedDoc_icon_style}`}
                                          onClick={() =>
                                            handleDeleteUploadedDoc(item.id)
                                          }
                                        />
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </Col>

                  <div className={`${styles.parent_vertical}`}>
                    <div className={styles.vertical_divider} />
                  </div>

                  <Col span={11}>
                    <div className={styles.approver_container}>
                      <div className={styles.approver_title}>Message</div>
                      <div>
                        <FormItem
                          label=""
                          name="message"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                        >
                          <div className={`${styles.textarea}`}>
                            <TextArea
                              defaultValue={getFormDetailsByIdData?.message}
                              className={styles.text_area}
                              rows={4}
                              placeholder={'Enter message'}
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'message',
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </FormItem>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Divider className={styles.divider_style} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start'
                    }}
                  >
                    <CustomButton
                      className={styles.back_btn_style}
                      onClick={() => setPageNum(2)}
                    >
                      Go Back
                    </CustomButton>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end'
                    }}
                  >
                    <CustomButton
                      className={styles.back_btn_style}
                      onClick={() => history.push(`/home`)}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      loading={uploadAttachments_formLoading}
                      type="primary"
                      className={styles.post_btn_style}
                      onClick={() => onFinish()}
                    >
                      Preview
                    </CustomButton>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </Spin>
    </CustomLayout>
  );
};

export default uploadDocuments;
