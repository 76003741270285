import React from 'react';
import { Form, Input, Table, Space, Select } from 'antd';
import styles from './globalFPM_table.module.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';

const { Option } = Select;

const GlobalFinancialPerformanceMetrics_table = ({
  dataSource_GFPM,
  setDataSource_GFPM,
  generateKey_gfpm,
  //storedData_gfpm,
  dropDown_gfpm_itemsData,
  debounceGfpm_items
  //edit
}) => {
  console.log('dataSource_GFPM$$$$$$$$$$$: ', dataSource_GFPM);

  // Function to add a new row
  const addRow = () => {
    const newData = {
      key: generateKey_gfpm(),
      name: '',
      value: ''
      //is_active: true
    };
    setDataSource_GFPM([...dataSource_GFPM, newData]);
  };

  // Function to delete a row by key
  const deleteRow = (key) => {
    const updatedData = dataSource_GFPM?.filter((item) => item.key !== key);
    setDataSource_GFPM(updatedData);

    // const newData = [...dataSource_GFPM];
    // const index = newData.findIndex((item) => key === item.key);
    // if (index > -1) {
    //   newData.splice(index, 1); // Remove the item from the array
    //   setDataSource_GFPM(newData); // Update the state with the modified array
    // }
    //----------------------------------------------

    // console.log('deleteKey: ', key);

    // // Create a copy of the original data source
    // const updatedDataSource_GFPM = [...dataSource_GFPM];

    // // Find the index of the object with the specified key
    // const index = updatedDataSource_GFPM.findIndex((item) => item.key === key);

    // // If the object with the specified key is found, update its is_active property
    // if (index !== -1) {
    //   updatedDataSource_GFPM[index].is_active = false;

    //   // Update the state with the modified data source
    //   setDataSource_GFPM(updatedDataSource_GFPM);
    // } else {
    //   console.log('Object with key', key, 'not found in dataSource_GFPM.');
    // }
  };

  // const headerStyle = () => ({
  //   style: { backgroundColor: '#65bade44', padding: '0.5rem' }
  // });

  const columns = [
    {
      title: 'Global Financial Performance Metrics',
      dataIndex: 'name',
      // onHeaderCell: headerStyle,
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          className={`${styles.select_styles}`}
        >
          <Select
            //disabled={edit === 'optional'}
            showSearch
            onSearch={debounceGfpm_items}
            placeholder="Select Global Financial Performance Metrics"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.children
                ?.toString()
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            defaultValue={record?.name}
            onChange={(value) => handleCellChange(record.key, 'name', value)}
          >
            {dropDown_gfpm_itemsData?.map((item, index) => {
              return (
                <Option value={item?.name} key={index}>
                  {item?.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      )
    },
    {
      title: '',
      dataIndex: 'value',
      // onHeaderCell: headerStyle,
      render: (_, record) => (
        <FormItem
          // name={['global_FPM_vlue', record.key]}
          rules={[{ required: true, message: 'This is required field' }]}
          // className="margin-0"
        >
          <Input
            // type="number"
            // step="0.01"
            // min={0}
            //disabled={edit === 'optional'}
            // className="border-none"
            placeholder="Click to add"
            value={record.value}
            onChange={(e) =>
              handleCellChange(record.key, 'value', e.target.value)
            }
          />
        </FormItem>
      )
    },

    {
      title: 'Action',
      dataIndex: 'action',
      // onHeaderCell: headerStyle,
      render: (_, record) => (
        <FormItem
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          // className="margin-0"
        >
          <Space
            size="middle"
            style={{
              display: 'flex',
              /*  justifyContent: 'center' */ paddingLeft: '16px'
            }}
          >
            <PlusOutlined
              className={`${styles.plusIcon}`}
              onClick={/* edit === 'optional' ? '' : */ addRow}
            />

            {dataSource_GFPM?.length > 1 ? (
              <DeleteOutlined
                className={`${styles.deleteIcon}`}
                onClick={() =>
                  /* edit === 'optional' ? '' : */ deleteRow(record.key)
                }
              />
            ) : null}
          </Space>
        </FormItem>
      )
    }
  ];

  // Function to handle changes in cell values
  const handleCellChange = (key, dataIndex, value) => {
    // console.log('key', key);
    // console.log('dataIndex', dataIndex);
    // console.log('value', value);
    const newData = [...dataSource_GFPM];
    console.log('newData', newData);
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData[index] = { ...item, [dataIndex]: value };
      setDataSource_GFPM(newData);
    }
  };

  // const filteredDataSource_GFPM = dataSource_GFPM?.filter(
  //   (item) => item.is_active
  // );

  return (
    <div>
      <Form>
        <Table
          dataSource={dataSource_GFPM}
          // {
          //   storedData_gfpm === 'true'
          //     ? filteredDataSource_GFPM
          //     : dataSource_GFPM
          // } //{dataSource_GFPM}
          columns={columns}
          pagination={false}
          scroll={{
            x: 900
          }}
          className={`${styles.table_styles}`}
        />
      </Form>
    </div>
  );
};

export default GlobalFinancialPerformanceMetrics_table;
